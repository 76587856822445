import * as turf from '@turf/turf'

import { axiosHub } from '../../config/axiosHub.config'
import { HubAreaRequest } from './contract/request/HubArea.request'

const createBulk = async (bulk: HubAreaRequest[]) => {
  const { data } = await axiosHub.post<void>('/api/v1/land/areas/bulk', bulk)
  return data
}

const deleteById = async (areaId: string) => {
  const { data } = await axiosHub.delete(`/api/v1/land/areas/${areaId}`)

  return data
}

const update = async (areaId: string, body: HubAreaRequest) => {
  const { data } = await axiosHub.put(`api/v1/land/areas/${areaId}`, body)
  return data
}
export type THubAreaNdvi = {
  referenceDate: string
  min: number
  mean: number
  max: number
}
const getAreaNdvi = async (areaId: string) => {
  const { data } = await axiosHub.get<THubAreaNdvi[]>(
    `/api/v1/land/areas/ndvi?areaId=${areaId}&minReferenceDate=2017-01-01T00:00:00.000Z`
  )
  return data
}

export type THubArea = {
  id: string
  name: string
  boundary: turf.Geometry
}

const getAreas = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubArea[]>(`/api/v1/land/areas?propertyId=${propertyId}`)
  return data
}

export const areas = { createBulk, deleteById, update, getAreas, getAreaNdvi }
