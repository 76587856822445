import { colors } from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

export const CustomMenu = styled(Menu)<MenuProps>(() => ({
  marginTop: '2.5rem',
  fontSize: '0.8125rem',
  '& .MuiList-root': {
    backgroundColor: colors.grey[900],
    color: 'rgba(255, 255, 255, 0.7)',
    '& .MuiMenuItem-root:hover': {
      backgroundColor: colors.grey[900],
      color: '#fff'
    }
  },
  '& .MuiTypography-root': {
    fontSize: '0.875rem'
  }
}))
