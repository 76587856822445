/**
 * Generates the text needed for the user to understand the rule strategy
 *
 * @param {boolean} isFirstOfKind - If the displayed rule is the first of its group (of permission or restriction)
 *
 * @param {boolean} isInverted - If the rule is a restriction
 * @returns {string} The text to be displayed
 *
 * @example
 * ```js
 * generateRuleHelperText(true, true)  // 'Bloquear quando'
 * ```
 */
export function generateRuleHelperText(
  isFirstOfKind: boolean,
  isInverted: boolean | undefined
): string {
  if (isFirstOfKind) {
    if (isInverted) {
      return 'Bloquear quando'
    }
    return 'Permitir quando'
  }

  if (isInverted) {
    return 'E'
  }

  return 'Ou'
}
