export enum BENDER_TASK_STATUS {
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export enum BENDER_TASK_TYPE {
  SEGMENTATION_REVIEW = 'SEGMENTATION_REVIEW',
  PROFILE_REVIEW = 'PROFILE_REVIEW',
  CREATE_STORAGE_FACILITIES_REVIEW = 'CREATE_STORAGE_FACILITIES_REVIEW',
  DETECT_SEASON_REVIEW = 'DETECT_SEASON_REVIEW',
  FISCAL_COMPLIANCE_REVIEW = 'FISCAL_COMPLIANCE_REVIEW',
  CRIMINAL_COMPLIANCE_REVIEW = 'CRIMINAL_COMPLIANCE_REVIEW',
  LABOR_COMPLIANCE_REVIEW = 'LABOR_COMPLIANCE_REVIEW',
  ENVIRONMENTAL_COMPLIANCE_REVIEW = 'ENVIRONMENTAL_COMPLIANCE_REVIEW',
  CONSENT_REVIEW = 'CONSENT_REVIEW',
  COLLECTOR_EXCEPTION_REVIEW = 'COLLECTOR_EXCEPTION_REVIEW',
  IRPF_REVIEW = 'IRPF_REVIEW'
}

export enum BENDER_EXECUTION_STATUS {
  RUNNING = 'RUNNING',
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING'
}

export enum BENDER_CONSENT_TYPE {
  CPR_ACCESS = 'CPR_ACCESS',
  SCR_ACCESS = 'SCR_ACCESS',
  UNKNOWN = 'UNKNOWN'
}

export enum BENDER_CONSENT_STATUS {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}
