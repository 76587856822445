import { Add } from '@mui/icons-material'
import {
  THubFormFieldDefinition,
  THubFormConditionsDefinition,
  ConditionAction,
  ConditionExpression
} from '@services'
import { Button, Modal, Typography } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
const { Title } = Typography

import { MainVerticalBox, HorizontalBox, VerticalBox } from '../../../components'
import { ExpressionsTable, ActionsTable } from '../tables'

type TEditConditionModalPage = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  formFields: THubFormFieldDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
  formConditions: THubFormConditionsDefinition[]
  record: AnyObject | null
}

export const EditConditionModal: React.FC<TEditConditionModalPage> = ({
  setIsModalOpen,
  isModalOpen,
  formFields,
  setFormConditions,
  formConditions,
  record
}) => {
  const [expressions, setExpressions] = useState<ConditionExpression[]>([])
  const [trueActions, setTrueActions] = useState<ConditionAction[]>([])
  const [falseActions, setFalseActions] = useState<ConditionAction[]>([])

  useEffect(() => {
    if (isModalOpen) {
      setExpressions(formConditions?.[record?.key]?.expressions)
      setTrueActions(
        formConditions?.[record?.key]?.actions?.filter(
          (action: ConditionAction) => !!action.whenEvaluator
        )
      )
      setFalseActions(
        formConditions?.[record?.key]?.actions?.filter(
          (action: ConditionAction) => !action.whenEvaluator
        )
      )
    } else {
      setExpressions([])
      setTrueActions([])
      setFalseActions([])
    }
  }, [isModalOpen])

  const handleOk = () => {
    if (!!record && record.key >= 0) {
      const newData: THubFormConditionsDefinition[] = [...formConditions]
      newData[record.key].actions = trueActions.concat(falseActions)
      newData[record.key].expressions = expressions

      for (let i = 0; i < newData[record.key]?.expressions.length; i += 1) {
        if (
          newData[record.key]?.expressions[i]?.operation == 'present' ||
          newData[record.key]?.expressions[i]?.operation == 'blank'
        )
          newData[record.key].expressions[i].value = ''
      }
      setFormConditions(newData)
    }
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleAddExpression = () => {
    const newData: ConditionExpression = {
      fieldIdentifier: '',
      operation: '',
      value: ''
    }
    setExpressions([...expressions, newData])
  }

  const handleAddFalseAction = () => {
    const newData: ConditionAction = {
      fieldIdentifier: '',
      type: 'hide',
      whenEvaluator: false
    }
    setFalseActions([...falseActions, newData])
  }

  const handleAddTrueAction = () => {
    const newData: ConditionAction = {
      fieldIdentifier: '',
      type: 'hide',
      whenEvaluator: true
    }
    setTrueActions([...trueActions, newData])
  }

  return (
    <Modal
      width={window.innerWidth - 32 > 1200 ? 1200 : window.innerWidth - 32}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={'Cancelar'}>
      <VerticalBox>
        <Title level={3} style={{ color: '#212121' }}>
          {`Edite a condição: ${record?.name}`}
        </Title>
        <MainVerticalBox style={{ height: window.innerHeight - 282 }}>
          <Button style={{ marginBottom: '16px' }} onClick={handleAddExpression} type="primary">
            <Add />
          </Button>
          <ExpressionsTable
            expressions={expressions}
            setExpressions={setExpressions}
            formFields={formFields}
          />
          <HorizontalBox style={{ gap: '32px', alignItems: 'start' }}>
            <MainVerticalBox>
              <HorizontalBox>
                <Title level={5} style={{ color: '#2E7E52' }}>
                  {'Se condição VERDADEIRA:'}
                </Title>
                <Button
                  style={{ marginBottom: '16px' }}
                  onClick={handleAddTrueAction}
                  type="primary">
                  <Add />
                </Button>
              </HorizontalBox>
              <ActionsTable
                style={{ borderColor: 'green' }}
                actions={trueActions}
                setActions={setTrueActions}
                formFields={formFields}
              />
            </MainVerticalBox>
            <MainVerticalBox>
              <HorizontalBox>
                <Title level={5} style={{ color: '#902E2E' }}>
                  {'Se condição FALSA:'}
                </Title>
                <Button
                  style={{ marginBottom: '16px' }}
                  onClick={handleAddFalseAction}
                  type="primary">
                  <Add />
                </Button>
              </HorizontalBox>
              <ActionsTable
                style={{ borderColor: 'red' }}
                actions={falseActions}
                setActions={setFalseActions}
                formFields={formFields}
              />
            </MainVerticalBox>
          </HorizontalBox>
        </MainVerticalBox>
      </VerticalBox>
    </Modal>
  )
}
