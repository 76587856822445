import { colors } from '@mui/material'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    discreet: true
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.amber[500],
      light: colors.amber[200],
      dark: colors.amber[700]
    }
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'subtitle1' },
          style: {
            fontSize: '1rem',
            color: colors.grey[700]
          }
        },
        {
          props: { variant: 'h1' },
          style: {
            fontSize: '2.5rem',
            color: colors.grey[900],
            fontWeight: 600
          }
        },
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '2rem',
            color: colors.grey[900],
            fontWeight: 600
          }
        },
        {
          props: { variant: 'h3' },
          style: {
            fontSize: '1.5rem',
            color: colors.grey[900],
            fontWeight: 600
          }
        },
        {
          props: { variant: 'h4' },
          style: {
            fontSize: '1.25rem',
            color: colors.grey[900],
            fontWeight: 600
          }
        },
        {
          props: { variant: 'h5' },
          style: {
            fontSize: '1.125rem',
            color: colors.grey[900],
            fontWeight: 500
          }
        },
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '1rem',
            color: colors.grey[900],
            fontWeight: 400
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          borderRadius: '12px',
          fontSize: '0.8125rem'
        }
      },
      variants: [
        {
          props: { color: 'success', variant: 'contained' },
          style: {
            backgroundColor: colors.green[700],
            color: '#fff',
            '&:hover': {
              backgroundColor: colors.green[800]
            }
          }
        },
        {
          props: { color: 'error', variant: 'contained' },
          style: {
            backgroundColor: colors.red[700],
            color: '#fff',
            '&:hover': {
              backgroundColor: colors.red[800]
            }
          }
        },
        {
          props: { variant: 'discreet' },
          style: {
            height: '2rem',
            gap: '0.25rem',
            fontSize: '0.75rem',
            color: 'rgba(255, 255, 255, 0.9)',
            backgroundColor: colors.blueGrey[300],
            '&:hover': {
              backgroundColor: colors.blueGrey[200]
            }
          }
        },
        {
          props: { variant: 'discreet', color: 'info' },
          style: {
            backgroundColor: colors.blue[500],
            '&:hover': {
              backgroundColor: colors.blue[400]
            }
          }
        },
        {
          props: { variant: 'discreet', color: 'warning' },
          style: {
            color: 'rgba(0, 0, 0, 0.6)',
            backgroundColor: colors.amber[500],
            '&:hover': {
              backgroundColor: colors.amber[400]
            }
          }
        },
        {
          props: { variant: 'discreet', color: 'error' },
          style: {
            backgroundColor: colors.red[500],
            '&:hover': {
              backgroundColor: colors.red[400]
            }
          }
        },
        {
          props: { variant: 'discreet', color: 'success' },
          style: {
            backgroundColor: colors.green[500],
            '&:hover': {
              backgroundColor: colors.green[400]
            }
          }
        },
        {
          props: { variant: 'discreet', disabled: true },
          style: {
            backgroundColor: colors.grey[200]
          }
        }
      ]
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '12px',
            overflow: 'hidden',
            width: 'max-content',
            maxWidth: 'calc(100vw - 4rem)'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
          fontWeight: 500,
          padding: '1rem 2rem',
          display: 'flex'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem',
          display: 'flex'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '16rem'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px'
        }
      }
    }
  }
})
