import { CustomDropzoneContent } from '@/pages/bender/Compliance/components/Sidebar/components/CreateRecordForm/components/CustomDropzoneContent'
import { IRecordCandidate } from '@/pages/bender/Compliance/providers/ComplianceContext'
import { OrNull } from '@/utils/OrNull'
import { Dropzone, FlexBox } from '@components'
import { OpenInNew } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import DatePicker from 'react-datepicker'

interface ICreateRecordFormViewProps {
  handleFormChange: (key: keyof IRecordCandidate, value: unknown) => void
  handleOpenNewTab: () => void
  documentStatusOptions: Readonly<string[]>
  complianceRecord: OrNull<IRecordCandidate>
}

export const CreateRecordFormView = (props: ICreateRecordFormViewProps) => {
  const { handleFormChange, handleOpenNewTab, documentStatusOptions = [], complianceRecord } = props

  return (
    <FlexBox
      vertical
      sx={{
        alignItems: 'flex-start',
        gap: '1rem'
      }}>
      <Typography variant="h5">Criar Registro de Compliance</Typography>

      <FlexBox>
        <FormControl sx={{ width: '100%', flexGrow: 1 }} variant="standard">
          <Typography variant="subtitle1">Tipo de Registro</Typography>
          <Typography>{complianceRecord.recordType}</Typography>
        </FormControl>
        <IconButton color="info" disabled={!complianceRecord.recordType} onClick={handleOpenNewTab}>
          <OpenInNew />
        </IconButton>
      </FlexBox>

      <FormControl sx={{ width: '100%' }} variant="standard">
        <InputLabel>Status do Documento</InputLabel>
        <Select
          value={complianceRecord.documentStatus || ''} // empty string is used to indicate not selected value
          label="Status do Documento"
          onChange={e => handleFormChange('documentStatus', e.target.value)}>
          {documentStatusOptions.map(status => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={complianceRecord.documentExpirationDate}
        onChange={date => handleFormChange('documentExpirationDate', date)}
        customInput={
          <TextField sx={{ width: '100%' }} variant="standard" label="Data de Validade" />
        }
      />

      <Dropzone
        fileObjects={[]}
        filesLimit={1}
        acceptedFiles={['.pdf']}
        content={<CustomDropzoneContent file={complianceRecord.file || undefined} />}
        onAdd={newFiles => handleFormChange('file', newFiles[0])}
        maxFileSize={50e6} /* 50MB */
        showAlerts={false}
      />
    </FlexBox>
  )
}
