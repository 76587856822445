import { TSelectedRegion } from '@/services/RadarApi'
import { Box, Typography, TextField } from '@mui/material'
import { useState, Dispatch, SetStateAction } from 'react'

export const LngLatInput = ({
  setSelectedRegion
}: {
  setSelectedRegion: Dispatch<SetStateAction<TSelectedRegion>>
}) => {
  const [latValue, setLatValue] = useState('')
  const [lngValue, setLngValue] = useState('')
  const [latError, setLatError] = useState(true)
  const [lngError, setLngError] = useState(true)

  const submitCoordinates = () => {
    if (!latError && !lngError)
      setSelectedRegion({
        latLng: { lat: parseFloat(latValue), lng: parseFloat(lngValue) },
        geometry: undefined
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        gap: '12px',
        width: '250px',
        height: 'fit-content'
      }}>
      <TextField
        label="Latitude"
        variant="outlined"
        size="small"
        error={latError && latValue !== ''}
        helperText={latError && latValue !== '' ? 'Latitude inválida' : undefined}
        value={latValue}
        onChange={e => {
          setLatValue(e.target.value)
        }}
        onBlur={() => {
          const value = parseFloat(latValue)
          if (latValue !== '' && !isNaN(value) && (value >= -90 || value <= 90)) setLatError(false)
        }}
        InputProps={{ style: { width: '250px', height: '35px' } }}
      />
      <TextField
        label="Longitude"
        variant="outlined"
        size="small"
        error={lngError && lngValue !== ''}
        helperText={lngError && lngValue !== '' ? 'Longitude inválida' : undefined}
        value={lngValue}
        onChange={e => {
          setLngValue(e.target.value)
        }}
        onBlur={() => {
          const value = parseFloat(lngValue)
          if (lngValue !== '' && !isNaN(value) && (value >= -90 || value <= 90)) setLngError(false)
        }}
        InputProps={{ style: { width: '250px', height: '35px' } }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px',
          gap: '8px',
          width: 'fit-content',
          height: '33px',
          borderRadius: '8px',
          background: !latError && !lngError ? '#111315' : '#868E96',
          '&:hover': {
            cursor: !latError && !lngError ? 'pointer' : 'no-drop'
          }
        }}
        onClick={submitCoordinates}>
        <Typography
          variant="subtitle1"
          sx={{
            color: '#FFFFFF'
          }}>
          Buscar região
        </Typography>
      </Box>
    </Box>
  )
}
