import { axiosHub } from '@/services/config/axiosHub.config'

import { ContactConsentsRequest, UpdateConsent } from './contract/request/ContactConsents.request'
import {
  ContactConsentDTO,
  ContactConsentsResponse
} from './contract/response/ContactConsents.response'

const getContactConsents = async (params: ContactConsentsRequest) => {
  const { data } = await axiosHub.get<ContactConsentsResponse>('/api/v1/contact-consents', {
    params
  })
  return data
}

const getContactConsentsById = async (contactConsentId: string) => {
  const { data } = await axiosHub.get<ContactConsentDTO>(
    `/api/v1/contact-consents/${contactConsentId}`
  )
  return data
}

const updateConsent = async ({ consentId, status, rejectionReason }: UpdateConsent) => {
  const { data } = await axiosHub.put<ContactConsentsResponse>(
    `/api/v1/contact-consents/${consentId}`,
    { status, rejectionReason }
  )
  return data
}

export const consents = {
  getContactConsents,
  updateConsent,
  getContactConsentsById
}
