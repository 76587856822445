import { Box } from '@mui/material'

interface IPageStyleRoot {
  children: React.ReactNode
}

export const PageStyleRoot = (params: IPageStyleRoot) => {
  const { children } = params
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}>
      {children}
    </Box>
  )
}
