import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { AxiosError } from 'axios'

import { renderEmptyState } from '@shared'

interface IDialogSaveFeedback {
  open: boolean
  handleClose: () => void
  createResponse: {
    error?: AxiosError | null
    isRequesting?: boolean
    success?: boolean
  }
  deleteResponse?: {
    error?: AxiosError | null
    isRequesting?: boolean
    success?: boolean
  }
}

export const DialogSaveFeedback = (props: IDialogSaveFeedback) => {
  const { open, handleClose, createResponse, deleteResponse } = props

  return (
    <Dialog open={open}>
      <DialogTitle>Registro de Alterações</DialogTitle>
      <DialogContent sx={{ flexDirection: 'column' }}>
        {renderEmptyState(
          createResponse.isRequesting || deleteResponse?.isRequesting,
          createResponse.error || deleteResponse?.error,
          'Salvando as alterações. Isto não deve demorar muito...'
        ) || <Typography>As alterações foram salvas com sucesso!</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="discreet">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
