import { MembershipRoleDTO } from '@/services/Hub/Authorization/contract/response/membership-role.response'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { HubApi } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { TData } from '../utils/authorization.utils'

export type TUseManageMembershipRoles = {
  membershipRoles: TData<MembershipRoleDTO[]>
  hasError: boolean
  availableRoles: RoleDTO[]
  handleRemoveRole: (membershipRoleId: string, onDelete?: () => void, onError?: () => void) => void
  handleAssignRoles: (roleIds: string[], onComplete?: () => void) => void
}

interface IUseManageMembershipRoles {
  membershipId: string
  roles: RoleDTO[] | undefined
}

export const useManageMembershipRoles = ({
  membershipId,
  roles
}: IUseManageMembershipRoles): TUseManageMembershipRoles => {
  const [membershipRoles, setMembershipRoles] = useState<TData<MembershipRoleDTO[]>>({
    data: undefined,
    loading: true
  })
  const [hasError, setHasError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fetchmembershipRoles = useCallback(() => {
    setMembershipRoles(old => ({ ...old, loading: true }))
    HubApi.Authorization.getMembershipRoles({
      membershipId
    })
      .then(res => {
        setMembershipRoles({
          data: res,
          loading: false
        })
      })
      .catch(() => {
        setHasError(true)
        setMembershipRoles({
          data: [],
          loading: false
        })
      })
  }, [membershipId])

  useEffect(() => {
    fetchmembershipRoles()
  }, [fetchmembershipRoles])

  const availableRoles: RoleDTO[] = useMemo(() => {
    if (!roles?.length) {
      return []
    }
    if (!membershipRoles.data?.length) {
      return roles
    }

    return roles.filter(
      role => !membershipRoles.data?.some(membershipRole => membershipRole.roleId === role.id)
    )
  }, [membershipRoles.data, roles])

  const handleRemoveRole = (
    membershipRoleId: string,
    onDelete?: () => void,
    onError?: () => void
  ) => {
    if (!membershipRoleId) return
    HubApi.Authorization.deleteMembershipRoles(membershipRoleId)
      .then(() => {
        setMembershipRoles(old => ({
          ...old,
          data: old.data?.filter(mRole => mRole.id !== membershipRoleId)
        }))
        enqueueSnackbar('Cargo removido com sucesso', { variant: 'success' })
        onDelete?.()
      })
      .catch(() => {
        onError?.()
        enqueueSnackbar('Erro ao remover cargo', { variant: 'error' })
      })
  }

  const handleAssignRoles = (roleIds: string[], onComplete?: () => void) => {
    if (!roleIds.length) return
    const membershipRolesToCreate = roleIds.map(roleId => ({
      membershipId,
      roleId
    }))
    HubApi.Authorization.postMembershipRoles(membershipRolesToCreate)
      .then(() => {
        fetchmembershipRoles()
        enqueueSnackbar('Cargos atribuídos com sucesso', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Erro ao atribuir cargos', { variant: 'error' })
      })
      .finally(() => {
        onComplete?.()
      })
  }

  return {
    membershipRoles,
    hasError,
    availableRoles,
    handleRemoveRole,
    handleAssignRoles
  }
}
