/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/material'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import React, { useRef, useEffect } from 'react'
import MapGl, { Layer, MapRef, Source } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

interface IModalMap {
  geojson: turf.helpers.FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  > | null
  addToSelectedAreas: (id: string) => void
  setSelectedAreas: (value: React.SetStateAction<string[]>) => void
  selectedAreas: string[]
  originAreaId: string
}

export const ModalMap = ({
  geojson,
  addToSelectedAreas,
  setSelectedAreas,
  selectedAreas,
  originAreaId
}: IModalMap) => {
  const mapRef = useRef<MapRef>(null)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = turf.bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 600,
      height: 300
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 10 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  useEffect(() => {
    if (mapRef.current && geojson) {
      console.log('points', getBoundsForPoints(geojson))
      mapRef.current?.flyTo({
        ...getBoundsForPoints(geojson),
        duration: 400
      })
    }
  }, [mapRef.current, geojson])

  const onMapClick = (
    e: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
    } & mapboxgl.EventData
  ) => {
    e.originalEvent.preventDefault()
    const clickedFeature = e.features ? e.features[0] : null
    const id = clickedFeature?.properties?.area_id
    if (id) {
      addToSelectedAreas(id as string)
    }
  }

  const getClickedLayerId = (long: number, lat: number): string | null => {
    const pt = turf.point([long, lat])
    let id: string | undefined | null = null
    geojson?.features?.forEach(feature => {
      if (feature && turf.booleanPointInPolygon(pt, feature.geometry as turf.Polygon)) {
        id = feature.properties?.area_id
      }
    })
    return id
  }

  // useEffect(() => {
  //   if (mapRef.current) {
  //     mapRef.current.getMap().off('click', 'fill-areas', onMapClick);
  //     mapRef.current.getMap().on('click', 'fill-areas', onMapClick);
  //   }
  // }, [selectedAreas, addToSelectedAreas]);
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        minWidth: '600px',
        width: '50vw',
        minHeight: '300px',
        maxHeight: '100%',
        border: '1px solid #CED4DA',
        borderRadius: '12px',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        padding: 0,
        overflow: 'hidden'
      }}>
      <MapGl
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken="pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg"
        doubleClickZoom={false}
        ref={mapRef}
        onClick={props => {
          // eslint-disable-next-line react/prop-types
          props.originalEvent.preventDefault()
          // eslint-disable-next-line react/prop-types
          const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
          if (id && id !== originAreaId) addToSelectedAreas(id)
        }}
        onLoad={() => {
          console.log(geojson)
          if (mapRef.current && geojson) {
            console.log('points', getBoundsForPoints(geojson))
            mapRef.current?.flyTo({
              ...getBoundsForPoints(geojson),
              duration: 400
            })
            // mapRef.current.getMap().on('click', 'fill-areas', (e) => {
            //   e.originalEvent.preventDefault();
            //   const clickedFeature = e.features ? e.features[0] : null;
            //   const id = clickedFeature?.properties?.area_id;
            //   if (id) {
            //     addToSelectedAreas(id as string);
            //   }
            // });
          }
        }}>
        {geojson && (
          <Source
            id={'areas'}
            type="geojson"
            // @ts-ignore
            data={geojson}>
            <Layer
              id={'layer-areas'}
              type={'line'}
              source={'areas'}
              paint={{
                'line-color': [
                  'case',
                  [
                    '!=',
                    ['index-of', ['get', 'area_id', ['properties']], ['literal', selectedAreas]],
                    -1
                  ],
                  '#5C7CFA',
                  ['==', ['get', 'area_id', ['properties']], ['literal', originAreaId]],
                  '#FCC419',
                  'white'
                ],
                'line-width': 2
              }}
            />
            <Layer
              id={'fill-areas'}
              type={'fill'}
              source={'areas'}
              paint={{
                'fill-color': [
                  'case',
                  [
                    '!=',
                    ['index-of', ['get', 'area_id', ['properties']], ['literal', selectedAreas]],
                    -1
                  ],
                  '#5C7CFA',
                  ['==', ['get', 'area_id', ['properties']], ['literal', originAreaId]],
                  '#FCC419',
                  'white'
                ],
                'fill-opacity': 0.1
              }}
            />
          </Source>
        )}
      </MapGl>
    </Box>
  )
}
