import { formatName } from '@/utils/formatName'
import { EllipsisText } from '@components'
import { Box, Typography } from '@mui/material'
import { MembershipDTO } from '@services'

export const MemberInformationContent = ({
  member,
  children
}: {
  member: MembershipDTO
  children?: React.ReactNode
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '4px'
      }}>
      <EllipsisText
        text={formatName(`${member.user.name} ${member.user.lastName}`)}
        customPlacement="top"
        maxLength={30}
        variant="body2"
        sx={{
          whiteSpace: 'nowrap'
        }}
      />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'nowrap'
        }}>
        {member.user.email}
      </Typography>
      {children}
    </Box>
  )
}
