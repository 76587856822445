import { CreateLaborDebtDTO } from '@/services/Hub/AssessmentsLabor'
import { LABOR_DEBTS_STATUS } from '@/services/Hub/enums/LaborDebts.enum'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildCreateLaborDebtDTO {
  record: IRecordCandidate
  documentUrl?: string
  canonicalProfileId: string
}

export const buildCreateLaborDebtDTO = (props: IBuildCreateLaborDebtDTO): CreateLaborDebtDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  const expirationDate = record.documentExpirationDate

  return {
    canonicalProfileId,
    creationType: 'USER_INPUT',
    expirationDate,
    referenceDate: new Date(),
    status: record.documentStatus as LABOR_DEBTS_STATUS,
    documentUrl
  }
}
