/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, colors, Box } from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import { Dispatch, SetStateAction } from 'react'
import ResizePanel from 'react-resize-panel-ts'
import { useParams } from 'react-router-dom'

import { CustomSidebarHeader } from '../../../../../components/customSidebarHeader'
import AreasList from '../AreasList/AreasListContainer'

interface ISidebar {
  handleBack: () => void
  geoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  setGeoJson: Dispatch<
    SetStateAction<
      | FeatureCollection<
          turf.helpers.Geometry | turf.helpers.GeometryCollection,
          turf.helpers.Properties
        >
      | null
      | undefined
    >
  >
  highlightedFeatId: string | null
  setHighlitedFeatId: Dispatch<SetStateAction<string | null>>
  editingIndex: number | null
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  cancelEditing: () => void
  removeArea: (index: number) => void
  header?: JSX.Element
  saveEditingArea: () => void
  isFeatureEditable: (feature: turf.Feature) => boolean
  onDoneButtonClick: () => void
  multipleSelectionMode: boolean
}

export const Sidebar = ({
  handleBack,
  geoJson,
  setGeoJson,
  highlightedFeatId,
  setHighlitedFeatId,
  editingIndex,
  setEditingIndex,
  cancelEditing,
  removeArea,
  header,
  saveEditingArea,
  isFeatureEditable,
  onDoneButtonClick,
  multipleSelectionMode
}: ISidebar) => {
  const { name } = useParams()

  return (
    <>
      {/* @ts-ignore */}
      <ResizePanel
        direction="e"
        handleClass="customHandle"
        borderClass="customResizeBorder"
        style={{
          minWidth: '350px',
          height: '100%'
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box
            sx={{
              width: '100%',
              overflow: 'scroll',
              bgcolor: colors.grey[100]
            }}>
            <CustomSidebarHeader
              onGoBack={handleBack}
              title={name || 'Não identificado'}
              subtitle={`${
                geoJson && geoJson.features.length
                  ? (
                      (100 * geoJson.features.filter(f => f.properties?.checked).length) /
                      geoJson.features.length
                    ).toLocaleString('pt-BR', { maximumFractionDigits: 1 })
                  : '-'
              }% de áreas verificadas`}
              sectionName="Áreas Detectadas"
            />
            <AreasList
              geoJson={geoJson}
              setGeoJson={setGeoJson}
              isFeatureEditable={isFeatureEditable}
              highlightedFeatId={highlightedFeatId}
              setHighlitedFeatId={setHighlitedFeatId}
              editingIndex={editingIndex}
              setEditingIndex={setEditingIndex}
              saveEditingArea={saveEditingArea}
              cancelEditing={cancelEditing}
              removeArea={removeArea}
              multipleSelectionMode={multipleSelectionMode}
            />
          </Box>
          <Box
            sx={{
              p: '20px',
              display: 'flex',
              justifyContent: 'center',
              borderTop: '2px solid #CED4DA'
            }}>
            <Button
              variant="contained"
              disableElevation
              color="success"
              sx={{ textTransform: 'capitalize', p: '7px 37px' }}
              onClick={onDoneButtonClick}>
              Finalizar Tarefa
            </Button>
          </Box>
        </Box>
      </ResizePanel>
    </>
  )
}
