import { axiosHub } from '../config/axiosHub.config'
import {
  ENVIRONMENTAL_DEBT_STATUS,
  ENVIRONMENTAL_EMBARGO_STATUS
} from './enums/EnvironmentalAssessment.enum'

export interface EnvironmentalEmbargoShortDTO {
  id: string
  documentUrl?: string
  status: ENVIRONMENTAL_EMBARGO_STATUS
}

export interface EnvironmentalDebtShortDTO {
  id: string
  documentUrl?: string
  status: ENVIRONMENTAL_DEBT_STATUS
}

export interface EnvironmentalAssessmentDTO {
  referenceDate?: string
  embargoes?: EnvironmentalEmbargoShortDTO
  debts?: EnvironmentalDebtShortDTO
}

export interface EnvironmentalDebtDTO {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  documentUrl?: string
  status: ENVIRONMENTAL_DEBT_STATUS
  referenceDate: string
  canonicalProfileId: string
  creationType: string
  modifiedByUserId?: string
}

export type CreateEnvironmentalDebtDTO = Omit<
  EnvironmentalDebtDTO,
  'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'modifiedByUserId'
>

export interface EnvironmentalEmbargoDTO {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  documentUrl?: string
  status: ENVIRONMENTAL_EMBARGO_STATUS
  expirationDate: string
  referenceDate: string
  canonicalProfileId: string
  creationType: string
  modifiedByUserId?: string
}

export type CreateEnvironmentalEmbargoDTO = Omit<
  EnvironmentalEmbargoDTO,
  'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'modifiedByUserId'
>

const getRequest = async (personProfileId: string) => {
  const { data } = await axiosHub.get<EnvironmentalAssessmentDTO>(
    `/api/v1/assessments/environmental?personProfileId=${personProfileId}`
  )
  return data
}

const postDebtRequest = async (body: CreateEnvironmentalDebtDTO) => {
  const { data } = await axiosHub.post<EnvironmentalDebtDTO>(
    '/api/v1/assessments/environmental/debts',
    body
  )
  return data
}

const postEmbargoRequest = async (body: CreateEnvironmentalEmbargoDTO) => {
  const { data } = await axiosHub.post<EnvironmentalEmbargoDTO>(
    '/api/v1/assessments/environmental/embargoes',
    body
  )
  return data
}

/**
 * Expects environmentalDebtId OR canonicalProfileId as params. If you send both, then the canonicalProfileId will be ignored.
 */
const deleteDebtRequest = async (params: {
  environmentalDebtId?: string
  canonicalProfileId?: string
}) => {
  if (!params.environmentalDebtId && !params.canonicalProfileId)
    throw new Error('Environmental Debt Id or Canonical Profile ID is required')

  const urlParams = params.environmentalDebtId
    ? { environmentalDebtId: params.environmentalDebtId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/environmental/debts', {
    params: urlParams
  })
  return data
}

/**
 * Expects environmentalEmbargoId OR canonicalProfileId as params. If you send both, then the canonicalProfileId will be ignored.
 */
const deleteEmbargoRequest = async (params: {
  environmentalEmbargoId?: string
  canonicalProfileId?: string
}) => {
  if (!params.environmentalEmbargoId && !params.canonicalProfileId)
    throw new Error('Environmental Embargo Id or Canonical Profile ID is required')

  const urlParams = params.environmentalEmbargoId
    ? { environmentalEmbargoId: params.environmentalEmbargoId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/environmental/embargoes', {
    params: urlParams
  })
  return data
}

export const AssessmentsEnvironmental = {
  getRequest,
  postDebtRequest,
  postEmbargoRequest,
  deleteDebtRequest,
  deleteEmbargoRequest
}
