import { axiosHub } from '../config/axiosHub.config'

export type IGetCustomer = {
  limit?: number
  page?: number
  sortAttribute?: string
  sortOrder?: string
  filterValue?: string
  organizationId: string
  enriched?: boolean
  personProfileId?: string
  customerId?: string
  assignedUserId?: string
  grouping?: string
  contactId?: string
  withLastIrpf?: boolean
  govRegCode?: string
}
const getCustomers = async ({
  limit = 10,
  page = 0,
  sortAttribute = 'name',
  sortOrder = 'ASC',
  filterValue,
  organizationId,
  enriched,
  personProfileId,
  assignedUserId,
  grouping,
  govRegCode
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IGetCustomer) => {
  const { data } = await axiosHub.get('/api/v1/customers', {
    params: {
      limit,
      page,
      sortAttribute,
      sortOrder,
      filterValue,
      organizationId,
      enriched,
      personProfileId,
      assignedUserId,
      grouping,
      govRegCode
    }
  })
  return data
}

export const Customer = {
  getCustomers
}
