import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  ModalProps,
  Typography,
  DialogTitle
} from '@mui/material'
import { useContext } from 'react'

import { ComplianceContext } from '../../providers/ComplianceContext'

interface IDialogSaveConfirmation extends Omit<ModalProps, 'onClose' | 'children'> {
  handleClose: () => void
  handleConfirm: () => void
}

export const DialogSaveConfirmation = (props: IDialogSaveConfirmation) => {
  const { handleClose, handleConfirm, ...restProps } = props
  const { recordCandidates, deleteRecords } = useContext(ComplianceContext)

  return (
    <>
      <Dialog onClose={handleClose} {...restProps}>
        {recordCandidates.length === 0 && deleteRecords.length === 0 ? (
          <>
            <DialogContent sx={{ flexDirection: 'column' }}>
              <Typography variant="h5" my="2rem">
                Nenhuma modificação de registro foi encontrada
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button sx={{ minWidth: '96px' }} variant="discreet" onClick={handleClose}>
                Ok
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Você deseja confirmar as seguintes alterações?</DialogTitle>
            <DialogContent sx={{ flexDirection: 'column' }}>
              <Typography variant="subtitle1" mb=".5rem">
                Criar Registros ({recordCandidates.length}):
              </Typography>
              {recordCandidates.length
                ? recordCandidates.map(record => (
                    <Typography key={record.index}>
                      {record.recordType} - {record.file.file.name}
                    </Typography>
                  ))
                : 'nenhum registro'}
              <Typography variant="subtitle1" mt="2rem" mb=".5rem">
                Excluir Registros ({deleteRecords.length}):
              </Typography>
              {deleteRecords.length
                ? deleteRecords.map(delRecord => (
                    <Typography key={delRecord.id}>{delRecord.id}</Typography>
                  ))
                : 'nenhum registro'}
            </DialogContent>
            <DialogActions>
              <Button
                variant="discreet"
                color="success"
                sx={{ minWidth: '128px' }}
                onClick={handleConfirm}>
                Confirmar
              </Button>
              <Button
                variant="discreet"
                color="warning"
                sx={{ minWidth: '128px' }}
                onClick={handleClose}>
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
