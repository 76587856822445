import { IGetPostPresignedS3Url } from '@/services/Hub/Assessments'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildGetUploadPresignedUrlDTO {
  personProfileId: string
  record: IRecordCandidate
}

export const buildGetUploadPresignedUrlDTO = (
  props: IBuildGetUploadPresignedUrlDTO
): IGetPostPresignedS3Url => {
  const { record, personProfileId } = props
  return {
    documentReferenceDate: new Date().toISOString(),
    mimetype: 'application/pdf',
    name: `${record.recordType.replace(/\W/g, '')}.pdf`,
    personProfileId,
    sizeBytes: record.file.file.size,
    type: 'JUDICIARY'
  }
}
