import { AvatarProps } from '@mui/material/Avatar'

export function stringToColor(string: string) {
  let hash = 0

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

function getLetterColor(backgroundColor: string) {
  // Calculate brightness of the background color
  const brightness =
    0.299 * parseInt(backgroundColor.slice(1, 3), 16) +
    0.587 * parseInt(backgroundColor.slice(3, 5), 16) +
    0.114 * parseInt(backgroundColor.slice(5, 7), 16)

  // Set letter color based on the background brightness
  return brightness > 128 ? '#212121' : '#fff'
}

export function stringAvatar(firstName = '?', lastName?: string): AvatarProps {
  const name = lastName ? `${firstName} ${lastName}` : firstName
  const initials = `${firstName[0]}${lastName ? lastName[0] : firstName.split(' ')?.[1]?.[0] ?? ''}`
  const backgroundColor = stringToColor(name)
  const letterColor = getLetterColor(backgroundColor)

  return {
    sx: {
      backgroundColor,
      color: letterColor
    },
    children: initials.toUpperCase()
  }
}
