import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { Box, Button, Typography } from '@mui/material'

interface THeaderProps {
  propertyImage?: string | null
  propertyName?: string | null
  handleRecoverTaskProgress: () => void
  handleSaveTaskProgress: () => void
}

export const Header = (props: THeaderProps) => {
  const { propertyImage, propertyName, handleRecoverTaskProgress, handleSaveTaskProgress } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '80px',
        height: '8vh',
        maxHeight: '80px',
        position: 'relative'
      }}>
      <Box sx={{ position: 'absolute', left: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '10px',
            alignItems: 'center'
          }}>
          {propertyImage && (
            <img
              src={propertyImage}
              alt="Property Image"
              style={{
                width: '7vh',
                height: '7vh',
                borderRadius: '10px'
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '16px',
                fontHeight: '24px'
                // flexBasis: '100%'
              }}>
              Propriedade
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '14px',
                fontHeight: '20px'
              }}>
              {propertyName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          right: '10px',
          gap: '10px'
        }}>
        <Button
          variant="contained"
          disableElevation
          color="inherit"
          onClick={() => {
            confirm('Deseja recuperar o progresso da revisão?') && handleRecoverTaskProgress()
          }}
          sx={{
            textTransform: 'capitalize',
            p: '7px 37px',
            color: '#000000',
            borderRadius: '50px'
          }}>
          <CloudDownloadIcon fontSize="small" sx={{ color: '#000000' }} />
          Recuperar
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="inherit"
          onClick={() => {
            confirm('Deseja salvar o progresso?') && handleSaveTaskProgress()
          }}
          sx={{
            textTransform: 'capitalize',
            p: '7px 37px',
            color: '#000000',
            borderRadius: '50px'
          }}>
          <FileUploadOutlinedIcon fontSize="small" sx={{ color: '#000000' }} />
          Salvar
        </Button>
      </Box>
    </Box>
  )
}
