import { LutienApi, ShortOrganizationDTO } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

import { TData } from '../utils/solutions.utils'

export type TUseSolutionsPage = {
  tarkenOrganizations: TData<ShortOrganizationDTO[]>
  hasError: boolean
}

export const useSolutionsPage = (): TUseSolutionsPage => {
  const [tarkenOrganizations, setTarkenOrganizations] = useState<TData<ShortOrganizationDTO[]>>({
    data: undefined,
    loading: true
  })

  const [hasError, setHasError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const updateTarkenOrganizations = useCallback(async () => {
    try {
      const res = await LutienApi.getOrganizations()
      setTarkenOrganizations({
        data: res,
        loading: false
      })
    } catch (error) {
      enqueueSnackbar('Erro ao carregar organizacoes do sistema', { variant: 'error' })
      setHasError(true)
    }
  }, [enqueueSnackbar])

  useEffect(() => {
    updateTarkenOrganizations()
  }, [updateTarkenOrganizations])

  return {
    tarkenOrganizations,
    hasError
  }
}
