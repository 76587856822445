import {
  TMongoOperatorKeys,
  TMongoOperators
} from '@/lib/AuthorizationForm/AuthorizationForm.constants'
import { NumericConditionDTO } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { forwardRef, useCallback, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import { TSaveValue } from '../ConditionContainer'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  prefix: string | undefined
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, prefix, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        prefix={prefix}
      />
    )
  }
)

interface INumericState {
  numberformat: number | undefined
}

interface INumericCondition {
  numericConditon: NumericConditionDTO
  saveValue: TSaveValue
  defaultOperator: TMongoOperatorKeys
  defaultValue: number | undefined
}

export const NumericCondition = ({
  numericConditon,
  saveValue,
  defaultOperator,
  defaultValue
}: INumericCondition) => {
  const [operator, setOperator] = useState<TMongoOperatorKeys>(defaultOperator)
  const [numericValue, setNumericValue] = useState<INumericState>({
    numberformat: defaultValue
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSaveValue = useCallback(
    debounce((value: number | undefined, op: string) => saveValue(value, op), 500),
    [saveValue]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: number | undefined = parseFloat(event.target.value)
    if (isNaN(value)) {
      value = undefined
    }
    setNumericValue({
      ...numericValue,
      [event.target.name]: value
    })
    debounceSaveValue(value, operator)
  }

  return (
    <Box
      className="numeric-condition"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px'
      }}>
      <FormControl required>
        <InputLabel>operador</InputLabel>
        <Select
          id="operator-selector"
          value={operator}
          onChange={e => {
            if (e.target.value in TMongoOperators) {
              setOperator(e.target.value as TMongoOperatorKeys)
              debounceSaveValue(numericValue.numberformat, e.target.value)
            }
          }}
          label={'operador'}>
          {numericConditon.config.allowedOperators.map((op, idx) => (
            <MenuItem value={op} key={idx}>
              {TMongoOperators[op]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl required>
        <TextField
          id="value"
          label="valor"
          value={numericValue.numberformat}
          onChange={handleChange}
          name="numberformat"
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumericFormatCustom as any,
            inputProps: {
              prefix: numericConditon.config.prefix?.concat(' ')
            }
          }}
        />
      </FormControl>
    </Box>
  )
}
