import { useParams, useNavigate } from 'react-router-dom'

import { Map } from './components/map/MapContainer'

export const SegmentationReviewView = () => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  if (taskId === undefined) {
    navigate(-1)
  }

  return (
    <>
      <Map taskId={taskId ? taskId : ''} closeMap={() => navigate(-1)} />
    </>
  )
}
