import { ExpandMore } from '@mui/icons-material'
import { Box, BoxProps, Collapse, IconButton } from '@mui/material'
import { useState } from 'react'

interface ICollapseContainer extends BoxProps {
  children: React.ReactNode
  titleComponent: React.ReactNode
}

export const CollapseContainer = (params: ICollapseContainer) => {
  const { children, titleComponent, ...props } = params
  const [openCollapse, setOpenCollapse] = useState(false)
  return (
    <Box
      className="collapse-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '10px',
        background: '#f9f9f9',
        borderRadius: '8px',
        height: 'fit-content'
      }}
      {...props}>
      <Box
        className="title-and-icon"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
        {titleComponent}
        <IconButton
          sx={{ color: 'black', padding: 0 }}
          onClick={() => {
            setOpenCollapse(!openCollapse)
          }}>
          <ExpandMore
            sx={{
              transform: !openCollapse ? 'rotate(0deg)' : 'rotate(180deg)'
            }}
            color="primary"
          />
        </IconButton>
      </Box>
      <Collapse
        className="collapse"
        in={openCollapse}
        timeout="auto"
        sx={{
          width: '100%',

          '& .MuiCollapse-wrapperInner': {
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '20px'
          },

          '& a': {
            color: '#3c78d8',
            textDecoration: 'underline'
          }
        }}>
        {children}
      </Collapse>
    </Box>
  )
}
