import axios from 'axios'
import { FileObject } from 'react-mui-dropzone'

export type ISignedPostUrl = {
  url: string
  fields: {
    Key: string
    bucket: string
    'X-Amz-Algorithm': string
    'X-Amz-Credential': string
    'X-Amz-Date': string
    Policy: string
    'X-Amz-Signature': string
  }
}

const uploadFileWithPresignedUrl = (signedUrl: ISignedPostUrl, file: FileObject) => {
  const formData = new FormData()
  Object.entries(signedUrl.fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('file', file.file)

  return axios.post(signedUrl.url, formData)
}

export const S3 = {
  uploadFileWithPresignedUrl
}
