import { Global, ThemeProvider } from '@emotion/react'
import Box from '@mui/material/Box'
import * as Sentry from '@sentry/react'
import { SnackbarProvider } from 'notistack'
import { Outlet } from 'react-router-dom'

import { Header } from './layout/header/HeaderContainer'
import { useAppSelector } from './redux/hooks'
import { RootState } from './redux/store'
import { globalStyle } from './styles/global'
import { theme } from './styles/theme'

const App = () => {
  const headerMode = useAppSelector((state: RootState) => state.header.mode)
  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)
  Sentry.setUser({ email: userInfo.email })

  return (
    <div id="App">
      <Global styles={globalStyle} />
      <Sentry.ErrorBoundary
        showDialog
        fallback={<p>An error has occurred</p>}
        beforeCapture={(scope, error) => {
          scope.setTag('errorBoundary', `Message: ${error?.message}`)
        }}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Header />
            <Box
              maxWidth={headerMode === 'none' ? '100vw' : 'xl'}
              sx={{
                m: headerMode === 'none' ? '0 auto' : '3rem auto 0 auto',
                height: headerMode === 'none' ? '100vh' : 'calc(100vh - 3rem)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Outlet />
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default Sentry.withProfiler(App)
