import { Box, BoxProps } from '@mui/material'

interface IMemberInformationRoot extends BoxProps {
  children: React.ReactNode
}

export const MemberInformationRoot = ({ children, ...props }: IMemberInformationRoot) => {
  return (
    <Box
      className="member-information-root"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        width: '300px',
        minWidth: '300px'
      }}
      {...props}>
      {children}
    </Box>
  )
}
