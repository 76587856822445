import { BENDER_TASK_TYPE } from '@services'

import { COMPLIANCE_TASK_TYPE } from '../enums/ComplianceTaskType'

export function getComplianceTaskType(benderTask: BENDER_TASK_TYPE) {
  if (benderTask === BENDER_TASK_TYPE.CRIMINAL_COMPLIANCE_REVIEW)
    return COMPLIANCE_TASK_TYPE.criminal
  if (benderTask === BENDER_TASK_TYPE.ENVIRONMENTAL_COMPLIANCE_REVIEW)
    return COMPLIANCE_TASK_TYPE.environmental
  if (benderTask === BENDER_TASK_TYPE.FISCAL_COMPLIANCE_REVIEW) return COMPLIANCE_TASK_TYPE.fiscal
  if (benderTask === BENDER_TASK_TYPE.LABOR_COMPLIANCE_REVIEW) return COMPLIANCE_TASK_TYPE.labor
}
