import { PageStyle } from '../PageStyle'
import { TPaginationParams } from '../PageStyle/Body'

interface IManageMembersView {
  children?: React.ReactNode
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  paginationParams: TPaginationParams
}

export const ManageMembersView = (props: IManageMembersView) => {
  const { children, handleSearchInputChange, paginationParams } = props

  return (
    <PageStyle.root>
      <PageStyle.header title="Membros" subtitle="Atribua cargos aos membros da organização">
        <PageStyle.SearchInput
          label="Procurar Membros"
          placeholder="Digite um termo para aplicar um filtro"
          onChange={handleSearchInputChange}
        />
      </PageStyle.header>
      <PageStyle.body paginationParams={paginationParams}>{children}</PageStyle.body>
    </PageStyle.root>
  )
}
