import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

interface IToggleButton {
  valueState: string
  setValueState: React.Dispatch<React.SetStateAction<string>>
  values: string[]
  msgValues: string[]
  sx?: SxProps<Theme> | undefined
}

export const CustomToggleButton = ({
  valueState,
  setValueState,
  values,
  msgValues,
  sx = []
}: IToggleButton) => {
  const handleToggle = (event: React.MouseEvent<HTMLElement>, newToggle: string | null) => {
    if (newToggle !== null) {
      setValueState(newToggle)
    }
  }

  return (
    <MainToggleButtonGroup
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      value={valueState}
      exclusive
      onChange={handleToggle}>
      {values.map((value, index) => {
        return (
          <MainToggleButton key={index} value={value}>
            {msgValues[index]}
          </MainToggleButton>
        )
      })}
    </MainToggleButtonGroup>
  )
}

const MainToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root.Mui-selected': {
    background: '#fff',
    color: '#000',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  }
}))

const MainToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  background: '#F1F3F5',
  color: '#868E96',
  borderRadius: '10px',
  border: '4px solid #F1F3F5',

  '& .MuiButtonBase-root': {
    border: 'none',
    fontWeight: '600',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '6px 24px'
  },

  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '10px'
  },
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '10px'
  }
}))
