/* eslint-disable @typescript-eslint/ban-ts-comment */
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { colors, Box, Typography } from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import React, { useMemo, RefObject } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'

import { EditAreasMap } from '../../../../../components/EditAreasMap'
import { TMapLegend } from '../../../../../components/EditAreasMap/MapLegend'

interface IMapView {
  mapRef: RefObject<MapRef>
  onMapClick: (props: mapboxgl.MapLayerMouseEvent) => void
  onMapDblClick?: (props: mapboxgl.MapLayerMouseEvent) => void
  editingIndex: number | null
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  intersectionsVisibility: boolean
  handleToggleIntersectionsVisibility: () => void
  geoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  highlightedFeatId: string | null
  isFeatureEditable: ((feature: turf.Feature) => boolean) | undefined
  legendValue: TMapLegend
  onMapDragEnd?: () => void
  outterFeatures: turf.Feature[]
  onLoad?: () => void
  multipleSelectionMode: boolean
  startMultipleSelection: () => void
  cancelMultipleSelection: () => void
  saveMultipleSelection: () => void
  selectedAreasIds: string[]
}

export const MapView = ({
  mapRef,
  onMapClick,
  onMapDblClick,
  editingIndex,
  intersections,
  intersectionsVisibility,
  handleToggleIntersectionsVisibility,
  geoJson,
  highlightedFeatId,
  isFeatureEditable,
  legendValue,
  onMapDragEnd,
  outterFeatures,
  onLoad,
  multipleSelectionMode,
  startMultipleSelection,
  cancelMultipleSelection,
  saveMultipleSelection,
  selectedAreasIds
}: IMapView) => {
  return (
    <EditAreasMap
      mapRef={mapRef}
      onMapClick={onMapClick}
      onMapDblClick={onMapDblClick}
      editingIndex={editingIndex}
      intersections={intersections}
      intersectionsVisibility={intersectionsVisibility}
      handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}
      legend={legendValue}
      onDragEnd={onMapDragEnd}
      onLoad={onLoad}>
      <>
        {useMemo(() => {
          return geoJson ? (
            <Source
              id={'areas'}
              type="geojson"
              // @ts-ignore
              data={{
                ...geoJson,
                features: geoJson.features
                  .filter((f, i) => i !== editingIndex)
                  .map(f => ({
                    ...f,
                    geometry: f.geometry as GeoJSON.Geometry,
                    properties: {
                      color:
                        f?.id?.toString() &&
                        isFeatureEditable !== undefined &&
                        !isFeatureEditable(f)
                          ? colors.grey[500]
                          : f?.properties?.productive
                          ? colors.blue[500]
                          : colors.deepOrange[500],
                      opacity:
                        f?.id === highlightedFeatId ||
                        (f.id && selectedAreasIds.includes(f.id.toString()))
                          ? 0.3
                          : 0.05
                    }
                  }))
              }}>
              <Layer
                id={'areasLine'}
                type={'line'}
                source={'areas'}
                paint={{
                  'line-color': ['get', 'color'],
                  'line-width': 2
                }}
              />
              <Layer
                id={'areasFill'}
                type={'fill'}
                source={'areas'}
                paint={{
                  'fill-color': ['get', 'color'],
                  'fill-opacity': ['get', 'opacity']
                }}
              />
            </Source>
          ) : (
            <></>
          )
        }, [geoJson, highlightedFeatId, editingIndex, selectedAreasIds])}
        <Source
          id={'outter'}
          type="geojson"
          data={{
            type: 'FeatureCollection',
            // @ts-ignore
            features: outterFeatures.map(f => ({
              ...f,
              geometry: f.geometry as GeoJSON.Geometry,
              properties: {
                color:
                  f?.id?.toString() && isFeatureEditable !== undefined && !isFeatureEditable(f)
                    ? colors.grey[500]
                    : f?.properties?.productive
                    ? colors.blue[500]
                    : colors.deepOrange[500]
              }
            })) as turf.Feature[]
          }}>
          <Layer
            id={'outterLine'}
            type={'line'}
            source={'outter'}
            paint={{
              'line-color': ['get', 'color'],
              'line-width': 2,
              'line-dasharray': [1, 1]
            }}
          />
          <Layer
            id={'outterFill'}
            type={'fill'}
            source={'outter'}
            paint={{
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.05
            }}
          />
        </Source>
        {intersections.features.length && intersectionsVisibility && (
          <Source
            id={'intersections'}
            type="geojson"
            // @ts-ignore
            data={intersections}>
            <Layer
              id={'layer-intersectins'}
              type={'line'}
              source={'intersections'}
              paint={{
                'line-color': '#6C1891',
                'line-width': 2
              }}
            />
            <Layer
              id={'fill-intersectins'}
              type={'fill'}
              source={'intersections'}
              paint={{
                'fill-color': '#6C1891',
                'fill-opacity': 0.7
              }}
            />
          </Source>
        )}
        {editingIndex === null && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              position: 'absolute',
              top: '36px',
              left: '47px'
            }}>
            {!multipleSelectionMode ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px 16px',
                  gap: '8px',
                  width: 'fit-content',
                  height: '43px',
                  background: '#FBFBFB',
                  border: '1px solid #E9ECEF',
                  borderRadius: '8px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={startMultipleSelection}>
                <Typography variant="subtitle1" sx={{ color: '#111315' }}>
                  Seleção Múltipla
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '12px 16px',
                    gap: '8px',
                    width: 'fit-content',
                    height: '43px',
                    background: '#FA5252',
                    border: '1px solid #F03E3E',
                    borderRadius: '8px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={cancelMultipleSelection}>
                  <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                    Cancelar
                  </Typography>
                </Box>
                {selectedAreasIds.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10px',
                      gap: '8px',
                      width: '44px',
                      height: '44px',
                      background: '#FBFBFB',
                      border: '1px solid #E9ECEF',
                      borderRadius: '8px',
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    onClick={saveMultipleSelection}>
                    <EditOutlinedIcon />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </>
    </EditAreasMap>
  )
}
