import { Close, KeyboardArrowUp, KeyboardArrowDown, List } from '@mui/icons-material'
import {
  THubFormFieldGroup,
  THubFormFieldDefinition,
  THubFormConditionsDefinition,
  ConditionAction,
  ConditionExpression
} from '@services'
import { Button, InputNumber, Table, TableProps, Select, Checkbox, Tag } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useState, Dispatch, SetStateAction } from 'react'

import { EditableCell, EditableRow } from '../../../components'
import useMessage from '../../../hooks/useMessage'
import { fixIdentifierNumber, toCamelCase } from '../../../utils/functions.utils'
import { SelectComponentsModal } from '../modal'
import { fieldTypeOptions, tagParameters } from '../options.constants'

type FieldsTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<FieldsTableProps['columns'], undefined>

interface ITableFields extends TableProps {
  formFields: THubFormFieldDefinition[]
  setFormFields: Dispatch<SetStateAction<THubFormFieldDefinition[]>>
  customFields: Array<string>
  nativeFields: THubFormFieldDefinition[] | undefined
  customFieldsGroups: THubFormFieldGroup[] | undefined
  formConditions: THubFormConditionsDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
}

export const FieldsTable: React.FC<ITableFields> = ({
  formFields,
  setFormFields,
  customFields,
  nativeFields,
  customFieldsGroups,
  formConditions,
  setFormConditions
}) => {
  const { contextHolder, notify } = useMessage()

  const handleDelete = (row: AnyObject) => {
    const identifiers: string[] = []
    const repeatedFields: string[] = []
    for (let i = 0; i < formFields.length; i += 1) {
      if (!identifiers.includes(formFields[i]?.identifier))
        identifiers.push(formFields[i]?.identifier)
      else repeatedFields.push(formFields[i]?.identifier)
    }

    const conditions: THubFormConditionsDefinition[] = []
    let toAtt = false
    for (let i = 0; i < formConditions?.length; i += 1) {
      const actions: ConditionAction[] = []
      const expressions: ConditionExpression[] = []
      for (let j = 0; j < formConditions[i]?.actions?.length; j += 1) {
        if (
          formConditions[i]?.actions[j]?.fieldIdentifier != row.identifier ||
          repeatedFields.includes(formConditions[i]?.actions[j]?.fieldIdentifier)
        )
          actions.push(formConditions[i]?.actions[j])
        else toAtt = true
      }
      for (let j = 0; j < formConditions[i]?.expressions?.length; j += 1) {
        if (formConditions[i]?.expressions[j]?.fieldIdentifier != row.identifier)
          expressions.push(formConditions[i]?.expressions[j])
        else if (repeatedFields.includes(formConditions[i]?.expressions[j]?.fieldIdentifier)) {
          expressions.push({
            ...formConditions[i]?.expressions[j],
            ...{ value: '' }
          })
          toAtt = true
        } else toAtt = true
      }
      conditions.push({
        name: formConditions[i]?.name,
        actions: actions,
        expressions: expressions
      })
    }
    if (toAtt) setFormConditions(conditions)

    const newData: THubFormFieldDefinition[] = []
    for (let i = 0; i < formFields.length; i += 1) {
      if (i != row.key) newData.push(formFields[i])
    }
    setFormFields(newData)
  }

  const handleMove = (event: any, row: AnyObject) => {
    let newIndex = event.target.value
    const newData = [...formFields]
    const item = newData[row.key]

    if (row.key === -1 || row.key == newIndex) {
      return
    }

    newData.splice(row.key, 1)

    if (newIndex >= newData.length) {
      newIndex = newData.length
    } else if (newIndex < 0) {
      newIndex = 0
    }

    newData.splice(newIndex, 0, item)
    setFormFields(newData)
  }

  const handleMoveUp = (row: AnyObject) => {
    if (row.key > 0) {
      const newData: THubFormFieldDefinition[] = [...formFields]
      ;[newData[row.key - 1], newData[row.key]] = [newData[row.key], newData[row.key - 1]]
      setFormFields(newData)
    }
  }

  const handleMoveDown = (row: AnyObject) => {
    if (row.key < formFields.length - 1) {
      const newData: THubFormFieldDefinition[] = [...formFields]
      ;[newData[row.key], newData[row.key + 1]] = [newData[row.key + 1], newData[row.key]]
      setFormFields(newData)
    }
  }

  const handleChange = (value: string | boolean, row: AnyObject, dataIndex: string) => {
    const newRow = { ...formFields[row.key], ...{ [dataIndex]: value, source: 'MANUAL' } }
    const newData = [...formFields]
    const item = newData[row.key]
    newData.splice(row.key, 1, {
      ...item,
      ...newRow
    })
    if (dataIndex == 'fieldType') {
      const conditions: THubFormConditionsDefinition[] = []
      let toAtt = false
      for (let i = 0; i < formConditions?.length; i += 1) {
        const expressions: ConditionExpression[] = []
        for (let j = 0; j < formConditions[i]?.expressions?.length; j += 1) {
          if (
            formConditions[i]?.expressions[j]?.fieldIdentifier != formFields[row.key]?.identifier ||
            formConditions[i]?.expressions[j]?.operation == 'present' ||
            formConditions[i]?.expressions[j]?.operation == 'blank'
          )
            expressions.push(formConditions[i]?.expressions[j])
          else toAtt = true
        }
        conditions.push({
          name: formConditions[i]?.name,
          actions: formConditions[i]?.actions,
          expressions: expressions
        })
      }
      if (toAtt) setFormConditions(conditions)
    }
    setFormFields(newData)
  }

  const handleSave = (
    row: THubFormFieldDefinition,
    index: number,
    dataIndex: keyof THubFormFieldDefinition
  ) => {
    const newData: THubFormFieldDefinition[] = []
    let identifiers: Array<string> = []

    if (formFields[index][dataIndex] != row[dataIndex]) {
      for (let i = 0; i < formFields.length; i += 1) {
        if (i == index) {
          const item = { ...formFields[i], ...row }
          item.source = 'MANUAL'
          newData.push(item)
        } else {
          newData.push(formFields[i])
          identifiers.push(formFields[i].identifier)
        }
      }

      if (dataIndex == 'label' && newData[index]?.label) {
        if (nativeFields)
          identifiers = identifiers.concat(nativeFields.map(field => field.identifier))
        newData[index].identifier = fixIdentifierNumber(
          toCamelCase(newData[index]?.label),
          identifiers
        )
      }

      if (formFields[index].identifier != newData[index].identifier) {
        const conditions: THubFormConditionsDefinition[] = []
        let toAtt = false
        for (let i = 0; i < formConditions?.length; i += 1) {
          const actions: ConditionAction[] = []
          const expressions: ConditionExpression[] = []
          for (let j = 0; j < formConditions[i]?.actions?.length; j += 1) {
            if (formConditions[i]?.actions[j]?.fieldIdentifier != formFields[index]?.identifier)
              actions.push(formConditions[i]?.actions[j])
            else {
              actions.push({
                ...formConditions[i]?.actions[j],
                ...{ fieldIdentifier: newData[index].identifier }
              })
              toAtt = true
            }
          }
          for (let j = 0; j < formConditions[i]?.expressions?.length; j += 1) {
            if (formConditions[i]?.expressions[j]?.fieldIdentifier != formFields[index]?.identifier)
              expressions.push(formConditions[i]?.expressions[j])
            else {
              expressions.push({
                ...formConditions[i]?.expressions[j],
                ...{ fieldIdentifier: newData[index].identifier }
              })
              toAtt = true
            }
          }
          conditions.push({
            name: formConditions[i]?.name,
            actions: actions,
            expressions: expressions
          })
        }
        if (toAtt) setFormConditions(conditions)
      }

      if (identifiers.includes(newData[index].identifier))
        notify(
          'warning',
          `Identifier '${newData[index].identifier}' encontrado em múltiplos campos da tabela, isso pode causar comportamentos inesperados!`
        )
      setFormFields(newData)
    }
  }

  const handleSelect = (row: AnyObject) => {
    setSelectedField(row)
    setIsSelectComponentsOpen(true)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      editable: true,
      width: '20%'
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      editable: true,
      width: '15%'
    },
    {
      title: 'Tipo',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (label: string, record: AnyObject) => (
        <Select
          value={label}
          onChange={value => handleChange(value, record, 'fieldType')}
          options={fieldTypeOptions}
          style={{ width: '100%' }}
          disabled={record?.source == 'NATIVE'}
          variant={record?.source == 'NATIVE' ? 'borderless' : 'outlined'}
        />
      ),
      width: '10%'
    },
    {
      title: 'Grupo',
      dataIndex: 'customFieldGroupId',
      key: 'customFieldGroupId',
      render: (label: string, record: AnyObject) => (
        <Select
          value={label}
          onChange={value => handleChange(value, record, 'customFieldGroupId')}
          options={customFieldsGroups?.map(group => ({ label: group?.label, value: group?.id }))}
          style={{ width: '100%' }}
          disabled={record?.source == 'NATIVE' || record?.source == 'DESTINY'}
          variant={
            record?.source == 'NATIVE' || record?.source == 'DESTINY' ? 'borderless' : 'outlined'
          }
        />
      ),
      width: '10%'
    },
    {
      title: 'Obrigatório',
      dataIndex: 'isRequired',
      key: 'isRequired',
      render: (label: boolean, record: AnyObject) =>
        formFields.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              checked={label}
              onChange={value => handleChange(value.target.checked, record, 'isRequired')}
              disabled={record?.source == 'NATIVE'}
            />
          </div>
        ) : null,
      width: '10%'
    },
    {
      title: 'Fonte',
      dataIndex: 'source',
      key: 'source',
      render: (label: 'MANUAL' | 'DESTINY' | 'ORIGIN' | 'NATIVE') => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tag color={tagParameters[label]['color']}>{tagParameters[label]['label']}</Tag>
        </div>
      ),
      width: '10%'
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: string, record: AnyObject) =>
        formFields.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <InputNumber
              style={{ width: '54px' }}
              controls={false}
              value={record.key}
              onPressEnter={event => handleMove(event, record)}
              onBlur={event => handleMove(event, record)}
            />
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleMoveUp(record)}>
              <KeyboardArrowUp />
            </Button>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleMoveDown(record)}>
              <KeyboardArrowDown />
            </Button>
            <Button
              type="text"
              style={{ padding: '4px' }}
              disabled={
                (record?.fieldType != 'select' && record?.fieldType != 'multiselect') ||
                record?.source == 'NATIVE'
              }
              onClick={() => handleSelect(record)}>
              <List />
            </Button>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleDelete(record)}>
              <Close style={{ color: 'red' }} />
            </Button>
          </div>
        ) : null,
      width: '15%'
    }
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: AnyObject) => ({
        record,
        editable: col.editable && record.source != 'NATIVE',
        dataIndex: col.dataIndex,
        handleSave,
        records: formFields,
        warning:
          col.dataIndex == 'identifier' &&
          (nativeFields?.map(field => field.identifier)?.includes(record.identifier) ||
            customFields?.includes(record.identifier)) &&
          record.source != 'DESTINY' &&
          record.source != 'NATIVE'
      })
    }
  })

  const [isSelectComponentsOpen, setIsSelectComponentsOpen] = useState(false)
  const [selectedField, setSelectedField] = useState<AnyObject | null>(null)

  const formFieldDataSource = []
  for (let i = 0; i < formFields.length; i += 1) {
    formFieldDataSource.push({ ...formFields[i], key: i })
  }

  return (
    <>
      {contextHolder}
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={formFieldDataSource}
        columns={columns as ColumnTypes}
        size={'small'}
        style={{ width: '100%', marginBottom: '16px' }}
        pagination={false}
        scroll={{ y: window.innerHeight - 183 }}
      />
      <SelectComponentsModal
        setIsModalOpen={setIsSelectComponentsOpen}
        isModalOpen={isSelectComponentsOpen}
        setFormFields={setFormFields}
        formFields={formFields}
        record={selectedField}
        formConditions={formConditions}
        setFormConditions={setFormConditions}
      />
    </>
  )
}
