import * as turf from '@turf/turf'
// eslint-disable-next-line import/no-named-as-default
import WebMercatorViewport from 'viewport-mercator-project'

export const getImageMapbox = (geometry: turf.Geometry): string => {
  const MAPBOX_TOKEN =
    'pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg'
  const geoJson = {
    type: 'Feature',
    properties: {
      stroke: '#9EEB215',
      'stroke-width': 2,
      fill: '#3E5CD1',
      'fill-opacity': 0.7
    },
    geometry: geometry
  }

  const cornersLongLat = turf.bbox(geometry)
  const viewport = new WebMercatorViewport({
    width: 300,
    height: 300
  }).fitBounds(
    [
      [cornersLongLat[0], cornersLongLat[1]],
      [cornersLongLat[2], cornersLongLat[3]]
    ],
    { padding: 40 }
  )
  const { longitude, latitude, zoom } = viewport

  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURIComponent(
    JSON.stringify(geoJson)
  )})/${longitude},${latitude},${zoom}/300x300?access_token=${MAPBOX_TOKEN}`
}
