import { Consent } from '@/services/Bender/Consents/contract/response/ContactConsents.response'
import { BENDER_CONSENT_STATUS, BenderApi } from '@services'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'

export const useConsents = () => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const [error, setError] = useState<unknown>(undefined)

  const resetStates = () => {
    setIsRequesting(true)
    setError(undefined)
  }

  const updateConsent = useCallback(
    async ({
      consent,
      status,
      rejectionReason
    }: {
      consent?: Consent
      status: BENDER_CONSENT_STATUS
      rejectionReason?: string
    }) => {
      try {
        if (!consent)
          return {
            success: false,
            data: null,
            error: null
          }
        resetStates()

        const response = await BenderApi.consents.updateConsent({
          consentId: consent.id,
          status,
          rejectionReason
        })
        setIsRequesting(false)
        return {
          success: true,
          data: response,
          error: null
        }
      } catch (err) {
        if (err instanceof AxiosError) console.error(err.response?.data)
        setIsRequesting(false)
        setError(err)
        return {
          success: false,
          data: null,
          error: err
        }
      }
    },
    []
  )

  return { isRequesting, error, updateConsent }
}
