import { THubOrganizationForm } from '@services'
import { Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'

interface IFormSelect {
  selectedForm: string | null
  isLoadingForms: boolean
  setFormId: Dispatch<SetStateAction<string | null>>
  forms: THubOrganizationForm[] | undefined
  placeholder?: string
  width?: string
}

export const FormsSelect = (props: IFormSelect) => {
  const { selectedForm, isLoadingForms, setFormId, forms, placeholder, width } = props

  return (
    <Select
      style={{ width: width ?? '100%', minHeight: 0 }}
      showSearch
      loading={isLoadingForms}
      disabled={isLoadingForms}
      placeholder={placeholder ?? 'Selecione um formulário'}
      optionFilterProp="children"
      onChange={value => setFormId(value)}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      value={selectedForm}
      options={forms?.map(({ id, title, entity }) => ({
        label: `${title} | ${entity}`,
        value: id
      }))}
    />
  )
}
