import { CopyAll } from '@mui/icons-material'
import { Button } from '@mui/material'
import * as turf from '@turf/turf'

interface ILatLongCopyButton {
  coordinates: turf.helpers.Position
}

export const LatLongCopyButton = ({ coordinates }: ILatLongCopyButton) => {
  return (
    <>
      <Button
        sx={{ mr: 0.5 }}
        variant="contained"
        onClick={() => {
          navigator.clipboard.writeText(`${coordinates[1]}`)
        }}>
        <CopyAll fontSize="inherit" /> Latitude
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          navigator.clipboard.writeText(`${coordinates[0]}`)
        }}>
        <CopyAll fontSize="inherit" /> Longitude
      </Button>
    </>
  )
}
