import { axiosHub } from '../config/axiosHub.config'
import { FISCAL_ASSESSMENT_LEVEL, FISCAL_ASSESSMENT_STATUS } from './enums/FiscalDebtInquiry.enum'

export type THubFiscalAssessment = {
  referenceDate: Date
  deferredCount: number //Total count of all certificates.
  underAnalysisCount?: number
  notConformingCount: number
  certificates: FiscalDebtInquiryDTO[]
}

export type FiscalDebtInquiryDTO = {
  id: string
  documentUrl?: string
  source: string
  status: FISCAL_ASSESSMENT_STATUS
  level: FISCAL_ASSESSMENT_LEVEL
  stateLongName?: string | null
  stateShortName?: string | null
  stateId?: number
  expirationDate: string
  referenceDate: string
  canonicalProfileId: string
  creationType: string
}

export type CreateFiscalDebtInquiryDTO = Omit<FiscalDebtInquiryDTO, 'id'>

const getRequest = async (personProfileId: string) => {
  const { data } = await axiosHub.get<THubFiscalAssessment>(
    `/api/v1/assessments/fiscal?personProfileId=${personProfileId}`
  )
  return data
}

const postDebtInquiryRequest = async (body: CreateFiscalDebtInquiryDTO) => {
  const { data } = await axiosHub.post<FiscalDebtInquiryDTO>(
    '/api/v1/assessments/fiscal/debt-inquiries',
    body
  )
  return data
}

/**
 * Expects debtInquiryId OR canonicalProfileId as params. If you send both, then the canonicalProfileId will be ignored.
 */
const deleteDebtInquiryRequest = async (params: {
  debtInquiryId?: string
  canonicalProfileId?: string
}) => {
  if (!params.debtInquiryId && !params.canonicalProfileId)
    throw new Error('Debt Inquery ID or Canonical Profile ID is required')

  // Rename debtInquiryId to debtInqueryId, because the API expects the WRONG writing
  const urlParams = params.debtInquiryId
    ? { debtInqueryId: params.debtInquiryId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/fiscal/debt-inquiries', {
    params: urlParams
  })
  return data
}

export const AssessmentsFiscal = {
  getRequest,
  postDebtInquiryRequest,
  deleteDebtInquiryRequest
}
