import { LUTIEN_MEMBERSHIP_ROLE, USER_ROLE } from '@services'

export function displayMembershipRoles(role: LUTIEN_MEMBERSHIP_ROLE): string {
  switch (role) {
    case LUTIEN_MEMBERSHIP_ROLE.ROLE_OWNER:
      return 'Owner (org)'
    case LUTIEN_MEMBERSHIP_ROLE.ROLE_MEMBER:
      return 'Membro (org)'
    case LUTIEN_MEMBERSHIP_ROLE.ROLE_WORKFLOW_APP:
      return 'Membro (app)'
    default:
      return '?? (org)'
  }
}

export function displayUserRoles(role: USER_ROLE): string {
  switch (role) {
    case USER_ROLE.ROLE_CUSTOMER:
      return 'Usuário'
    case USER_ROLE.ROLE_STAFF:
      return 'Staff'
    default:
      return '?? do sistema'
  }
}
