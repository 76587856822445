import 'react-datepicker/dist/react-datepicker.css'
import { criminalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceCriminal/helpers/criminalRecordTypeCatalog'
import { environmentalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceEnvironmental/helpers/environmentalRecordTypeCatalog'
import { fiscalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceFiscal/helpers/fiscalRecordTypeCatalog'
import { laborRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceLabor/helpers/laborRecordTypeCatalog'
import { COMPLIANCE_TASK_TYPE } from '@/pages/bender/Compliance/enums/ComplianceTaskType'
import {
  ComplianceContext,
  documentStatusCatalog,
  IRecordCandidate,
  recordTypeCatalog
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { OrNull } from '@/utils/OrNull'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CreateRecordFormView } from './CreateRecordFormView'

const emptyComplianceRecord: OrNull<IRecordCandidate> = {
  documentExpirationDate: null,
  documentStatus: null,
  file: null,
  recordType: null
}

export const CreateRecordForm = () => {
  const { taskType } = useParams() as { taskType: COMPLIANCE_TASK_TYPE }

  const { addRecordCandidate } = useContext(ComplianceContext)

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [complianceRecord, setComplianceRecord] = useState(emptyComplianceRecord)

  const recordTypeOptions = recordTypeCatalog[taskType]
  const documentStatusOptions = documentStatusCatalog[taskType]

  const handleFormChange = (key: keyof IRecordCandidate, value: unknown) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      [key]: value
    }))
  }

  const handleOpenNewTab = () => {
    if (!complianceRecord.recordType) return

    if (taskType === COMPLIANCE_TASK_TYPE.criminal)
      window.open(criminalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.environmental)
      window.open(environmentalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.fiscal)
      window.open(fiscalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.labor)
      window.open(laborRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
  }

  const clearForm = () => {
    setComplianceRecord(emptyComplianceRecord)
  }

  const handleFormSubmit = () => {
    addRecordCandidate(complianceRecord as IRecordCandidate)
    clearForm()
  }

  useEffect(() => {
    const isThereANullField = Object.values(complianceRecord).some(value => value === null)
    setSubmitDisabled(isThereANullField)
  }, [complianceRecord])

  return (
    <CreateRecordFormView
      handleOpenNewTab={handleOpenNewTab}
      handleChange={handleFormChange}
      handleSubmit={handleFormSubmit}
      recordTypeOptions={recordTypeOptions}
      documentStatusOptions={documentStatusOptions}
      complianceRecord={complianceRecord}
      submitDisabled={submitDisabled}
    />
  )
}
