import { THubDetectedSeason } from '@/services/HubApi'
import { axiosBender } from '@/services/config/axiosBender.config'
import { FeatureCollection } from '@turf/turf'

import { BenderCompleteTaskRequest } from './contract/request/BenderCompleteTask.request'
import { BenderSearchTasksRequest } from './contract/request/BenderSearchTasks.request'
import {
  BenderSearchTasksResponse,
  TBenderInputs
} from './contract/response/BenderSearchTasks.response'
import { BenderTaskAttributesResponse } from './contract/response/BenderTaskAttributes.response'

const search = async <TInput = TBenderInputs['All']>(body: BenderSearchTasksRequest) => {
  const { data } = await axiosBender.post<BenderSearchTasksResponse<TInput>>('/task/search', body)
  return data
}

const getAttributes = async () => {
  const { data } = await axiosBender.get<BenderTaskAttributesResponse[]>('/task/get-attributes')
  return data
}

const complete = async (taskId: string, body: BenderCompleteTaskRequest) => {
  const completionInputData = { output: [] }
  if (body.completionInput) {
    if (Array.isArray(body.completionInput)) {
      body.completionInput.map(completionInput => {
        completionInput['data'] = completionInputData
      })
    } else {
      body.completionInput['data'] = completionInputData
    }
  }
  if (!body.completionInput) {
    body.completionInput = { message: 'accepted', data: completionInputData }
  }

  const { data } = await axiosBender.post(`/task/${taskId}/complete`, body)
  return data
}

const geojson = async (id: string) => {
  const { data } = await axiosBender.get<FeatureCollection>(`/task/segmentation/${id}/geojson`)
  return data
}

const saveTaskProgress = async (taskId: string, body: BenderCompleteTaskRequest) => {
  if (!body.completionInput) body.completionInput = { message: 'accepted' }

  const { data } = await axiosBender.post(`/task/${taskId}/progress`, body)
  return data
}

const getTaskProgress = async (
  taskId: string
): Promise<FeatureCollection | THubDetectedSeason[]> => {
  const { data } = await axiosBender.get(`/task/${taskId}/progress`)
  return data
}

export const task = {
  search,
  getAttributes,
  complete,
  geojson,
  saveTaskProgress,
  getTaskProgress
}
