import Avatar, { AvatarProps } from '@mui/material/Avatar'

import { stringAvatar } from './LetterAvatarUtils'

export interface LetterAvatarProps extends AvatarProps {
  name?: string
  lastName?: string
}

export const LetterAvatar = (props: LetterAvatarProps) => {
  const { name, lastName, sx, ...rest } = props
  const style = stringAvatar(name, lastName)

  if (sx) {
    style.sx = {
      ...style.sx,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(sx as any)
    }
  }

  return <Avatar {...style} {...(rest ? rest : {})} />
}
