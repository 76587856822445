import { colors } from '@mui/material'

export const getOrderedColor = (index: number) => {
  const arr = [
    colors.red[500],
    colors.green[500],
    colors.blue[500],
    colors.deepOrange[500],
    colors.teal[500],
    colors.indigo[500],
    colors.pink[500],
    colors.amber[500],
    colors.cyan[500],
    colors.red[900],
    colors.green[900],
    colors.blue[900],
    colors.deepOrange[900],
    colors.teal[900],
    colors.indigo[900],
    colors.pink[900],
    colors.amber[900],
    colors.cyan[900]
  ]

  return arr[index % arr.length]
}
