import {
  BenderSearchTasksResponse,
  TBenderInputs
} from '@/services/Bender/Task/contract/response/BenderSearchTasks.response'
import { BenderApi } from '@services'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

export type Task<TInput extends keyof TBenderInputs> = BenderSearchTasksResponse<
  TBenderInputs[TInput]
>['tasks'][0]

export const useFetchTask = <TInput extends keyof TBenderInputs>(
  taskId: string | undefined,
  callback?: (task: Task<TInput>) => void
) => {
  const [task, setTask] = useState<Task<TInput>>()
  const [isFetchingTask, setIsFetchingTask] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const bootstrap = async () => {
      if (!taskId) {
        enqueueSnackbar('Não foi encontrado nenhum id para a tarefa.', { variant: 'error' })
        return
      }

      try {
        const response = await BenderApi.task.search<TBenderInputs[TInput]>({
          filter: { id: taskId },
          fields: ['input'], // You can add more fields here if necessary
          limit: 1
        })

        setTask(response.tasks[0])
      } catch (error) {
        enqueueSnackbar(`Erro ao buscar tarefa no servidor: ${taskId}.`, {
          variant: 'error'
        })
        console.error(error)
      } finally {
        setIsFetchingTask(false)
      }
    }

    bootstrap()
  }, [enqueueSnackbar, taskId])

  useEffect(() => {
    // Removed from try-catch block to not handle callback errors
    if (callback && task) callback(task)
  }, [callback, task])

  return { task, isFetchingTask }
}
