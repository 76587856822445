import { Box, BoxProps } from '@mui/material'

interface ISplitScreenProps extends Omit<BoxProps, 'gridTemplateColumns'> {
  leftElement: JSX.Element
  rightElement: JSX.Element
  gridTemplateColumns: string
}

export const SplitScreen = (props: ISplitScreenProps) => {
  const { leftElement, rightElement, gridTemplateColumns, ...restProps } = props
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100vw',
        gridTemplateColumns,
        bgcolor: '#fafafa'
      }}
      {...restProps}>
      <Box height="calc(100vh - 3rem)" overflow="auto">
        {leftElement}
      </Box>
      <Box height="calc(100vh - 3rem)" overflow="auto">
        {rightElement}
      </Box>
    </Box>
  )
}
