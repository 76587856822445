import { Box, Button } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'

import { CustomSidebarHeader } from '../../../../../components/customSidebarHeader'
import { TLandField } from '../../EditSeasons'
import { AreaCard, TAreasCrops } from './components/AreaCard'

interface ISidebar {
  areas: TLandField[]
  selectedAreaId: string
  onSelectArea: (areaId: string) => void
  // onSubmitReview: () => void;
  onDoneButtonClick: () => void
  // onCancelReview: () => void;
  areasCrops?: TAreasCrops[]
  checkedIds: string[]
}

export const Sidebar = ({
  areas,
  selectedAreaId,
  onSelectArea,
  // onSubmitReview,
  // onCancelReview,
  areasCrops,
  checkedIds,
  onDoneButtonClick
}: ISidebar) => {
  const { name } = useParams()
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        height: 'calc(100vh - 3rem)',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {/* <Box
        sx={{
          height: '4rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          paddingInline: '0.5rem',
          mb: '0.5rem',
        }}
      >
        <img src={PropertyImage} style={{ height: '3rem' }} />
        <Box>
          <Typography>Propriedade</Typography>
          <Typography color={colors.grey[700]} variant="body2">
            #propertyName
          </Typography>
        </Box>
      </Box>
      <Typography variant="h6" p="0.25rem 0.5rem">
        Todos os Talhões
      </Typography> */}
      <CustomSidebarHeader
        onGoBack={() => navigate(-1)}
        title={name || 'Não identificado'}
        subtitle={`${
          areas.length
            ? ((100 * checkedIds.length) / areas.length).toLocaleString('pt-BR', {
                maximumFractionDigits: 1
              })
            : '-'
        }% de áreas verificadas`}
        sectionName="Áreas Detectadas"
      />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          padding: '10px 5%',
          gap: '10px'
        }}>
        {areas.map((area, index) => {
          const areaCrops = areasCrops?.find(a => a.areaId == area.id)
          return (
            <AreaCard
              key={area.id}
              area={area}
              isSelected={area.id === selectedAreaId}
              index={index + 1}
              onClick={() => onSelectArea(area.id)}
              areaCrops={areaCrops}
              isChecked={checkedIds.includes(area.id)}
            />
          )
        })}
      </Box>
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          justifyContent: 'center',
          borderTop: '2px solid #CED4DA'
        }}>
        <Button
          variant="contained"
          disableElevation
          color="success"
          sx={{ textTransform: 'capitalize', p: '7px 37px' }}
          onClick={onDoneButtonClick}>
          Finalizar Tarefa
        </Button>
      </Box>
    </Box>
  )
}
