import { UploadOutlined } from '@ant-design/icons'
import { Form, Upload, Button } from 'antd'

export const FileUpload = () => {
  return (
    <Form.Item
      name="spreadsheet"
      valuePropName="fileList"
      getValueFromEvent={e => (Array.isArray(e) ? e : e?.fileList)}
      style={{ margin: 0, width: '200px' }}>
      <Upload accept=".xlsx" beforeUpload={() => false} maxCount={1}>
        <Button icon={<UploadOutlined />}>Selecione uma planilha</Button>
      </Upload>
    </Form.Item>
  )
}
