/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useFetchCrops } from '@/pages/bender/segmentationReview/hooks/useFetchCrops'
import * as turf from '@turf/turf'
import * as React from 'react'
import { useState } from 'react'

import { AreasListView } from './AreasListView'

interface IAreasList {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoJson: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGeoJson: React.Dispatch<React.SetStateAction<any>>
  highlightedFeatId: number | null
  setHighlightedFeatId: React.Dispatch<React.SetStateAction<number | null>>
  editingIndex: number | null
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  saveEditingArea: () => void
  cancelEditing: () => void
  addSelectedAreas: (id: number) => void
  removeArea: (featId: turf.helpers.Id | undefined) => void
  isControlPressed: boolean | null
  selectedAreas: string[] | null
  getImage: (geometry: turf.Geometry) => string
  isClickDisabled?: boolean | null
}

export default function AreasList({
  geoJson,
  setGeoJson,
  highlightedFeatId,
  setHighlightedFeatId,
  editingIndex,
  setEditingIndex,
  saveEditingArea,
  cancelEditing,
  removeArea,
  isControlPressed,
  addSelectedAreas,
  selectedAreas,
  getImage,
  isClickDisabled
}: IAreasList) {
  const [indexPopoverOpen, setIndexPopoverOpen] = useState<number>()
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<EventTarget & SVGSVGElement>()
  const { crops, fetchCrops, isFetchingCrops } = useFetchCrops()
  const handleListItemClick = (id: number) => {
    setHighlightedFeatId(id)
  }

  const handleClickInfo = (index: number, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    fetchCrops(geoJson.features[index])
    setIndexPopoverOpen(index)
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(undefined)
    setIndexPopoverOpen(undefined)
  }

  const selectedRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (selectedRef.current)
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
  }, [highlightedFeatId])

  const handleSwitchChange = (index: number) => {
    const tempGeoJsonFeatures = geoJson.features
    const isProductive = tempGeoJsonFeatures[index].properties.productive === 1
    tempGeoJsonFeatures[index].properties.productive = isProductive ? 0 : 1
    setGeoJson({
      ...geoJson,
      features: tempGeoJsonFeatures
    })
  }

  return (
    <AreasListView
      cancelEditing={cancelEditing}
      editingIndex={editingIndex}
      geoJson={geoJson}
      handleListItemClick={handleListItemClick}
      handleSwitchChange={handleSwitchChange}
      highlightedFeatId={highlightedFeatId}
      saveEditingArea={saveEditingArea}
      selectedRef={selectedRef}
      setEditingIndex={setEditingIndex}
      removeArea={removeArea}
      isControlPressed={isControlPressed}
      addSelectedAreas={addSelectedAreas}
      selectedAreas={selectedAreas}
      getImage={getImage}
      isClickDisabled={isClickDisabled}
      crops={crops}
      handleClickInfo={handleClickInfo}
      indexPopoverOpen={indexPopoverOpen}
      popoverAnchorEl={popoverAnchorEl}
      handleClose={handleClose}
      isFetchingCrops={isFetchingCrops}
    />
  )
}
