import { TSelectedRegion } from '@/services/RadarApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TRadar = {
  selectedRegion: TSelectedRegion
}

const initialState: TRadar = { selectedRegion: { latLng: undefined, geometry: undefined } }

export const radarSlice = createSlice({
  name: 'radar',
  initialState,
  reducers: {
    setSelectedRegion: (state, action: PayloadAction<TRadar>) => {
      state.selectedRegion = action.payload.selectedRegion
    }
  }
})

export const { setSelectedRegion } = radarSlice.actions

export const radarReducer = radarSlice.reducer
