import { BackArrow } from '@assets'
import { IconButton } from '@mui/material'
import {
  ShortOrganizationDTO,
  THubDocumentFileTemplates,
  THubFormFieldDefinition,
  HubApi
} from '@services'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, Typography } from 'antd'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import * as XLSX from 'xlsx'

import { OrgSelect, FileUpload, MainBox, VerticalBox } from '../../components'
import useMessage from '../../hooks/useMessage'
import { createAutofills } from '../../services/api/endpoints'
import { TData } from '../../utils/solutions.utils'
import { inspectorOptions, sourceOptions, targetOptions } from './options.contants'

const { Title } = Typography

function transformData(
  data: any,
  documentFileTemplates: THubDocumentFileTemplates[],
  customFields: THubFormFieldDefinition[],
  nativeFields: THubFormFieldDefinition[]
) {
  const resultMap: any = {}

  const documentFilesRelation: any = {}
  for (let i = 0; i < documentFileTemplates?.length; i += 1) {
    documentFilesRelation[documentFileTemplates[i]?.fileType?.label] = documentFileTemplates[i]?.id
  }

  const inspectorOptionIdentifiers: Array<string> = inspectorOptions.map(
    option => option.identifier
  )

  const customerFields = []
  const ticketFields = []
  const documentFormalizationTicketFields = []
  const fields = [...customFields, ...nativeFields]
  for (let i = 0; i < fields?.length; i += 1) {
    if (fields?.[i]?.entity == 'customer') customerFields.push(fields?.[i]?.identifier)
    if (fields?.[i]?.entity == 'ticket') ticketFields.push(fields?.[i]?.identifier)
    if (fields?.[i]?.entity == 'documentFormalizationTicket')
      documentFormalizationTicketFields.push(fields?.[i]?.identifier)
  }

  for (let i = 0; i < data.length; i += 1) {
    const record = data[i]

    const recordSource = record?.source
    const recordSourceField = record?.sourceField
    const recordTargetEntity = record?.targetEntity
    const recordTargetField = record?.targetField

    const key = `${recordTargetEntity}-${recordSource}`

    let canonicalSource
    let canonicalSourceField
    let canonicalTargetEntity
    let canonicalTargetField

    if (Object.keys(targetOptions).includes(recordTargetEntity)) {
      if (recordTargetEntity == 'Ticket') {
        if (ticketFields.includes(recordTargetField)) {
          canonicalTargetEntity = 'ticket'
          canonicalTargetField = `ticket_${recordTargetField}`
        } else
          return {
            statusCode: 400,
            message: `Campo do alvo '${recordTargetField}' na linha ${i + 2} não existe!`
          }
      } else if (recordTargetEntity == 'Customer') {
        if (customerFields.includes(recordTargetField)) {
          canonicalTargetEntity = 'customer'
          canonicalTargetField = `customer_${recordTargetField}`
        } else
          return {
            statusCode: 400,
            message: `Campo do alvo '${recordTargetField}' na linha ${i + 2} não existe!`
          }
      }
    } else if (Object.keys(documentFilesRelation).includes(recordTargetEntity)) {
      canonicalTargetEntity = `documentFileTemplate_{id:${documentFilesRelation[recordTargetEntity]}}`
      canonicalTargetField = recordTargetField
    } else {
      return {
        statusCode: 400,
        message: `Campo de entidade alvo '${recordTargetEntity}' na linha ${i + 2} não existe!`
      }
    }

    if (!sourceOptions.includes(recordSource))
      return {
        statusCode: 400,
        message: `Fonte '${recordSource}' na linha ${i + 2} não é válida!`
      }
    else canonicalSource = record?.source

    if (recordSource == 'CUSTOMER_INSPECTOR_PROFILE') {
      if (inspectorOptionIdentifiers.includes(recordSourceField))
        canonicalSourceField = recordSourceField
      else
        return {
          statusCode: 400,
          message: `Campo da fonte '${recordSourceField}' na linha ${i + 2} não é válido!`
        }
    } else if (recordSource == 'CUSTOMER') {
      if (customerFields.includes(recordSourceField)) {
        canonicalSourceField = `customer_${recordSourceField}`
      } else
        return {
          statusCode: 400,
          message: `Campo da fonte '${recordSourceField}' na linha ${i + 2} não existe!`
        }
    } else if (recordSource == 'DOCUMENT_FORMALIZATION_TICKET') {
      if (customerFields.includes(recordSourceField)) {
        canonicalSourceField = `documentFormalizationTicket_${recordSourceField}`
      } else
        return {
          statusCode: 400,
          message: `Campo da fonte '${recordSourceField}' na linha ${i + 2} não existe!`
        }
    }

    if (resultMap?.[key]) {
      resultMap[key].items.push({
        targetField: canonicalTargetField,
        sourceField: canonicalSourceField
      })
    } else {
      resultMap[key] = {
        targetEntity: canonicalTargetEntity,
        source: canonicalSource,
        items: [
          {
            targetField: canonicalTargetField,
            sourceField: canonicalSourceField
          }
        ]
      }
    }
  }

  return { statusCode: 200, data: Object.keys(resultMap).map(key => resultMap?.[key]) }
}

export type TUsersPage = {
  setSelectMenu: Dispatch<SetStateAction<string>>
  tarkenOrganizations: TData<ShortOrganizationDTO[]>
}

export const CreateAutofillPageContainer = ({ setSelectMenu, tarkenOrganizations }: TUsersPage) => {
  const [selectedOrganization, setOrganizationId] = useState<string | null>(null)
  const [autofillData, setAutofillData] = useState<object[] | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const { contextHolder, notify } = useMessage()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: createAutofills
  })

  const { data: customFields } = useQuery({
    queryKey: ['getCustomFields', selectedOrganization],
    queryFn: () => HubApi.getCustomFields(selectedOrganization),
    enabled: !!selectedOrganization
  })

  const { data: documentFileTemplates } = useQuery({
    queryKey: ['getDocumentFileTemplates', selectedOrganization],
    queryFn: () => HubApi.getDocumentFileTemplates(selectedOrganization),
    enabled: !!selectedOrganization
  })

  const { data: nativeFields } = useQuery({
    queryKey: ['getNativeFields'],
    queryFn: () => HubApi.getNativeFields()
  })

  useEffect(() => {
    if (
      autofillData &&
      selectedOrganization &&
      documentFileTemplates &&
      customFields &&
      nativeFields
    ) {
      const data: any = transformData(
        autofillData,
        documentFileTemplates,
        customFields,
        nativeFields
      )
      if (data.statusCode != 200) {
        notify('error', data?.message ?? '')
      } else {
        const formData: any = {}
        formData.organizationId = selectedOrganization
        formData.autofills = data.data
        mutateAsync(formData)
      }
      setAutofillData(null)
    }
    setLoading(false)
  }, [autofillData])

  const onFinish = (spreadsheet: any) => {
    const spreadsheetFile = spreadsheet?.spreadsheet?.[0]?.originFileObj
    if (selectedOrganization && spreadsheetFile) {
      setLoading(true)
      const reader = new FileReader()
      reader.readAsArrayBuffer(spreadsheetFile)
      reader.onload = e => {
        const excelFile = e.target?.result
        const workbook = XLSX.read(excelFile, { type: 'buffer' })
        const worksheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[worksheetName]
        setAutofillData(XLSX.utils.sheet_to_json(worksheet))
      }
    }
  }

  return (
    <MainBox>
      {contextHolder}
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          background: '#FFFFFF',
          borderRadius: '50%'
        }}
        onClick={() => setSelectMenu('menu')}>
        <BackArrow />
      </IconButton>
      <VerticalBox
        style={{ width: '100%', height: '100%', marginRight: '32px', paddingTop: '16px' }}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            width: '65%',
            padding: '16px'
          }}
          name="login"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}>
          <Title level={3} style={{ color: '#212121', margin: 0 }}>
            Criar Autofill
          </Title>
          <Form.Item style={{ margin: 0 }}>
            <OrgSelect
              selectedOrg={selectedOrganization}
              isLoadingOrgs={tarkenOrganizations?.loading}
              setOrganizationId={setOrganizationId}
              organizations={tarkenOrganizations?.data}
              width="200px"
            />
          </Form.Item>
          <FileUpload />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!selectedOrganization || isLoading || !customFields || !nativeFields}
              loading={isPending || isLoading}>
              {isPending ? 'Carregando' : 'Enviar'}
            </Button>
          </Form.Item>
        </Form>
      </VerticalBox>
    </MainBox>
  )
}
