import { axiosHub } from '../config/axiosHub.config'

export class CompleteProfileAnalysisRequestFiltersDTO {
  id?: string
  executionArn?: string
}

const complete = async (filters: CompleteProfileAnalysisRequestFiltersDTO) => {
  const { data } = await axiosHub.post<void>('/api/v1/profile-analysis-requests/complete', filters)
  return data
}

export const ProfileAnalysisRequest = {
  complete
}
