export type TData<T> = {
  data: T | undefined
  loading: boolean
}

export function toCamelCase(str: string) {
  function removeAccentsAndSpecialChars(s: string) {
    return s.normalize('NFD').replace(/[^a-zA-Z0-9 ]/g, '')
  }

  str = removeAccentsAndSpecialChars(str)

  const words: string[] = str.split(' ').filter(word => word.length > 0)

  for (let i = 1; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase()
  }

  words[0] = words[0].toLowerCase()
  str = words.join('')

  return str
}

export function fixIdentifierNumber(str: string, list: Array<string>) {
  function getLastNumberAtEnd(input: string) {
    const match = input.match(/(.*?)(\d+)$/)
    if (match) {
      return {
        number: match[2],
        stringWithoutNumber: match[1]
      }
    } else {
      return {
        number: null,
        stringWithoutNumber: input
      }
    }
  }

  if (list.includes(str)) {
    const strObj = getLastNumberAtEnd(str)
    let baseNum = 2
    if (strObj.number) {
      baseNum = Number(strObj.number) + 1
    }
    str = `${strObj.stringWithoutNumber}${baseNum}`
    while (list.includes(str)) {
      baseNum += 1
      str = `${strObj.stringWithoutNumber}${baseNum}`
    }
  }

  return str
}
