import { colors } from '@mui/material'
import { cloneDeep } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { AxisDomain } from 'recharts/types/util/types'

import { getDiffInDays } from '../../shared/date'
import { AreasChartView } from './AreasChartView'
import { AREA_ACTIONS } from './enums'

type Point = {
  label: string
  value: Date
}

export type TArea = {
  id: number
  xMin: Point
  xMax: Point
  color: string
}

export enum CHART_MODE {
  VISUALIZATION = 'VISUALIZATION',
  EDITION = 'EDITION',
  ZOOM = 'ZOOM',
  CREATION = 'CREATION'
}

export type TData = {
  x: number
  y: number
}

type TActiveAreaToResize = {
  index: number
  value: 'xMin' | 'xMax'
}

type TAreasChart = {
  data: TData[]
  lines?: { id: string; color: string; data: TData[] }[]
  areas: TArea[]
  setAreas: (areas: TArea[]) => void
  disableEditing?: boolean
  headerComponent?: React.ReactNode
  legendComponent?: React.ReactNode
  title?: string
  mode: CHART_MODE
  setMode: Dispatch<SetStateAction<CHART_MODE>>
  selectedAreas: TArea[]
  setSelectedAreas: (action: AREA_ACTIONS, area?: TArea) => void
  height?: string
  width?: string
  hideAreas?: boolean
  onFinishClick?: () => Promise<boolean | undefined>
  isEditable?: boolean
  isLoading?: boolean
  isLoadingRecover?: boolean
  isLoadingSave?: boolean
  onSave?: () => void
  onRecover?: () => void
}

export const AreasChart = ({
  data,
  areas,
  lines,
  setAreas: updateAreas,
  disableEditing = false,
  headerComponent,
  legendComponent,
  title,
  mode,
  setMode,
  selectedAreas,
  setSelectedAreas: updateSelectedAreas,
  height,
  width,
  hideAreas,
  onFinishClick,
  isEditable,
  isLoading,
  isLoadingRecover,
  isLoadingSave,
  onSave,
  onRecover
}: TAreasChart) => {
  const [previousMode, setPreviousMode] = useState(CHART_MODE.VISUALIZATION)
  const [activeAreaToResize, setActiveAreaToResize] = useState<TActiveAreaToResize>()
  const [domainX, setDomainX] = useState<AxisDomain>()
  const [mouseX, setMouseX] = useState<number>()
  const [previousMouseX, setPreviousMouseX] = useState<number>()
  const [backupAreasBeforeEditing, setBackupAreasBeforeEditing] = useState<TArea[]>()
  const [intersections, setIntersectionAreas] = useState<TArea[]>([])
  const [showIntersections, setShowIntersections] = useState(false)
  const [monthlyTicks, setMonthlyTicks] = useState<number[]>([])

  const defaultDomainX = useRef<AxisDomain>()

  const changeMode = (newMode: CHART_MODE) => {
    if (newMode === CHART_MODE.VISUALIZATION) updateSelectedAreas(AREA_ACTIONS.DESELECT_ALL)
    if (newMode === CHART_MODE.EDITION) setBackupAreasBeforeEditing(cloneDeep(areas))

    setPreviousMode(mode)
    setMode(newMode)
  }

  const getPointedArea = (mouseX: number) => {
    for (let i = 0; i < areas.length; i++)
      if (mouseX > areas[i].xMin.value.getTime() && mouseX < areas[i].xMax.value.getTime())
        return areas[i]
  }

  const resetDomainX = () => setDomainX(defaultDomainX.current)

  const isResizeInvertingTheArea = (area: TArea, edgeToResize: 'xMin' | 'xMax', newEdgeX: number) =>
    (edgeToResize === 'xMin' && newEdgeX >= area.xMax.value.getTime()) ||
    (edgeToResize === 'xMax' && newEdgeX <= area.xMin.value.getTime())
      ? true
      : false

  const resizeActiveArea = (mouseX: number) => {
    if (
      !activeAreaToResize ||
      isResizeInvertingTheArea(areas[activeAreaToResize.index], activeAreaToResize.value, mouseX)
    )
      return

    const newAreas = [...areas]
    newAreas[activeAreaToResize.index][activeAreaToResize.value] = {
      ...areas[activeAreaToResize.index][activeAreaToResize.value],
      value: new Date(mouseX)
    }
    updateAreas(newAreas)
  }

  const restoreAreas = () => {
    if (!backupAreasBeforeEditing) return
    updateAreas(backupAreasBeforeEditing)
    setBackupAreasBeforeEditing(undefined)
    changeMode(CHART_MODE.VISUALIZATION)
  }

  const deleteSelectedAreas = () => {
    updateAreas(
      areas.filter(
        area => selectedAreas.findIndex(selectedArea => selectedArea.id === area.id) === -1
      )
    )
  }

  const handleMouseUpZoom = (mouseX: number) => {
    if (!previousMouseX) {
      setPreviousMouseX(mouseX)
      return
    }
    setDomainX(previousMouseX < mouseX ? [previousMouseX, mouseX] : [mouseX, previousMouseX])
    setPreviousMouseX(undefined)
    setMode(previousMode)
  }

  const handleMouseUpCreation = (mouseX: number) => {
    if (!previousMouseX) {
      setPreviousMouseX(mouseX)
      return
    }

    const xMaxValue = Math.max(previousMouseX, mouseX)
    const xMinValue = Math.min(previousMouseX, mouseX)

    updateAreas([
      ...areas,
      {
        color: colors.amber[500],
        id: -1, // The created area id is set to -1, to facilitate identify it
        xMax: {
          label: '',
          value: new Date(xMaxValue)
        },
        xMin: {
          label: '',
          value: new Date(xMinValue)
        }
      }
    ])

    setPreviousMouseX(undefined)
    setMode(previousMode)
  }

  const handleMouseDown = (mouseX: number) => {
    if (mode === CHART_MODE.EDITION) {
      const clickedArea = getPointedArea(mouseX)
      if (!clickedArea) return
      if (selectedAreas.find(area => area.id === clickedArea.id))
        updateSelectedAreas(AREA_ACTIONS.DESELECT, clickedArea)
      else updateSelectedAreas(AREA_ACTIONS.SELECT, clickedArea)
    }
  }

  const handleMouseMove = (event: CategoricalChartState | undefined) => {
    if (!event?.activeLabel) return
    if (previousMouseX) setMouseX(+event.activeLabel)
    if (mode === CHART_MODE.EDITION) resizeActiveArea(+event.activeLabel)
  }

  const handleMouseUp = (event: CategoricalChartState | undefined) => {
    setActiveAreaToResize(undefined) // Drag and drop
    if (!event?.activeLabel) return
    if (mode === CHART_MODE.ZOOM) handleMouseUpZoom(+event.activeLabel)
    if (mode === CHART_MODE.CREATION) handleMouseUpCreation(+event.activeLabel)
  }

  const dragAreaBoundary = (xPos: number, clickedArea: TArea) => {
    if (!selectedAreas.find(area => area.id === clickedArea.id))
      updateSelectedAreas(AREA_ACTIONS.SELECT, clickedArea)

    if (activeAreaToResize) return

    for (let i = 0; i < areas.length; i++) {
      if (areas[i].xMin.value.getTime() === xPos) {
        setActiveAreaToResize({ index: i, value: 'xMin' })
        return
      }
      if (areas[i].xMax.value.getTime() === xPos) {
        setActiveAreaToResize({ index: i, value: 'xMax' })
        return
      }
    }
  }

  const intersectionBetweenAreas = (area1: TArea, area2: TArea): TArea | null => {
    const olderArea = area1.xMin.value.getTime() <= area2.xMin.value.getTime() ? area1 : area2
    const newerArea = area1.xMin.value.getTime() <= area2.xMin.value.getTime() ? area2 : area1
    if (olderArea.xMax.value.getTime() >= newerArea.xMax.value.getTime())
      return {
        ...newerArea,
        color: colors.red[500]
      }

    if (olderArea.xMax.value.getTime() > newerArea.xMin.value.getTime())
      return {
        ...newerArea,
        xMax: olderArea.xMax,
        color: colors.red[500]
      }
    return null
  }

  const getAllIntersections = (areasArray: TArea[]): TArea[] => {
    const intersections: TArea[] = []
    for (let i = 0; i < areasArray.length; i++)
      for (let j = i + 1; j < areasArray.length; j++) {
        const intersection = intersectionBetweenAreas(areasArray[i], areasArray[j])
        if (intersection) intersections.push(intersection)
      }
    return intersections
  }

  useEffect(() => {
    if (showIntersections) setIntersectionAreas(getAllIntersections(areas))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, showIntersections])

  useEffect(() => {
    if (!data.length || !areas.length || (mode !== CHART_MODE.EDITION && defaultDomainX.current))
      return

    const firstArea = areas.reduce(
      (firstArea, currentArea) =>
        currentArea.xMin.value < firstArea.xMin.value ? currentArea : firstArea,
      areas[0]
    )

    const lastArea = areas.reduce(
      (lastArea, currentArea) =>
        currentArea.xMax.value > lastArea.xMax.value ? currentArea : lastArea,
      areas[0]
    )

    defaultDomainX.current = [
      Math.min(data[0].x, firstArea.xMin.value.getTime()),
      Math.max(data[data.length - 1].x, lastArea.xMax.value.getTime())
    ]
    setDomainX(defaultDomainX.current)

    const firstMonth = new Date(Number(defaultDomainX.current[0]))
    firstMonth.setMonth(firstMonth.getMonth() + 1)
    firstMonth.setDate(1)
    const lastMonth = new Date(Number(defaultDomainX.current[1]))
    lastMonth.setDate(1)
    const newMonthlyTicks = []

    while (getDiffInDays(lastMonth, firstMonth) > 0) {
      newMonthlyTicks.push(firstMonth.getTime())
      firstMonth.setMonth(firstMonth.getMonth() + 1)
    }

    setMonthlyTicks(newMonthlyTicks)
  }, [data, areas, mode])

  const onFinishClickFunction = onFinishClick
    ? async () => {
        const success = await onFinishClick()
        if (success) changeMode(CHART_MODE.VISUALIZATION)
        else if (success === false) restoreAreas()
      }
    : undefined

  return (
    <AreasChartView
      data={data}
      areas={areas}
      lines={lines}
      mode={mode}
      changeMode={changeMode}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      handleMouseUp={handleMouseUp}
      dragAreaBoundary={dragAreaBoundary}
      domainX={domainX}
      resetDomainX={resetDomainX}
      mouseX={mouseX}
      previousMouseX={previousMouseX}
      title={title}
      legendComponent={legendComponent}
      headerComponent={headerComponent}
      selectedAreas={selectedAreas}
      restoreAreas={restoreAreas}
      deleteSelectedAreas={deleteSelectedAreas}
      showIntersections={showIntersections}
      setShowIntersections={setShowIntersections}
      intersections={intersections}
      monthlyTicks={monthlyTicks}
      disableEditing={disableEditing}
      height={height}
      width={width}
      hideAreas={hideAreas}
      onFinishClick={onFinishClickFunction}
      isEditable={isEditable}
      isLoading={isLoading}
      isLoadingRecover={isLoadingRecover}
      isLoadingSave={isLoadingSave}
      onSave={onSave}
      onRecover={onRecover}
    />
  )
}
