/**
 * @Important
 *
 * Use the following terms to refer to:
 *
 * - HUB Area (Talhão) = landField;
 * - HUB Season in the chart = chartArea;
 * - HUB Season in the table = tableArea; (tableArea = chartArea + table payload, so it can be used as chartArea)
 * - HUB Property = the entire code refers to a specific property, so it is unnecessary;
 */

import { GisSaveSeasonsAreasRequest } from '@/services/Gis/Areas/contract/request/GisSaveSeasonsAreas.request'
import { colors } from '@mui/material'
import Box from '@mui/material/Box'
import { GisApi } from '@services'
import * as turf from '@turf/turf'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { TData } from '../../../components/AreasChart/AreasChartContainer'
import { AreasChartTable } from '../../../components/AreasChartTable/AreasChartTableContainer'
import { TTableArea } from '../../../components/AreasTable/AreasTableView'
import { ConfirmationModal } from '../../../components/confirmationModal'
import { MessageModal } from '../../../components/messageModal'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { RootState } from '../../../redux/store'
import { THubDetectedSeason } from '../../../services/HubApi'
import { LutienApi, TCrop } from '../../../services/LutienApi'
import { RadarApi, TRadarDetectedSeason } from '../../../services/RadarApi'
import { UnidentifiedCrop } from '../../../shared/constants/unidentifiedCrop'
import { ShareSeasonsModal } from './components/ShareSeasonsModal'
import { Sidebar } from './components/Sidebar'
import { TAreasCrops } from './components/Sidebar/components/AreaCard'

/* -------------------- Types -------------------- */

export type TLandField = {
  id: string
  name: string
  geometry: turf.Geometry
}

/* -------------------- Start of Main Component -------------------- */

export const EditSeasons = () => {
  const [ndviData, setNdviData] = useState<TData[]>([])
  const [tableAreas, setTableAreas] = useState<TTableArea[]>([])
  const [selectedLandFieldId, setSelectedLandFieldId] = useState<string>()
  const [landFields, setLandFields] = useState<TLandField[]>([])
  const [allAreas, setAllAreas] = useState<turf.FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  > | null>(null)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [selectedAreaCoordinates, setSelectedAreaCoordinates] =
    useState<turf.helpers.Position | null>(null)
  const [areasCrops, setAreasCrops] = useState<TAreasCrops[]>([])
  const [checkedAreasIds, setCheckedAreasds] = useState<string[]>([])

  const [cultivationAreas, setCultivationAreas] = useState<{ id: string; name: string }[]>([]) // TODO: cultivationAreas = landFields!!

  const [cropsCatalog, setCropsCatalog] = useState<TCrop[]>([])
  const [detectedSeasons, setDetectedSeasons] = useState<THubDetectedSeason[]>([])
  const [message, setMessage] = useState({
    title: '',
    message: ''
  })

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { task_id: taskId } = useParams()

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  /* -------------------- API Requests -------------------- */

  const fetchAreaNdvi = useCallback(
    (areaId: string) => GisApi.areas.getNdvi(areaId),
    [selectedLandFieldId]
  )

  const fetchDetectedSeasons = useCallback(
    (taskId?: string) => {
      if (!taskId) return [] as TRadarDetectedSeason[]
      return RadarApi.getSeasons(taskId)
    },
    [taskId]
  )

  const fetchLandFields = useCallback(
    (taskId?: string) => {
      if (!taskId) return null
      return RadarApi.getAreas(taskId)
    },
    [taskId]
  )

  /* -------------------- Several Functions -------------------- */
  function shareSeasonsBetweenAreas(ownerAreaId: string, recipientAreasIds: string[]) {
    const donorSeasons = tableAreas.filter(area => area.landFieldId === ownerAreaId)
    const seasonsWithoutRecipients = tableAreas.filter(
      area => !recipientAreasIds.includes(area.landFieldId)
    )
    const newRecipientSeasons: TTableArea[] = []
    recipientAreasIds.forEach(recipientId => {
      donorSeasons.forEach(season => {
        newRecipientSeasons.push({
          ...season,
          landFieldId: recipientId
        })
      })
    })
    setTableAreas([...seasonsWithoutRecipients, ...newRecipientSeasons])
    setMessage({
      title: 'Safras replicadas',
      message: 'Confirme cada talhão individualmente para salvar o registro.'
    })
  }

  /* -------------------- Handlers -------------------- */

  function onDoneButtonClick() {
    //sidebar confirm button
    setIsModalOpen(true)
  }

  const onSelectCultArea = (areaId: string) => {
    setSelectedLandFieldId(areaId)
  }

  function onCancelReview() {
    if (confirm('Deseja cancelar a revisão?')) navigate('/radar')
  }

  function formattedSeasonsToSave(): GisSaveSeasonsAreasRequest[] {
    const seasonsToSave: GisSaveSeasonsAreasRequest[] = []
    tableAreas
      .filter(area => area.landFieldId === selectedLandFieldId)
      .forEach(area => {
        const startDate = area.xMin.value
        const endDate = area.xMax.value
        const areaCropInfo = area.infos.find(e => e.label == 'Cultura')
        const ndviStartIndex = ndviData.findIndex(data => data.x >= startDate.getTime())
        const ndviEndIndex = ndviData.findIndex(data => data.x >= endDate.getTime())
        let ndvipeak = 0
        if (ndviStartIndex > -1 && ndviEndIndex > -1)
          ndvipeak = Math.max(...ndviData.slice(ndviStartIndex, ndviEndIndex + 1).map(e => e.y))
        const crop = areaCropInfo
          ? cropsCatalog.find(crop => areaCropInfo.value == crop.cropName)
          : null
        seasonsToSave.push({
          crops_id: crop && crop.cropId !== '' ? crop.cropId : null,
          duration: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
          end_date: endDate.toISOString(),
          ndvi_peak: ndvipeak,
          start_date: startDate.toISOString(),
          duration_pred: null,
          end_date_pred: null,
          ndvi_peak_pred: null
        } as GisSaveSeasonsAreasRequest)
      })
    return seasonsToSave
  }

  async function saveSeasonsInApi(areaId: string, seasons: GisSaveSeasonsAreasRequest[]) {
    let success = false
    await GisApi.areas
      .saveSeasons(areaId, seasons)
      .then(() => {
        if (!checkedAreasIds.includes(areaId)) setCheckedAreasds(e => [...e, areaId])
        alert('Salvo com sucesso!')
        success = true
      })
      .catch(() => alert('Erro ao salvar'))

    return success
  }

  /* -------------------- UseEffects -------------------- */

  useEffect(() => {
    onPageRender()
  }, [])

  useEffect(() => {
    if (selectedLandFieldId) console.log('current season', formattedSeasonsToSave())
  }, [selectedLandFieldId])

  async function onPageRender() {
    // dispatch(changeHeader({ mode: 'fixed' }));

    const seasons: TRadarDetectedSeason[] = await fetchDetectedSeasons(taskId)
    setDetectedSeasons(
      seasons
        .filter(season => !!season.end_date)
        .map((season, index) => {
          return {
            crop_name: '',
            crops_id: season.crops_id ? season.crops_id : '',
            area_id: season.area_id,
            area_name: `Talhão ${index}`,
            start_date: season.start_date,
            end_date: season.end_date,
            validation: season.has_been_checked ? 'valid' : ''
          } as THubDetectedSeason
        })
    )

    setCropsCatalog([...(await LutienApi.getCrops()), UnidentifiedCrop])

    // const landFields = await RadarApi.getAreas(
    //   '556663f3-d061-4902-89c3-0185597e1cb3'
    // );

    const landFields = await fetchLandFields(taskId)
    setAllAreas(
      landFields
        ? {
            ...landFields,
            features: landFields.features.filter(f => f.properties?.has_productive_area)
          }
        : null
    )
    setSelectedLandFieldId(landFields?.features[0]?.properties?.area_id || '')
    setLandFields(
      landFields
        ? landFields.features
            .filter(f => f.properties?.has_productive_area)
            .map((propertyCultArea, index) => ({
              id: propertyCultArea.properties?.area_id,
              name: `Talhão ${index}`,
              geometry: propertyCultArea.geometry as turf.Geometry
            }))
        : []
    )
  }

  useEffect(() => {
    const areas: {
      id: string
      checked: boolean
    }[] = []
    landFields
      .map(e => e.id)
      .forEach(id => {
        areas.push({
          id: id,
          checked: true
        })
      })
    detectedSeasons.forEach(s => {
      if (s.validation === '') {
        const index = areas.findIndex(a => a.id == s.area_id)
        if (index > -1) areas[index].checked = false
      }
    })
    setCheckedAreasds(areas.filter(e => e.checked).map(e => e.id))
  }, [landFields, detectedSeasons])

  useEffect(() => {
    if (detectedSeasons.length > 0 && cropsCatalog.length > 0) {
      const seasonsWithCrops: THubDetectedSeason[] = detectedSeasons.map(season => {
        const crop = cropsCatalog.find(e => e.cropId === season.crops_id)
        return {
          ...season,
          crop_name: crop !== undefined ? crop.cropName : 'Não identificado'
        } as THubDetectedSeason
      })
      setDetectedSeasons(seasonsWithCrops)
    }
  }, [cropsCatalog])

  useEffect(() => {
    const temp: TAreasCrops[] = []
    tableAreas.forEach(tArea => {
      const cropInfo = {
        areaId: tArea.landFieldId,
        cropName: tArea.infos.find(e => e.label == 'Cultura')?.value
      }
      if (typeof cropInfo.cropName == 'string') {
        const cropName = cropInfo.cropName == 'Não identificado' ? 'Outros' : cropInfo.cropName
        const areaIndex = temp.findIndex(e => e.areaId == cropInfo.areaId)
        if (areaIndex > -1) {
          const cropAlreadyAdded = temp[areaIndex].cropsNames.includes(cropName)
          if (!cropAlreadyAdded)
            temp[areaIndex] = {
              ...temp[areaIndex],
              cropsNames: [...temp[areaIndex].cropsNames, cropName]
            }
        } else {
          temp.push({
            areaId: cropInfo.areaId,
            cropsNames: [cropName]
          })
        }
      }
    })
    setAreasCrops(temp)
  }, [tableAreas])

  useEffect(() => {
    const selectedPropertyAreaGeometry = landFields.find(
      propertyArea => propertyArea.id == selectedLandFieldId
    )?.geometry

    selectedPropertyAreaGeometry !== undefined
      ? setSelectedAreaCoordinates(turf.centroid(selectedPropertyAreaGeometry).geometry.coordinates)
      : setSelectedAreaCoordinates(null)

    if (!selectedLandFieldId) return

    fetchAreaNdvi(selectedLandFieldId).then(res => {
      setNdviData(
        res.map(({ mean, reference_date }) => ({
          x: new Date(reference_date).getTime(),
          y: mean
        }))
      )
    })
  }, [selectedLandFieldId])

  return (
    <Box
      sx={{
        width: '100vw',
        height: 'calc(100vh-3rem)',
        display: 'flex',
        bgcolor: colors.grey[50]
      }}>
      {taskId && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          taskType={'seasons'}
          id={taskId}
        />
      )}
      <Box
        style={{
          height: '100%',
          width: '330px'
        }}>
        {message.message !== '' && (
          <MessageModal {...message} clearMessage={() => setMessage({ title: '', message: '' })} />
        )}
        <ShareSeasonsModal
          open={openShareModal}
          data={ndviData}
          areas={tableAreas.filter(
            ({ landFieldId: cultAreaId }) => cultAreaId === selectedLandFieldId
          )}
          propertyAreas={landFields}
          checkedAreas={checkedAreasIds}
          onClose={() => setOpenShareModal(false)}
          originAreaId={selectedLandFieldId || ''}
          shareSeasonsBetweenAreas={shareSeasonsBetweenAreas}
          selectedAreaCoordinates={selectedAreaCoordinates}
          geojson={allAreas}
        />
        <Sidebar
          areas={landFields}
          selectedAreaId={selectedLandFieldId || ''}
          onSelectArea={onSelectCultArea}
          // onSubmitReview={onSubmitReview}
          // onCancelReview={onCancelReview}
          onDoneButtonClick={onDoneButtonClick}
          areasCrops={areasCrops}
          checkedIds={checkedAreasIds}
        />
      </Box>
      <AreasChartTable
        ndviData={ndviData}
        height={'calc(100vh - 3rem)'}
        tableAreas={tableAreas}
        selectedLandFieldId={selectedLandFieldId}
        setOpenShareModal={setOpenShareModal}
        setTableAreas={setTableAreas}
        cropsCatalog={cropsCatalog}
        detectedSeasons={detectedSeasons}
        cultivationAreas={cultivationAreas}
        setCultivationAreas={setCultivationAreas}
        isEditable={
          selectedLandFieldId !== undefined && !checkedAreasIds.includes(selectedLandFieldId)
        }
        onFinishClick={() => {
          if (selectedLandFieldId && confirm('Salvar areas editadas?')) {
            return saveSeasonsInApi(selectedLandFieldId, formattedSeasonsToSave())
          } else {
            return new Promise(function (myResolve, myReject) {
              myResolve(undefined)
              myReject(undefined)
            })
          }
        }}
      />
    </Box>
  )
}
