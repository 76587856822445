import { Close, DoneAll } from '@mui/icons-material'
import { Box, Button, colors, Typography } from '@mui/material'
import { Scrollbars } from 'react-custom-scrollbars'

import { THubProperty } from '../../../../../services/HubApi'
import { TLandField } from '../../EditSeasons'
import { AreaCard } from './components/AreaCard'

interface ISidebar {
  property?: THubProperty
  areas: TLandField[]
  selectedAreaId: string
  onSelectArea: (areaId: string) => void
  onSubmitReview: () => void
  onCancelReview: () => void
}

export const Sidebar = ({
  property,
  areas,
  selectedAreaId,
  onSelectArea,
  onSubmitReview,
  onCancelReview
}: ISidebar) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box
        sx={{
          height: '4rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          paddingInline: '0.5rem',
          mb: '0.5rem'
        }}>
        <Box>
          <Typography variant="h6">Propriedade</Typography>
          <Typography color={colors.grey[700]} variant="subtitle2">
            {property ? property.name : '#propertyName'}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" p="0.25rem 0.5rem">
        Todos os Talhões
      </Typography>
      <Box
        sx={{
          flexGrow: 1
        }}>
        <Scrollbars style={{ width: 330, height: 580, paddingLeft: 5 }}>
          {areas.map((area, index) => {
            return (
              <AreaCard
                key={area.id}
                area={area}
                isSelected={area.id === selectedAreaId}
                index={index + 1}
                onClick={() => onSelectArea(area.id)}
              />
            )
          })}
        </Scrollbars>
      </Box>
      <Box
        sx={{
          p: '0.25rem 0.5rem',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '0.5rem'
        }}>
        <Button variant="contained" disableElevation color="success" onClick={onSubmitReview}>
          <DoneAll />
          Salvar
        </Button>
        <Button variant="contained" disableElevation color="error" onClick={onCancelReview}>
          <Close />
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}
