import { CreateCriminalRecordDTO } from '@/services/Hub/AssessmentsCriminal'
import { CRIMINAL_ASSESSMENT_STATUS } from '@/services/Hub/enums/CriminalReports.enum'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildCreateCriminalRecordDTO {
  record: IRecordCandidate
  documentUrl: string
  canonicalProfileId: string
}

export const buildCreateCriminalRecordDTO = (
  props: IBuildCreateCriminalRecordDTO
): CreateCriminalRecordDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  return {
    canonicalProfileId,
    creationType: 'USER_INPUT',
    referenceDate: new Date().toISOString(),
    status: record.documentStatus as CRIMINAL_ASSESSMENT_STATUS,
    documentUrl
  }
}
