import { SUBJECT_DOMAINS } from '@/services/Hub/Authorization/authorization.constants'

export const SIZES = {
  SIDEBAR_WIDTH: '100px',
  HEADER_GAP: '20px',
  MAX_FORM_WIDTH: '1920px'
}

export const SUBJECT_DOMAIN_KEYS = Object.keys(SUBJECT_DOMAINS)

export const TMongoOperators = {
  $lte: 'Menor ou Igual',
  $gte: 'Maior ou Igual',
  $eq: 'igual a',
  $ne: 'diferente de'
}

export type TMongoOperatorKeys = keyof typeof TMongoOperators
