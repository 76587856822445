import { Box, Typography } from '@mui/material'

import { TAuthorizationFormTab } from '../AuthorizationForm.interface'

interface IFormTabs {
  handleTabChange: (newValue: TAuthorizationFormTab) => void
  currentTab: TAuthorizationFormTab
  availableTabs: TAuthorizationFormTab[]
}

export const FormTabs = (props: IFormTabs) => {
  const { availableTabs, handleTabChange, currentTab } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0px'
      }}>
      {availableTabs.map(tab => {
        const isActive = tab.value === currentTab.value
        return (
          <Box
            key={tab.value}
            className={`authorization-form-tab ${tab.value}`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#fff',
              cursor: 'pointer',
              width: 'fit-content',

              padding: '4px 16px',

              ...(isActive && {
                background: '#ccc',
                outline: '1px solid #ccc',
                border: '1px solid black',
                borderBottom: 'none',
                borderRadius: '8px 8px 0 0'
              }),

              transition: 'background 0.4s ease-in, border-radius 0.4s ease-in'
            }}
            onClick={() => handleTabChange(tab)}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {tab.label}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
