import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { confirmable } from 'react-confirm'

const sxButton = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  borderRadius: '12px',
  height: '2rem',
  fontSize: '0.75rem',
  color: 'rgba(0, 0, 0, 0.6)',
  bgcolor: colors.blueGrey[100],
  '&:hover': {
    bgcolor: colors.blueGrey[200]
  },
  p: '0.25rem 1rem'
}

export interface IDialogConfirmProps {
  show: boolean
  proceed: (proceed: boolean) => void
  confirmation: string
  options?: {
    title?: string
  }
}

const DialogConfirmComponent = (props: IDialogConfirmProps) => {
  const { show, proceed, confirmation, options } = props

  return (
    <Dialog onClose={() => proceed(false)} open={show}>
      <DialogTitle sx={{ p: '1rem 2rem' }}>
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 700
          }}>
          {options?.title || 'Alerta'}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: '2rem', mt: '1rem', minWidth: '510px' }}>
        <Typography variant="body1">{confirmation}</Typography>
      </DialogContent>

      <DialogActions sx={{ p: '1rem 2rem' }}>
        <Button color="warning" sx={sxButton} onClick={() => proceed(false)}>
          Cancelar
        </Button>
        <Button
          sx={{ ...sxButton, bgcolor: colors.blue[400], '&:hover': { bgcolor: colors.blue[500] } }}
          onClick={() => proceed(true)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export const DialogConfirm = confirmable(DialogConfirmComponent)
