import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { AreasIcon, SeasonsIcon, RequestChecked } from '../../../../../assets/svg'
import { TRequest } from '../../../../../services/RadarApi'

interface IRequestCard {
  request: TRequest
}

export const RequestCard = ({ request }: IRequestCard) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        padding: '22.5px 18px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid #E9ECEF',
        borderRadius: '12px',
        marginTop: '24px'
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center'
        }}>
        {request.validated && <RequestChecked />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#495057'
            }}>
            {request.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#868E96',
              marginTop: '4px'
            }}>
            {`${request.perc_seg_complete_task.toLocaleString('pt-BR', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}% de áreas validadas   |   ${request.perc_season_complete_task.toLocaleString(
              'pt-BR',
              {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }
            )}% de safras validadas   |   id: ${request.id}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '14px 18px',
            gap: '10px',
            height: '47px',
            background: '#F8F9FA',
            border: '2px solid #DEE2E6',
            borderRadius: '12px',
            color: '#111315'
          }}
          // onClick={() =>
          //   navigate(
          //     `/radar/segmentations/3da3da9b-daaa-47e4-8d48-d1bc622e0996/${request.name}`
          //   )
          // }
          onClick={() =>
            navigate(`/radar/groupedTasks/${request.name}/${request.id}/segmentation`)
          }>
          <>
            <AreasIcon />
            Validar Talhões
          </>
        </Button>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '14px 18px',
            gap: '10px',
            height: '47px',
            background: '#F8F9FA',
            border: '2px solid #DEE2E6',
            borderRadius: '12px',
            color: '#111315',
            marginLeft: '24px'
          }}
          // onClick={() =>
          //   navigate(
          //     `/radar/seasons/8aee2327-94e8-4d7b-a1bf-4e642ed31f3d/d96875b2-04b2-429b-a762-659aebf62110/${request.name}`
          //   )
          // }
          onClick={() => navigate(`/radar/groupedTasks/${request.name}/${request.id}/seasons`)}>
          <SeasonsIcon />
          Validar Safras
        </Button>
      </Box>
    </Box>
  )
}
