import { Box, BoxProps } from '@mui/material'

interface IMainBox extends BoxProps {
  children: React.ReactNode
}

export const MainBox = (params: IMainBox) => {
  const { children, ...props } = params

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '360px',
        gap: '16px'
      }}
      {...props}>
      {children}
    </Box>
  )
}
