import { Box, Typography } from '@mui/material'
import { LUTIEN_MEMBERSHIP_ROLE, MembershipDTO, USER_ROLE } from '@services'

import { displayMembershipRoles, displayUserRoles } from './utils/roleEnumToString'

//todo(maiaatala): make it so we can change the member current role with these buttons

const MemberRole = ({ currentRole }: { currentRole: LUTIEN_MEMBERSHIP_ROLE }) => {
  return (
    <Typography
      sx={{
        background: '#9DD9E199',
        fontWeight: 600,
        fontSize: '12px',
        padding: '2px 4px',
        borderRadius: '4px',
        whiteSpace: 'nowrap'
      }}>
      {displayMembershipRoles(currentRole)}
    </Typography>
  )
}

const UserRole = ({ currentRole }: { currentRole: USER_ROLE }) => {
  return (
    <Typography
      sx={{
        background: '#EEACC299',
        fontWeight: 600,
        fontSize: '12px',
        padding: '2px 4px',
        borderRadius: '4px',
        whiteSpace: 'nowrap'
      }}>
      {displayUserRoles(currentRole)}
    </Typography>
  )
}

export const MemberInformationSystemRoles = ({ member }: { member: MembershipDTO }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap: '4px'
      }}>
      <UserRole currentRole={member.user.role} />
      <MemberRole currentRole={member.role} />
    </Box>
  )
}
