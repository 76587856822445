import { axiosHub } from '@/services/config/axiosHub.config'

import { getMembershipRolesOptions } from './contract/request/membership-role.request'
import { IGetResources } from './contract/request/resources.request'
import { CreateRoleBody, UpdateRoleBody } from './contract/request/role.request'
import { MembershipRoleDTO } from './contract/response/membership-role.response'
import { AuthorizationResourceResponse } from './contract/response/resources.response'
import { RoleDTO } from './contract/response/roles.response'
import { SubjectWithDetailedActionsResponse } from './contract/response/subjects.response'

const getRoles = async (organizationId: string): Promise<RoleDTO[]> => {
  const { data } = await axiosHub.get<RoleDTO[]>(
    `/api/v1/authorization/roles?organizationId=${organizationId}`
  )
  return data
}

const deleteRole = async (roleId: string): Promise<void> => {
  const { data } = await axiosHub.delete<void>(`/api/v1/authorization/roles/${roleId}`)
  return data
}

const postRole = async (body: CreateRoleBody): Promise<RoleDTO> => {
  const { data } = await axiosHub.post<RoleDTO>('/api/v1/authorization/roles', body)
  return data
}

const updateRole = async (roleId: string, body: UpdateRoleBody): Promise<RoleDTO> => {
  const { data } = await axiosHub.put<RoleDTO>(`/api/v1/authorization/roles/${roleId}`, body)
  return data
}

const getMembershipRoles = async (
  params: getMembershipRolesOptions
): Promise<MembershipRoleDTO[]> => {
  const { data } = await axiosHub.get<MembershipRoleDTO[]>(
    '/api/v1/authorization/membership-roles',
    {
      params
    }
  )
  return data
}

const deleteMembershipRoles = async (membershipRoleId: string): Promise<void> => {
  const { data } = await axiosHub.delete<void>(
    `/api/v1/authorization/membership-roles/${membershipRoleId}`
  )
  return data
}

interface CreateMembershipRole {
  membershipId: string
  roleId: string
}

const postMembershipRoles = async (body: CreateMembershipRole[]): Promise<void> => {
  const { data } = await axiosHub.post<void>('/api/v1/authorization/membership-roles/bulk', body)
  return data
}

const getSubjectsWithDetailedAction = async (): Promise<SubjectWithDetailedActionsResponse[]> => {
  const { data } = await axiosHub.get<SubjectWithDetailedActionsResponse[]>(
    '/api/v1/authorization/subjects/detailed'
  )
  return data
}

const getResources = async (params: IGetResources): Promise<AuthorizationResourceResponse> => {
  const { data } = await axiosHub.get<AuthorizationResourceResponse>(
    '/api/v1/authorization/resources',
    {
      params
    }
  )
  return data
}

export const Authorization = {
  getRoles,
  deleteRole,
  getMembershipRoles,
  postMembershipRoles,
  deleteMembershipRoles,
  getSubjectsWithDetailedAction,
  getResources,
  postRole,
  updateRole
}
