import { EllipsisText } from '@components'
import { Clear } from '@mui/icons-material'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { useState } from 'react'

interface IRoleTag {
  roleLabel: string
  membershipRoleId: string
  handleRemoveRole: (membershipRoleId: string, onDelete?: () => void, onError?: () => void) => void
}

export const RoleTag = (props: IRoleTag) => {
  const { roleLabel, handleRemoveRole, membershipRoleId } = props
  const [isDeleting, setIsDeleting] = useState(false)

  return (
    <Box
      sx={{
        borderRadius: '8px',
        display: 'flex',
        padding: '4px 6px',
        alignItems: 'center',
        background: isDeleting ? '#faa0a0' : '#DBE4FF',
        transition: 'all 1s ease-in'
      }}>
      <EllipsisText
        variant="body1"
        text={roleLabel}
        maxLength={20}
        sx={{
          color: isDeleting ? '#c73636' : '#364FC7',
          fontSize: '14px',
          fontWeight: 500
        }}
      />
      <IconButton
        size="small"
        disabled={isDeleting}
        sx={{
          '& svg': {
            height: '16px',
            width: '16px'
          }
        }}
        onClick={() => {
          setIsDeleting(true)
          handleRemoveRole(
            membershipRoleId,
            () => {
              setIsDeleting(false)
            },
            () => {
              setIsDeleting(false)
            }
          )
        }}>
        {isDeleting ? <CircularProgress size={16} color="error" /> : <Clear />}
      </IconButton>
    </Box>
  )
}
