import { Delete, Edit, Info } from '@mui/icons-material'
import { colors, Link, Typography, Popover, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import { makeStyles } from '@mui/styles'
import * as turf from '@turf/turf'

// import { CustomSwitch } from './CustomSwitch'

const useStyles = makeStyles(() => ({
  saveButton: {
    padding: '5px 10px',
    border: 'none',
    backgroundColor: '#808080',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export type TAreasListView = {
  geoJson: turf.FeatureCollection
  highlightedFeatId: number | null
  handleListItemClick: (id: number) => void
  selectedRef: React.RefObject<HTMLElement>
  editingIndex: number | null
  saveEditingArea: () => void
  cancelEditing: () => void
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  handleSwitchChange: (index: number) => void
  removeArea: (featId: turf.helpers.Id | undefined) => void
  isControlPressed?: boolean | null
  isClickDisabled?: boolean | null
  addSelectedAreas: (id: number) => void
  selectedAreas: string[] | null
  getImage: (geometry: turf.Geometry) => string
  handleClickInfo: (index: number, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  handleClose: () => void
  crops: any[] | undefined
  indexPopoverOpen: number | undefined
  popoverAnchorEl: (EventTarget & SVGSVGElement) | undefined
  isFetchingCrops: boolean
}

export const AreasListView = ({
  geoJson,
  highlightedFeatId,
  handleListItemClick,
  handleClickInfo,
  selectedRef,
  editingIndex,
  saveEditingArea,
  cancelEditing,
  setEditingIndex,
  handleSwitchChange,
  removeArea,
  isControlPressed,
  addSelectedAreas,
  selectedAreas,
  getImage,
  isClickDisabled,
  crops,
  indexPopoverOpen,
  popoverAnchorEl,
  handleClose,
  isFetchingCrops
}: TAreasListView) => {
  const isBlocked = isClickDisabled || isControlPressed
  const styles = useStyles()

  return (
    <Box sx={{ width: '100%', height: '100%', bgcolor: 'background.paper', position: 'relative' }}>
      {/* header */}

      <List
        component="nav"
        //style={{ maxHeight: '100%', overflow: 'auto'}}
      >
        {geoJson &&
          geoJson.features.map((feature, index) => {
            const isSelected =
              (selectedAreas && selectedAreas.includes(index.toString())) ||
              highlightedFeatId == index
            // let isProductive = feature?.properties?.productive === 1
            const imageUrl = getImage(feature.geometry as turf.Geometry)
            return (
              <ListItemButton
                key={index}
                selected={isSelected}
                sx={{
                  borderBlock: `1px solid ${
                    highlightedFeatId == index || isSelected ? colors.amber[500] : 'transparent'
                  }`
                }}
                onClick={() => {
                  if (isControlPressed && feature.id) {
                    addSelectedAreas(index)
                  } else if (feature.id && !isClickDisabled) {
                    handleListItemClick(index)
                  }
                }}>
                <Box
                  ref={highlightedFeatId == index ? selectedRef : undefined}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    // flexDirection: 'column',
                    gap: '0.5rem',
                    alignItems: 'center'
                  }}>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      gap: '0.25rem',
                      alignItems: 'center',
                      color: colors.grey[600],
                      width: '100%'
                    }}> */}
                  {imageUrl.length < 8192 ? (
                    <img
                      src={imageUrl}
                      alt="Imagem de satélite do talhão"
                      style={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '12px'
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '12px',
                        background: 'grey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Typography
                        style={{
                          width: '70px',
                          wordWrap: 'break-word',
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '10px',
                          textAlign: 'center'
                        }}>
                        Vizualização Indisponível
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.25rem',
                      color: colors.grey[600],
                      width: '100%'
                    }}>
                    <Typography style={{ fontWeight: '600' }} color={colors.grey[900]} flexGrow={1}>
                      {`Talhão ${index + 1}`}
                    </Typography>
                    {!isBlocked ? (
                      <Link
                        href={
                          'https://plataforma.alerta.mapbiomas.org/mapa#?p=' +
                          JSON.stringify(feature.geometry)
                        }
                        underline="always"
                        target="_blank"
                        rel="noreferrer">
                        <Typography
                          sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '12px',
                            color: '#FF922B'
                          }}>
                          MAPBIOMAS
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '12px',
                          lineHeight: '12px',
                          color: '#FF922B'
                        }}>
                        MAPBIOMAS
                      </Typography>
                    )}
                    {/* {areaId == feature.id && (
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '12px',
                          lineHeight: '12px',
                          color: '#FF922B'
                        }}>
                        {items[feature.id].title}
                      </Typography>
                    )} */}
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {editingIndex === index && !isBlocked ? (
                      <div style={{ display: 'flex' }}>
                        <button className={styles.saveButton} onClick={saveEditingArea}>
                          Salvar
                        </button>
                        <button
                          className={styles.saveButton}
                          style={{ marginLeft: '5px' }}
                          onClick={() => {
                            if (confirm('Deseja cancelar as mudanças feitas?')) cancelEditing()
                          }}>
                          Cancelar
                        </button>
                      </div>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Delete
                          sx={{ color: '#000000' }}
                          onClick={() => {
                            if (!isBlocked && confirm('Deseja remover a área selecionada?'))
                              removeArea(feature.id)
                          }}
                        />
                        <Edit
                          sx={{ color: '#000000' }}
                          onClick={() => {
                            if (!isBlocked) setEditingIndex(index)
                          }}
                        />
                        <Info
                          sx={{ color: '#000000' }}
                          onClick={event => handleClickInfo(index, event)}
                        />
                        <Popover
                          id={index.toString()}
                          anchorEl={popoverAnchorEl}
                          open={index == indexPopoverOpen}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}>
                          {!isFetchingCrops && (
                            <Box sx={{ p: 2 }}>
                              {crops &&
                                crops.map((crop, index) => (
                                  <p key={index}>{crop[0] + ': ' + crop[1]}</p>
                                ))}
                            </Box>
                          )}
                          {isFetchingCrops && (
                            <CircularProgress size="1.5rem" sx={{ margin: '8px' }} />
                          )}
                        </Popover>
                      </Box>
                    )}
                    {/* </Box> */}
                    {/* <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={isProductive}
                          onChange={() => {
                            if (isBlocked) return
                            isProductive = !isProductive
                            handleSwitchChange(index)
                          }}
                          sx={{ m: '0.5rem', mr: '1rem' }}
                        />
                      }
                      labelPlacement="start"
                      label={isProductive ? 'Produtivo' : 'Não produtivo'}
                      sx={{
                        width: '120px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        // width: 'fit-content',
                        '& .MuiTypography-root': {
                          color: '#868E96',
                          fontSize: '0.825rem',
                          fontWeight: 500
                        }
                      }}
                    /> */}
                  </Box>
                  {/* <Button
                    target="newtab"
                    size="small"
                    variant="contained"
                    href={
                      'https://plataforma.alerta.mapbiomas.org/mapa#?p=' +
                      JSON.stringify(feature.geometry)
                    }>
                    Mapa
                  </Button> */}
                </Box>
              </ListItemButton>
            )
          })}
      </List>
    </Box>
  )
}
