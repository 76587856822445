import { Autocomplete, TextField, Typography } from '@mui/material'
import { ShortOrganizationDTO } from '@services'

interface ICustomSelect {
  options: ShortOrganizationDTO[]
  handleOrganizationChange: (org: ShortOrganizationDTO) => void
  selectedOrganization: ShortOrganizationDTO | null
}

export const CustomSelect = (props: ICustomSelect) => {
  const { options, handleOrganizationChange, selectedOrganization } = props

  return (
    <Autocomplete
      disableClearable
      value={selectedOrganization ?? undefined}
      options={options}
      getOptionLabel={option => option.fantasyName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event: any, newValue: ShortOrganizationDTO) => {
        handleOrganizationChange(newValue)
      }}
      renderInput={params => <TextField {...params} label="organizações" variant="standard" />}
      renderOption={(propsRender, option) => (
        <li
          {...propsRender}
          key={typeof option === 'string' ? option : option.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '0',
            padding: '8px'
          }}>
          <Typography>{option.fantasyName}</Typography>
          <Typography variant="caption">{option.id}</Typography>
        </li>
      )}
    />
  )
}
