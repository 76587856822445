import { Add, SaveAlt } from '@mui/icons-material'
import {
  ShortOrganizationDTO,
  THubFormFieldGroup,
  THubFormFieldDefinition,
  THubFormConditionsDefinition
} from '@services'
import { Button, TableProps, Segmented } from 'antd'
import React, { useState, Dispatch, SetStateAction } from 'react'

import { HorizontalBox, MainVerticalBox } from '../../components'
import { OriginFieldsModal, OriginFormsModal } from './modal'
import { FieldsTable, ConditionsTable } from './tables'

interface ITableBox extends TableProps {
  formFields: THubFormFieldDefinition[]
  setFormFields: Dispatch<SetStateAction<THubFormFieldDefinition[]>>
  formConditions: THubFormConditionsDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
  customFields: Array<string>
  nativeFields: THubFormFieldDefinition[] | undefined
  customFieldsGroups: THubFormFieldGroup[] | undefined
  selectedFormEntity: string | null
  organizations: ShortOrganizationDTO[] | undefined
  selectedOrganization: string | null
  disallowButton: boolean
}

export const TableBox: React.FC<ITableBox> = ({
  formFields,
  setFormFields,
  formConditions,
  setFormConditions,
  customFields,
  nativeFields,
  customFieldsGroups,
  selectedFormEntity,
  organizations,
  selectedOrganization,
  disallowButton
}) => {
  const handleFieldAdd = () => {
    const newData: THubFormFieldDefinition = {
      kind: 'CUSTOM',
      identifier: '',
      entity: 'ticket',
      label: '',
      isRequired: false,
      isEditable: false,
      fieldType: null,
      description: null,
      customFieldGroupId: null,
      components: null,
      config: null,
      source: 'MANUAL'
    }
    setFormFields([...formFields, newData])
  }

  const handleConditionAdd = () => {
    const newData: THubFormConditionsDefinition = {
      name: '',
      actions: [],
      expressions: []
    }
    setFormConditions([...formConditions, newData])
  }

  const [menu, setMenu] = useState<string>('fields')

  const [isFieldsModalOpen, setIsFieldsModalOpen] = useState(false)
  const [isFormsModalOpen, setIsFormsModalOpen] = useState(false)
  let selectedTable
  let handleAdd

  if (menu == 'fields') {
    selectedTable = (
      <FieldsTable
        formFields={formFields}
        setFormFields={setFormFields}
        customFields={customFields}
        nativeFields={nativeFields}
        customFieldsGroups={customFieldsGroups}
        formConditions={formConditions}
        setFormConditions={setFormConditions}
      />
    )
    handleAdd = handleFieldAdd
  } else if (menu == 'conditions') {
    selectedTable = (
      <ConditionsTable
        formFields={formFields}
        formConditions={formConditions}
        setFormConditions={setFormConditions}
      />
    )
    handleAdd = handleConditionAdd
  }

  return (
    <MainVerticalBox>
      <HorizontalBox>
        <div style={{ display: 'flex', gap: '16px' }}>
          <Button
            onClick={handleAdd}
            disabled={disallowButton}
            type="primary"
            style={{ marginBottom: 16 }}>
            <Add />
          </Button>
          <Segmented
            options={[
              { label: 'Campos', value: 'fields' },
              { label: 'Condições', value: 'conditions' }
            ]}
            defaultValue={menu}
            style={{ marginBottom: 16 }}
            onChange={setMenu}
          />
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <Button
            onClick={() => setIsFieldsModalOpen(true)}
            disabled={disallowButton}
            type="primary"
            style={{ marginBottom: 16, display: 'flex' }}
            icon={<Add />}>
            Importar campos
          </Button>
          <OriginFieldsModal
            setIsModalOpen={setIsFieldsModalOpen}
            isModalOpen={isFieldsModalOpen}
            setFormFields={setFormFields}
            formFields={formFields}
            nativeFields={nativeFields}
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            selectedFormEntity={selectedFormEntity}
          />
          <Button
            onClick={() => setIsFormsModalOpen(true)}
            disabled={disallowButton}
            type="primary"
            style={{ marginBottom: 16, display: 'flex' }}
            icon={<SaveAlt />}>
            Importar formulário
          </Button>
          <OriginFormsModal
            setIsModalOpen={setIsFormsModalOpen}
            isModalOpen={isFormsModalOpen}
            setFormFields={setFormFields}
            formFields={formFields}
            customFieldsGroups={customFieldsGroups}
            formConditions={formConditions}
            setFormConditions={setFormConditions}
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            selectedFormEntity={selectedFormEntity}
          />
        </div>
      </HorizontalBox>
      {selectedTable}
    </MainVerticalBox>
  )
}
