import type { GetRef, InputRef } from 'antd'
import { Form, Input } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

type FormInstance<T> = GetRef<typeof Form<T>>

const EditableContext = createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
  index: number
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps {
  editable: boolean
  dataIndex: keyof AnyObject
  record: AnyObject
  handleSave: (record: AnyObject, index: number, dataIndex: string) => void
  records: AnyObject[]
  warning: boolean
}

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  records,
  warning,
  ...restProps
}) => {
  const [editing, setEditing] = useState<boolean>(false)

  const inputRef = useRef<InputRef>(null)
  const form = useContext(EditableContext)!

  const changeProp = async () => {
    const values = await form.validateFields()
    const index = record.key
    const newData = { ...record, ...values }
    toggleEdit(false)
    handleSave(newData, index, String(dataIndex))
  }

  let childNode = children
  const toggleEdit = (status: boolean) => {
    setEditing(status)
  }

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }
  }, [record, dataIndex, form])

  if (editable) {
    childNode =
      editing || record?.[dataIndex] == null || record?.[dataIndex] == '' ? (
        <Form.Item style={{ margin: 0 }} name={String(dataIndex)}>
          <Input
            value={record?.[dataIndex]}
            placeholder={`${String(dataIndex)}`}
            ref={inputRef}
            onPressEnter={changeProp}
            onBlur={changeProp}
          />
        </Form.Item>
      ) : (
        <div
          style={{
            paddingRight: 24,
            color: warning ? 'red' : 'black',
            minWidth: '100%',
            minHeight: 22
          }}
          onClick={() => toggleEdit(true)}>
          {children}
        </div>
      )
  }

  return (
    <td style={{ width: 'fit-content' }} {...restProps}>
      {childNode}
    </td>
  )
}
