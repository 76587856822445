import { colors, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { TCrop } from '../../../../services/LutienApi'

export const SeasonsChartLegend = ({ crops }: { crops: TCrop[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          display: 'flex',
          mb: '0.5rem',
          ml: '2rem',
          paddingInline: '0.5rem',
          gap: '2rem',
          width: 'fit-content',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '10px'
        }}>
        {crops.map((crop, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem'
              }}>
              <Box
                sx={{
                  height: '0.5rem',
                  width: '0.5rem',
                  borderRadius: '50%',
                  bgcolor: crop.cropColor
                }}
              />
              <Typography variant="caption">{crop.cropName || '-'}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
