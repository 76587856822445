import { useContext } from 'react'

import { ComplianceContext } from '../../providers/ComplianceContext'
import { SidebarView } from './SidebarView'

interface ISidebarProps {
  handleClickSaveTask: () => void
  handleClickCompleteTask: () => void
}

export const Sidebar = (props: ISidebarProps) => {
  const { handleClickSaveTask, handleClickCompleteTask } = props

  const { personProfile } = useContext(ComplianceContext)

  const { hasPendingModifications } = useContext(ComplianceContext)

  const handleOpenHubProfile = () => {
    window.open(
      `${process.env.REACT_APP_URL_HUB_FRONT}/tomador/registro/${personProfile.id}`,
      '_blank'
    )
  }

  return (
    <SidebarView
      handleClickSaveTask={handleClickSaveTask}
      handleClickCompleteTask={handleClickCompleteTask}
      handleOpenHubProfile={handleOpenHubProfile}
      hasPendingModifications={hasPendingModifications}
      personProfile={personProfile}
    />
  )
}
