import { CreateEnvironmentalDebtDTO } from '@/services/Hub/AssessmentsEnvironmental'
import { ENVIRONMENTAL_DEBT_STATUS } from '@/services/Hub/enums/EnvironmentalAssessment.enum'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildCreateEnvironmentalDebtDTO {
  record: IRecordCandidate
  documentUrl?: string
  canonicalProfileId: string
}

export const buildCreateEnvironmentalDebtDTO = (
  props: IBuildCreateEnvironmentalDebtDTO
): CreateEnvironmentalDebtDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  return {
    canonicalProfileId,
    creationType: 'USER_INPUT',
    referenceDate: new Date().toISOString(),
    status: record.documentStatus as ENVIRONMENTAL_DEBT_STATUS,
    documentUrl
  }
}
