import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authenticatorReducer } from './authenticator'
import { headerReducer } from './header'
import { radarReducer } from './radar'

const persistConfig = {
  key: 'root',
  storage
}

const persistedAuthenticatorReducer = persistReducer(persistConfig, authenticatorReducer)

export const store = configureStore({
  reducer: {
    authenticator: persistedAuthenticatorReducer,
    header: headerReducer,
    radar: radarReducer
  }
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
