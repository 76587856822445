import { TCrop } from '../../services/LutienApi'

export const UnidentifiedCrop: Required<Omit<TCrop, 'statusValidation'>> & {
  statusValidation?: TCrop['statusValidation']
} = {
  cropId: '',
  cropName: 'Não identificado',
  cropColor: '#5C7CFA',
  commoditiesPackageUnitId: '',
  cropDescription: '',
  iconUrl: '',
  statusValidation: undefined
} as const
