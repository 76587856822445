import { Close } from '@mui/icons-material'
import { THubFormFieldDefinition, ConditionExpression } from '@services'
import { Button, Input, Select, Switch, Table, TableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { Dispatch, SetStateAction } from 'react'

import { EditableCell, EditableRow } from '../../../components'
import { expressionOperators } from '../options.constants'

type ConditionsTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<ConditionsTableProps['columns'], undefined>

interface IExpressionsTable extends TableProps {
  formFields: THubFormFieldDefinition[]
  setExpressions: Dispatch<SetStateAction<ConditionExpression[]>>
  expressions: ConditionExpression[]
}

export const ExpressionsTable: React.FC<IExpressionsTable> = ({
  formFields,
  setExpressions,
  expressions
}) => {
  const formFieldsDict: AnyObject = {}
  const fieldOptions: { label: string; value: string }[] = []
  for (let i = 0; i < formFields.length; i += 1) {
    formFieldsDict[`${formFields[i].identifier}`] = formFields[i]
    if (formFields[i].fieldType != 'address') {
      fieldOptions.push({
        label: `${formFields[i].label} (${formFields[i].identifier})`,
        value: formFields[i].identifier
      })
    }
  }

  const handleDelete = (row: AnyObject) => {
    const newData: ConditionExpression[] = []
    for (let i = 0; i < expressions.length; i += 1) {
      if (i != row.key) newData.push(expressions[i])
    }
    setExpressions(newData)
  }

  const handleSave = (row: ConditionExpression, index: number, dataIndex: string) => {
    const newData: ConditionExpression[] = []

    for (let i = 0; i < expressions.length; i += 1) {
      if (i == index) {
        const item = { ...expressions[i], ...row }
        newData.push(item)
      } else {
        newData.push(expressions[i])
      }
    }
    setExpressions(newData)
  }

  const handleChange = (value: string | boolean, row: AnyObject, dataIndex: string) => {
    const newRow = { ...{ [dataIndex]: value } }
    const newData = [...expressions]
    const item = newData[row.key]

    if (dataIndex == 'fieldIdentifier') {
      if (formFieldsDict[String(value)]?.fieldType == 'boolean') newRow.value = false
      else newRow.value = ''
      if (
        formFieldsDict[String(value)]?.fieldType !=
        formFieldsDict[expressions[row.key]?.fieldIdentifier]?.fieldType
      )
        newRow.operation = ''
    }

    newData.splice(row.key, 1, {
      ...item,
      ...newRow
    })
    setExpressions(newData)
  }

  const valueComponent = (label: string, record: AnyObject) => {
    const disableField = record?.operation == 'present' || record?.operation == 'blank'
    const placeholder = formFieldsDict[record?.fieldIdentifier]?.fieldType?.includes('date')
      ? 'YYYY-MM-DD'
      : ''
    if (formFieldsDict[record?.fieldIdentifier]?.fieldType?.includes('select')) {
      return (
        <Select
          style={{ width: '100%' }}
          showSearch
          optionFilterProp="label"
          value={label}
          onChange={value => handleChange(value, record, 'value')}
          options={formFieldsDict[expressions[record?.key]?.fieldIdentifier]?.components?.nodes}
          disabled={!expressions[record?.key]?.operation || disableField}
          variant={!expressions[record?.key]?.operation || disableField ? 'borderless' : 'outlined'}
        />
      )
    } else if (formFieldsDict[expressions[record?.key]?.fieldIdentifier]?.fieldType == 'boolean') {
      return (
        <Switch
          value={Boolean(label) ?? false}
          onChange={value => handleChange(value, record, 'value')}
          disabled={!expressions[record?.key]?.operation || disableField}
        />
      )
    } else {
      return (
        <Input
          value={disableField ? '' : label}
          onChange={e => handleChange(e.target.value, record, 'value')}
          style={{ width: '100%' }}
          disabled={!expressions[record?.key]?.operation || disableField}
          variant={!expressions[record?.key]?.operation || disableField ? 'borderless' : 'outlined'}
          placeholder={placeholder}
        />
      )
    }
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Identifier',
      dataIndex: 'fieldIdentifier',
      render: (label: string, record: AnyObject) => (
        <Select
          value={label}
          showSearch
          optionFilterProp="label"
          onChange={value => handleChange(value, record, 'fieldIdentifier')}
          options={fieldOptions}
          style={{ width: '100%' }}
        />
      ),
      width: '33%'
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (label: string, record: AnyObject) => (
        <Select
          value={!expressions[record?.key]?.fieldIdentifier ? '' : label}
          onChange={value => handleChange(value, record, 'operation')}
          options={expressionOperators(formFieldsDict[record?.fieldIdentifier]?.fieldType) ?? ''}
          style={{ width: '100%' }}
          disabled={!expressions[record?.key]?.fieldIdentifier}
          variant={!expressions[record?.key]?.fieldIdentifier ? 'borderless' : 'outlined'}
        />
      ),
      width: '33%'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (label: string, record: AnyObject) => valueComponent(label, record),
      width: '33%'
    },
    {
      title: '',
      dataIndex: 'functions',
      render: (_: string, record: AnyObject) =>
        expressions.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleDelete(record)}>
              <Close style={{ color: 'red' }} />
            </Button>
          </div>
        ) : null,
      width: '10%'
    }
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: AnyObject) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
        records: expressions,
        warning: false
      })
    }
  })

  const expressionDataSource = []
  for (let i = 0; i < expressions?.length; i += 1) {
    expressionDataSource.push({ ...expressions[i], key: i })
  }

  return (
    <>
      <Table
        showHeader={false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={expressionDataSource}
        columns={columns as ColumnTypes}
        size={'small'}
        style={{ width: '100%', marginBottom: '16px' }}
        scroll={{ y: (window.innerHeight - 396) / 2 }}
        pagination={false}
      />
    </>
  )
}
