import { axiosBender } from '@/services/config/axiosBender.config'

import { BenderSearchExecutionsRequest } from './contract/request/BenderSearchExecutions.request'
import { BenderExecutionAttributesResponse } from './contract/response/BenderExecutionAttributes.response'
import { BenderSearchExecutionsResponse } from './contract/response/BenderSearchExecutions.response'

const search = async (body: BenderSearchExecutionsRequest) => {
  const { data } = await axiosBender.post<BenderSearchExecutionsResponse>(
    '/executions/search',
    body
  )
  return data
}

const getAttributes = async () => {
  const { data } = await axiosBender.get<BenderExecutionAttributesResponse[]>(
    '/executions/get-attributes'
  )
  return data
}

export const executions = {
  search,
  getAttributes
}
