import { UpdateRoleBody } from '@/services/Hub/Authorization/contract/request/role.request'
import { RuleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { SubjectWithDetailedActionsResponse } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Box, Paper, Typography } from '@mui/material'
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik'

import { ResolveSubjectAction } from './ResolveSubjectAction'
import { RulesRender } from './RulesRender'

interface IResolveSubjects {
  detailedSubjects: SubjectWithDetailedActionsResponse[] | undefined
  organizationId: string
  hidden: boolean
}

export const ResolveSubjects = (props: IResolveSubjects) => {
  const { detailedSubjects, hidden, organizationId } = props
  const { values } = useFormikContext<UpdateRoleBody>()

  if (!detailedSubjects) {
    return <></>
  }

  return (
    <Box
      className="resolve-subjects"
      sx={{
        background: 'transparent',
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>
      <FieldArray name="rules">
        {({ push, unshift, remove }: ArrayHelpers<UpdateRoleBody['rules']>) => (
          <>
            {detailedSubjects.map(subject => (
              <Paper
                elevation={1}
                className="subject"
                key={subject.key}
                sx={{
                  width: '100%',
                  padding: '10px',
                  background: '#fff',

                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Typography variant="h5">{subject.label}</Typography>
                <Typography variant="body1">{subject.description}</Typography>
                {subject.subjectActions?.map(subjectAction => {
                  const existingRules: RuleDTO[] | undefined = values.rules?.filter(
                    rule =>
                      rule.subject === subjectAction.subjectKey &&
                      rule.action === subjectAction.actionKey
                  )
                  // we separate the view of the inverted and normal rules to properly show the user the messages
                  return (
                    <ResolveSubjectAction
                      key={subjectAction.actionKey}
                      subjectAction={subjectAction}
                      arrayHelpers={{ push, unshift, remove }}>
                      {existingRules
                        ?.filter(e => !e.inverted)
                        ?.map((rule, idx) => (
                          <RulesRender
                            organizationId={organizationId}
                            key={rule.id}
                            availableConditions={subjectAction.conditions}
                            arrayHelpers={{ remove }}
                            currentRuleValue={rule}
                            isFirstOfKind={idx === 0}
                          />
                        ))}
                      {existingRules
                        ?.filter(e => e.inverted)
                        ?.map((rule, idx) => (
                          <RulesRender
                            organizationId={organizationId}
                            key={rule.id}
                            availableConditions={subjectAction.conditions}
                            arrayHelpers={{ remove }}
                            currentRuleValue={rule}
                            isFirstOfKind={idx === 0}
                          />
                        ))}
                    </ResolveSubjectAction>
                  )
                })}
              </Paper>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  )
}
