import { Box, Typography } from '@mui/material'

export type TMapLegend = {
  label: string
  color: string
  dashed: boolean
}[]

interface IMapLegend {
  legendValues: TMapLegend
  right?: boolean
}

export const MapLegend = ({ legendValues, right }: IMapLegend) => {
  const xPosition = right
    ? {
        right: '1.43%'
      }
    : {
        left: '1.43%'
      }
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '18px',
        gap: '12px',
        position: 'absolute',
        height: 'fit-content',
        ...xPosition,
        bottom: '1.43%',
        background: '#FFFFFF',
        border: '1px solid #E9ECEF',
        borderRadius: '12px',
        zIndex: 100
      }}>
      {legendValues.map(legend => (
        <Box key={legend.label} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '40px',
              height: '6px',
              background: legend.color,
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '8px'
            }}>
            {legend.dashed && (
              <Box
                sx={{
                  width: '8px',
                  height: '6px',
                  background: 'white'
                }}
              />
            )}
          </Box>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: '#495057'
            }}>
            {legend.label}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
