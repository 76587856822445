import FilterListIcon from '@mui/icons-material/FilterList'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

interface IEnhancedTableToolbar {
  handleFilter: () => void
}

export const EnhancedTableToolbar = ({ handleFilter }: IEnhancedTableToolbar) => {
  return (
    <Toolbar
      sx={{
        width: '100%',
        p: '0px !important'
      }}>
      <Typography sx={{ flexGrow: 1, pl: '1.5rem' }} variant="h6">
        Revisão de tarefas
      </Typography>
      <Tooltip title="Filtrar lista">
        <IconButton onClick={() => handleFilter()}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}
