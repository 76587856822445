import { axiosGisSector } from '@/services/config/axiosGis.config'
import { FeatureCollection, Geometry } from '@turf/turf'

import { GisCreateAreasRequest } from './contract/request/GisCreateAreas.request'
import { GisSaveSeasonsAreasRequest } from './contract/request/GisSaveSeasonsAreas.request'
import { GisUpdateAreasRequest } from './contract/request/GisUpdateAreas.request'
import { GisCreateAreasResponse } from './contract/response/GisCreateAreas.response'
import { GisGetNdviAreasResponse } from './contract/response/GisGetNdviAreas.response'

const getInGeometry = async (geometry: Geometry) => {
  const { data } = await axiosGisSector.post<FeatureCollection>('/areas/raw', {
    geometry
  })
  return data
}

const create = async (body: GisCreateAreasRequest) => {
  const { data } = await axiosGisSector.post<GisCreateAreasResponse>('/areas', body)
  return data
}

const update = async (id: string, body: GisUpdateAreasRequest) => {
  const { data } = await axiosGisSector.put<unknown>(`/areas/${id}`, body)
  return data
}

const deleteById = async (id: string) => {
  const { data } = await axiosGisSector.delete(`/areas/${id}`)
  return data
}

const getNdvi = async (id: string) => {
  const { data } = await axiosGisSector.get<GisGetNdviAreasResponse[]>(`/areas/${id}/ndvi-history`)
  return data
}

const saveSeasons = async (id: string, seasons: GisSaveSeasonsAreasRequest[]) => {
  const { data } = await axiosGisSector.post<unknown>(`/areas/${id}/seasons`, {
    area_id: id,
    seasons
  })
  return data
}

export const areas = {
  getInGeometry,
  create,
  update,
  deleteById,
  getNdvi,
  saveSeasons
}
