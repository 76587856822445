import * as turf from '@turf/turf'
import { AxiosResponse } from 'axios'

import { axiosRadar } from './config/axiosRadar.config'

export type TSelectedRegion = {
  latLng?: {
    lat: number
    lng: number
  }
  geometry?: turf.helpers.Geometry | turf.helpers.GeometryCollection
}

export type TRequest = {
  id: string
  validated: boolean
  sector_protocol: string
  name: string
  perc_seg_complete_task: number
  perc_season_complete_task: number
}

export type TTaskSubtask = {
  id: string
  task_id: string
  created_at: Date
  finished: boolean
  type: string
  user_id: string | null
}

export type TTasksRequest = {
  task_id: string
  group: string
  geometry: turf.Geometry
  subtasks: TTaskSubtask[]
}

export type TTasksByGeometry = {
  task_id: string
  group: string
  request_id: string
}

export type TRadarDetectedSeason = {
  area_id: string
  has_been_checked: boolean
  crops_id: string | null
  duration: number | null
  end_date: string | null
  ndvi_peak: number | null
  start_date: string
  duration_pred: number | null
  end_date_pred: string | null
  ndvi_peak_pred: number | null
}

const getRequests = async () => {
  const { data } = await axiosRadar.get<TRequest[]>('/api/v1/requests')
  return data
}

const getAreas = async (taskId: string) => {
  const { data } = await axiosRadar.get<turf.FeatureCollection>(`/api/v1/tasks/${taskId}/areas`)
  return data
}

const getTasks = async (requestID: string) => {
  const { data } = await axiosRadar.get<TTasksRequest[]>('/api/v1/tasks', {
    params: { request_id: requestID }
  })
  return data
}

const getTask = async (taskId: string) => {
  const { data } = await axiosRadar.get<TTasksRequest>(`/api/v1/tasks/${taskId}`)
  return data
}

const getSeasons = async (taskId: string) => {
  const { data } = await axiosRadar.get<TRadarDetectedSeason[]>(
    `/api/v1/tasks/${taskId}/areas-seasons`
  )
  return data
}

const updateTask = async (id: string, type: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await axiosRadar.put<AxiosResponse>(`/api/v1/tasks/${id}/finish?type=${type}`)
}

const getTaskByGeometry = async (selectedRegion: TSelectedRegion) => {
  const { data } = await axiosRadar.post<TTasksRequest[]>('/api/v1/tasks/by-geometry', {
    geometry: selectedRegion.geometry,
    lat: selectedRegion.latLng,
    long: selectedRegion.latLng
  })
  return data
}

export const RadarApi = {
  getRequests,
  getAreas,
  getTasks,
  getTask,
  getSeasons,
  getTaskByGeometry,
  updateTask
}
