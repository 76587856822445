import { ErrorPage } from '@components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { useState } from 'react'

import { useSolutionsPage } from './hooks/useSolutionsPage'
import { CreateAutofillPageContainer } from './pages/createAutofill'
import { CreateFormPageContainer } from './pages/createForms'
// import { ManageFieldsPageContainer } from './pages/manageFields'
import { MenuPageContainer } from './pages/menu/MenuPageContainer'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 300000
    }
  }
})

export const SolutionsPageContainer = () => {
  const { tarkenOrganizations, hasError } = useSolutionsPage()
  const [selectedMenu, setSelectedMenu] = useState<string>('menu')
  let content

  if (hasError) {
    return <ErrorPage />
  }

  // if (selectedMenu == 'manage-fields')
  //   content = (
  //     <ManageFieldsPageContainer
  //       setSelectMenu={setSelectedMenu}
  //       tarkenOrganizations={tarkenOrganizations}
  //     />
  //   )
  if (selectedMenu == 'create-forms')
    content = (
      <CreateFormPageContainer
        setSelectMenu={setSelectedMenu}
        tarkenOrganizations={tarkenOrganizations}
      />
    )
  else if (selectedMenu == 'create-autofill')
    content = (
      <CreateAutofillPageContainer
        setSelectMenu={setSelectedMenu}
        tarkenOrganizations={tarkenOrganizations}
      />
    )
  else {
    content = <MenuPageContainer setSelectMenu={setSelectedMenu} />
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#FFC107',
            colorPrimaryHover: '#212121',
            colorPrimaryActive: '#FFC107',
            fontWeight: 'bold'
          }
        }
      }}>
      <QueryClientProvider client={queryClient}>{content}</QueryClientProvider>
    </ConfigProvider>
  )
}
