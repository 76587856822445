import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { ErrorPage } from '@components'
import { CircularProgress } from '@mui/material'
import { MembershipDTO } from '@services'
import { debounce } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { useFuzzyFilter } from '../../../../utils/hooks/useFuzzyFilter'
import { TData } from '../../utils/authorization.utils'
import { ManageMembersView } from './ManageMembersView'
import { MembershipRoleCard } from './components/MembershipRoleCard'

interface IManageMembersContainer {
  organizationMembers: TData<MembershipDTO[]>
  organizationRoles: TData<RoleDTO[]>
}
const MAX_ITEMS_PER_PAGE = 7

export const ManageMembersContainer = (props: IManageMembersContainer) => {
  const { organizationMembers, organizationRoles } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined)

  const sliceProps = {
    start: (currentPage - 1) * MAX_ITEMS_PER_PAGE,
    end: (currentPage - 1) * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE
  }
  const { getFilteredData } = useFuzzyFilter({
    data: organizationMembers.data,
    searchKeys: ['user.name', 'user.lastName', 'role', 'user.role', 'user.email']
  })

  const filterResults = useMemo(() => {
    const dataResults = getFilteredData(searchInput)

    return {
      data: dataResults,
      length: dataResults.length
    }
  }, [searchInput, getFilteredData])

  const handleSearchValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value)
      setCurrentPage(1)
    },
    [setSearchInput]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearchValueChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(event), 300),
    [handleSearchValueChange]
  )

  if (organizationMembers.loading || organizationRoles.loading) {
    return <CircularProgress />
  }

  if (!organizationMembers.data) {
    return <ErrorPage />
  }

  return (
    <ManageMembersView
      handleSearchInputChange={debounceHandleSearchValueChange}
      paginationParams={{
        count: Math.ceil(filterResults.length / MAX_ITEMS_PER_PAGE),
        page: currentPage,
        onChange: (e, page: number) => setCurrentPage(page)
      }}>
      <>
        {filterResults.data.slice(sliceProps.start, sliceProps.end).map(member => (
          <MembershipRoleCard
            key={member.id}
            membership={member}
            organizationRoles={organizationRoles}
          />
        ))}
      </>
    </ManageMembersView>
  )
}
