import { Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'

import { TAuthorizationMenus, TTabsValues } from '../AuthorizationPageContainer'

interface IAuthorizationInnerScreen {
  authorizationMenus: TAuthorizationMenus[]
  handleMenuSelect: (value: TTabsValues) => void
  selectedMenu: TTabsValues
  extraMenuOptions: React.ReactNode
  children: React.ReactNode
}

export const AuthorizationInnerScreen = (props: IAuthorizationInnerScreen) => {
  const { authorizationMenus, handleMenuSelect, extraMenuOptions, children, selectedMenu } = props

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '260px 1fr',
        gridAutoFlow: 'row',
        gridTemplateAreas: '"menu content"',

        width: '100%',
        height: '100%',

        '& .menu': { gridArea: 'menu' },

        '& .content': { gridArea: 'content' }
      }}>
      <Box className="menu" sx={{ padding: '10px' }}>
        {extraMenuOptions}
        <Divider sx={{ margin: '25px 0' }} />
        <MenuList>
          {authorizationMenus.map(menu => (
            <MenuItem
              key={menu.value}
              onClick={() => handleMenuSelect(menu.value)}
              sx={{
                '&:hover': {
                  background: '#fad228'
                },

                background: menu.value === selectedMenu ? '#ffee99' : 'transparent',
                borderLeft: menu.value === selectedMenu ? '4px solid #212121' : 'none',

                '& .MuiListItemIcon-root': {
                  color: '#212121CC'
                }
              }}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText>{menu.title}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
      <Box className="content" sx={{ padding: '20px', overflowY: 'auto' }}>
        {children}
      </Box>
    </Box>
  )
}
