import axios from 'axios'

const axiosSerasa = axios.create({
  timeout: 20000,
  baseURL: 'https://api.serasaexperian.com.br/',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${process.env.REACT_APP_SERASA_TOKEN}`
  }
})

const login = async () => {
  const { data } = await axiosSerasa.post(
    `/security/iam/v1/client-identities/login?clientId=${process.env.REACT_APP_SERASA_CLIENT_ID}&clientSecret=${process.env.REACT_APP_SERASA_SECRET}`
  )
  return data
}

const checkSales = async (govRegCode: string, token: string) => {
  const response = await fetch(
    `https://api.serasaexperian.com.br/sales/v1/analyse-sales-distributor/025033061?indirectCustomerDocument=0${govRegCode}&indirectCustomerEmail=123`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  )

  return await response.json()
}

export const SerasaService = {
  login,
  checkSales
}
