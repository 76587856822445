import { Toolbar } from '@mui/material'

import { UUIDToggle } from './UUIDToggle'

interface ITaskOptionsToolbar {
  handleOptions: () => void
}

export const TaskOptionsToolbar = ({ handleOptions }: ITaskOptionsToolbar) => {
  return (
    <Toolbar
      sx={{
        width: '100%',
        p: '0px !important',
        mb: '15px'
      }}>
      <UUIDToggle handleOptions={handleOptions}></UUIDToggle>
    </Toolbar>
  )
}
