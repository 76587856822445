import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { useFuzzyFilter } from '@/utils/hooks/useFuzzyFilter'
import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField
} from '@mui/material'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useState } from 'react'

interface IAssignRoleButton {
  availableRoles: RoleDTO[]
  handleAssignRoles: (roleIds: string[], onComplete?: () => void) => void
}

export const AssignRoleButton = (props: IAssignRoleButton) => {
  const { availableRoles, handleAssignRoles } = props
  const [isCreating, setIsCreating] = useState(false)
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined)

  const { getFilteredData } = useFuzzyFilter({
    data: availableRoles,
    searchKeys: ['label']
  })

  const handleRoleClick = (roleId: string) => {
    if (selectedRoleIds.includes(roleId)) {
      setSelectedRoleIds(old => old.filter(id => id !== roleId))
      return
    }
    setSelectedRoleIds(old => [...old, roleId])
  }

  const handleSearchValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchInput(event.target.value)
    },
    [setSearchInput]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearchValueChange = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        handleSearchValueChange(event),
      300
    ),
    [handleSearchValueChange]
  )

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          display: 'flex',
          padding: '4px 6px',
          alignItems: 'center',
          background: isCreating ? '#91fac2' : '#F1F3F5',
          transition: 'all 1s ease-out'
        }}>
        <IconButton
          size="small"
          disabled={isCreating}
          sx={{
            '& svg': {
              height: '16px',
              width: '16px'
            }
          }}
          onClick={event => {
            setAnchorEl(event.currentTarget)
          }}>
          {isCreating ? <CircularProgress size={16} color="success" /> : <Add />}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        autoFocus={false}
        disableAutoFocusItem
        sx={{
          maxHeight: '500px',
          minWidth: '500px',
          '& .MuiList-root': {
            paddingTop: 0,
            minWidth: '500px'
          }
        }}>
        <Box
          className="menu-header"
          sx={{
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 2,
            width: '100%',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '8px 10px'
          }}>
          <TextField
            size="small"
            variant="standard"
            sx={{
              flex: 1
            }}
            label="Procurar Cargos"
            placeholder="Digite um termo para aplicar um filtro"
            onKeyDown={event => event.stopPropagation()}
            onChange={e => {
              debounceHandleSearchValueChange(e)
            }}
          />
          <Button
            color="success"
            disabled={selectedRoleIds.length === 0}
            onClick={() => {
              handleAssignRoles(selectedRoleIds, () => {
                setIsCreating(false)
              })
              setIsCreating(true)
              setAnchorEl(null)
            }}>
            Atribuir Cargos
          </Button>
          <Divider />
        </Box>
        {getFilteredData(searchInput).map(role => (
          <MenuItem key={role.id} onClick={() => handleRoleClick(role.id)}>
            <ListItemIcon>
              <Checkbox color="success" checked={selectedRoleIds.includes(role.id)} />
            </ListItemIcon>
            <ListItemText>{role.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
