import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as React from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

type TModalOptions = {
  open: boolean
  ExecutionTree: React.FC
  onClose: () => void
}

export const BasicModal = ({ open, ExecutionTree, onClose }: TModalOptions) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ExecutionTree />
        </Box>
      </Modal>
    </div>
  )
}
