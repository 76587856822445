/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import DeleteIcon from '@mui/icons-material/Delete'
import MergeIcon from '@mui/icons-material/Merge'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Badge, Button, colors, Tooltip, Box } from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { RefObject } from 'react'
import MapGl, { MapRef } from 'react-map-gl'

import { LatLongCopyButton } from '../LatLongCopyButton/LatLongCopyButton'
import { TMapLegend, MapLegend } from './MapLegend'

interface IEditAreasMap {
  mapRef: RefObject<MapRef>
  onMapClick: (props: mapboxgl.MapLayerMouseEvent) => void
  onMapDblClick?: (props: mapboxgl.MapLayerMouseEvent) => void
  editingIndex: number | null
  isSplittingArea?: boolean
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  intersectionsVisibility: boolean
  isLoadingSubmit?: boolean
  isLoadingSave?: boolean
  isLoadingRecover?: boolean
  isAddingNewArea?: boolean
  hasSelectedAreas?: boolean
  onSubmit?: () => void
  onSave?: () => void
  onRecover?: () => void
  highlightedFeatCoordinates?: turf.helpers.Position | null
  handleToggleIntersectionsVisibility: () => void
  handleToggleAddNewArea?: () => void
  handleToggleSplitArea?: () => void
  handleDeleteSelectedAreas?: () => void
  handleMergeSelectedAreas?: () => void
  children: JSX.Element
  legend?: TMapLegend
  onDragEnd?: () => void
  onMouseUp?: () => void
  onLoad?: () => void
}

export const EditAreasMap = ({
  mapRef,
  onMapClick,
  onMapDblClick,
  editingIndex,
  intersections,
  intersectionsVisibility,
  isLoadingSubmit,
  isLoadingSave,
  isLoadingRecover,
  isSplittingArea,
  isAddingNewArea,
  hasSelectedAreas,
  onSubmit,
  onSave,
  onRecover,
  highlightedFeatCoordinates,
  handleToggleIntersectionsVisibility,
  handleToggleAddNewArea,
  handleToggleSplitArea,
  handleDeleteSelectedAreas,
  handleMergeSelectedAreas,
  children,
  legend,
  onDragEnd,
  onMouseUp,
  onLoad
}: IEditAreasMap) => {
  return (
    <Box
      sx={{
        flexGrow: '1',
        minWidth: '50vw',
        maxHeight: 'calc(100vh - 3rem)'
      }}>
      <MapGl
        attributionControl={false}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken="pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg"
        doubleClickZoom={false}
        onDblClick={onMapDblClick}
        onClick={onMapClick}
        onDragEnd={onDragEnd}
        onMouseUp={onMouseUp}
        onLoad={onLoad}>
        {children}
      </MapGl>
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          marginRight: '0.5rem',
          gap: '0.5rem',
          right: '0.5em'
        }}>
        {onRecover && (
          <LoadingButton
            loading={isLoadingRecover}
            variant="contained"
            sx={{
              display: editingIndex ? 'none' : 'flex',
              right: '0rem',
              bottom: '3rem',
              gap: '0.5rem',
              bgcolor: '#fff',
              '&:hover': {
                bgcolor: colors.grey[200]
              }
            }}
            onClick={onRecover}>
            {'Recuperar progresso'}
            <CachedIcon fontSize="small" sx={{ color: colors.orange[500] }} />
          </LoadingButton>
        )}
        {onSave && (
          <LoadingButton
            loading={isLoadingSave}
            variant="contained"
            sx={{
              display: editingIndex ? 'none' : 'flex',
              right: '0rem',
              bottom: '3rem',
              gap: '0.5rem',
              bgcolor: '#fff',
              '&:hover': {
                bgcolor: colors.grey[200]
              }
            }}
            onClick={onSave}>
            {'Salvar progresso'}
            <SaveIcon fontSize="small" sx={{ color: colors.orange[500] }} />
          </LoadingButton>
        )}
        {onSubmit && (
          <LoadingButton
            loading={isLoadingSubmit}
            variant="contained"
            sx={{
              display: editingIndex ? 'none' : 'flex',
              right: '0rem',
              bottom: '3rem',
              gap: '0.5rem',
              bgcolor: '#fff',
              '&:hover': {
                bgcolor: colors.grey[200]
              }
            }}
            onClick={onSubmit}>
            {'Finalizar'}
            <CheckCircle fontSize="small" sx={{ color: colors.green[500] }} />
          </LoadingButton>
        )}
      </Box>
      {legend && <MapLegend legendValues={legend} right />}
      <Box
        sx={{
          position: 'fixed',
          right: '0.5rem',
          top: '3.5rem',
          textAlign: 'right'
        }}>
        <Box
          sx={{
            marginBottom: '0.5rem',
            justifyContent: 'right',
            display: 'flex',
            marginRight: '0.5rem'
          }}>
          {highlightedFeatCoordinates ? (
            <LatLongCopyButton coordinates={highlightedFeatCoordinates} />
          ) : null}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '10px',
            marginRight: '0.5rem'
          }}>
          <Tooltip
            title={intersectionsVisibility ? 'Ocultar interseções' : 'Mostrar interseções'}
            placement="left">
            <Badge
              badgeContent={intersections.features.length}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  right: 36,
                  top: 5,
                  padding: '0 4px'
                }
              }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  minWidth: 0,
                  padding: '0.5rem',
                  bgcolor: '#FFD43B',
                  '&:hover': {
                    bgcolor: colors.grey[200]
                  }
                }}
                onClick={handleToggleIntersectionsVisibility}>
                {intersectionsVisibility ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </Button>
            </Badge>
          </Tooltip>
          {isAddingNewArea && (
            <Tooltip title="Adicionar novo talhão" placement="left">
              <Badge color="error">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleToggleAddNewArea}
                  sx={{
                    minWidth: 0,
                    padding: '0.5rem',
                    bgcolor: '#FFD43B',
                    '&:hover': {
                      bgcolor: colors.grey[200]
                    }
                  }}>
                  <AddIcon fontSize="small" />
                </Button>
              </Badge>
            </Tooltip>
          )}
          {hasSelectedAreas && (
            <Tooltip title="Juntar talhões" placement="left">
              <Badge color="error">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMergeSelectedAreas}
                  sx={{
                    minWidth: 0,
                    padding: '0.5rem',
                    bgcolor: '#FFD43B',
                    '&:hover': {
                      bgcolor: colors.grey[200]
                    }
                  }}>
                  <MergeIcon fontSize="small" />
                </Button>
              </Badge>
            </Tooltip>
          )}
          {isSplittingArea && (
            <Tooltip title="Dividir talhão" placement="left">
              <Badge color="error">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleToggleSplitArea}
                  sx={{
                    minWidth: 0,
                    padding: '0.5rem',
                    bgcolor: '#FFD43B',
                    '&:hover': {
                      bgcolor: colors.grey[200]
                    }
                  }}>
                  <ContentCutIcon fontSize="small" />
                </Button>
              </Badge>
            </Tooltip>
          )}
          {hasSelectedAreas && (
            <Tooltip title="Deletar talhões selecionados" placement="left">
              <Badge color="error">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleDeleteSelectedAreas}
                  sx={{
                    minWidth: 0,
                    padding: '0.5rem',
                    bgcolor: '#FFD43B',
                    '&:hover': {
                      bgcolor: colors.grey[200]
                    }
                  }}>
                  <DeleteIcon fontSize="small" />
                </Button>
              </Badge>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}
