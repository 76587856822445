import { ISignedPostUrl } from '../Aws/S3'
import { axiosHub } from '../config/axiosHub.config'

export interface DocumentPresignedUrlMetadataDTO {
  timeoutSecondsToUpload: number
  timeoutSecondsToReadDocumentUrl: number
  expectedDocumentUrlPath: string
  /**
   * The URL to make the POST request in order to upload the file.
   */
  url: string
  /**
   * A key-value object containing all fields that the client must send as the form data payload along with the file to upload.
   */
  fields: ISignedPostUrl['fields']
}

export interface IGetPostPresignedS3Url {
  personProfileId: string
  /**
   * example: "compliance-fiscal.pdf"
   * User-land file name with extension.
   */
  name: string
  mimetype: 'application/pdf' | 'text/csv'
  /**
   * 1 to 500.000.000 (500MB)
   */
  sizeBytes: number
  documentReferenceDate: string
  type:
    | 'ENVIRONMENTAL_DEBT'
    | 'ENVIRONMENTAL_EMBARGO'
    | 'FISCAL'
    | 'FGTS_DEBT'
    | 'SLAVERY_OCCURRENCE'
    | 'JUDICIARY'
    | 'LABOR_DEBT'
  relatedFiscalDebtInquiryData?: { level: 'COUNTRY' | 'STATE' }
}

const getPostPresignedS3Url = async (body: IGetPostPresignedS3Url) => {
  const { data } = await axiosHub.post<DocumentPresignedUrlMetadataDTO>(
    '/api/v1/assessments/signed-urls',
    body
  )
  return data
}

export const Assessments = {
  getPostPresignedS3Url
}
