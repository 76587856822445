import { message } from 'antd'
import { NoticeType } from 'antd/es/message/interface'

export default function useMessage() {
  const [messageApi, contextHolder] = message.useMessage()

  const notify = (type: NoticeType, msg: string) => {
    messageApi.open({
      type: type,
      content: msg,
      duration: 2
    })
  }

  return { contextHolder, notify }
}
