import {
  ComplianceContext,
  IDeleteRecord,
  IRecordCandidate
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { CriminalRecordDTO } from '@/services/Hub/AssessmentsCriminal'
import { CRIMINAL_ASSESSMENT_STATUS } from '@/services/Hub/enums/CriminalReports.enum'
import { Table, TRowRules, openConfirm } from '@components'
import { Delete, Download } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'

type ICriminalRecordCandidate = Omit<IRecordCandidate, 'documentStatus'> & {
  documentStatus: CRIMINAL_ASSESSMENT_STATUS
}

const rowRules: TRowRules = {
  status: {
    label: 'Status',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  documentUrl: {
    label: 'Certidão',
    renderComponent: (props: { value: string; candidate: boolean }) =>
      props.candidate ? (
        <Typography>{props.value}</Typography>
      ) : (
        <Button variant="text" color="info" onClick={() => window.open(props.value, '__blank')}>
          <Download />
          Download
        </Button>
      )
  },
  delete: {
    label: 'Remover',
    renderComponent: (props: { value: string; handleDelete: () => void }) => (
      <IconButton
        onClick={async () => {
          const res = await openConfirm('Deseja remover o registro?', { title: 'Remover' })
          res && props.handleDelete()
        }}>
        <Delete />
      </IconButton>
    )
  }
}

function createRows(
  criminalRecords: CriminalRecordDTO[],
  handleDelete: (record: IDeleteRecord) => void
) {
  return criminalRecords.map(criminalRecord => ({
    _candidate: false,
    _id: criminalRecord.id,
    status: criminalRecord.status,
    documentUrl: { value: criminalRecord.documentUrl, candidate: false },
    delete: {
      value: criminalRecord.id,
      handleDelete: () => handleDelete({ id: criminalRecord.id })
    }
  }))
}

function createRowsFromCandidates(
  candidates: ICriminalRecordCandidate[],
  handleDelete: (index: number) => void
) {
  return candidates.map(candidate => ({
    _candidate: true,
    _id: candidate?.index?.toString() || '',
    status: candidate.documentStatus,
    documentUrl: { value: candidate.file.file.name, candidate: true },
    delete: {
      value: candidate.index?.toString() || '',
      handleDelete: () => handleDelete(candidate.index ?? -1)
    }
  }))
}

interface ICriminalProps {
  criminalRecords: CriminalRecordDTO[]
}

export const CriminalTable = (props: ICriminalProps) => {
  const { criminalRecords } = props
  const { recordCandidates, removeRecordCandidate, deleteRecords, addDeleteRecord } =
    useContext(ComplianceContext)
  const rowsFromServer = useRef(createRows(criminalRecords, addDeleteRecord))
  const [rows, setRows] = useState(rowsFromServer.current)

  useEffect(() => {
    const recordCandidatesRows = createRowsFromCandidates(
      recordCandidates as ICriminalRecordCandidate[],
      removeRecordCandidate
    )
    setRows(prevRows => [
      ...prevRows.filter(({ _candidate }) => !_candidate),
      ...recordCandidatesRows
    ])
  }, [recordCandidates, removeRecordCandidate])

  useEffect(() => {
    setRows(prevRows => prevRows.filter(prevRow => !deleteRecords.find(v => v.id === prevRow._id)))
  }, [deleteRecords])

  return (
    <Box sx={{ minWidth: '900px', maxWidth: '1440px' }}>
      <Typography variant="h2" mb="1rem">
        Compliance Criminal
      </Typography>
      <Table rowRules={rowRules} rows={rows} />
    </Box>
  )
}
