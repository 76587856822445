import { COMPLIANCE_TASK_TYPE } from '../../Compliance/enums/ComplianceTaskType'

export type TCompliance = {
  type: COMPLIANCE_TASK_TYPE
  subtype: string
}

const splittedCollectorNameToCompliance: Record<string, TCompliance> = {
  RECORDS: {
    // 'CRIMINAL_RECORDS', but the split last word gets only the 'RECORDS'
    type: COMPLIANCE_TASK_TYPE.criminal,
    subtype: 'Compliance Criminal'
  },
  FGTS: {
    type: COMPLIANCE_TASK_TYPE.labor,
    subtype: 'FGTS'
  },
  CNDT: {
    type: COMPLIANCE_TASK_TYPE.labor,
    subtype: 'CNDT'
  },
  EMBARGO: {
    type: COMPLIANCE_TASK_TYPE.environmental,
    subtype: 'Environmental Embargoes'
  },
  DEBIT: {
    type: COMPLIANCE_TASK_TYPE.environmental,
    subtype: 'Environmental Debts'
  },
  FEDERAL: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND Federal'
  },
  BA: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND BA'
  },
  GO: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND GO'
  },
  MG: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND MG'
  },
  MS: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND MS'
  },
  MT: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND MT'
  },
  PR: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND PR'
  },
  PA: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND PA'
  },
  TO: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND TO'
  },
  SP: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND SP'
  },
  RS: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND RS'
  },
  PI: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND PI'
  },
  MA: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND MA'
  },
  RO: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND RO'
  },
  SC: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND SC'
  },
  ES: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND ES'
  },
  RR: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND RR'
  },
  AC: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND AC'
  },
  RJ: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND RJ'
  },
  RN: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND RN'
  },
  DF: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND DF'
  },
  AM: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND AM'
  },
  PE: {
    type: COMPLIANCE_TASK_TYPE.fiscal,
    subtype: 'CND PE'
  }
}

export function getComplianceFromCollectorName(collectorName: string | undefined) {
  try {
    if (!collectorName) throw new Error('Collector name is undefined.')

    const lastCollectorNameWord = collectorName.split('_').pop()
    if (!lastCollectorNameWord) throw new Error(`Non-standard collector name: ${collectorName}`)

    const compliance = splittedCollectorNameToCompliance[lastCollectorNameWord]

    if (compliance) return compliance

    throw new Error(`Unknown collector name: ${collectorName}`)
  } catch (error) {
    alert(error)
  }
}
