import { HorizontalBox } from '@/pages/solutions/components'
import { HelpOutline } from '@mui/icons-material'
import { Tooltip, Typography } from 'antd'
import { RenderFunction } from 'antd/es/_util/getRenderPropValue'
import React, { ReactNode } from 'react'

const helpText: ReactNode | RenderFunction = (
  <HorizontalBox>
    <Typography style={{ color: 'white' }}>
      Define o tratamento para campos com identifiers já existentes na organização de destino.{' '}
      <br />
      <br />
      OBS: Campos que serão impactados são sinalizados em <b style={{ color: 'red' }}>vermelho</b>.
    </Typography>
  </HorizontalBox>
)

export const ValidateMethodTT: React.FC = () => (
  <Tooltip title={helpText} placement="bottom">
    <HelpOutline style={{ color: '#212121' }} />
  </Tooltip>
)
