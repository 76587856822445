import { formatGovRegCode } from '@/utils/formatGovRegCode'
import { FlexBox, NavigateBackButton } from '@components'
import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'

import { TPersonProfile } from '../../providers/ComplianceContext'
import { CreateRecordForm } from './components/CreateRecordForm/CreateRecordForm'

interface ISidebarViewProps {
  handleClickSaveTask: () => void
  handleClickCompleteTask: () => void
  handleOpenHubProfile: () => void
  hasPendingModifications: boolean
  personProfile: TPersonProfile
}

export const SidebarView = (props: ISidebarViewProps) => {
  const {
    handleClickSaveTask,
    handleClickCompleteTask,
    handleOpenHubProfile,
    hasPendingModifications,
    personProfile
  } = props

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 3rem)'
      }}>
      {/* ---------- Header ---------- */}
      <Box sx={{ p: '0.5rem 2rem' }}>
        <NavigateBackButton />
        <FlexBox
          sx={{
            mt: '1rem',
            p: '0.5rem 1rem',
            border: '1px solid #ddd',
            borderRadius: '12px',
            justifyContent: 'space-between'
          }}>
          <Box>
            <Typography variant="subtitle1">{personProfile.name}</Typography>
            <Typography variant="subtitle2">
              {formatGovRegCode(personProfile.govRegCode)}
            </Typography>
          </Box>
          <Button onClick={handleOpenHubProfile}>
            <OpenInNew /> HUB
          </Button>
        </FlexBox>
      </Box>
      {/* ---------- Content ---------- */}
      <Box sx={{ p: '1rem 2rem', flexGrow: 1 }}>
        <CreateRecordForm />
      </Box>
      {/* ---------- Actions ---------- */}
      <FlexBox sx={{ p: '0.5rem 1rem', gap: '0.5rem', justifyContent: 'space-between' }}>
        <Button
          disabled={!hasPendingModifications}
          variant="discreet"
          color="success"
          style={{ minWidth: '128px' }}
          onClick={handleClickSaveTask}>
          Confirmar Alterações
        </Button>
        <Button
          disabled={hasPendingModifications}
          variant="discreet"
          color="warning"
          style={{ minWidth: '128px' }}
          onClick={handleClickCompleteTask}>
          Finalizar Tarefa
        </Button>
      </FlexBox>
    </Card>
  )
}
