import { Box, BoxProps } from '@mui/material'

interface IMainBox extends BoxProps {
  children: React.ReactNode
}

export const VerticalBox = (params: IMainBox, width?: string) => {
  const { children, ...props } = params

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '16px',
        width: width ?? '50%'
      }}
      {...props}>
      {children}
    </Box>
  )
}

export const HorizontalBox = (params: IMainBox) => {
  const { children, ...props } = params

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        width: '100%'
      }}
      {...props}>
      {children}
    </Box>
  )
}

export const MainBox = (params: IMainBox) => {
  const { children, ...props } = params

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        margin: 0
      }}
      {...props}>
      {children}
    </Box>
  )
}

export const MainVerticalBox = (params: IMainBox) => {
  const { children, ...props } = params

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        width: '100%',
        height: '100%'
      }}
      {...props}>
      {children}
    </Box>
  )
}
