/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getImageMapbox } from '@/utils/getImageMapbox'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
// @ts-ignore
import defaultDrawStyle from '@mapbox/mapbox-gl-draw/src/lib/theme.js'
import { Box } from '@mui/material'
import { BenderApi, HubApi } from '@services'
// import {crops} from '@services/Gis/'
import bbox from '@turf/bbox'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { FeatureCollection, point } from '@turf/helpers'
import * as turf from '@turf/turf'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import SelectFeatureMode, {
  drawStyles as selectFeatureDrawStyles
} from 'mapbox-gl-draw-select-mode'
import SplitPolygonMode, {
  drawStyles as splitPolygonDrawStyles
} from 'mapbox-gl-draw-split-polygon-mode'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
// eslint-disable-next-line import/no-named-as-default
import WebMercatorViewport from 'viewport-mercator-project'

import { useAppSelector } from '../../../../../redux/hooks'
import { RootState } from '../../../../../redux/store'
import { BENDER_TASK_STATUS } from '../../../../../services/enums/bender.enum'
import { MapView } from './MapView'
import { Header } from './components/Header/index'
import { SideBar } from './components/SideBar/index'
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface IMap {
  taskId: string
  closeMap: () => void
}

export const Map = ({ taskId, closeMap }: IMap) => {
  const [geoJson, setGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [selectedAreasIds, setSelectedAreasIds] = useState<string[] | null>([])
  const [intersections, setIntersections] = useState<FeatureCollection>({
    features: [],
    type: 'FeatureCollection'
  })
  const [propertyBoundary, setPropertyBoundary] = useState<turf.MultiPolygon>({
    coordinates: [],
    type: 'MultiPolygon'
  })
  const [intersectionsVisibility, setIntersectionsVisibility] = useState(true)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [splittingIndex, setSplittingIndex] = useState<number | null>(null)
  const [isAddingArea, setIsAddingArea] = useState<boolean | null>(false)
  const [addingAreaFlag, setAddingAreaFlag] = useState<boolean>(false)
  const [isSplittingArea, setIsSplittingArea] = useState<boolean | null>(false)
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [isControlPressed, setIsControlPressed] = useState(false)
  const [highlightedFeatId, setHighlightedFeatId] = useState<number | null>(null)
  const [reRenderIntersec, setReRenderIntersec] = useState(false)
  const [drawRef, setDrawRef] = useState<MapboxDraw | null>(null)
  const [propertyName, setPropertyName] = useState<string | null>(null)
  const [propertyImage, setPropertyImage] = useState<string | null>(null)
  // const [isSendingReview, setIsSendingReview] = useState(false)
  // const [isSavingTask, setIsSavingTask] = useState(false)
  // const [isRecoveringProgress, setIsRecoveringProgress] = useState(false)
  const pageOpeningTime = useRef(new Date())
  const [highlightedFeatCoordinates, setHighlightedFeatCoordinates] =
    useState<turf.helpers.Position | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  const mapRef = useRef<MapRef>(null)

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 40 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  const calculateIntersections = () => {
    if (!geoJson) return
    const intersectionsFeatures: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = []
    geoJson.features.forEach((feature, index) => {
      for (let i = index + 1; i < geoJson.features.length; i++) {
        try {
          const intersection = turf.intersect(
            feature as turf.Feature<turf.Polygon>,
            geoJson.features[i] as turf.Feature<turf.Polygon>
          )
          intersection && intersectionsFeatures.push(intersection)
        } catch (error) {
          console.error(error)
        }
      }
    })
    setIntersections({
      type: 'FeatureCollection',
      features: intersectionsFeatures
    })
    // } catch (err) {
    //   console.error(err)
    // }
  }
  const getClickedLayerId = (long: number, lat: number): number | null => {
    const pt = point([long, lat])
    let id = null
    geoJson?.features?.forEach(feature => {
      if (feature && booleanPointInPolygon(pt, feature.geometry as turf.Polygon)) {
        id = feature.id
      }
    })
    return id
  }

  const handleSwitchChange = (featureId: number) => {
    setGeoJson(value => {
      if (!value) return
      return {
        ...value,
        features: value?.features?.map(feature => {
          if (feature.id === featureId && feature.properties)
            feature.properties.productive = feature?.properties?.productive ? 0 : 1
          return feature
        })
      }
    })
  }

  const handleBack = () => {
    setHighlightedFeatId(null)
    closeMap()
  }

  const handleFinalizeReview = () => {
    if (!geoJson || intersections.features.length) {
      setReRenderIntersec(!reRenderIntersec)
      alert('Erro, verifique se há interseções entre as áreas desenhadas.')
      return
    }
    // setIsSendingReview(true)
    const geoJsonOfProdutiveAreas = {
      ...geoJson,
      features: geoJson.features.filter(feature => feature?.properties?.productive)
    }
    BenderApi.task

      .complete(taskId, {
        taskIdentifier: 'segmentations',
        completedBy: userInfo.email,
        completionInput: geoJsonOfProdutiveAreas,
        id: taskId,
        status: BENDER_TASK_STATUS.SUCCEEDED,
        startedAt: pageOpeningTime.current
      })
      .then(() => {
        // setIsSendingReview(false)
        closeMap()
      })
      .catch(err => {
        // setIsSendingReview(false)
        enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })
        console.error(err)
      })
  }

  const handleSaveTaskProgress = () => {
    if (!geoJson || intersections.features.length) {
      setReRenderIntersec(!reRenderIntersec)
      alert('Erro, verifique se há interseções entre as áreas desenhadas.')
      return
    }
    // setIsSavingTask(true)
    const geoJsonOfProdutiveAreas = {
      ...geoJson
    }

    BenderApi.task
      .saveTaskProgress(taskId, {
        id: taskId,
        taskIdentifier: 'segmentations',
        completedBy: userInfo.email,
        completionInput: geoJsonOfProdutiveAreas,
        status: BENDER_TASK_STATUS.PENDING,
        startedAt: pageOpeningTime.current
      })
      .then(() => {
        // setIsSavingTask(false)
        enqueueSnackbar('Progresso salvo com sucesso!', { variant: 'success' })
      })
      .catch(err => {
        // setIsSavingTask(false)
        enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })
        console.error(err)
      })
  }

  const handleRecoverTaskProgress = (taskId: string) => {
    // setIsRecoveringProgress(true)
    BenderApi.task
      .getTaskProgress(taskId)
      .then(res => {
        // setIsRecoveringProgress(false)
        if (!res) {
          enqueueSnackbar('Não há progresso para ser recuperado.', { variant: 'warning' })
        } else {
          loadTaskProgress(res as FeatureCollection)
          enqueueSnackbar('Progresso recuperado com sucesso!', { variant: 'success' })
        }
      })
      .catch(err => {
        // setIsRecoveringProgress(false)
        enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })
        console.error(err)
      })
  }

  const loadTaskProgress = (taskProgress: FeatureCollection) => {
    setGeoJson(taskProgress)
  }

  const handleToggleIntersectionsVisibility = () => {
    setIntersectionsVisibility(value => {
      if (!value && intersections.features.length)
        mapRef.current?.flyTo({
          ...getBoundsForPoints(intersections),
          duration: 400
        })
      return !value
    })
  }

  useEffect(() => {
    const onPageLoad = async () => {
      BenderApi.task.geojson(taskId).then(geoJson => {
        setGeoJson(geoJson)
      })
      const res = await BenderApi.task.search({
        fields: ['input'],
        filter: { id: taskId }
      })

      const name = res.tasks[0].input.Property?.PropertyName
      const propId = res.tasks[0].input.Property?.PropertyId
      if (name) setPropertyName(name)
      if (propId) {
        const data = await HubApi.getProperty(propId)
        if (data.sketchBoundary) {
          const image = getImageMapbox(data.sketchBoundary)
          setPropertyImage(image)
        }
        if (data.sketchBoundary && data.sketchBoundary.type == 'MultiPolygon') {
          setPropertyBoundary(data.sketchBoundary)
        }
      }
    }

    onPageLoad()
    // teste()
  }, [taskId])

  if (mapRef.current) mapRef.current.getMap().dragRotate.disable()

  useEffect(() => {
    calculateIntersections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJson])

  useEffect(() => {
    const highLightedFeat = geoJson?.features.find(feature => feature.id == highlightedFeatId)
    if (highLightedFeat) {
      setHighlightedFeatCoordinates(turf.centroid(highLightedFeat).geometry.coordinates)
    } else {
      setHighlightedFeatCoordinates(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId])

  useEffect(() => {
    const map = mapRef.current?.getMap()
    // console.log(isAddingArea, isSplittingArea, editingIndex)
    if (firstTime) {
      if (geoJson && geoJson.features.length && map) {
        // setViewportOptions(getBoundsForPoints(geoJson));
        map.flyTo({
          ...getBoundsForPoints(geoJson),
          duration: 400,
          padding: 20
        })
        setFirstTime(false)
      }
    }
    if (geoJson && geoJson.features.length === 0) alert('Erro: geometria vazia')
  }, [geoJson, mapRef])

  // useEffect(() => {
  //   if (geoJson)
  //     if (highlightedFeatId !== null) {
  //       // geoJson.features.find(feature.id === highlightedFeatId)
  //       geoJson.features.forEach(feature => {
  //         if (feature.id == highlightedFeatId && !isControlPressed)
  //           mapRef.current?.getMap().flyTo({
  //             ...getBoundsForPoints(feature.geometry),
  //             duration: 400,
  //             padding: 20
  //           })
  //       })
  //     }
  //   // else {
  //   //   setViewportOptions(getBoundsForPoints(geoJson));
  //   // }
  // }, [highlightedFeatId, geoJson, isControlPressed])

  //MapBoxDraw mode that overrides function of dragging a polygon
  const NewSimpleSelect = _.extend(MapboxDraw.modes.simple_select, {
    dragMove() {
      // @ts-ignore
    }
  })

  const NewDirectSelect = _.extend(MapboxDraw.modes.direct_select, {
    dragFeature() {
      // @ts-ignore
    }
  })

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    modes: {
      ...MapboxDraw.modes,
      simple_select: NewSimpleSelect,
      direct_select: NewDirectSelect,
      ...SplitPolygonMode(SelectFeatureMode(MapboxDraw.modes))
    },
    controls: {
      line_string: false,
      point: false,
      trash: false,
      polygon: false,
      combine_features: false,
      uncombine_features: false
    },
    styles: [...splitPolygonDrawStyles(selectFeatureDrawStyles(defaultDrawStyle))],
    userProperties: true
  })

  const cancelEditing = () => {
    if (drawRef && mapRef.current) {
      mapRef.current.getMap().removeControl(drawRef)
      setEditingIndex(null)
      setDrawRef(null)
    }
  }

  const saveEditingArea = () => {
    if (editingIndex !== null && drawRef !== null) {
      const newGeoJson = geoJson
      const featCollection = drawRef.getAll()
      if (newGeoJson)
        newGeoJson.features[editingIndex] = featCollection
          .features[0] as turf.Feature<turf.Geometry>
      setGeoJson(newGeoJson)
      cancelEditing()
      calculateIntersections()
    }
  }

  const removeArea = (featId: turf.helpers.Id | undefined) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id !== featId) features.push(feat)
      })
      // setHighlightedFeatId(null)
      setEditingIndex(null)
      setGeoJson({ ...geoJson, features: features })
      setSplittingIndex(null)
      // calculateIntersections()
    }
  }
  const removeAreaByFeatureId = (featId: number) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id != featId) features.push(feat)
      })
      setGeoJson({ ...geoJson, features: features })
      setHighlightedFeatId(null)
    }
    // calculateIntersections()
  }

  const removeSelectedAreas = () => {
    if (!selectedAreasIds || !geoJson?.features) return
    if (!confirm(`Deseja apagar a(s) ${selectedAreasIds.length} área(s) selecionadas?`)) return
    // selectedAreasIds.map((feat: string) => removeAreaByFeatureId(+feat))
    const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] = []
    geoJson.features.forEach(feat => {
      if (feat.id) if (!selectedAreasIds.includes(feat.id.toString())) features.push(feat)
    })
    setSelectedAreasIds([])
    setGeoJson({ ...geoJson, features: features })
    setHighlightedFeatId(null)

    // setHighlightedFeatCoordinates(null)
  }

  const removeUnproductiveAreas = () => {
    if (geoJson?.features) {
      const features = geoJson.features.filter(feat => {
        const featProps = feat.properties
        if (featProps && featProps.productive == 1) return feat
      })
      const numRemovedAreas: number = geoJson.features.length - features.length
      if (numRemovedAreas == 0) {
        alert('Não existema áreas improdutivas para apagar!')
      } else {
        if (!confirm(`Deseja apagar a(s) ${numRemovedAreas} área(s) improdutivas?`)) return
        setGeoJson({ ...geoJson, features: features })
      }
      setHighlightedFeatId(null)
      setIsControlPressed(false)
      setSelectedAreasIds([])
    }
  }

  const mergeSelectedAreas = () => {
    if (!selectedAreasIds || !geoJson?.features) return
    if (!confirm(`Deseja juntar a(s) ${selectedAreasIds.length} área(s) selecionadas?`)) return
    const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] = []
    const relatedFeatures: turf.FeatureCollection<
      turf.Geometry | turf.GeometryCollection
    >['features'] = []
    geoJson.features.forEach(feat => {
      if (feat.id)
        if (!selectedAreasIds.includes(feat.id.toString())) {
          features.push(feat)
        } else relatedFeatures.push(feat)
    })

    features.map((feat, id) => {
      feat.id = id.toString()
    })
    const featuresJoined: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = relatedFeatures.map(
      feat => {
        return feat as turf.Feature<turf.Polygon | turf.MultiPolygon>
      }
    )

    const collection = turf.featureCollection(featuresJoined)
    const polygon = turf.convex(collection)
    if (polygon) {
      polygon.id = features.length.toString()
      polygon.properties = relatedFeatures[0].properties
      features.push(polygon)
    }
    setSelectedAreasIds([])
    setGeoJson({
      ...geoJson,
      features: features
    })
    setHighlightedFeatId(features.length - 1)
  }

  const addSelectedAreas = (id: number) => {
    if (isControlPressed && selectedAreasIds && id !== null) {
      if (selectedAreasIds.includes(id.toString())) {
        const newAreas: string[] = selectedAreasIds.filter(
          (idArea: string) => idArea != id.toString()
        )
        setSelectedAreasIds(newAreas)
      } else {
        const newAreas: string[] = []
        selectedAreasIds.forEach(feat => newAreas.push(feat))
        newAreas.push(id.toString())
        newAreas && setSelectedAreasIds(newAreas)
      }
      setHighlightedFeatId(null)
    } else {
      setHighlightedFeatId(id)
      setSelectedAreasIds([])
    }
  }

  const handleToggleAddNewArea = () => {
    if (splittingIndex !== null || editingIndex !== null) return
    if (isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)) {
      setSelectedAreasIds([])
    }
    setHighlightedFeatId(null)
    setAddingAreaFlag(true)
    setIsAddingArea(true)
  }

  const createArea = () => {
    if (drawRef && geoJson?.features && mapRef.current) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach((feat, id) => {
        feat.id = id.toString()
        features.push(feat)
      })
      const newArea = drawRef.getAll().features[0] as turf.Feature<turf.Geometry>
      newArea.type = 'Feature'
      newArea.id = geoJson.features.length.toString()
      newArea.properties = { productive: 1 }
      features.push(newArea)
      setGeoJson({ ...geoJson, features: features })
      cancelEditing()
      setHighlightedFeatId(features.length - 1)
    }
  }

  const splitPolygon = () => {
    if (confirm('Deseja dividir o talhão?')) {
      if (drawRef && geoJson?.features && mapRef.current) {
        const features: turf.FeatureCollection<
          turf.Geometry | turf.GeometryCollection
        >['features'] = geoJson.features.filter(feat => {
          if (feat.id != splittingIndex) return feat
        })

        features.forEach((feat, id) => (feat.id = id.toString()))

        const splittedPolygon = drawRef.getAll().features[0]
        const featureGeometry = splittedPolygon.geometry
        if (featureGeometry.type == 'MultiPolygon') {
          featureGeometry.coordinates.forEach(coords => {
            const polygon = {
              type: 'Polygon',
              coordinates: coords
            }

            features.push({
              type: 'Feature',
              geometry: polygon,
              id: features.length.toString(),
              properties: { productive: 1 }
            } as turf.Feature)
          })
          setGeoJson({ ...geoJson, features: features })
          setHighlightedFeatId(features.length - 1)
        } else if (featureGeometry.type == 'Polygon') {
          features.push({
            type: 'Feature',
            geometry: featureGeometry,
            id: features.length.toString(),
            properties: { productive: 1 }
          })
          setGeoJson({ ...geoJson, features: features })
          setHighlightedFeatId(features.length - 1)
        }
      }
    }
    setSplittingIndex(null)
    if (mapRef?.current && drawRef) mapRef.current.getMap().removeControl(drawRef)
    setDrawRef(null)
  }
  // useEffect for opening split area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson && splittingIndex !== null && !isAddingArea) {
      if (isSplittingArea && drawRef === null) {
        setDrawRef(draw)
        map.getMap().addControl(draw, 'top-left')
        const splittedFeature = geoJson.features.find(feat => feat.id == splittingIndex)
        draw.set({
          // @ts-ignore
          features: [splittedFeature],
          type: 'FeatureCollection'
        })
        if (splittedFeature) {
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: splittedFeature.id
            }
          )
          try {
            draw.changeMode('split_polygon', {
              featuresIds: [splittedFeature]
            })
            map.on('draw.update', () => setIsSplittingArea(false))
          } catch (err) {
            console.error(err)
          }
        }
      } else if (!isSplittingArea && splittingIndex !== null && drawRef !== null) {
        splitPolygon()
      }
    }
  }, [isSplittingArea])

  // useEffect for opening edit area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (editingIndex !== null && drawRef === null) {
        map.getMap().addControl(draw)
        setDrawRef(draw)
        if (geoJson.features[editingIndex]) {
          draw.set({
            // @ts-ignore
            features: [geoJson.features[editingIndex]],
            type: 'FeatureCollection'
          })
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: geoJson.features[editingIndex].id
            }
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, editingIndex, geoJson])

  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (isAddingArea && drawRef === null) {
        map.getMap().addControl(draw, 'top-left')
        setDrawRef(draw)
        draw.changeMode('draw_polygon')
        map.on('draw.create', () => setIsAddingArea(false))
      } else if (!isAddingArea && drawRef !== null && !splittingIndex) {
        if (confirm('Deseja adicionar a área criada?')) {
          createArea()
        } else cancelEditing()
        setAddingAreaFlag(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingArea])

  const pressDelete = (event: KeyboardEvent) => {
    if (event.key === 'Delete' && drawRef !== null) {
      drawRef.trash()
    } else if (event.key === 'Delete' && highlightedFeatId !== null && geoJson) {
      if (confirm('Deseja remover a área selecionada?')) {
        removeAreaByFeatureId(highlightedFeatId)
      }
    }
  }

  const pressControl = (event: KeyboardEvent) => {
    if (event.key !== 'Control') return
    setIsControlPressed(true)
  }

  const releaseControl = (event: KeyboardEvent) => {
    if (event.key !== 'Control') return
    setIsControlPressed(false)
    // if (selectedAreasIds?.length == 0) setIsControlPressed(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', pressDelete)
    window.addEventListener('keydown', pressControl)
    window.addEventListener('keyup', releaseControl)
    return () => {
      window.removeEventListener('keydown', pressDelete)
      window.removeEventListener('keydown', pressControl)
      window.removeEventListener('keyup', releaseControl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId, geoJson, drawRef])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Header
        propertyImage={propertyImage}
        propertyName={propertyName}
        handleRecoverTaskProgress={() => handleRecoverTaskProgress(taskId)}
        handleSaveTaskProgress={handleSaveTaskProgress}></Header>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100vw',
          height: '100%',
          overflow: 'hidden'
        }}>
        {/* <Box> */}
        <SideBar
          geoJson={geoJson}
          setGeoJson={setGeoJson}
          highlightedFeatId={highlightedFeatId}
          setHighlightedFeatId={setHighlightedFeatId}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
          saveEditingArea={saveEditingArea}
          handleBack={handleBack}
          handleFinalizeReview={handleFinalizeReview}
          cancelEditing={cancelEditing}
          removeArea={removeArea}
          isControlPressed={isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)}
          addSelectedAreas={addSelectedAreas}
          selectedAreas={selectedAreasIds}
          getImage={getImageMapbox}
          removeUnproductiveAreas={removeUnproductiveAreas}
          isClickDisabled={
            (selectedAreasIds && selectedAreasIds.length > 0) ||
            splittingIndex !== null ||
            addingAreaFlag
          }></SideBar>
        {/* </Box> */}

        <MapView
          geoJson={geoJson}
          mapRef={mapRef}
          onMapClick={props => {
            props.originalEvent.preventDefault()
            if (
              editingIndex === null &&
              splittingIndex === null &&
              !addingAreaFlag &&
              !isSplittingArea
            ) {
              const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
              if (id === null) {
                setHighlightedFeatId(null)
                setSelectedAreasIds([])
              } else if (isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)) {
                addSelectedAreas(id)
              } else {
                setHighlightedFeatId(id)
                if (selectedAreasIds) if (selectedAreasIds.length > 0) setSelectedAreasIds([])
              }
              // setHighlightedFeatId(id)
            }
          }}
          onMapDblClick={props => {
            props.originalEvent.preventDefault()
            if (isControlPressed) return
            if (
              editingIndex === null &&
              splittingIndex == null &&
              !isSplittingArea &&
              !addingAreaFlag
            ) {
              const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
              setHighlightedFeatId(id)
              if (id) handleSwitchChange(id)
            }
          }}
          editingIndex={editingIndex}
          intersections={intersections}
          intersectionsVisibility={intersectionsVisibility}
          isAddingNewArea={true}
          highlightedFeatId={highlightedFeatId}
          splittingIndex={highlightedFeatId}
          hasSelectedAreas={selectedAreasIds ? selectedAreasIds.length > 1 : false}
          handleToggleAddNewArea={handleToggleAddNewArea}
          handleToggleSplitArea={() => {
            if (editingIndex !== null) return
            setSplittingIndex(highlightedFeatId)
            setIsSplittingArea(true)
          }}
          handleDeleteSelectedAreas={removeSelectedAreas}
          handleMergeSelectedAreas={mergeSelectedAreas}
          highlightedFeatCoordinates={highlightedFeatCoordinates}
          handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}
          selectedAreasIds={selectedAreasIds}
          propertyBoundary={propertyBoundary}></MapView>
      </Box>
    </Box>
  )
}
