import { FlexBox } from '@components'

import { renderEmptyState } from '@shared'

import { useFiscalAssessment } from '../hooks/useFiscalAssessment'
import { FiscalTable } from './FiscalTable'

interface IComplianceFiscalProps {
  personProfileId: string
}

export const ComplianceFiscal = (props: IComplianceFiscalProps) => {
  const { personProfileId } = props

  const { response, isRequesting, error } = useFiscalAssessment({ personProfileId })

  return (
    <FlexBox vertical>
      {renderEmptyState(isRequesting, error) || (
        <FiscalTable fiscalDebtInquiries={response?.certificates || []} />
      )}
    </FlexBox>
  )
}
