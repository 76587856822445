import { criminalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceCriminal/helpers/criminalRecordTypeCatalog'
import { environmentalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceEnvironmental/helpers/environmentalRecordTypeCatalog'
import { fiscalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceFiscal/helpers/fiscalRecordTypeCatalog'
import { laborRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceLabor/helpers/laborRecordTypeCatalog'
import { COMPLIANCE_TASK_TYPE } from '@/pages/bender/Compliance/enums/ComplianceTaskType'
import {
  documentStatusCatalog,
  IRecordCandidate
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { OrNull } from '@/utils/OrNull'
import { Dispatch, SetStateAction } from 'react'

import { CreateRecordFormView } from './CreateRecordFormView'

interface ICreateRecordFormProps {
  complianceRecord: OrNull<IRecordCandidate>
  setComplianceRecord: Dispatch<SetStateAction<OrNull<IRecordCandidate>>>
  taskType: COMPLIANCE_TASK_TYPE | undefined
}

export const CreateRecordForm = (props: ICreateRecordFormProps) => {
  const { complianceRecord, setComplianceRecord, taskType } = props

  if (!taskType) return <></>

  const documentStatusOptions = documentStatusCatalog?.[taskType]

  const handleFormChange = (key: keyof IRecordCandidate, value: unknown) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      [key]: value
    }))
  }

  const handleOpenNewTab = () => {
    if (!complianceRecord.recordType) return

    if (taskType === COMPLIANCE_TASK_TYPE.criminal)
      window.open(criminalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.environmental)
      window.open(environmentalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.fiscal)
      window.open(fiscalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.labor)
      window.open(laborRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
  }

  return (
    <CreateRecordFormView
      handleFormChange={handleFormChange}
      handleOpenNewTab={handleOpenNewTab}
      documentStatusOptions={documentStatusOptions}
      complianceRecord={complianceRecord}
    />
  )
}
