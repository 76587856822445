import { ShortOrganizationDTO, HubApi, THubFormFieldDefinition } from '@services'
import { useQuery } from '@tanstack/react-query'
import { Modal, Select, Tag } from 'antd'
import type { SelectProps } from 'antd'
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'

import { OrgSelect, VerticalBox } from '../../../components'
import useMessage from '../../../hooks/useMessage'
import { entityColors } from '../options.constants'

type TagRender = SelectProps['tagRender']

const tagRender: TagRender = props => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  let color = 'orange'
  for (const key in entityColors) {
    if (typeof label == 'string' && label.includes(key))
      color = entityColors[key as keyof typeof entityColors]
  }

  return (
    <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}>
      {label}
    </Tag>
  )
}

type TOriginFieldsModalPage = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setFormFields: Dispatch<SetStateAction<THubFormFieldDefinition[]>>
  formFields: THubFormFieldDefinition[]
  nativeFields: THubFormFieldDefinition[] | undefined
  organizations: ShortOrganizationDTO[] | undefined
  selectedOrganization: string | null
  selectedFormEntity: string | null
}

export const OriginFieldsModal: React.FC<TOriginFieldsModalPage> = ({
  setIsModalOpen,
  isModalOpen,
  setFormFields,
  formFields,
  nativeFields,
  organizations,
  selectedOrganization,
  selectedFormEntity
}) => {
  const [selectedOriginOrganization, setOriginOrganizationId] = useState<string | null>(null)
  const [selectedCustomFields, setSelectedCustomFieldsId] = useState<Array<string>>([])
  const [selectedNativeFields, setSelectedNativeFieldsId] = useState<Array<string>>([])
  const { contextHolder, notify } = useMessage()

  const addedNativeFields = formFields
    ?.filter(field => field.source == 'NATIVE')
    ?.map(field => field.identifier)

  useEffect(() => {
    setSelectedCustomFieldsId([])
  }, [selectedOriginOrganization])

  const { data: customFields } = useQuery({
    queryKey: ['getCustomFields', selectedOriginOrganization],
    queryFn: () => HubApi.getCustomFields(selectedOriginOrganization),
    enabled: !!selectedOriginOrganization
  })

  const handleOk = async () => {
    const repeatedFields: string[] = []
    const filteredFields = []
    if (nativeFields) {
      for (let i = 0; i < nativeFields.length; i += 1) {
        if (selectedNativeFields.includes(nativeFields[i].identifier)) {
          filteredFields.push({ ...nativeFields?.[i], source: 'NATIVE' })
        }
      }
    }
    if (selectedCustomFields && customFields) {
      const identifiers: string[] = formFields.map(field => field.identifier)
      for (let i = 0; i < customFields.length; i += 1) {
        if (selectedCustomFields.includes(customFields[i].id ?? '')) {
          const source =
            selectedOriginOrganization == selectedOrganization &&
            customFields?.[i]?.entity == selectedFormEntity
              ? 'DESTINY'
              : 'ORIGIN'
          const groupId =
            selectedOriginOrganization == selectedOrganization
              ? customFields[i].customFieldGroupId
              : null
          filteredFields.push({
            ...customFields?.[i],
            source: source,
            customFieldGroupId: groupId,
            identifier: identifiers.includes(customFields[i]?.identifier)
              ? ''
              : customFields[i]?.identifier
          })

          if (identifiers.includes(customFields[i]?.identifier))
            repeatedFields.push(`'${customFields[i]?.identifier}'`)
        }
      }
    }
    if (repeatedFields.length > 0)
      notify(
        'warning',
        `${
          repeatedFields.length > 1
            ? `Identifiers ${repeatedFields.join(
                ', '
              )} removidos para evitar duplicidades, preencha-os corretamente!`
            : `Identifier ${repeatedFields.join(
                ', '
              )} removido para evitar duplicidade, preencha-o corretamente!`
        }`
      )

    if (filteredFields?.length > 0) setFormFields(formFields.concat(filteredFields))
    setOriginOrganizationId(null)
    setSelectedCustomFieldsId([])
    setSelectedNativeFieldsId([])
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setOriginOrganizationId(null)
    setSelectedCustomFieldsId([])
    setSelectedNativeFieldsId([])
    setIsModalOpen(false)
  }

  return (
    <Modal
      title="Selecione os campos a serem importados"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={'Cancelar'}>
      {contextHolder}
      <VerticalBox>
        <Select
          showSearch
          mode="multiple"
          placeholder="Campos nativos a serem adicionados"
          optionFilterProp="label"
          onChange={value => setSelectedNativeFieldsId(value)}
          style={{ width: '100%' }}
          value={selectedNativeFields}
          options={nativeFields
            ?.filter(field => !addedNativeFields.includes(field.identifier))
            ?.map(field => ({
              value: field.identifier,
              label: `${field.label} | ${field.identifier} | ${field.entity}`
            }))}
          tagRender={tagRender}
          loading={!nativeFields}
          disabled={!nativeFields}
          maxTagCount="responsive"
        />
        <OrgSelect
          selectedOrg={selectedOriginOrganization}
          isLoadingOrgs={!organizations}
          setOrganizationId={setOriginOrganizationId}
          organizations={organizations}
          placeholder="Organização de origem"
          width="100%"
        />
        <Select
          showSearch
          mode="multiple"
          placeholder="Campos customizados a serem adicionados"
          optionFilterProp="label"
          onChange={value => setSelectedCustomFieldsId(value)}
          style={{ width: '100%' }}
          value={selectedCustomFields}
          options={customFields?.map(field => ({
            value: field.id,
            label: `${field.identifier} | ${field.fieldType} | ${field.entity}`
          }))}
          tagRender={tagRender}
          loading={!customFields}
          disabled={!customFields}
          maxTagCount="responsive"
        />
      </VerticalBox>
    </Modal>
  )
}
