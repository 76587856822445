import { AwsApi, HubApi } from '@services'

import { IRecordCandidate } from '../../providers/ComplianceContext'
import { buildCreateFiscalDebtInquiryDTO } from '../helpers/buildCreateFiscalDebtInquiryDTO'
import { buildGetUploadPresignedUrlDTO } from '../helpers/buildGetUploadPresignedUrlDTO'

interface IPostFiscalRecordApi {
  canonicalProfileId: string
  personProfileId: string
  record: IRecordCandidate
}

export const postFiscalRecordApi = async (props: IPostFiscalRecordApi) => {
  const { canonicalProfileId, personProfileId, record } = props

  const presignedUrl = await HubApi.Assessments.getPostPresignedS3Url(
    buildGetUploadPresignedUrlDTO({ personProfileId, record })
  )
  await AwsApi.S3.uploadFileWithPresignedUrl(presignedUrl, record.file)

  const createFiscalDebtInquiryDTO = buildCreateFiscalDebtInquiryDTO({
    canonicalProfileId,
    record,
    documentUrl: presignedUrl?.expectedDocumentUrlPath
  })
  return HubApi.AssessmentsFiscal.postDebtInquiryRequest(createFiscalDebtInquiryDTO)
}
