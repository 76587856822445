import { SVGProps } from 'react'

export const GreenCheckedIcon = ({ width, height, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width || '26'}
      height={height || '20'}
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.50362 18C9.23858 17.9964 8.98445 17.8938 8.79112 17.7125L1.79112 10.7125C1.63166 10.5182 1.55017 10.2715 1.5625 10.0205C1.57483 9.76943 1.6801 9.53194 1.85783 9.3542C2.03557 9.17647 2.27306 9.0712 2.52411 9.05887C2.77516 9.04654 3.02183 9.12803 3.21612 9.28749L9.50362 15.5875L22.7911 2.28749C22.9854 2.12803 23.2321 2.04654 23.4831 2.05887C23.7342 2.0712 23.9717 2.17647 24.1494 2.3542C24.3271 2.53194 24.4324 2.76943 24.4447 3.02048C24.4571 3.27153 24.3756 3.5182 24.2161 3.71249L10.2161 17.7125C10.0228 17.8938 9.76867 17.9964 9.50362 18Z"
        fill="#94D82D"
        stroke="#94D82D"
        strokeWidth="3"
        strokeMiterlimit="1.41421"
      />
    </svg>
  )
}
