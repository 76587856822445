import { Box, CircularProgress, Typography } from '@mui/material'

interface ILoadingState {
  message?: string
}

export const LoadingState = (props: ILoadingState) => {
  const { message = 'Obtendo dados... Por favor, aguarde um instante' } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.5rem'
      }}>
      <CircularProgress />
      <Typography>{message}</Typography>
    </Box>
  )
}
