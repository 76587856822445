import { TSelectedRegion } from '@/services/RadarApi'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { Box, Typography, Button } from '@mui/material'
import * as turf from '@turf/turf'
import { useState, SetStateAction, Dispatch } from 'react'

export const GeoJsonInput = ({
  setSelectedRegion
}: {
  setSelectedRegion: Dispatch<SetStateAction<TSelectedRegion>>
}) => {
  const [fileName, setFileName] = useState('')
  const [inputGeoJson, setInputGeoJson] = useState<turf.FeatureCollection | turf.Feature | null>(
    null
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px',
        gap: '12px',
        width: '250px',
        height: '100%'
      }}>
      <Button
        variant="contained"
        component="label"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '12px',
          gap: '12px',
          minWidth: '250px',
          width: 'fit-content',
          height: '35px',
          border: '1px solid #CED4DA',
          background: 'white',
          '&:hover': {
            background: 'white'
          },
          borderRadius: '8px'
        }}>
        <input
          hidden
          type="file"
          accept=".geojson"
          onInput={e => {
            const inputFilePath = (e.target as HTMLInputElement).value.split('/')
            const inputFilePathNames = inputFilePath[inputFilePath.length - 1].split('\\')
            const file = inputFilePathNames[inputFilePathNames.length - 1]
            if (file) setFileName(file)
          }}
          onChange={e => {
            if (e.target.files == null) return
            const reader = new FileReader()
            reader.onload = event => {
              if (event.target !== null && event.target.result !== null) {
                setInputGeoJson(JSON.parse(event.target.result as string))
              }
            }
            reader.readAsText(e.target.files[0])
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px',
            gap: '10px',
            width: '28px',
            height: '28px',
            background: '#EDF2FF',
            borderRadius: '50px'
          }}>
          <NoteAddIcon sx={{ fill: '#5C7CFA', height: '18px', width: '18px' }} />
        </Box>
        <Typography
          variant="h4"
          sx={{
            fontSize: '0.75rem',
            color: '#868E96'
          }}>
          {fileName || 'Insira um arquivo'}
        </Typography>
      </Button>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px',
          gap: '8px',
          width: 'fit-content',
          height: '33px',
          background: inputGeoJson ? '#111315' : '#868E96',
          borderRadius: '8px',
          '&:hover': {
            cursor: inputGeoJson ? 'pointer' : 'no-drop'
          }
        }}
        onClick={() => {
          if (!inputGeoJson) return
          const geometry =
            inputGeoJson.type === 'FeatureCollection'
              ? inputGeoJson.features[0].geometry
              : inputGeoJson.geometry
          setSelectedRegion({
            latLng: undefined,
            geometry
          })
        }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: '#FFFFFF'
          }}>
          Buscar geometria
        </Typography>
      </Box>
      <Typography
        variant="h6"
        sx={{
          color: '#495057',
          fontSize: '0.8rem'
        }}>
        Apenas arquivos .geojson são suportados
      </Typography>
    </Box>
  )
}
