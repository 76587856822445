import { LetterAvatar } from '@/components/LetterAvatar/LetterAvatar'
import { MembershipDTO } from '@services'

export const MemberInformationAvatar = ({ user }: { user: MembershipDTO['user'] }) => {
  return (
    <LetterAvatar
      src={user.photoURL}
      name={user.name}
      lastName={user.lastName}
      sx={{
        width: '37px',
        height: '37px',
        fontSize: '14px'
      }}
    />
  )
}
