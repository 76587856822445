import { useMultiselectCondition } from '@/lib/AuthorizationForm/hooks/useMultiselectcondition'
import { MultiSelectConditionDTO } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Autocomplete, Box, Checkbox, FormControl, TextField } from '@mui/material'

import { TSaveValue } from '../ConditionContainer'

interface IMultiselectCondition {
  multiselectCondition: MultiSelectConditionDTO
  saveValue: TSaveValue
  organizationId: string
  defaultValues: string[]
}

export const MultiselectCondition = ({
  multiselectCondition,
  saveValue,
  organizationId,
  defaultValues
}: IMultiselectCondition) => {
  const { options, isLoading, selectedValues, handleChangeSelectedValues } =
    useMultiselectCondition({ multiselectCondition, organizationId, defaultValues })

  return (
    <Box
      className="multiselect-condition"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '100%',
        minWidth: 600,

        '& .MuiFormControl-root': {
          width: '100%'
        }
      }}>
      <FormControl className="multiselect-form" required fullWidth>
        <Autocomplete
          multiple
          disableCloseOnSelect
          color="secondary"
          id="tags-standard"
          options={options}
          groupBy={option => option.group ?? ''}
          loading={isLoading}
          getOptionLabel={option => option.label}
          value={selectedValues}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(event, newValue) => {
            handleChangeSelectedValues({
              newSelectArray: newValue
            })
            saveValue(newValue?.map(v => v.value))
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Estiver incluso em"
              placeholder="Digite para pesquisar um valor"
            />
          )}
          renderOption={(propsRender, option, { selected }) => (
            <li {...propsRender} key={typeof option === 'string' ? option : option.value}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {typeof option === 'string' ? option : option.label}
            </li>
          )}
          sx={{
            color: 'black',
            width: '100%',
            maxWidth: '100%',
            minWidth: 600
          }}
        />
      </FormControl>
    </Box>
  )
}
