import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'

interface IMessageModal {
  title: string
  message: string
  clearMessage: () => void
}

export const MessageModal = ({ title, message, clearMessage }: IMessageModal) => {
  return (
    <Dialog open={message !== ''} onClose={clearMessage}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <IconButton sx={{ width: '1.5em', height: '1.5em' }} onClick={clearMessage}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          justifyContent: 'right',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '40vw',
          maxWidth: '40vw',
          height: 'fit-content',
          padding: '1.4em'
        }}>
        <DialogContent sx={{ display: 'flex' }}></DialogContent>
        <Typography textAlign={'center'} variant="subtitle1">
          {message}
        </Typography>
        <DialogActions>
          <Button variant="contained" color="error" onClick={clearMessage}>
            Fechar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
