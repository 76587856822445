import { Box, Typography } from '@mui/material'

import { EmptyCubeIMage } from '../../assets/svg'

interface IEmptyObjectErrorMessage {
  errorMsg: string
  suggestion?: string
}

export const EmptyObjectErrorMessage = ({ errorMsg, suggestion }: IEmptyObjectErrorMessage) => {
  return (
    <Box
      sx={{
        margin: '80px 18px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent'
      }}>
      <EmptyCubeIMage />
      <Typography
        sx={{
          fontFamily: 'Arial',
          fontSize: '18px',
          fontWeight: '600',
          lineHeight: '21px',
          textAlign: 'center',
          maxWidth: '50%',
          marginTop: '30px'
        }}>
        {errorMsg}
      </Typography>

      {suggestion && (
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontSize: '17px',
            fontWeight: '500',
            lineHeight: '21px',
            textAlign: 'center',
            maxWidth: '50%',
            marginTop: '10px'
          }}>
          {suggestion}
        </Typography>
      )}
    </Box>
  )
}
