import { Box } from '@mui/system'

export const StatusCheck = (props: { color: string }) => {
  return (
    <Box
      sx={{
        ml: '0.7rem',
        height: '1rem',
        width: '1rem',
        borderRadius: '50%',
        bgcolor: props.color
      }}></Box>
  )
}
