import { FlexBox } from '@components'

import { renderEmptyState } from '@shared'

import { useCriminalAssessment } from '../hooks/useCriminalAssessment'
import { CriminalTable } from './CriminalTable'

interface IComplianceCriminalProps {
  personProfileId: string
}

export const ComplianceCriminal = (props: IComplianceCriminalProps) => {
  const { personProfileId } = props

  const { response, isRequesting, error } = useCriminalAssessment({ personProfileId })

  return (
    <FlexBox vertical>
      {renderEmptyState(isRequesting, error) || (
        <CriminalTable criminalRecords={response?.criminal ? [response?.criminal] : []} />
      )}
    </FlexBox>
  )
}
