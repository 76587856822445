export const AreasIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 13.4375L2.25 14.5625V4.4375L6.75 3.3125"
      stroke="#111315"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 15.6875L6.75 13.4375V3.3125L11.25 5.5625V15.6875Z"
      stroke="#111315"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 5.5625L15.75 4.4375V14.5625L11.25 15.6875"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
