import { css } from '@emotion/react'
import { colors } from '@mui/material'

export const globalStyle = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
  }

  body,
  input,
  textarea,
  select,
  button {
    font-family: 'Roboto', sans-serif;
    line-height: 150%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    line-height: 125%;
  }

  h1 {
    line-height: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  .customHandle {
    display: none;
  }

  .customResizeBorderRow {
    cursor: row-resize;
    z-index: 99;
    height: 4px;
    background-color: ${colors.grey[200]};
  }

  .customResizeBorder {
    cursor: ew-resize;
    z-index: 99;
    width: 3px;
    margin-right: -3px;
    background-image: linear-gradient(to right, ${colors.grey[500]}, transparent);
  }
`
