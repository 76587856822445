export enum FISCAL_ASSESSMENT_LEVEL {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE'
}

export enum FISCAL_ASSESSMENT_STATUS {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  POSITIVE_WITH_NEGATIVE_EFFECTS = 'POSITIVE_WITH_NEGATIVE_EFFECTS',
  UNDEFINED = 'UNDEFINED'
}
