import { openConfirm } from '@components'
import { BENDER_CONSENT_STATUS, BENDER_CONSENT_TYPE } from '@services'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ValidateConsentsView } from './ValidateConsentsView'
import { useBenderTask } from './hooks/useBenderTask'
import { useConsents } from './hooks/useConsents'
import { useGetCustomer } from './hooks/useGetCustomer'

export const ValidateConsents = () => {
  const { taskId } = useParams()
  const { personProfileInfo, organizationInfo, consentType, consent, isFetchingTasks, hasError } =
    useBenderTask(taskId)

  const { customerId } = useGetCustomer({
    personProfileId: personProfileInfo?.PersonId,
    organizationId: organizationInfo?.OrganizationId
  })

  const {
    updateConsent,
    isRequesting: isRequestingUpdateConsent,
    error: errorUpdateConsent
  } = useConsents()
  const { enqueueSnackbar } = useSnackbar()

  const [openRejectionDialog, setOpenRejectionDialog] = useState(false)

  const handleAcceptTask = async () => {
    if (await openConfirm('Deseja validar a anuência?', { title: 'Validar' })) {
      const updateConsentResponse = await updateConsent({
        consent: consent,
        status: BENDER_CONSENT_STATUS.ACTIVE
      })
      if (updateConsentResponse.success) {
        enqueueSnackbar('Tarefa aprovada com sucesso!', { variant: 'success' })
        window.location.reload()
      } else enqueueSnackbar('Falha ao aprovar anuência, tente novamente!', { variant: 'error' })
    }
  }

  const handleOpenRejectionDialog = () => {
    setOpenRejectionDialog(true)
  }

  const handleCancelRejectionDialog = () => {
    setOpenRejectionDialog(false)
  }

  const handleConfirmRejectionDialog = async (reason: string) => {
    setOpenRejectionDialog(false)

    const updateConsentResponse = await updateConsent({
      consent: consent,
      status: BENDER_CONSENT_STATUS.REJECTED,
      rejectionReason: reason
    })
    if (updateConsentResponse.success) {
      enqueueSnackbar('Anuência rejeitada com sucesso!', { variant: 'success' })
      window.location.reload()
    } else enqueueSnackbar('Falha ao rejeitar anuência, tente novamente!', { variant: 'error' })
  }

  const handleOpenHubPage = () => {
    window.open(
      `${process.env.REACT_APP_URL_HUB_FRONT}/cliente/documento/${customerId}/anuencia/pessoa/${personProfileInfo?.PersonId}`,
      '_blank'
    )
  }

  const handleOpenPdf = () => {
    if (consent?.documentUrl) {
      window.open(consent.documentUrl)
    }
  }

  const type = useMemo(() => {
    if (consentType === BENDER_CONSENT_TYPE.CPR_ACCESS) {
      return 'CPR'
    }
    if (consentType === BENDER_CONSENT_TYPE.SCR_ACCESS) {
      return 'SCR'
    }
    return 'Não identificado'
  }, [consentType])

  return (
    <ValidateConsentsView
      profileGovRegCode={personProfileInfo?.PersonGovRegCode}
      profileId={personProfileInfo?.PersonId}
      profileName={personProfileInfo?.PersonName}
      organizationName={organizationInfo?.OrganizationName}
      consentType={type}
      handleOpenHubPage={handleOpenHubPage}
      handleAcceptTask={handleAcceptTask}
      isOpenHubDisabled={!customerId || !personProfileInfo?.PersonId}
      handleOpenPdf={handleOpenPdf}
      isRequesting={isRequestingUpdateConsent}
      isFetchingTasks={isFetchingTasks}
      error={errorUpdateConsent}
      rejectionDialog={{
        handleCancel: handleCancelRejectionDialog,
        handleConfirm: handleConfirmRejectionDialog,
        handleOpen: handleOpenRejectionDialog,
        open: openRejectionDialog
      }}
      pdfUrl={hasError ? undefined : consent?.documentUrl}
      status={hasError ? undefined : consent?.status}
      consent={consent}
      hasConsentError={hasError}
    />
  )
}
