import { BenderCompleteTaskRequest } from '@/services/Bender/Task/contract/request/BenderCompleteTask.request'
import { RootState, useAppSelector } from '@redux'
import { BenderApi } from '@services'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'

export const useBenderCompleteTask = (taskId?: string, executionArn?: string) => {
  const { email: userEmail } = useAppSelector((root: RootState) => root.authenticator.user.info)

  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const [error, setError] = useState<unknown>(undefined)

  const resetStates = () => {
    setIsRequesting(true)
    setError(undefined)
  }

  const benderCompleteTask = useCallback(
    async (taskCompletion: Omit<BenderCompleteTaskRequest, 'id' | 'completedBy'>) => {
      try {
        if (!taskId)
          return {
            success: false,
            data: null,
            error: null
          }
        resetStates()

        const response = await BenderApi.task.complete(taskId, {
          completedBy: userEmail,
          id: taskId,
          ...taskCompletion
        })
        setIsRequesting(false)
        return {
          success: true,
          data: response,
          error: null
        }
      } catch (err) {
        if (err instanceof AxiosError) console.error(err.response?.data)
        setIsRequesting(false)
        setError(err)
        return {
          success: false,
          data: null,
          error: err
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskId, executionArn]
  )

  return { isRequesting, error, benderCompleteTask }
}
