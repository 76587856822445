import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type THeader = {
  mode: 'fixed' | 'none'
}

const initialState: THeader = { mode: 'fixed' }

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    changeHeader: (state, action: PayloadAction<THeader>) => {
      state.mode = action.payload.mode
    }
  }
})

export const { changeHeader } = headerSlice.actions

export const headerReducer = headerSlice.reducer
