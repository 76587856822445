export enum TASK_FILTER_TYPE {
  STATUS = 'status',
  TYPE = 'tipo',
  ORGANIZATION = 'organização',
  PROFILE = 'perfil'
}

export enum EXECUTION_FILTER_TYPE {
  STATUS = 'status',
  NAME = 'nome',
  ORGANIZATION = 'organização'
}
