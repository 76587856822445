/* eslint-disable @typescript-eslint/ban-ts-comment */
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Button, Typography } from '@mui/material'
import * as turf from '@turf/turf'
import * as React from 'react'
import ResizePanel from 'react-resize-panel-ts'

import AreasList from '../AreasList/AreasListContainer'

interface TSideBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoJson: any
  propertyName: string | null
  propertyImage: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGeoJson: React.Dispatch<React.SetStateAction<any>>
  highlightedFeatId: number | null
  setHighlightedFeatId: React.Dispatch<React.SetStateAction<number | null>>
  editingIndex: number | null
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  saveEditingArea: () => void
  cancelEditing: () => void
  handleBack: () => void
  handleFinalizeEdition: () => void
  addSelectedAreas: (id: number) => void
  removeArea: (featId: turf.helpers.Id | undefined) => void
  removeUnproductiveAreas: () => void
  getImage: (geometry: turf.Geometry) => string
  isControlPressed: boolean | null
  selectedAreas: string[] | null
  isClickDisabled: boolean | null
}
export const SideBar = (props: TSideBarProps) => {
  const {
    geoJson,
    setGeoJson,
    highlightedFeatId,
    handleFinalizeEdition,
    setHighlightedFeatId,
    editingIndex,
    setEditingIndex,
    saveEditingArea,
    cancelEditing,
    removeArea,
    isControlPressed,
    addSelectedAreas,
    selectedAreas,
    handleBack,
    getImage,
    isClickDisabled,
    propertyImage,
    propertyName
  } = props
  return (
    <Box style={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItens: 'center'
          // gap: '10px'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '10px',
            alignItems: 'center',
            p: '8px 16px',
            widht: '350px',
            height: '80px'
          }}>
          {propertyImage && (
            <img
              src={propertyImage}
              alt="Imagem de satélite do talhão"
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '12px'
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '16px',
                fontHeight: '24px'
                // flexBasis: '100%'
              }}>
              Propriedade
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '14px',
                fontHeight: '20px'
              }}>
              {propertyName}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '18px',
            justifyContent: 'space-around',
            alignItems: 'center',
            background: '#F8F9FA'
          }}>
          <Typography sx={{ fontWeight: '700' }}>{'Total de talhões: '}</Typography>
          {geoJson && (
            <Typography
              sx={{
                fontWeight: '700',
                color: 'grey'
              }}>
              {`${geoJson.features.length}`}
            </Typography>
          )}
        </Box>
        {/* {isControlPressed && selectedAreas && selectedAreas.length > 1 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button
              variant="contained"
              // disableElevation
              color="warning"
              onClick={removeUnproductiveAreas}
              sx={{
                textTransform: 'capitalize',
                p: '7px 40px',
                color: '#000000',
                borderRadius: '50px',
                width: '250px'
              }}>
              <CloseIcon fontSize="small" sx={{ color: '#000000' }}></CloseIcon>Apagar improdutivos
            </Button>
          </Box>
        )} */}
      </Box>
      {/* @ts-ignore */}
      <ResizePanel
        direction="e"
        handleClass="customHandle"
        borderClass="customResizeBorder"
        style={{
          minWidth: '350px',
          maxWidth: '30vw',
          height: '78%'
          // position: 'relative'
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            // position: 'relative',
            gap: '10px'
          }}>
          <Box
            sx={{
              width: '100%',
              overflow: 'scroll',
              overflowX: 'hidden'
              // bgcolor: colors.grey[600]
              // height: '90%'
            }}>
            {/* <ArrowBackButton handleBack={handleBack} /> */}
            <AreasList
              geoJson={geoJson}
              setGeoJson={setGeoJson}
              highlightedFeatId={highlightedFeatId}
              setHighlightedFeatId={setHighlightedFeatId}
              editingIndex={editingIndex}
              setEditingIndex={setEditingIndex}
              saveEditingArea={saveEditingArea}
              cancelEditing={cancelEditing}
              removeArea={removeArea}
              isControlPressed={isControlPressed}
              addSelectedAreas={addSelectedAreas}
              selectedAreas={selectedAreas}
              getImage={getImage}
              isClickDisabled={isClickDisabled}
            />
          </Box>
          <Box
            sx={{
              p: '10px',
              display: 'flex',
              width: '250px',
              gap: '10px'
              // justifyContent: 'center'
            }}>
            <Button
              variant="contained"
              disableElevation
              color="error"
              onClick={handleBack}
              sx={{
                textTransform: 'capitalize',
                p: '7px 40px',
                color: '#000000',
                borderRadius: '50px'
              }}>
              <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="success"
              onClick={() => {
                confirm('Deseja Finalizar a revisão?') && handleFinalizeEdition()
              }}
              sx={{
                textTransform: 'capitalize',
                p: '7px 40px',
                color: '#000000',
                borderRadius: '50px'
              }}>
              <DoneIcon fontSize="small" sx={{ color: '#000000' }} />
              Finalizar
            </Button>
          </Box>
        </Box>
      </ResizePanel>
    </Box>
  )
}
