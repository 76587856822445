import { Box, colors, Link, Typography } from '@mui/material'
import * as turf from '@turf/turf'
import WebMercatorViewport from 'viewport-mercator-project'

export type TAreasCrops = {
  areaId: string
  cropsNames: string[]
}

import { GreenCheckedIcon } from '../../../../../../assets/svg'
import { TLandField } from '../../../EditSeasons'

const getImage = (geometry: turf.Geometry): string => {
  const MAPBOX_TOKEN =
    'pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg'
  const geoJson = {
    type: 'Feature',
    properties: {
      stroke: 'white',
      'stroke-width': 5,
      fill: '#3E5CD1',
      'fill-opacity': 0.7
    },
    geometry: geometry
  }

  const cornersLongLat = turf.bbox(geometry)
  const viewport = new WebMercatorViewport({
    width: 300,
    height: 300
  }).fitBounds(
    [
      [cornersLongLat[0], cornersLongLat[1]],
      [cornersLongLat[2], cornersLongLat[3]]
    ],
    { padding: 40 }
  )
  const { longitude, latitude, zoom } = viewport

  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURIComponent(
    JSON.stringify(geoJson)
  )})/${longitude},${latitude},${zoom}/300x300?access_token=${MAPBOX_TOKEN}`
}

interface IAreaCard {
  area: TLandField
  isSelected: boolean
  index: number
  areaCrops?: TAreasCrops
  onClick: () => void
  isChecked: boolean
}

export const AreaCard = ({ area, isSelected, index, onClick, areaCrops, isChecked }: IAreaCard) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '9px 16px',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%',
        height: '106px',
        border: '1px solid #E9ECEF',
        borderRadius: '12px',
        background: isSelected ? '#F3F4F6' : 'white',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={onClick}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}>
        <img
          src={getImage(area.geometry)}
          alt="Imagem de satélite do talhão"
          style={{
            width: '70.5px',
            height: '70px',
            background: '#DEE2E6',
            borderRadius: '8px'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px'
            //flexGrow: 1,
          }}>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              color: '#212529'
            }}>{`Talhão ${index.toLocaleString('pt-BR', {
            minimumIntegerDigits: 2
          })}`}</Typography>
          <Typography
            variant="caption"
            color={colors.grey[700]}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1
            }}>
            {areaCrops
              ? areaCrops.cropsNames.sort((a, b) => (a == 'Outros' ? 1 : -1)).join(' | ')
              : ''}
          </Typography>
          <Link
            href={
              'https://plataforma.alerta.mapbiomas.org/mapa#?p=' + JSON.stringify(area.geometry)
            }
            underline="always"
            target="_blank"
            rel="noreferrer">
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '12px',
                color: '#FF922B'
              }}>
              MAPBIOMAS
            </Typography>
          </Link>
        </Box>
      </Box>
      {isChecked && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '4px'
          }}>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '16px',
              color: '#94D82D'
            }}>
            Validado
          </Typography>
          <GreenCheckedIcon width="14px" height="10px" />
        </Box>
      )}
      {/* <Typography variant="body2" color={colors.grey[700]}>
        {`${(turf.area(area.geometry) / 10000).toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })} ha`}
      </Typography> */}
    </Box>
  )
}
