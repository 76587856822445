import { AuthorizationResourceResponse } from '@/services/Hub/Authorization/contract/response/resources.response'
import {
  DynamicOptionsDTO,
  MultiSelectConditionDTO,
  SelectOptionDTO
} from '@/services/Hub/Authorization/contract/response/subjects.response'
import { HubApi } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'underscore'

import { objectIsEmpty } from '../utils/isNill'

function sortByGroup(curr: SelectOptionDTO, next: SelectOptionDTO): number {
  const currGroup = `${curr?.group ?? ''}`
  const nextGroup = `${next?.group ?? ''}`

  return currGroup.localeCompare(nextGroup)
}

/**
 * @IMPORTANT this is the delimitation of parameters on the rules JSON
 */
export const templateSettings = {
  interpolate: /\{\{(.+?)\}\}/g
}

export function interpolateString(str: string, context: unknown): string {
  const template = _.template(str, templateSettings)
  return template(context)
}

function buildTSelectOptionData(
  multiselectCondition: Pick<DynamicOptionsDTO, 'mapValue' | 'mapLabel' | 'groupBy'>,
  data: Record<string, unknown>
): SelectOptionDTO {
  const value = interpolateString(multiselectCondition.mapValue, data)
  const label = interpolateString(multiselectCondition.mapLabel, data)
  const group = multiselectCondition.groupBy
    ? interpolateString(multiselectCondition.groupBy, data)
    : undefined

  return {
    value,
    label,
    group
  }
}

type TMultiselectDataResponse = {
  data: AuthorizationResourceResponse | undefined
  isLoading: boolean
  hasError: boolean
}

interface IUseMultiselectCondition {
  multiselectCondition: MultiSelectConditionDTO
  organizationId: string
  defaultValues: string[]
}

export const useMultiselectCondition = (props: IUseMultiselectCondition) => {
  const [data, setData] = useState<TMultiselectDataResponse>({
    data: undefined,
    isLoading: true,
    hasError: false
  })

  const [isLoadingOptions, setIsLoadingOptions] = useState(true)
  const [selectedValues, setSelectedValues] = useState<SelectOptionDTO[]>([])

  const { enqueueSnackbar } = useSnackbar()

  const { multiselectCondition, organizationId, defaultValues } = props

  const options: SelectOptionDTO[] = useMemo(() => {
    if (data.isLoading) {
      setIsLoadingOptions(true)
      return []
    }
    if (data.hasError) {
      setIsLoadingOptions(false)
      return []
    }
    setIsLoadingOptions(true)

    const newOptions: SelectOptionDTO[] = []
    if (multiselectCondition.config.options?.length) {
      newOptions.push(...multiselectCondition.config.options)
    }

    if (!!data.data?.length && !objectIsEmpty(multiselectCondition.config.dynamicOptions)) {
      const apiOptions: SelectOptionDTO[] = data.data.map(dataItem =>
        buildTSelectOptionData(multiselectCondition.config.dynamicOptions!, dataItem)
      )
      apiOptions.sort(sortByGroup)
      newOptions.push(...apiOptions)
    }

    setIsLoadingOptions(false)
    return newOptions
  }, [data, multiselectCondition])

  const handleChangeSelectedValues = useCallback(
    ({
      newSelectArray,
      defaultValues
    }: {
      newSelectArray?: SelectOptionDTO[]
      defaultValues?: string[]
    }) => {
      if (newSelectArray) {
        setSelectedValues(newSelectArray)
        return
      }
      if (defaultValues) {
        const newSelectedValues = options.filter(option => defaultValues.includes(option.value))
        setSelectedValues(newSelectedValues)
      }
    },
    [options]
  )

  const fetchOptions = useCallback(async () => {
    if (!multiselectCondition.config.dynamicOptions) {
      setData({
        data: undefined,
        isLoading: false,
        hasError: false
      })

      return
    }

    try {
      const resourceResponse = await HubApi.Authorization.getResources({
        organizationId,
        resource: multiselectCondition.config.dynamicOptions?.resource
      })
      setData({
        data: resourceResponse,
        isLoading: false,
        hasError: false
      })
    } catch (error) {
      enqueueSnackbar('Erro ao carregar as opções do multiselect, recarrega a pagina', {
        variant: 'error'
      })
      setData({
        data: undefined,
        isLoading: false,
        hasError: true
      })
    }
  }, [multiselectCondition, enqueueSnackbar, organizationId])

  useEffect(() => {
    fetchOptions()
  }, [fetchOptions])

  useEffect(() => {
    if (options.length) {
      handleChangeSelectedValues({ defaultValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return { options, isLoading: isLoadingOptions, selectedValues, handleChangeSelectedValues }
}
