/**
 * Formats a full name by capitalizing the first letter of each word, except for articles and prepositions
 *
 * @param {string} fullName - The full name to be formatted.
 *
 * @returns {string} The formatted name.
 *
 * @example
 * ```js
 * formatName('joão pedro da silva')  // 'João Pedro da Silva'
 * ```
 */
export const formatName = (fullName: string): string => {
  const articlesAndPrepositions = [
    'o',
    'a',
    'os',
    'as',
    'ao',
    'aos',
    'à',
    'às',
    'de',
    'do',
    'da',
    'dos',
    'das',
    'em',
    'no',
    'na',
    'nos',
    'nas'
  ]

  const words = fullName.toLocaleLowerCase().split(' ').filter(Boolean)

  return words
    .map((name, index) => {
      if (index !== 0 && articlesAndPrepositions.includes(name)) return name
      return (name[0]?.toUpperCase() || '') + (name?.substring(1) || '')
    })
    .join(' ')
}
