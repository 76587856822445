import { Box, Checkbox, colors, Typography } from '@mui/material'
import * as turf from '@turf/turf'

import { TLandField } from '../../../EditSeasons'

interface IAreaCard {
  area: TLandField
  isSelected: boolean
  disabledCheck: boolean
  index: number
  onClick: () => void
  color?: string
}

export const AreaCard = ({ area, isSelected, index, onClick, disabledCheck, color }: IAreaCard) => {
  return (
    <Box
      sx={{
        padding: '0.5rem',
        width: '100%',
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      bgcolor={isSelected || disabledCheck ? colors.grey[200] : 'transparent'}
      borderLeft={isSelected && color ? `5px solid ${color}` : '5px solid transparent'}
      onClick={!disabledCheck ? onClick : undefined}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Typography variant="subtitle1">
          {`Talhão ${index.toLocaleString('pt-BR', {
            minimumIntegerDigits: 2
          })}`}
        </Typography>
        <Typography variant="caption" color={colors.grey[700]} flexGrow={1}>
          #currentCrop?
        </Typography>
      </Box>

      <Typography
        variant="body2"
        color={colors.grey[500]}
        sx={{
          flexGrow: 1,
          pl: '1.5rem',
          pr: '1rem',
          textAlign: 'right'
        }}>
        {`${(turf.area(area.geometry) / 10000).toLocaleString('pt-BR', {
          maximumFractionDigits: 2
        })} ha`}
      </Typography>

      <Checkbox
        sx={{
          p: 0
        }}
        checked={isSelected || disabledCheck}
        disabled={disabledCheck}
      />
    </Box>
  )
}
