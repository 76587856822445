import { Body } from './Body'
import { Header } from './Header'
import { PageStyleRoot } from './Root'
import { SearchInput } from './SearchInput'

export const PageStyle = {
  root: PageStyleRoot,
  body: Body,
  header: Header,
  SearchInput
}
