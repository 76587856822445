import { useFetchCrops } from '@/pages/bender/segmentationReview/hooks/useFetchCrops'
import { Info } from '@mui/icons-material'
import { Box, Button, colors, Popover, Typography } from '@mui/material'
import * as turf from '@turf/turf'
import { useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import WebMercatorViewport from 'viewport-mercator-project'

import { TLandField } from '../../../EditSeasons'

const getImage = (geometry: turf.Geometry): string => {
  const MAPBOX_TOKEN =
    'pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg'
  const geoJson = {
    type: 'Feature',
    properties: {
      stroke: '#9EEB215',
      'stroke-width': 2,
      fill: '#3E5CD1',
      'fill-opacity': 0.7
    },
    geometry: geometry
  }

  const cornersLongLat = turf.bbox(geometry)
  const viewport = new WebMercatorViewport({
    width: 300,
    height: 300
  }).fitBounds(
    [
      [cornersLongLat[0], cornersLongLat[1]],
      [cornersLongLat[2], cornersLongLat[3]]
    ],
    { padding: 40 }
  )
  const { longitude, latitude, zoom } = viewport

  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURIComponent(
    JSON.stringify(geoJson)
  )})/${longitude},${latitude},${zoom}/300x300?access_token=${MAPBOX_TOKEN}`
}

interface IAreaCard {
  area: TLandField
  isSelected: boolean
  index: number
  onClick: () => void
}

export const AreaCard = ({ area, isSelected, index, onClick }: IAreaCard) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>()
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<EventTarget & SVGSVGElement>()
  const { crops, fetchCrops } = useFetchCrops()

  const handleClickInfo = (index: number, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    fetchCrops({ geometry: area.geometry, type: 'Feature' } as turf.Feature<turf.Geometry>)
    setPopoverOpen(true)
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(undefined)
    setPopoverOpen(undefined)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        width: '98%',
        gap: '1rem',
        height: '4rem',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      bgcolor={isSelected ? '#EBEBEB' : 'transparent'}
      onClick={onClick}>
      <img
        src={getImage(area.geometry)}
        alt="Imagem de satélite do talhão"
        style={{
          width: '3em',
          height: '3em',
          borderRadius: '12px'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
          //flexGrow: 1,
        }}>
        <Typography variant="subtitle1">{`Talhão ${index.toLocaleString('pt-BR', {
          minimumIntegerDigits: 2
        })}`}</Typography>
        <Info sx={{ color: '#000000' }} onClick={event => handleClickInfo(index, event)} />
        <Popover
          id={index.toString()}
          anchorEl={popoverAnchorEl}
          open={!!popoverOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <Typography sx={{ p: 2 }}>
            {crops && crops.map((crop, index) => <p key={index}>{crop[0] + ': ' + crop[1]}</p>)}
          </Typography>
        </Popover>
      </Box>
      <Box
        sx={{
          width: '3.5rem'
        }}>
        <Typography variant="body2" color={colors.grey[700]}>
          {`${(turf.area(area.geometry) / 10000).toLocaleString('pt-BR', {
            maximumFractionDigits: 2
          })} ha`}
        </Typography>
      </Box>
      <Button
        target="newtab"
        size="small"
        variant="contained"
        href={'https://plataforma.alerta.mapbiomas.org/mapa#?p=' + JSON.stringify(area.geometry)}>
        Mapa
      </Button>
    </Box>
  )
}
