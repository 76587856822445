/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * @note This 'CollectorException' is a wrapper of the 'Compliance' component.
 */

import { useFetchTask } from '@/utils/hooks/useFetchTask'
import { openConfirm } from '@components'
import { RootState, useAppSelector } from '@redux'
import { BenderApi, BENDER_TASK_STATUS } from '@services'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateRecordsBackend } from '../Compliance/hooks/useCreateRecordsBackend'
import { ComplianceContext } from '../Compliance/providers/ComplianceContext'
import { CollectorExceptionView } from './CollectorExceptionView'
import {
  getComplianceFromCollectorName,
  TCompliance
} from './helpers/getComplianceFromCollectorName'

export const CollectorException = () => {
  const { taskId } = useParams()

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  // The collector exception does not use multiple records, but inherit the same structure of Compliance
  const { setPersonProfile, recordCandidates } = useContext(ComplianceContext)

  const [compliance, setCompliance] = useState<TCompliance>()
  const metricsEnterPageTimestamp = useRef(new Date())

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { task } = useFetchTask<'CollectorException'>(taskId, task => {
    setCompliance(getComplianceFromCollectorName(task.input.CollectorInput?.collector))
  })
  const { createRecordsBackend, isCreatingRecord } = useCreateRecordsBackend(compliance?.type)

  const handleCompleteTask = async () => {
    if (recordCandidates.length === 0) return
    if (
      await openConfirm('Você deseja criar o registro e finalizar a tarefa?', {
        title: 'Finalizar Tarefa'
      })
    ) {
      await createRecordsBackend(recordCandidates)
      completeTask()
    }
  }

  const completeTask = () => {
    if (!taskId) {
      enqueueSnackbar('ID da tarefa não encontrado.', { variant: 'error' })
      return
    }

    BenderApi.task
      .complete(taskId, {
        completedBy: userInfo.email,
        id: taskId,
        status: BENDER_TASK_STATUS.SUCCEEDED,
        startedAt: metricsEnterPageTimestamp.current,
        completionInput: { action: 'SUCCESS' }
      })
      .then(() => {
        enqueueSnackbar('Tarefa finalizada com sucesso!', { variant: 'success' })
        navigate(-1)
      })
      .catch(err => {
        alert('Ocorreu um erro ao tentar finalizar a tarefa.')
        console.error(err)
        enqueueSnackbar('Erro ao tentar finalizar a tarefa.', { variant: 'error' })
        navigate(-1)
      })
  }

  useEffect(() => {
    const personInfo = task?.input?.PersonInfo
    if (!personInfo) return

    setPersonProfile({
      canonicalProfileId: personInfo.canonicalProfileId,
      govRegCode: personInfo.govRegCode,
      govRegType: personInfo.type,
      id: personInfo.id,
      name: personInfo.name
    })
  }, [setPersonProfile, task?.input?.PersonInfo])

  return (
    <CollectorExceptionView
      handleCompleteTask={handleCompleteTask}
      sendingCompleteTask={isCreatingRecord}
    />
  )
}
