import { FlexBox } from '@components'

import { renderEmptyState } from '@shared'

import { useLaborAssessment } from '../hooks/useLaborAssessment'
import { LaborTable } from './LaborTable'

interface IComplianceLaborProps {
  personProfileId: string
}

export const ComplianceLabor = (props: IComplianceLaborProps) => {
  const { personProfileId } = props

  const { response, isRequesting, error } = useLaborAssessment({ params: { personProfileId } })

  return (
    <FlexBox vertical>
      {renderEmptyState(isRequesting, error) || (
        <LaborTable
          laborDebts={response?.laborDebt ? [response.laborDebt] : []}
          fgtsDebts={response?.fgtsDebt ? [response.fgtsDebt] : []}
          slaveryOccurrence={response?.slaveryOccurrence ? [response.slaveryOccurrence] : []}
        />
      )}
    </FlexBox>
  )
}
