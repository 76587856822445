import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

import { TAutocompleteOption } from '../BenderExecution'

type TFilterInput = {
  label: string
  handleChange: (event: React.SyntheticEvent<Element, Event>, value: TAutocompleteOption[]) => void
  options: TAutocompleteOption[]
  defaultValue?: TAutocompleteOption[]
}

export const MultipleAutocomplete = ({
  label,
  handleChange,
  options,
  defaultValue
}: TFilterInput) => {
  const renderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Partial<TAutocompleteOption>
  ) => {
    return (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    )
  }
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.value === value.value}
      size="small"
      multiple
      options={options}
      defaultValue={defaultValue ? [...defaultValue] : undefined}
      getOptionLabel={option => option.label || option.value}
      limitTags={1}
      sx={{
        width: 250
      }}
      onChange={handleChange}
      renderInput={params => <TextField {...params} label={label} variant="standard" />}
      renderOption={renderOptions}
    />
  )
}
