import { COMPLIANCE_TASK_TYPE } from '@/pages/bender/Compliance/enums/ComplianceTaskType'
import {
  IRecordCandidate,
  TPersonProfile
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { OrNull } from '@/utils/OrNull'
import { formatGovRegCode } from '@/utils/formatGovRegCode'
import { FlexBox, NavigateBackButton } from '@components'
import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { CreateRecordForm } from './components/CreateRecordForm'

interface TCollectorExceptionMainViewProps {
  handleClickComplete: () => void
  handleClickCancel: () => void
  handleOpenHubProfile: () => void
  personProfile: TPersonProfile
  complianceRecord: OrNull<IRecordCandidate>
  setComplianceRecord: Dispatch<SetStateAction<OrNull<IRecordCandidate>>>
  completeDisabled: boolean
  taskType: COMPLIANCE_TASK_TYPE | undefined
}

export const CollectorExceptionMainView = (props: TCollectorExceptionMainViewProps) => {
  const {
    handleClickComplete,
    handleClickCancel,
    handleOpenHubProfile,
    personProfile,
    complianceRecord,
    setComplianceRecord,
    completeDisabled,
    taskType
  } = props

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 3rem)'
      }}>
      {/* ---------- Header ---------- */}
      <Box sx={{ p: '0.5rem 2rem' }}>
        <NavigateBackButton />
        <FlexBox
          sx={{
            mt: '1rem',
            p: '0.5rem 1rem',
            border: '1px solid #ddd',
            borderRadius: '12px',
            justifyContent: 'space-between'
          }}>
          <Box>
            <Typography variant="subtitle1">{personProfile.name}</Typography>
            <Typography variant="subtitle2">
              {formatGovRegCode(personProfile.govRegCode)}
            </Typography>
          </Box>
          <Button onClick={handleOpenHubProfile}>
            <OpenInNew /> HUB
          </Button>
        </FlexBox>
      </Box>
      {/* ---------- Content ---------- */}
      <Box sx={{ p: '1rem 2rem', flexGrow: 1 }}>
        <CreateRecordForm
          complianceRecord={complianceRecord}
          setComplianceRecord={setComplianceRecord}
          taskType={taskType}
        />
      </Box>
      {/* ---------- Actions ---------- */}
      <FlexBox sx={{ p: '0.5rem 1rem', gap: '0.5rem' }}>
        <Button
          variant="discreet"
          color="warning"
          style={{ minWidth: '128px', flexGrow: 1 }}
          onClick={handleClickCancel}>
          Cancelar
        </Button>
        <Button
          disabled={completeDisabled}
          variant="discreet"
          color="success"
          style={{ minWidth: '128px', flexGrow: 1 }}
          onClick={handleClickComplete}>
          Criar e Finalizar
        </Button>
      </FlexBox>
    </Card>
  )
}
