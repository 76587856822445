import { ErrorPage } from '@components'
import { Groups2, Badge } from '@mui/icons-material'
import { ShortOrganizationDTO } from '@services'
import { useCallback, useState } from 'react'

import { AuthorizationInnerScreen } from './components/AuthorizationInnerScreen'
import { HelpPageContainer } from './components/HelpPage/HelpPageContainer'
import { LoadingSkeleton } from './components/LoadingSkeleton'
import { ManageMembersContainer } from './components/ManageMembers/ManageMembersContainer'
import { ManageRolesContainer } from './components/ManageRoles/ManageRolesContainer'
import { MenuOrganizationOptions } from './components/MenuOrganizationOptions'
import { OrganizationSelect } from './components/OrganizationSelect'
import { useAuthorizationPage } from './hooks/useAuthorizationPage'

export type TAuthorizationMenus = {
  title: string
  value: TTabsValues
  icon: React.ReactNode
}

const authorizationMenus: TAuthorizationMenus[] = [
  { title: 'Gerenciar Membros', value: 'members', icon: <Groups2 /> },
  { title: 'Gerenciar Cargos', value: 'roles', icon: <Badge /> }
  // { title: 'Ajuda', value: 'help', icon: <Help /> }
  //! Page will be implemeted in next sprint
]

const authorizationUrlParams = ['roles', 'members', 'help'] as const
export type TTabsValues = (typeof authorizationUrlParams)[number] | undefined

export const AuthorizationPageContainer = () => {
  const {
    tarkenOrganizations,
    organizationMembers,
    organizationRoles,
    hasError,
    updateOrganizationMembers,
    updateOrganizationRoles,
    resetStates
  } = useAuthorizationPage()

  const [selectedOrganization, setSelectedOrganization] = useState<ShortOrganizationDTO | null>(
    null
  )
  const [selectedMenu, setSelectedMenu] = useState<TTabsValues>('members')

  const handleOrganizationChange = useCallback(
    async (organization: ShortOrganizationDTO | undefined) => {
      if (!organization || organization?.id === selectedOrganization?.id) return
      resetStates()
      setSelectedOrganization(organization)
      await Promise.allSettled([
        updateOrganizationMembers(organization.id),
        updateOrganizationRoles(organization.id, true)
      ])
    },
    [selectedOrganization?.id, resetStates, updateOrganizationMembers, updateOrganizationRoles]
  )

  if (hasError) {
    return <ErrorPage />
  }

  if (tarkenOrganizations.loading) {
    return <LoadingSkeleton />
  }

  if (!selectedOrganization) {
    return (
      <OrganizationSelect.root>
        <OrganizationSelect.title>Bem Vindo ao Gerenciamento de cargos!</OrganizationSelect.title>
        <OrganizationSelect.subtitle>
          Por favor, selecione uma organização para começar!
        </OrganizationSelect.subtitle>
        <OrganizationSelect.select
          options={tarkenOrganizations.data || []}
          handleOrganizationChange={handleOrganizationChange}
          selectedOrganization={selectedOrganization}
        />
      </OrganizationSelect.root>
    )
  }

  return (
    <AuthorizationInnerScreen
      authorizationMenus={authorizationMenus}
      handleMenuSelect={setSelectedMenu}
      selectedMenu={selectedMenu}
      extraMenuOptions={
        <MenuOrganizationOptions
          fetchMembers={() => updateOrganizationMembers(selectedOrganization.id)}
          updateOrganizationRoles={() => updateOrganizationRoles(selectedOrganization.id, true)}
          isRolesLoading={organizationRoles.loading}
          isMembersLoading={organizationMembers.loading}
          selectedOrganization={selectedOrganization}>
          <OrganizationSelect.root>
            <OrganizationSelect.title>Selectione uma organização</OrganizationSelect.title>
            <OrganizationSelect.subtitle>
              Atenção: suas mudanças não serão salvas
            </OrganizationSelect.subtitle>
            <OrganizationSelect.select
              options={tarkenOrganizations.data || []}
              handleOrganizationChange={handleOrganizationChange}
              selectedOrganization={selectedOrganization}
            />
          </OrganizationSelect.root>
        </MenuOrganizationOptions>
      }>
      {selectedMenu === 'help' && <HelpPageContainer />}
      {selectedMenu === 'members' && (
        <ManageMembersContainer
          organizationMembers={organizationMembers}
          organizationRoles={organizationRoles}
        />
      )}
      {selectedMenu === 'roles' && (
        <ManageRolesContainer
          refreshCurrentOrgRoles={() => updateOrganizationRoles(selectedOrganization.id)}
          organizationRoles={organizationRoles}
          selectedOrganization={selectedOrganization}
        />
      )}
    </AuthorizationInnerScreen>
  )
}
