import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'

import { SIZES, SUBJECT_DOMAIN_KEYS } from './AuthorizationForm.constants'
import { IAuthorizationFormContainer } from './AuthorizationForm.interface'
import { FormTabs } from './components/FormTabs'
import { LabelAndDescriptionField } from './components/LabelAndDescriptionField'
import { ResolveSubjects } from './components/ResolveSubjects'
import { useAuthorizationFormHook } from './hooks/useAuthorizationFormHook'

export const AuthorizationForm = (props: IAuthorizationFormContainer) => {
  const {
    onSubmissionCall,
    organizationId,
    selectedRole,
    extraTabs,
    children,
    headerButtons,
    appBar
  } = props

  const {
    availableTabs,
    detailedSubjects,
    currentTab,
    isSubmitting,
    isLoading,
    hasError,
    normalizedRole,
    submitForm,
    handleTabChange
  } = useAuthorizationFormHook({
    extraTabs,
    organizationId,
    onSubmissionCall,
    selectedRole
  })

  const { enqueueSnackbar } = useSnackbar()

  if (isLoading) {
    return <CircularProgress />
  }

  if (hasError) {
    return <div>error</div>
  }

  return (
    <Formik
      initialValues={normalizedRole}
      onSubmit={values => {
        submitForm(values)
      }}>
      {({ handleSubmit, validateForm }) => (
        <Form
          className="authorization-form-container"
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            background: '#ccc',
            maxWidth: SIZES.MAX_FORM_WIDTH,
            border: '1px solid black'
          }}>
          {appBar}
          <Box
            className="authorization-form-header"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              background: '#fff',
              padding: '10px 10px 20px',
              gap: SIZES.HEADER_GAP
            }}>
            <Box
              className="header-buttons"
              sx={{
                minWidth: SIZES.SIDEBAR_WIDTH,
                maxWidth: SIZES.SIDEBAR_WIDTH,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                pointerEvents: isSubmitting ? 'none' : 'auto'
              }}>
              {headerButtons}
            </Box>
            <Box className="header" sx={{ flex: 1 }}>
              <LabelAndDescriptionField />
            </Box>
          </Box>
          <Box
            className="authorization-form-tabs"
            sx={{
              background: '#fff',
              padding: '0',
              paddingLeft: `calc(${SIZES.SIDEBAR_WIDTH} + ${SIZES.HEADER_GAP})`,
              borderBottom: '1px solid black'
            }}>
            <FormTabs
              currentTab={currentTab}
              availableTabs={availableTabs}
              handleTabChange={handleTabChange}
            />
          </Box>
          <Box
            className="authorization-form-content"
            sx={{
              overflowY: 'auto',
              flex: 1,
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}>
            {children}
            {SUBJECT_DOMAIN_KEYS.map(domain => (
              <ResolveSubjects
                key={domain}
                hidden={currentTab.value !== domain}
                organizationId={organizationId}
                detailedSubjects={detailedSubjects?.filter(subject => subject.domain === domain)}
              />
            ))}
            <Paper
              elevation={4}
              className="authorization-form-button"
              sx={{
                height: '40px',
                position: 'sticky',
                left: '50%',
                bottom: '0px',
                transform: 'translate(-50%, 0)',

                background: '#fff',
                borderRadius: '8px',
                width: 'fit-content',
                maxWidth: '100%',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex',
                alignItems: 'center',
                padding: '10px',
                gap: '10px',
                zIndex: 100
              }}>
              <Typography variant="caption" sx={{ flex: 1, fontWeight: 500 }}>
                Salve as alterações para que elas tenham efeito
              </Typography>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                color="success"
                onClick={e => {
                  e.preventDefault()
                  validateForm()
                    .then(() => {
                      handleSubmit()
                    })
                    .catch(err => {
                      console.error(err)
                      enqueueSnackbar('Existem campos não preenchidos corretamente no formulário', {
                        variant: 'error'
                      })
                    })
                }}>
                Salvar alterações
              </LoadingButton>
            </Paper>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
