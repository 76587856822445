export const formatDateToLongText = (date: string) => {
  return `${formatDateToDayMonthYear(date)} às ${formatDateToHoursMinutes(date)}`
}

const formatDateToDayMonthYear = (date: string) => {
  const [year, month, day] = date.split('T')[0].split('-')
  return `${day}/${month}/${year}`
}

const formatDateToHoursMinutes = (date: string) => {
  const [hours, minutes] = date.split('T')[1].split(':')
  return `${parseInt(hours) - 3}:${minutes}`
}
