import { CopyAll } from '@mui/icons-material'
import { Box, Button, colors, Divider, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'

interface IRequestErrorProps {
  error: unknown
}

export const RequestError = (props: IRequestErrorProps) => {
  const { error } = props
  const { enqueueSnackbar } = useSnackbar()

  const axiosError = error instanceof AxiosError ? error : undefined
  const errorUrl = axiosError
    ? axiosError?.config?.baseURL?.concat(axiosError?.config?.url || '') || ''
    : undefined

  const copyError = () => {
    const errorMessage = JSON.stringify({
      staffUrl: window.location.href,
      createdAt: new Date(),
      error: error
    })

    navigator.clipboard.writeText(errorMessage)
    enqueueSnackbar('Mensagem de erro copiada!', {
      variant: 'success'
    })
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem'
      }}>
      <Typography variant="h1">Oops! Não foi possível fazer a requisição :(</Typography>
      <Typography variant="subtitle1">{axiosError?.message || 'Erro não identificado'}</Typography>
      <Divider flexItem sx={{ my: '1rem' }} />
      <Typography color={colors.blue[900]}>{errorUrl}</Typography>
      <Typography>
        {axiosError?.response?.data
          ? `Resposta do servidor: ${JSON.stringify(axiosError?.response?.data)}`
          : 'O servidor não retornou nenhuma resposta'}
      </Typography>
      <Button variant="discreet" color="info" sx={{ mt: '2rem' }} onClick={copyError}>
        <CopyAll />
        Copiar Erro Completo
      </Button>
    </Box>
  )
}
