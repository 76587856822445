import { THubFiscalAssessment } from '@/services/Hub/AssessmentsFiscal'
import { HubApi } from '@services'
import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ComplianceContext } from '../../providers/ComplianceContext'

export const useFiscalAssessment = (params: { personProfileId: string }) => {
  const [error, setError] = useState<AxiosError | null>()
  const [isRequesting, setIsRequesting] = useState(true)
  const [response, setResponse] = useState<THubFiscalAssessment>()
  const { numOfResets: resetCounter } = useContext(ComplianceContext)

  const resetStates = () => {
    setError(undefined)
    setIsRequesting(true)
    setResponse(undefined)
  }

  const fetchFiscalAssessmentApi = useCallback(async () => {
    try {
      const res = await HubApi.AssessmentsFiscal.getRequest(params.personProfileId)
      setResponse(res)
      setError(null)
    } catch (err) {
      if (err instanceof AxiosError) setError(err)
    } finally {
      setIsRequesting(false)
    }
  }, [params.personProfileId])

  useEffect(() => {
    resetStates()
    fetchFiscalAssessmentApi()
  }, [fetchFiscalAssessmentApi, resetCounter])

  return { response, isRequesting, error }
}
