import { CustomSelect } from './CustomSelect'
import { OrganizationSelectCard } from './OrganizationSelectCard'
import { Subtitle } from './Subtitle'
import { Title } from './Title'

export const OrganizationSelect = {
  root: OrganizationSelectCard,
  title: Title,
  subtitle: Subtitle,
  select: CustomSelect
}
