import { store, RootState } from '../redux/store'
import { axiosLutien } from './config/axiosLutien.config'
import { LUTIEN_MEMBERSHIP_ROLE, USER_GENDER, USER_ROLE } from './enums/lutien.enum'

export type TResponseLogin = {
  token: string
}

export type TUser = {
  id: string
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  name: string
  lastName?: string
  email: string
  phoneNumber: string
  govRegCode?: string
  govRegTypeId?: string
  photoURL?: string
  birthdate?: Date
  gender?: USER_GENDER
  locale?: string
  role?: USER_ROLE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  memberships: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  govRegType: any
}

export type TOrganization = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  corporateName: string
  fantasyName: string
  email: string
  description: string
  locale: string
  govRegTypeId: string
  govRegCode: string
  mobilePhoneNumber: string
  fixedPhoneNumber: string
  stateSubscription: string
  logoUrl: string
  bannerUrl: string
  webSiteURL: string
  isCompleted: boolean
  tradeRole: string
  contactPreference: string
  availabilityPreference: string
  country: string
  stateLongName: string
  stateShortName: string
  city: string
  addressLine: string
  neighborhood: string
  streetName: string
  streetNumber: string
  complement: string
  reference: string
  postalCode: string
  latitude: number
  longitude: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provider: any
  placeId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  govRegType: any
  mainFacilityId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  organizationBusinessTypes: any
  isProduzindoCerto: boolean
  produzindoCertoUrl: string
}

export type TCrop = {
  cropId: string
  cropName?: string
  cropColor: string
  cropDescription?: string
  iconUrl?: string
  commoditiesPackageUnitId?: string
  statusValidation?: {
    plantingMonthsInterval: {
      start: number
      end: number
    }
    harvestMonthsInterval: {
      start: number
      end: number
    }
    maxDays?: number
  }
}

export type ShortOrganizationDTO = {
  id: string
  corporateName: string
  fantasyName: string
  email: string
  govRegCode: string
  [k: string]: unknown
}

type ShortUserDTO = {
  id: string
  role: USER_ROLE
  email: string
  name: string
  lastName?: string
  govRegCode?: string
  photoURL?: string
  [k: string]: unknown
}

export type MembershipDTO = {
  id: string
  organizationRoleId: string
  organizationId: string
  userId: string
  jobTitle?: string
  organization: ShortOrganizationDTO
  user: ShortUserDTO
  role: LUTIEN_MEMBERSHIP_ROLE
  [k: string]: unknown
}

const login = async (email: string, password: string) => {
  const { data } = await axiosLutien.post<TResponseLogin>('/api/v1/auth/login', {
    email,
    password
  })
  return data
}

const getUserInfo = async (token: string) => {
  const { data } = await axiosLutien.get<TUser>('/api/v1/auth/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

const getOrganizationInfo = async (orgId: string): Promise<TOrganization | undefined> => {
  if (!orgId) return
  const reduxState: RootState = store.getState()
  const { data } = await axiosLutien.get<TOrganization>(`/api/v1/organizations/${orgId}`, {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return data
}

const getCrops = async (): Promise<TCrop[]> => {
  const { data } = await axiosLutien.get<TCrop[]>('/api/v1/catalog/crops/')
  return data
}

const getOrganizations = async (): Promise<ShortOrganizationDTO[]> => {
  const reduxState: RootState = store.getState()
  const { data } = await axiosLutien.get<ShortOrganizationDTO[]>('/api/v1/organizations', {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return data
}

const getMemberships = async (organizationId: string): Promise<MembershipDTO[]> => {
  const reduxState: RootState = store.getState()
  const { data } = await axiosLutien.get<MembershipDTO[]>(
    `/api/v1/organizations/${organizationId}/memberships`,
    {
      headers: {
        Authorization: `Bearer ${reduxState.authenticator.user.token}`
      }
    }
  )
  return data
}

export const LutienApi = {
  login,
  getUserInfo,
  getOrganizationInfo,
  getCrops,
  getOrganizations,
  getMemberships
}
