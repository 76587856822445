import { ArrowForwardIos, CheckCircleOutline } from '@mui/icons-material'
import { Box, Typography, Button, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { TTaskSubtask } from '../../../../../services/RadarApi'

interface ITarefasCard {
  tasksArr: TTaskSubtask[]
  validationType: string
}

export const RequestCard = ({ tasksArr, validationType }: ITarefasCard) => {
  const navigate = useNavigate()

  const handleNavigate = (taskID: string, index: number) => {
    if (validationType === 'segmentation') {
      return navigate(`/radar/segmentations/${taskID}/Tarefa${index + 1}`)
    }

    return navigate(`/radar/seasons/${taskID}/Tarefa${index + 1}`)
  }

  return (
    <>
      {tasksArr.map((task, index) => {
        return (
          <MainBox key={task.id}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Typography className="mainText">{`Tarefa ${index + 1}`}</Typography>
              <Typography className="secondaryText">{`id: ${task.task_id}`}</Typography>
            </Box>
            {task.finished ? (
              <Typography className="validado">
                Validado
                <CheckCircleOutline />
              </Typography>
            ) : (
              <Button
                variant="text"
                className="textButton"
                endIcon={<ArrowForwardIos />}
                onClick={() => handleNavigate(task.task_id, index)}>
                Validar
              </Button>
            )}
          </MainBox>
        )
      })}
    </>
  )
}

const MainBox = styled(Box)(() => ({
  padding: '12px 18px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#FFFFFF',
  border: '1px solid #E9ECEF',
  borderRadius: '12px',
  marginTop: '12px',

  '& .mainText': {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#495057'
  },

  '& .secondaryText': {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#868E96',
    marginTop: '4px'
  },

  '& .validado': {
    color: '#51CF66',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px'
  },

  '& .textButton': {
    color: '#5C7CFA',
    textTransform: 'capitalize',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px'
  }
}))
