import { Paper, Typography } from '@mui/material'
import moment from 'moment'

export type TCustomTooltip = {
  active: boolean
  label: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

export const AreasChartTooltip = ({ active, label, payload }: TCustomTooltip) => {
  return active ? (
    <Paper
      sx={{
        p: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Typography>{`NDVI: ${(payload?.[0]?.payload?.y as number).toLocaleString('en-US', {
        maximumFractionDigits: 3
      })}`}</Typography>
      <Typography>{moment(label).format('DD MMM YY')}</Typography>
    </Paper>
  ) : null
}
