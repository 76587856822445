export enum LABOR_DEBTS_STATUS {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  UNDEFINED = 'UNDEFINED'
}

export enum FGTS_CREATION_TYPE {
  DB = 'DB',
  USER_INPUT = 'USER_INPUT',
  COLLECTOR = 'COLLECTOR',
  SYSTEM = 'SYSTEM'
}

export enum SLAVERY_OCCURRENCE_CREATION_TYPE {
  USER_INPUT = 'USER_INPUT',
  COLLECTOR = 'COLLECTOR',
  SYSTEM = 'SYSTEM'
}
