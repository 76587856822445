import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { CHART_MODE } from '../../AreasChart/AreasChartContainer'
import { TTableArea } from '../AreasTableView'

type TAreasTableColumnLabels = {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  areas?: TTableArea[]
  mode: CHART_MODE
  idColumnLabel?: string
}

export const AreasTableColumnLabels = ({
  numSelected,
  onSelectAllClick,
  areas,
  mode,
  idColumnLabel = 'Id'
}: TAreasTableColumnLabels) => {
  const rowCount = areas?.length

  return rowCount ? (
    <TableHead sx={{ display: 'table-header-group' }}>
      <TableRow>
        {mode === CHART_MODE.EDITION && (
          <TableCell padding="none">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        <TableCell padding="none" align="center">
          {idColumnLabel}
        </TableCell>
        <TableCell padding="none">{areas[0].xMin.label}</TableCell>
        <TableCell padding="none">{areas[0].xMax.label}</TableCell>
        {areas[0].infos?.map(info => (
          <TableCell padding="none" key={info.label}>
            {info.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  ) : (
    <></>
  )
}
