import { Search } from '@mui/icons-material'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'

export const SearchInput = (params: TextFieldProps) => {
  const { ...props } = params

  return (
    <TextField
      size="small"
      sx={{
        flex: 1
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}
