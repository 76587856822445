import { LoadingButton } from '@mui/lab'
import { Alert, Box, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import React from 'react'

import { FlexBox, NavigateBackButton } from '../../components'
import { CheckSalesDTO } from '../../services/Serasa/SerasaDTO'

type SerasaViewProps = {
  onHandleFetchInfo: () => void
  setGovRegCode: React.Dispatch<React.SetStateAction<string | null>>
  checkSalesInfo?: CheckSalesDTO
  isChecking: boolean
  govRegCode: string | null
}

export const SerasaView = (props: SerasaViewProps) => {
  const { checkSalesInfo, onHandleFetchInfo, setGovRegCode, isChecking, govRegCode } = props

  return (
    <Box sx={{ height: 'calc(100vh - 3rem)', m: '32px 20px', maxWidth: 'calc(100vw - 40px)' }}>
      <Card sx={{ p: '2rem', borderRadius: '12px', gap: '8px' }}>
        <CardContent sx={{ padding: '0px' }}>
          <FlexBox
            sx={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}>
            <FlexBox sx={{ gap: '1rem' }}>
              <NavigateBackButton />

              <Typography variant="h4" flexGrow={1} minWidth="max-content">
                Conferir Venda?
              </Typography>
            </FlexBox>

            <FlexBox sx={{ justifyContent: 'space-between' }}>
              <TextField
                label="8 primeiros digitos do cnpj"
                sx={{ width: '100%' }}
                onChange={e => setGovRegCode(e.target.value)}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>
        <CardActions>
          <FlexBox vertical={true} sx={{ justifyContent: 'flex-end' }}>
            {checkSalesInfo && (
              <Alert
                severity={checkSalesInfo?.saleAllowed ? 'success' : 'error'}
                sx={{ width: '100%' }}>
                {checkSalesInfo?.saleAllowed ? 'Venda permitida!' : 'Venda não permitida!'}
              </Alert>
            )}

            {checkSalesInfo && !checkSalesInfo?.saleAllowed && (
              <Typography>Motivo: {checkSalesInfo?.description ?? 'não retornado'}</Typography>
            )}

            <LoadingButton
              disableElevation
              loading={isChecking}
              onClick={onHandleFetchInfo}
              disabled={
                !govRegCode ||
                (!!govRegCode && govRegCode.toString().length !== 8) ||
                !/^[0-9]+$/.test(govRegCode)
              }
              sx={{ mt: '1rem' }}
              variant="contained">
              Conferir Venda
            </LoadingButton>
          </FlexBox>
        </CardActions>
      </Card>
    </Box>
  )
}
