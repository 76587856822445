import { THubLaborAssessment } from '@/services/Hub/AssessmentsLabor'
import { HubApi } from '@services'
import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ComplianceContext } from '../../providers/ComplianceContext'

export const useLaborAssessment = ({ params }: { params: { personProfileId: string } }) => {
  const [error, setError] = useState<AxiosError | null>()
  const [isRequesting, setIsRequesting] = useState(true)
  const [response, setResponse] = useState<THubLaborAssessment>()
  const { numOfResets: resetCounter } = useContext(ComplianceContext)

  const resetStates = () => {
    setError(undefined)
    setIsRequesting(true)
    setResponse(undefined)
  }

  const fetchLaborAssessmentApi = useCallback(async () => {
    try {
      const res = await HubApi.AssessmentsLabor.getRequest(params.personProfileId)
      setResponse(res)
      setError(null)
    } catch (err) {
      if (err instanceof AxiosError) setError(err)
    } finally {
      setIsRequesting(false)
    }
  }, [params.personProfileId])

  useEffect(() => {
    resetStates()
    fetchLaborAssessmentApi()
  }, [fetchLaborAssessmentApi, resetCounter])

  return { response, isRequesting, error }
}
