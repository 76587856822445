import { TConditionType } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Clear } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'

import { isNil } from '../../utils/isNill'
import { ConditionHandler } from './ConditionHandler'

interface IConditionHandler {
  condition: TConditionType
  ruleIndex: number
  onRemove: () => void
  organizationId: string
}

export type TSaveValue = (value?: string[] | number, op?: string) => void

const ValidateCondition = (value: Record<string, unknown>): string | undefined => {
  if (isNil(value)) {
    return 'O valor da condição é obrigatório'
  }
  const valueKeys = Object.keys(value)
  if (!valueKeys.length) {
    return 'O valor da condição é obrigatório'
  }
  return undefined
}

export const ConditionContainer = (props: IConditionHandler) => {
  const { condition, ruleIndex, organizationId, onRemove } = props

  return (
    <Field
      name={`rules[${ruleIndex}].conditions["${condition.key}"]`}
      validate={ValidateCondition}
      required
      fullWidth>
      {({ field, form, meta }: FieldProps<Record<string, unknown>, Record<string, unknown>>) => (
        <Box
          className="condition-container"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',

            width: '100%'
          }}>
          <Box
            className="condition-label-description"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
              flexShrink: 0
            }}>
            <Typography
              className="condition-helper-text"
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: 'rgb(73,80,87)'
              }}>
              e
            </Typography>
            <Tooltip title={condition.description} arrow>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {condition.label}
              </Typography>
            </Tooltip>
          </Box>

          <ConditionHandler
            errorMessage={meta.error}
            condition={condition}
            organizationId={organizationId}
            saveValue={(value: string[] | number | undefined, op = '$in') => {
              if (isNil(value) || (Array.isArray(value) && !value.length)) {
                form.setFieldValue(field.name, undefined)
                return
              }

              form.setFieldValue(field.name, {
                [`${op}`]: value
              })
            }}
            fieldValue={field.value}
          />

          <IconButton
            title="deletar condição"
            size="small"
            sx={{
              color: 'rgba(219, 0, 38, 1)'
            }}
            onClick={() => {
              form.setFieldValue(field.name, undefined)
              onRemove()
            }}>
            <Clear />
          </IconButton>
        </Box>
      )}
    </Field>
  )
}
