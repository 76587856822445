import { AwsApi, HubApi } from '@services'

import { IRecordCandidate } from '../../providers/ComplianceContext'
import { buildCreateEnvironmentalDebtDTO } from '../helpers/buildCreateEnvironmentalDebtDTO'
import { buildCreateEnvironmentalEmbargoDTO } from '../helpers/buildCreateEnvironmentalEmbargoDTO'
import { buildGetUploadPresignedUrlDTO } from '../helpers/buildGetUploadPresignedUrlDTO'

interface IPostEnvironmentalRecordApi {
  canonicalProfileId: string
  personProfileId: string
  record: IRecordCandidate
}

export const postEnvironmentalRecordApi = async (props: IPostEnvironmentalRecordApi) => {
  const { canonicalProfileId, personProfileId, record } = props

  const presignedUrl = await HubApi.Assessments.getPostPresignedS3Url(
    buildGetUploadPresignedUrlDTO({ personProfileId, record })
  )
  await AwsApi.S3.uploadFileWithPresignedUrl(presignedUrl, record.file)

  if (record.recordType === 'Environmental Debts') {
    const createEnvironmentalDebtDTO = buildCreateEnvironmentalDebtDTO({
      canonicalProfileId,
      record,
      documentUrl: presignedUrl?.expectedDocumentUrlPath
    })
    return HubApi.AssessmentsEnvironmental.postDebtRequest(createEnvironmentalDebtDTO)
  }
  if (record.recordType === 'Environmental Embargoes') {
    const createEnvironmentalEmbargoDTO = buildCreateEnvironmentalEmbargoDTO({
      canonicalProfileId,
      record,
      documentUrl: presignedUrl?.expectedDocumentUrlPath
    })
    return HubApi.AssessmentsEnvironmental.postEmbargoRequest(createEnvironmentalEmbargoDTO)
  }
  throw new Error(`Unknow environmental record subtype: ${record.recordType}`)
}
