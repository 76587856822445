import { useManageMembershipRoles } from '@/pages/authorization/hooks/useManageMembershipRoles'
import { TData } from '@/pages/authorization/utils/authorization.utils'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { Box, CircularProgress } from '@mui/material'

import { AssignRoleButton } from './AssignRoleButton'
import { RoleTag } from './RoleTag'

interface IManageMembershipRoles {
  membershipId: string
  organizationRoles: TData<RoleDTO[]>
}
export const ManageMembershipRoles = (props: IManageMembershipRoles) => {
  const { membershipId, organizationRoles } = props
  const { membershipRoles, hasError, availableRoles, handleRemoveRole, handleAssignRoles } =
    useManageMembershipRoles({ membershipId, roles: organizationRoles.data })

  if (membershipRoles.loading || organizationRoles.loading) {
    return <CircularProgress />
  }

  if (hasError) {
    return <div>error</div>
  }

  return (
    <Box
      sx={{
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: '10px'
      }}>
      {membershipRoles.data?.map(membershipRole => {
        const wantedRole = organizationRoles.data?.find(role => role.id === membershipRole.roleId)

        if (!wantedRole) {
          return <></>
        }

        return (
          <RoleTag
            key={membershipRole.id}
            roleLabel={wantedRole.label}
            membershipRoleId={membershipRole.id}
            handleRemoveRole={handleRemoveRole}
          />
        )
      })}
      <AssignRoleButton availableRoles={availableRoles} handleAssignRoles={handleAssignRoles} />
    </Box>
  )
}
