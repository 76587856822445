import { BackArrow } from '@assets'
import { IconButton } from '@mui/material'
import {
  ShortOrganizationDTO,
  HubApi,
  THubFormFieldDefinition,
  THubFormConditionsDefinition,
  ConditionAction,
  ConditionExpression
} from '@services'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, Select, Input, Typography } from 'antd'
import { useState, Dispatch, SetStateAction, useEffect } from 'react'

import { TableBox } from '.'
import { OrgSelect, HorizontalBox, MainBox, VerticalBox } from '../../components'
import useMessage from '../../hooks/useMessage'
import { createForms } from '../../services/api/endpoints'
import { TData } from '../../utils/solutions.utils'
import { methodsOptions, entityOptions, validateOptions } from './options.constants'
import { ValidateMethodTT } from './tooltip'
const { Title } = Typography

export type TFormPage = {
  setSelectMenu: Dispatch<SetStateAction<string>>
  tarkenOrganizations: TData<ShortOrganizationDTO[]>
}

export const CreateFormPageContainer = ({ setSelectMenu, tarkenOrganizations }: TFormPage) => {
  const [selectedOrganization, setOrganizationId] = useState<string | null>(null)
  const [selectedFormMethods, setFormMethods] = useState<Array<string>>([])
  const [selectedFormEntity, setFormEntity] = useState<string | null>(null)
  const [selectedFormName, setFormName] = useState<string | null>(null)
  const [selectedValidateMethod, setValidateMethod] = useState<string | null>(null)
  const [formFields, setFormFields] = useState<THubFormFieldDefinition[]>([])
  const [formConditions, setFormConditions] = useState<THubFormConditionsDefinition[]>([])
  const { contextHolder, notify } = useMessage()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createForms
  })

  const { data: customFields } = useQuery({
    queryKey: ['getCustomFields', selectedOrganization],
    queryFn: () => HubApi.getCustomFields(selectedOrganization),
    enabled: !!selectedOrganization
  })

  const { data: customFieldsGroups } = useQuery({
    queryKey: ['getCustomFieldsGroups', selectedOrganization],
    queryFn: () => HubApi.getCustomFieldsGroups(selectedOrganization),
    enabled: !!selectedOrganization
  })

  const onFinish = async () => {
    const existingCustomFields: Array<string> =
      customFields
        ?.filter(field => field?.entity == selectedFormEntity)
        ?.map(field => field?.identifier) ?? []
    const identifiers: Array<string> = []
    for (let i = 0; i < formFields.length; i += 1) {
      if (!formFields[i].identifier || !formFields[i].label || !formFields[i].fieldType) {
        notify('error', `Parâmetro em branco na linha ${i} da tabela!`)
        return
      }
      if (identifiers.includes(formFields[i].identifier)) {
        notify(
          'error',
          `Identifier '${formFields[i].identifier}' encontrado em múltiplos campos da tabela!`
        )
        return
      }
      identifiers.push(formFields[i].identifier)
    }

    const conditions: THubFormConditionsDefinition[] = []
    for (let i = 0; i < formConditions?.length; i += 1) {
      if (!formConditions[i].name) {
        notify('error', `Nome da condicional em branco na linha ${i} da tabela!`)
        return
      }
      const actions: ConditionAction[] = []
      const expressions: ConditionExpression[] = []
      for (let j = 0; j < formConditions[i]?.expressions?.length; j += 1) {
        const expression: ConditionExpression = formConditions[i]?.expressions[j]
        const fieldWithIdentifier = formFields?.find(
          field => field.identifier == expression?.fieldIdentifier
        )
        expressions.push({
          ...expression,
          fieldIdentifier: `${selectedFormEntity}_${expression?.fieldIdentifier}`
        })
        if (fieldWithIdentifier) {
          if (
            (nativeFields?.map(field => field.identifier)?.includes(expression?.fieldIdentifier) ||
              existingCustomFields?.includes(expression?.fieldIdentifier)) &&
            fieldWithIdentifier?.source != 'DESTINY' &&
            fieldWithIdentifier?.source != 'NATIVE'
          ) {
            notify(
              'error',
              `Campo '${fieldWithIdentifier?.identifier}' com conflito sendo usado em expressão da condição '${formConditions[i]?.name}'!`
            )
            return
          }
          if (
            !expression.operation ||
            (!expression.value &&
              expression.operation != 'blank' &&
              expression.operation != 'present' &&
              fieldWithIdentifier.fieldType != 'boolean')
          ) {
            notify(
              'error',
              `Parâmetro em branco em expressão da condição '${formConditions[i]?.name}'!`
            )
            return
          }
        } else if (!expression.fieldIdentifier) {
          notify(
            'error',
            `Identifier em branco em expressão da condição '${formConditions[i]?.name}'!`
          )
          return
        } else {
          notify(
            'error',
            `Campo '${expression?.fieldIdentifier}' presente em expressão da condição '${formConditions[i]?.name}' mas inexistente no formulário!`
          )
          return
        }
      }
      for (let j = 0; j < formConditions[i]?.actions?.length; j += 1) {
        const action: ConditionAction = formConditions[i]?.actions[j]
        const fieldWithIdentifier = formFields?.find(
          field => field.identifier == action?.fieldIdentifier
        )
        actions.push({
          ...action,
          fieldIdentifier: `${selectedFormEntity}_${action?.fieldIdentifier}`
        })
        if (fieldWithIdentifier) {
          if (
            (nativeFields?.map(field => field.identifier)?.includes(action?.fieldIdentifier) ||
              existingCustomFields?.includes(action?.fieldIdentifier)) &&
            fieldWithIdentifier?.source != 'DESTINY' &&
            fieldWithIdentifier?.source != 'NATIVE'
          ) {
            notify(
              'error',
              `Campo '${fieldWithIdentifier?.identifier}' com conflito sendo usado em ação da condição '${formConditions[i]?.name}'!`
            )
            return
          }
        } else if (!action.fieldIdentifier) {
          notify('error', `Identifier em branco em ação da condição '${formConditions[i]?.name}'!`)
          return
        } else {
          notify(
            'error',
            `Campo '${action?.fieldIdentifier}' presente em ação da condição '${formConditions[i]?.name}' mas inexistente no formulário!`
          )
          return
        }
      }
      conditions.push({
        name: formConditions[i]?.name,
        actions: actions,
        expressions: expressions
      })
    }

    const formData: any = {}
    formData.organizationId = selectedOrganization
    formData.formFields = formFields
    formData.formConditions = conditions
    formData.formMethods = selectedFormMethods
    formData.formEntity = selectedFormEntity
    formData.formName = selectedFormName
    formData.validateMethod = selectedValidateMethod
    notify('success', 'Seu formulário será criado nos próximos instantes!')
    await mutateAsync(formData)
  }

  const formatCustomFields = (
    customFields: THubFormFieldDefinition[] | undefined,
    formEntity: string | null
  ) => {
    const customFieldsFormatted: Array<string> = []
    if (!customFields || !formEntity) return customFieldsFormatted
    for (let i = 0; i < customFields?.length; i += 1) {
      if (customFields[i].entity == formEntity && !!customFields[i]?.identifier != null) {
        customFieldsFormatted.push(customFields[i]?.identifier)
      }
    }
    return customFieldsFormatted
  }

  useEffect(() => {
    const formFieldsUpdated = formFields.map(field =>
      field.source === 'DESTINY'
        ? { ...field, source: 'ORIGIN', customFieldGroupId: null }
        : { ...field, customFieldGroupId: null }
    )
    setFormFields(formFieldsUpdated)
  }, [selectedOrganization])

  const { data: nativeFields } = useQuery({
    queryKey: ['getNativeFields', selectedFormEntity],
    queryFn: () => HubApi.getNativeFields(selectedFormEntity),
    enabled: !!selectedFormEntity
  })

  useEffect(() => {
    const formFieldsUpdated = formFields
      .map(field => (field.source === 'DESTINY' ? { ...field, source: 'ORIGIN' } : field))
      .filter(field => field.source !== 'NATIVE')
    setFormFields(formFieldsUpdated)
  }, [selectedFormEntity])

  const disallowButton: boolean =
    !selectedOrganization ||
    selectedFormMethods?.length == 0 ||
    !selectedFormEntity ||
    !selectedFormName

  return (
    <MainBox>
      {contextHolder}
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          background: '#FFFFFF',
          borderRadius: '50%'
        }}
        onClick={() => setSelectMenu('menu')}>
        <BackArrow />
      </IconButton>
      <VerticalBox
        style={{ width: '100%', height: '100%', marginRight: '32px', paddingTop: '16px' }}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            height: '250px',
            width: '65%',
            padding: '16px'
          }}
          name="login"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}>
          <Title level={3} style={{ color: '#212121', margin: 0 }}>
            Criar Formulário
          </Title>
          <HorizontalBox>
            <VerticalBox style={{ width: '48%' }}>
              <Input
                placeholder="Informe o nome do formulário"
                onChange={e => setFormName(e.target.value)}
                style={{ width: '100%' }}
              />
              <Select
                placeholder="Escolha a entidade"
                onChange={value => setFormEntity(value)}
                style={{ width: '100%' }}
                options={entityOptions}
              />
              <Select
                mode="multiple"
                placeholder="Escolha os métodos"
                onChange={value => setFormMethods(value)}
                style={{ width: '100%' }}
                options={methodsOptions}
                maxTagCount="responsive"
              />
            </VerticalBox>
            <VerticalBox style={{ width: '48%' }}>
              <OrgSelect
                selectedOrg={selectedOrganization}
                isLoadingOrgs={tarkenOrganizations?.loading}
                setOrganizationId={setOrganizationId}
                organizations={tarkenOrganizations?.data}
                placeholder="Organização de destino"
                width="100%"
              />
              <HorizontalBox style={{ width: '100%' }}>
                <Select
                  placeholder="Escolha o tratamento para campos existentes"
                  onChange={value => setValidateMethod(value)}
                  options={validateOptions}
                  style={{ width: '90%' }}
                />
                <VerticalBox style={{ width: '10%' }}>
                  <ValidateMethodTT />
                </VerticalBox>
              </HorizontalBox>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disallowButton || !selectedValidateMethod}
                loading={isPending}>
                {isPending ? 'Carregando' : 'Enviar'}
              </Button>
            </VerticalBox>
          </HorizontalBox>
        </Form>
        <TableBox
          formFields={formFields}
          setFormFields={setFormFields}
          formConditions={formConditions}
          setFormConditions={setFormConditions}
          customFields={formatCustomFields(customFields, selectedFormEntity)}
          nativeFields={nativeFields}
          customFieldsGroups={customFieldsGroups}
          selectedFormEntity={selectedFormEntity}
          organizations={tarkenOrganizations?.data}
          selectedOrganization={selectedOrganization}
          disallowButton={disallowButton}
        />
      </VerticalBox>
    </MainBox>
  )
}
