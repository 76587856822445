import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { RefObject, useMemo } from 'react'

import { CHART_MODE, TArea } from '../AreasChart/AreasChartContainer'
import { AreasTableColumnLabels } from './components/AreasTableColumnLabels'
import { AreasTableHeader } from './components/AreasTableHeader'
import { EditableDate } from './components/EditableDate'

/* Extends TArea of AreasChart and store data for AreasTable */
export type TTableArea = TArea & {
  // TODO: Remove this. It is specific from HUB and does not refers to chart area, but cultivation area (talhão id).
  landFieldId: string
  infos: TTableAreaInfo[]
}

export type TTableAreaInfo = {
  label: string
  value: string | number | Date | JSX.Element
  isEditable?: boolean
  renderComponent?: (area: TTableArea, info: TTableAreaInfo, editionMode: boolean) => JSX.Element
}

export type TAreasTableView = {
  selectedAreas: TTableArea[]
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  areas: TTableArea[]
  mode: CHART_MODE
  checkAreaIsSelected: (id: number) => boolean
  areaIdToScroll?: number
  rowRefToScroll?: RefObject<HTMLTableRowElement>
  handleSelectClick: (area: TTableArea) => void
  handleChangeDate: (
    newDate: Date,
    areaToUpdate: TTableArea,
    valueToUpdate: 'xMin' | 'xMax'
  ) => void
}

export const AreasTableView = ({
  selectedAreas,
  handleSelectAllClick,
  areas,
  mode,
  checkAreaIsSelected,
  areaIdToScroll,
  rowRefToScroll,
  handleSelectClick,
  handleChangeDate
}: TAreasTableView) => {
  return (
    <Box bgcolor="#fff">
      <AreasTableHeader numSelected={selectedAreas.length} />
      <TableContainer>
        <Table>
          <AreasTableColumnLabels
            numSelected={selectedAreas.length}
            onSelectAllClick={handleSelectAllClick}
            areas={areas}
            mode={mode}
            // idColumnLabel="Safra"
          />
          <TableBody>
            {useMemo(
              () =>
                areas.map(area => {
                  const isItemSelected = checkAreaIsSelected(area.id)
                  return (
                    <TableRow
                      ref={area.id === areaIdToScroll ? rowRefToScroll : undefined}
                      role="checkbox"
                      tabIndex={-1}
                      key={area.id}
                      selected={isItemSelected}
                      hover>
                      {mode === CHART_MODE.EDITION && (
                        <TableCell padding="none">
                          <Checkbox
                            onClick={() => handleSelectClick(area)}
                            color="primary"
                            checked={isItemSelected}
                          />
                        </TableCell>
                      )}
                      <TableCell align="center">{area.id}</TableCell>
                      {mode === CHART_MODE.EDITION ? (
                        <>
                          <TableCell padding="none">
                            <EditableDate
                              date={new Date(area.xMin.value)}
                              onChange={date => handleChangeDate(date, area, 'xMin')}
                            />
                          </TableCell>
                          <TableCell padding="none">
                            <EditableDate
                              date={new Date(area.xMax.value)}
                              onChange={date => handleChangeDate(date, area, 'xMax')}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell padding="none">
                            {moment(area.xMin.value).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell padding="none">
                            {moment(area.xMax.value).format('DD/MM/YYYY')}
                          </TableCell>
                        </>
                      )}
                      {area.infos.map(info => (
                        <TableCell key={info.label} padding="none">
                          {info?.renderComponent ? (
                            info.renderComponent(area, info, mode === CHART_MODE.EDITION)
                          ) : (
                            <>{info.value}</>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                }),
              // eslint-disable-next-line react-hooks/exhaustive-deps
              [areas, selectedAreas, mode]
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
