import { FlexBox, LoadingState } from '@components'
import { Box } from '@mui/material'

import { CollectorExceptionMain } from './components/Sidebar/CollectorExceptionMain'

interface ICollectorExceptionViewProps {
  handleCompleteTask: () => void
  sendingCompleteTask: boolean
}

export const CollectorExceptionView = (props: ICollectorExceptionViewProps) => {
  const { handleCompleteTask, sendingCompleteTask } = props

  return (
    <>
      {sendingCompleteTask && (
        <FlexBox pt="8rem">
          <LoadingState message={'Finalizando tarefa...'} />
        </FlexBox>
      )}
      {!sendingCompleteTask && (
        <Box height="calc(100vh - 3rem)" overflow="auto">
          <CollectorExceptionMain handleCompleteTask={handleCompleteTask} />
        </Box>
      )}
    </>
  )
}
