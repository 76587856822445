import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

type TAreasTableHeader = {
  numSelected: number
  title?: string
}

export const AreasTableHeader = ({ numSelected, title }: TAreasTableHeader) => (
  <Toolbar
    sx={{
      p: '0.5rem 1rem !important',
      minHeight: '0 !important'
    }}>
    {numSelected > 0 ? (
      <Typography>{numSelected} selecionado(s)</Typography>
    ) : (
      <Typography>{title}</Typography>
    )}
  </Toolbar>
)
