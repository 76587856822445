import { Box, Typography } from '@mui/material'

import { TLandField } from '../../../EditSeasons'
import { AreaCard } from './AreaCard'

interface IModalSidebar {
  areas: TLandField[]
  colors?: { id: string; value: string }[]
  selectedAreas: string[]
  addToSelectedAreas: (id: string) => void
  originAreaId: string
}

export const ModalSidebar = ({
  areas,
  originAreaId,
  selectedAreas,
  addToSelectedAreas,
  colors
}: IModalSidebar) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: 'max-content',
        overflowY: 'scroll'
      }}>
      <Typography variant="h6" p="0.25rem 0.5rem">
        Todos os Talhões
      </Typography>
      {areas.map((area, index) => {
        return (
          <AreaCard
            key={`area${index}`}
            disabledCheck={area.id === originAreaId}
            area={area}
            index={index + 1}
            color={colors?.filter(color => color.id === area.id)?.[0]?.value || 'transparent'}
            onClick={() => addToSelectedAreas(area.id)}
            isSelected={selectedAreas.indexOf(area.id) >= 0}
          />
        )
      })}
    </Box>
  )
}
