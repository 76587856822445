import { SVGProps } from 'react'

export const TarkenTypography = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={106.56}
    height={12.517}
    viewBox="0 0 106.56 12.517"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      style={{
        fill: props.color || '#242729',
        strokeWidth: 0.32
      }}
      d="M123.319 19.409v-1.12h6.08v2.24h-6.08zM135.159 19.729v-6.24h2.258l5.742 4.32 5.742 4.32h.338v-8.64h2.56v12.48H150.2l-6.32-4.655-6.321-4.654-.089 4.654-.089 4.655h-2.222z"
      transform="translate(-45.239 -13.452)"
    />
    <path
      style={{
        fill: props.color || '#242729',
        strokeWidth: 0.32
      }}
      d="M117.879 19.729v-6.24h13.12v1.92h-10.56v8.64h10.56v1.92h-13.12zM100.279 19.729v-6.24h2.56v4.8h4.114l2.157-2.4 2.157-2.4h3.152l-2.746 2.916-2.745 2.917 2.733 3.323 2.734 3.324-1.538-.003-1.538-.003-2.231-2.73-2.23-2.731-1.93.093-1.93.094-.092 2.64-.093 2.64h-2.534zM81.719 19.71v-6.258l6.551.099 6.552.098.728.729.729.729v4.41l-.776.826-.776.826h-1.168v.747l1.114 2.026 1.113 2.027h-2.86l-1.127-2.24-1.128-2.24h-6.392v4.48h-2.56zm11.776-.845.384-.384v-1.834l-.332-.62-.331-.618H84.279v3.84h8.832zM60.279 25.79v-.179l3.987-6.06 3.988-6.062 1.052.016 1.053.016 3.84 5.946 3.84 5.947.106.277.107.278H75.474l-3.035-4.8-3.036-4.8-.162.003-.162.003-3.04 4.794-3.04 4.793-1.36.004-1.36.003zM51.886 20.769l-.087-5.2-3.28-.091-3.28-.092v-1.898h15.36v1.92h-6.08v10.56h-2.545z"
      transform="translate(-45.239 -13.452)"
    />
  </svg>
)
