import { Button } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'

type IButtonUnit = {
  onClickAction: Dispatch<SetStateAction<string>>
  menuOption: string
  label: string
}

export const ButtonUnit: React.FC<IButtonUnit> = ({ onClickAction, menuOption, label }) => {
  return (
    <Button
      type="primary"
      style={{
        padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        width: '150px'
      }}
      onClick={() => {
        onClickAction(menuOption)
      }}>
      {label}
    </Button>
  )
}
