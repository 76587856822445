import { Close } from '@mui/icons-material'
import { Box, styled, Typography, Button, IconButton, Modal, CircularProgress } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { RadarApi } from '../../services/RadarApi'

interface IConfirmationModal {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  id: string
  taskType: 'seasons' | 'segmentation'
  sx?: SxProps<Theme> | undefined
}

export const ConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  id,
  taskType,
  sx = []
}: IConfirmationModal) => {
  const [modalStep, setModalStep] = useState<TValidModalSteps>('init')
  const navigate = useNavigate()

  const handleCloseModal = () => {
    setModalStep('init')
    setIsModalOpen(false)
  }

  const handleNavigateBack = () => {
    setModalStep('init')
    navigate(-1)
  }

  const submitTaskDone = (id: string, taskType: string) => {
    RadarApi.updateTask(id, taskType)
      .then(res => {
        console.log(res)
        setModalStep('success')
      })
      .catch(e => {
        setModalStep('error')
        console.log('error: ', e.response.status)
      })
  }

  useEffect(() => {
    if (modalStep === 'loading') {
      submitTaskDone(id, taskType)
      // setTimeout(() => {
      //   setModalStep('success');
      // }, 1000);
    }
  }, [modalStep])

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <MainBox>
        <div className="closeButton">
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </div>
        <Typography className="title">Finalizar Edição</Typography>
        <StepContent
          modalStep={modalStep}
          setModalStep={setModalStep}
          handleCloseModal={handleCloseModal}
          handleNavigateBack={handleNavigateBack}
          taskType={taskType}
        />
      </MainBox>
    </Modal>
  )
}

type TValidModalSteps = 'init' | 'loading' | 'success' | 'error'
type TValidValidationName = 'safras' | 'talhões'

interface IStepContent {
  modalStep: string
  setModalStep: React.Dispatch<React.SetStateAction<TValidModalSteps>>
  handleCloseModal: () => void
  taskType: 'seasons' | 'segmentation'
  handleNavigateBack: () => void
}

const StepContent = ({
  modalStep,
  setModalStep,
  handleCloseModal,
  handleNavigateBack,
  taskType
}: IStepContent) => {
  const [validationName, setValidationName] = useState<TValidValidationName>('talhões')

  useEffect(() => {
    if (taskType === 'seasons') {
      setValidationName('safras')
    }
    if (taskType === 'segmentation') {
      setValidationName('talhões')
    }
  }, [taskType])

  if (modalStep === 'loading') {
    return (
      <>
        <Typography className="text">Analizando dados, não feche...</Typography>
        <CircularProgress />
      </>
    )
  }

  if (modalStep === 'success') {
    return (
      <>
        <Typography className="text">Tarefa completada com sucesso!</Typography>
        <div className="buttonContainer">
          <Button
            variant="contained"
            disableElevation
            color="success"
            sx={{ textTransform: 'capitalize', p: '7px 37px' }}
            onClick={handleNavigateBack}>
            Voltar aos pedidos
          </Button>
        </div>
      </>
    )
  }

  if (modalStep === 'error') {
    return (
      <>
        <Typography className="text">Ainda existem {validationName} a serem validadas</Typography>
        <Typography className="errorMsg">
          A tarefa só pode ser finalizada se todas as {validationName} tiverem sido validadas
        </Typography>
        <div className="buttonContainer">
          <Button
            variant="contained"
            disableElevation
            color="error"
            sx={{ textTransform: 'capitalize', p: '7px 37px' }}
            onClick={handleCloseModal}>
            Continuar Editando
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Typography className="text">Deseja Completar a tarefa?</Typography>
      <div className="buttonContainer">
        <Button
          variant="contained"
          disableElevation
          color="success"
          sx={{ textTransform: 'capitalize', p: '7px 37px' }}
          onClick={() => {
            setModalStep('loading')
          }}>
          Finalizar
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="error"
          sx={{ textTransform: 'capitalize', p: '7px 37px' }}
          onClick={handleCloseModal}>
          Cancelar
        </Button>
      </div>
    </>
  )
}

const MainBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  backgroundColor: '#FFF',
  border: '1px solid #000',
  boxShadow: '0px 6px 18px rgba(1, 25, 85, 0.2)',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',

  '& .closeButton': {
    position: 'absolute',
    top: '0%',
    right: '0%',
    padding: '10px'
  },

  '& .title': {
    fontFamily: 'Arial',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#111315'
  },
  '& .text': {
    fontFamily: 'Arial',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#111315'
  },
  '& .errorMsg': {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#111315'
  },

  '& .buttonContainer': {
    display: 'flex',
    marginTop: '10px',
    flexDirection: 'row',
    gap: '30px',
    justifyContent: 'center'
  }
}))
