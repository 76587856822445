import AuthorizationPageContainer from '@/pages/authorization'
import { CollectorException } from '@/pages/bender/CollectorException/CollectorException'
import { ComplianceProvider } from '@/pages/bender/Compliance/providers/ComplianceContext'
import { ProfileReview } from '@/pages/bender/ProfileReview/ProfileReview'
import { ValidateConsents } from '@/pages/bender/ValidateConsents/ValidateConsents'
import { IRPFReview } from '@/pages/bender/ValidateIRPF/IRPFReview'
import { WarehouseReview } from '@/pages/bender/WarehouseReview/WarehouseReview'
import { EditAreas } from '@/pages/hub/properties/EditAreas/EditAreasContainer'
import SolutionsPageContainer from '@/pages/solutions'
import { Route, Routes } from 'react-router-dom'

import App from '../App'
import { Compliance } from '../pages/bender/Compliance/Compliance'
import { ReviewTasks } from '../pages/bender/ReviewTasks/ReviewTasksContainer'
import { EditSeasons } from '../pages/bender/editSeasons/EditSeasons'
import { BenderExecution } from '../pages/bender/execution/BenderExecution'
import { SegmentationReview } from '../pages/bender/segmentationReview/SegmentationReviewContainer'
import { Login } from '../pages/login/LoginContainer'
import { EditSeasons as RadarEditSeasons } from '../pages/radar/editSeasons/EditSeasons'
import { GroupedTasksPage } from '../pages/radar/groupedTasks'
import { RequestsPage } from '../pages/radar/requests'
import { SegmentationReview as RadarSegmentationReview } from '../pages/radar/segmentationReview/SegmentationReviewContainer'
import { SerasaContainer } from '../pages/serasa/SerasaContainer'
import { useAppSelector } from '../redux/hooks'
import { RootState } from '../redux/store'

export const BenderRoutes = () => {
  const token = useAppSelector((state: RootState) => state.authenticator.user.token)

  return (
    <Routes>
      <Route path="*" element={<App />}>
        {!token ? (
          <Route path="*" element={<Login />} />
        ) : (
          <>
            <Route path="bender/executions" element={<BenderExecution />} />
            <Route path="bender/tasks" element={<ReviewTasks />} />
            <Route path="bender/segmentations/:taskId" element={<SegmentationReview />} />
            <Route path="bender/seasons/:property_id/:task_id" element={<EditSeasons />} />
            <Route path="bender/profile-review/:taskId" element={<ProfileReview />} />
            <Route path="bender/consent-review/:taskId" element={<ValidateConsents />} />
            <Route path="bender/warehouse-review/:taskId" element={<WarehouseReview />} />
            <Route path="bender/irpf-review/:taskId" element={<IRPFReview />} />
            <Route
              path="bender/compliance/:taskType/:taskId"
              element={
                <ComplianceProvider>
                  <Compliance />
                </ComplianceProvider>
              }
            />
            <Route
              path="bender/collector-exception/:taskId"
              element={
                <ComplianceProvider>
                  <CollectorException />
                </ComplianceProvider>
              }
            />

            <Route path="radar">
              <Route path="" element={<RequestsPage />} />
              {/* criar requests/:id */}
              <Route path="groupedTasks/:taskName/:taskId/">
                <Route path="" element={<GroupedTasksPage />} />
                <Route path=":validation" element={<GroupedTasksPage />} />
              </Route>
              <Route path="selectedRegion">
                <Route path="" element={<GroupedTasksPage fromSelectedRegion />} />
                <Route path=":validation" element={<GroupedTasksPage fromSelectedRegion />} />
              </Route>
              <Route path="segmentations/:id/:name" element={<RadarSegmentationReview />} />
              <Route path="segmentations/:id" element={<RadarSegmentationReview />} />
              <Route path="seasons/:task_id/:name" element={<RadarEditSeasons />} />
              <Route path="seasons/:task_id" element={<RadarEditSeasons />} />
            </Route>

            <Route path="hub">
              <Route path="properties/edit-areas/:property_id" element={<EditAreas />} />
            </Route>

            <Route path="Authorization">
              <Route path="" element={<AuthorizationPageContainer />} />
              <Route path=":organizationId" element={<AuthorizationPageContainer />} />
            </Route>

            <Route path="serasa">
              <Route path="conferir-vendas" element={<SerasaContainer />} />
            </Route>

            <Route path="solutions">
              <Route path="" element={<SolutionsPageContainer />} />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  )
}
