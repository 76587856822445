import { Box, IconButton, Typography } from '@mui/material'

import { CheckedIcon, BackArrow } from '../../assets/svg'

interface ICustomSidebarHeader {
  title: string
  subtitle: string
  sectionName: string
  onGoBack: () => void
}

export const CustomSidebarHeader = ({
  title,
  subtitle,
  sectionName,
  onGoBack
}: ICustomSidebarHeader) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: '#FFFFFF'
      }}>
      <Box
        sx={{
          width: '100%',
          padding: '22.5px 32px',
          display: 'flex',
          position: 'relative',
          justifyContent: 'center'
        }}>
        <IconButton
          sx={{
            width: '37px',
            height: '37px',
            background: '#FFFFFF',
            borderRadius: '50%',
            border: '1px solid #DEE2E6',
            position: 'abolute',
            top: 0,
            left: -32,
            margin: 0
          }}
          onClick={() => onGoBack()}>
          <BackArrow />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontStyle: 'normal',
              fontWeight: 600,
              fontDize: '24px',
              lineheight: '29px',
              color: '#212529'
            }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CheckedIcon />
            <Typography
              sx={{
                marginLeft: '10px',
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineheight: '19px',
                color: '#868E96'
              }}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
          width: '100%',
          height: '48px',
          borderBottom: '3.5px solid #212529'
        }}>
        <Typography
          sx={{
            marginLeft: '10px',
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineheight: '19px',
            color: '#212529'
          }}>
          {sectionName}
        </Typography>
      </Box>
    </Box>
  )
}
