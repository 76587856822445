import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { THubFormFieldDefinition, ConditionAction } from '@services'
import { Button, Select, Switch, Table, TableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { Dispatch, SetStateAction } from 'react'

import { EditableCell, EditableRow } from '../../../components'

type ConditionsTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<ConditionsTableProps['columns'], undefined>

interface IActionsTable extends TableProps {
  formFields: THubFormFieldDefinition[]
  setActions: Dispatch<SetStateAction<ConditionAction[]>>
  actions: ConditionAction[]
}

export const ActionsTable: React.FC<IActionsTable> = ({ formFields, setActions, actions }) => {
  const formFieldsDict: AnyObject = {}
  const fieldOptions: { label: string; value: string }[] = []
  for (let i = 0; i < formFields.length; i += 1) {
    formFieldsDict[`${formFields[i].identifier}`] = formFields[i]
    fieldOptions.push({
      label: `${formFields[i].label} (${formFields[i].identifier})`,
      value: formFields[i].identifier
    })
  }

  const handleDelete = (row: AnyObject) => {
    const newData: ConditionAction[] = []
    for (let i = 0; i < actions.length; i += 1) {
      if (i != row.key) newData.push(actions[i])
    }
    setActions(newData)
  }

  const handleSave = (row: ConditionAction, index: number, dataIndex: string) => {
    const newData: ConditionAction[] = []

    for (let i = 0; i < actions.length; i += 1) {
      if (i == index) {
        const item = { ...actions[i], ...row }
        newData.push(item)
      } else {
        newData.push(actions[i])
      }
    }
    setActions(newData)
  }

  const handleChange = (value: string | boolean, row: AnyObject, dataIndex: string) => {
    const newRow = { ...{ [dataIndex]: value } }
    const newData = [...actions]
    const item = newData[row.key]
    newData.splice(row.key, 1, {
      ...item,
      ...newRow
    })
    setActions(newData)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Identifier',
      dataIndex: 'fieldIdentifier',
      render: (label: string, record: AnyObject) => (
        <Select
          showSearch
          optionFilterProp="label"
          value={label}
          onChange={value => handleChange(value, record, 'fieldIdentifier')}
          options={fieldOptions}
          style={{ width: '100%' }}
        />
      ),
      width: '45%'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      render: (label: string, record: AnyObject) => (
        <Switch
          value={label == 'show' ?? 'hide'}
          checkedChildren={
            <div
              style={{
                display: 'flex',
                height: '22px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px'
              }}>
              <Visibility style={{ width: 'auto', height: '16px' }} />
              <p style={{ fontWeight: 'bold' }}>Mostrar</p>
            </div>
          }
          unCheckedChildren={
            <div
              style={{
                display: 'flex',
                height: '22px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px'
              }}>
              <VisibilityOff style={{ width: 'auto', height: '16px' }} />
              <p style={{ fontWeight: 'bold' }}>Esconder</p>
            </div>
          }
          onChange={value => handleChange(value ? 'show' : 'hide', record, 'type')}
        />
      ),
      width: '45%'
    },
    {
      title: '',
      dataIndex: 'functions',
      render: (_: string, record: AnyObject) =>
        actions.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleDelete(record)}>
              <Close style={{ color: 'red' }} />
            </Button>
          </div>
        ) : null,
      width: '10%'
    }
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: AnyObject) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
        records: actions,
        warning: false
      })
    }
  })

  const actionDataSource = []
  for (let i = 0; i < actions?.length; i += 1) {
    actionDataSource.push({ ...actions[i], key: i })
  }

  return (
    <>
      <Table
        showHeader={false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={actionDataSource}
        columns={columns as ColumnTypes}
        size={'small'}
        style={{ width: '100%', marginBottom: '16px' }}
        scroll={{ y: (window.innerHeight - 396) / 2 }}
        pagination={false}
      />
    </>
  )
}
