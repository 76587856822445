import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import React from 'react'

import { TMapLegend } from '../../../../../../../components/EditAreasMap/MapLegend'
import { ModalMap } from './components/ModalMap'
import { MODAL_OPTIONS } from './option.enum'

interface IConfirmEditingModal {
  open: boolean
  onClose: () => void
  onSave: () => void
  subtitle: string
  ConfirmMessageTitle?: string
  ConfirmMessageBody?: string
  legendValues: TMapLegend
  type: MODAL_OPTIONS
  geoJson: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
}

export const ConfirmEditingModal = ({
  open,
  onClose,
  onSave,
  subtitle,
  ConfirmMessageTitle,
  ConfirmMessageBody,
  legendValues,
  type,
  geoJson
}: IConfirmEditingModal) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5" align="center">
            Confirmar alteração do talhão
          </Typography>
          <Typography variant="subtitle1" align="center">
            {subtitle}
          </Typography>
        </Box>
        <IconButton sx={{ width: '1.5em', height: '1.5em' }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            flexGrow: 1,
            marginLeft: 'auto',
            height: '100%',
            width: '100%',
            maxWidth: '950px',
            maxHeight: '380px',
            minHeight: '200px',
            borderRadius: '12px',
            objectFit: 'cover'
          }}>
          <ModalMap geoJson={geoJson} legendValues={legendValues} highlightedFeatId={1} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}>
          {ConfirmMessageTitle && (
            <Typography variant="h6" align="center">
              {ConfirmMessageTitle}
            </Typography>
          )}
          {ConfirmMessageBody && (
            <Typography variant="subtitle1" align="center">
              {ConfirmMessageBody}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {type !== MODAL_OPTIONS.FORBIDDEN_INTERSECTION && (
              <Button
                variant="outlined"
                sx={{
                  marginLeft: '25px',
                  padding: '14px 18px',
                  height: '47px',
                  background: '#2B8A3E',
                  color: 'white'
                }}
                color="info"
                onClick={() => {
                  onSave()
                }}>
                Salvar
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                marginLeft: '25px',
                padding: '14px 18px',
                height: '47px',
                background: '#FA5252',
                color: 'white'
              }}
              onClick={() => {
                onClose()
              }}>
              {type === MODAL_OPTIONS.FORBIDDEN_INTERSECTION ? 'Continuar editando' : 'Cancelar'}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
