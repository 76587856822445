import { ArrowBack } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { changeHeader, useAppDispatch } from '@redux'
import { useNavigate } from 'react-router-dom'

export const NavigateBackButton = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(changeHeader({ mode: 'fixed' }))
    navigate(-1)
  }

  return (
    <IconButton
      sx={{ alignSelf: 'flex-start' }}
      title="Voltar para a página anterior"
      onClick={handleClick}>
      <ArrowBack />
    </IconButton>
  )
}
