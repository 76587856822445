import { Consent } from '@/services/Bender/Consents/contract/response/ContactConsents.response'
// import { getLatestPendingConsent } from '@/services/Bender/Consents/utils/getLatestPendingConsent'
import {
  BenderSearchTasksResponse,
  TBenderInputs
} from '@/services/Bender/Task/contract/response/BenderSearchTasks.response'
import { BenderApi } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

export const useBenderTask = (taskId?: string) => {
  const [organizationInfo, setOrganizationInfo] =
    useState<TBenderInputs['ConsentReview']['OrganizationInfo']>()
  const [contactInfo, setContactInfo] = useState<TBenderInputs['ConsentReview']['ContactInfo']>()
  const [personProfileInfo, setPersonProfileInfo] =
    useState<
      BenderSearchTasksResponse<TBenderInputs['ConsentReview']>['tasks'][0]['input']['PersonInfo']
    >()

  const { enqueueSnackbar } = useSnackbar()

  const [executionArn, setExecutionArn] = useState<string>()
  const [consentType, setConsentType] = useState<string>()
  const [consent, setConsent] = useState<Consent>()
  const [isFetchingTasks, setIsFetchingTasks] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  const bootstrap = async () => {
    try {
      const { tasks } = await BenderApi.task.search<TBenderInputs['ConsentReview']>({
        filter: { id: taskId },
        fields: ['input'],
        limit: 1
      })
      if (!tasks || tasks?.length == 0) {
        return
      }
      setPersonProfileInfo(tasks[0].input.PersonInfo)
      setOrganizationInfo(tasks[0].input.OrganizationInfo)
      setConsentType(tasks[0].input.ConsentInfo?.Type)
      setContactInfo(tasks[0].input.ContactInfo)
      setExecutionArn(tasks[0].input.Execution?.ExecutionArn)

      if (tasks[0].input.ContactInfo) {
        try {
          const consents = await BenderApi.consents.getContactConsents({
            contactId: tasks[0].input.ContactInfo.ContactId
          })
          const currConsent = consents.find(elem => {
            return elem.id === tasks[0].input.ConsentInfo?.ContactConsentId
          })
          if (currConsent) setConsent(currConsent)
          else setConsent(undefined)
        } catch (error) {
          setHasError(true)
          console.error(error)
          enqueueSnackbar('Erro ao buscar anuencias', { variant: 'error' })
        }
      }
    } catch (error) {
      setHasError(true)
      console.error(error)
      enqueueSnackbar('Erro ao buscar task', { variant: 'error' })
    }
  }

  const fetchTasks = useCallback(() => {
    setIsFetchingTasks(true)
    bootstrap()
    setIsFetchingTasks(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchTasks()
  }, [fetchTasks])

  return {
    personProfileInfo,
    organizationInfo,
    contactInfo,
    executionArn,
    consentType,
    consent,
    isFetchingTasks,
    hasError,
    fetchTasks
  }
}
