import { openConfirm } from '@components'
import { BENDER_TASK_STATUS } from '@services'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ProfileReviewView } from './ProfileReviewView'
import { useBenderCompleteTask } from './hooks/useBenderCompleteTask'
import { useBenderTask } from './hooks/useBenderTask'

export const ProfileReview = () => {
  const { taskId } = useParams()
  const { personProfileInfo, executionArn } = useBenderTask(taskId)
  const { benderCompleteTask, isRequesting, error } = useBenderCompleteTask(taskId, executionArn)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [openRejectionDialog, setOpenRejectionDialog] = useState(false)
  const metricsEnterPageTimestamp = useRef(new Date())

  const handleAcceptTask = async () => {
    if (await openConfirm('Deseja aprovar a tarefa?', { title: 'Aprovar Tarefa' })) {
      const response = await benderCompleteTask({
        startedAt: metricsEnterPageTimestamp.current,
        status: BENDER_TASK_STATUS.SUCCEEDED
      })
      if (response.success) {
        enqueueSnackbar('Tarefa aprovada com sucesso!', { variant: 'success' })
        navigate(-1)
      }
    }
  }

  const handleOpenRejectionDialog = () => {
    setOpenRejectionDialog(true)
  }

  const handleCancelRejectionDialog = () => {
    setOpenRejectionDialog(false)
  }

  const handleConfirmRejectionDialog = async (reason: string) => {
    setOpenRejectionDialog(false)
    const response = await benderCompleteTask({
      status: BENDER_TASK_STATUS.FAILED,
      completionInput: { message: 'failed' },
      completionError: { cause: 'rejected by user', error: reason },
      startedAt: metricsEnterPageTimestamp.current
    })
    if (response.success) {
      enqueueSnackbar('Tarefa rejeitada com sucesso!', { variant: 'success' })
      navigate(-1)
    }
  }

  const handleOpenHubPage = () => {
    window.open(
      `${process.env.REACT_APP_URL_HUB_FRONT}/tomador/registro/${personProfileInfo?.PersonId}`,
      '_blank'
    )
  }

  return (
    <ProfileReviewView
      profileGovRegCode={personProfileInfo?.PersonGovRegCode}
      profileId={personProfileInfo?.PersonId}
      profileName={personProfileInfo?.PersonName}
      // requestId={profileAnalysisRequestId} // TODO: display profile analysis request id
      handleOpenHubPage={handleOpenHubPage}
      handleAcceptTask={handleAcceptTask}
      isRequesting={isRequesting}
      error={error}
      rejectionDialog={{
        handleCancel: handleCancelRejectionDialog,
        handleConfirm: handleConfirmRejectionDialog,
        handleOpen: handleOpenRejectionDialog,
        open: openRejectionDialog
      }}
    />
  )
}
