import 'react-calendar/dist/Calendar.css'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import Calendar from 'react-calendar'

type TDigitControl = {
  digit: number
  onAdd: (value: number) => void
  onSubtract: (value: number) => void
  minLength?: number
  onLabelClick?: () => void
}

const DigitControl = ({ digit, onAdd, onSubtract, minLength = 2, onLabelClick }: TDigitControl) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <IconButton onClick={() => onAdd(digit)}>
        <KeyboardArrowUp fontSize="small" />
      </IconButton>

      <Typography
        variant="body2"
        onClick={() => onLabelClick && onLabelClick()}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        {String(digit).padStart(minLength, '0')}
      </Typography>

      <IconButton onClick={() => onSubtract(digit)}>
        <KeyboardArrowDown fontSize="small" />
      </IconButton>
    </Box>
  )
}

const addDay = (date: Date, value: number): Date => moment(date).utc().add(value, 'days').toDate()

const addMonth = (date: Date, value: number): Date =>
  moment(date).utc().add(value, 'months').toDate()

const addYear = (date: Date, value: number): Date => moment(date).utc().add(value, 'years').toDate()

export type TEditableDate = {
  date: Date
  onChange: (date: Date) => void
}

export const EditableDate = ({ date, onChange }: TEditableDate) => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const toggleDatePicker = () => setShowDatePicker(v => !v)

  return (
    <Box
      sx={{
        display: 'flex',
        width: 'fit-content',
        position: 'relative'
      }}>
      <DigitControl
        digit={date.getDate()}
        onAdd={() => onChange(addDay(date, 1))}
        onSubtract={() => onChange(addDay(date, -1))}
        onLabelClick={toggleDatePicker}
      />
      <DigitControl
        digit={date.getMonth() + 1}
        onAdd={() => onChange(addMonth(date, 1))}
        onSubtract={() => onChange(addMonth(date, -1))}
        onLabelClick={toggleDatePicker}
      />
      <DigitControl
        digit={date.getFullYear()}
        onAdd={() => onChange(addYear(date, 1))}
        onSubtract={() => onChange(addYear(date, -1))}
        onLabelClick={toggleDatePicker}
      />
      {showDatePicker && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100
          }}>
          <Calendar
            value={date}
            onChange={(newDate: Date) => {
              if (newDate) onChange(moment(newDate).utc().toDate())
              toggleDatePicker()
            }}
            locale="pt-BR"
          />
        </Box>
      )}
    </Box>
  )
}
