import { createConfirmation } from 'react-confirm'

import { DialogConfirm, IDialogConfirmProps } from './DialogConfirm'

// create confirm function
const createConfirm = createConfirmation(DialogConfirm)

// This is optional. But wrapping function makes it easy to use.
export function openConfirm(confirmation: string, options?: IDialogConfirmProps['options']) {
  return createConfirm({ confirmation, options })
}
