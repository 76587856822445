import { ArrowBackIosNew } from '@mui/icons-material'
import { Paper, Box, Stack, Typography, IconButton, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { Loading, CustomToggleButton } from '../../../components'
import { EmptyObjectErrorMessage } from '../../../components/emptyObjectErrorMessage'
import { changeHeader } from '../../../redux/header'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { RootState } from '../../../redux/store'
import { RadarApi, TSelectedRegion, TTasksRequest, TTaskSubtask } from '../../../services/RadarApi'
import { RegionModal } from '../components/regionModal'
import { RequestCard } from './components/groupedTasksCard'

export type TValidSubtasks = 'segmentation' | 'seasons'

export type TGroupedSubTasks = {
  group: string
  subtasks: TTaskSubtask[]
}

type Params = {
  taskId: string
  taskName: string
  validation?: TValidSubtasks
}

export const GroupedTasksPage = ({ fromSelectedRegion }: { fromSelectedRegion?: boolean }) => {
  // botao de toggle
  const [validationType, setValidationType] = useState<string>('segmentation')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [TasksFetchData, setTasksFetchData] = useState<TTasksRequest[] | null>(null)
  const [areasTasksData, setAreasTasksData] = useState<TGroupedSubTasks[] | null>(null)
  const [seasonsTasksData, setSeasonsTasksData] = useState<TGroupedSubTasks[] | null>(null)
  const [openRegionModal, setOpenRegionModal] = useState(false)
  const [filteredGroups, setFilteredGroups] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { taskId, taskName, validation } = useParams<Params>()
  const selectedRegion = useAppSelector((state: RootState) => state.radar.selectedRegion)

  const fetchGetTasks = async (taskId: string) => {
    try {
      const tasks = await RadarApi.getTasks(taskId)
      setTasksFetchData(tasks)
    } catch {
      setTasksFetchData([])
      setIsLoading(false)
    }
  }

  const fetchGetTasksByGeometry = async (selectedRegion: TSelectedRegion) => {
    try {
      const tasks = await RadarApi.getTaskByGeometry(selectedRegion)
      setTasksFetchData(tasks)
    } catch {
      setTasksFetchData([])
      setIsLoading(false)
    }
  }

  const mergeGroups = (list: TTasksRequest[]) => {
    const tasksByGroup: TGroupedSubTasks[] = []
    for (const { group, subtasks } of list) {
      if (tasksByGroup.find(e => e.group === group)) {
        tasksByGroup.find(e => e.group === group)?.subtasks.push(...subtasks)
      } else {
        tasksByGroup.push({ group, subtasks: [...subtasks] })
      }
    }
    return tasksByGroup
  }

  const filterResult = (data: TGroupedSubTasks[], keyword: TValidSubtasks) => {
    const newData = data.reduce((acc: TGroupedSubTasks[], curr: TGroupedSubTasks) => {
      curr.subtasks = curr.subtasks.reduce((acc2: TTaskSubtask[], currSub: TTaskSubtask) => {
        if (currSub.type === keyword) {
          acc2.push(currSub)
        }
        return acc2
      }, [])

      if (curr.subtasks.length > 0) {
        acc.push(curr)
      }
      return acc
    }, [])

    return newData
  }

  useEffect(() => {
    dispatch(changeHeader({ mode: 'fixed' }))

    if (fromSelectedRegion) {
      if (selectedRegion.geometry !== undefined || selectedRegion.latLng !== undefined) {
        setIsLoading(true)
        fetchGetTasksByGeometry(selectedRegion)
      } else {
        navigate('/radar')
      }
    } else if (taskId) {
      setIsLoading(true)
      fetchGetTasks(taskId)
    } else {
      setTasksFetchData([])
      setIsLoading(false)
    }

    if (validation && (validation === 'segmentation' || validation === 'seasons')) {
      setValidationType(validation)
    }
  }, [])

  useEffect(() => {
    if (TasksFetchData && !areasTasksData) {
      setAreasTasksData(filterResult(mergeGroups(TasksFetchData), 'segmentation'))
    }
    if (TasksFetchData && !seasonsTasksData) {
      setSeasonsTasksData(filterResult(mergeGroups(TasksFetchData), 'seasons'))
    }

    if (TasksFetchData && seasonsTasksData && areasTasksData) {
      setIsLoading(false)
    }
  }, [TasksFetchData, areasTasksData])

  //useState para filtrar as subtasks mudar loading para aqui

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newToggle: TValidSubtasks | null) => {
    if (newToggle !== null) {
      setValidationType(newToggle)
    }
  }

  return (
    <Paper
      sx={{
        width: '1162px',
        height: 'fit-content',
        marginTop: '40px',
        borderRadius: '18px',
        padding: '18px 26px'
      }}
      elevation={3}>
      <RegionModal
        open={openRegionModal}
        setOpen={(value: boolean) => setOpenRegionModal(value)}
        onSubmit={(selectedRegion: TSelectedRegion) => {
          RadarApi.getTaskByGeometry(selectedRegion)
            .then(res => {
              setFilteredGroups(res.map(e => e.group))
            })
            .catch(() => {
              setFilteredGroups([])
            })
            .finally(() => setOpenRegionModal(false))
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <IconButton size="large" onClick={() => navigate('/radar')}>
            <ArrowBackIosNew />
          </IconButton>
          <Stack>
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '29px',
                color: '#212529'
              }}>
              {fromSelectedRegion
                ? `Região selecionada por ${selectedRegion.geometry ? 'geojson' : 'coordenadas'}`
                : taskName}
            </Typography>
            {!fromSelectedRegion && (
              <Typography
                sx={{
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '17px',
                  color: '#868E96'
                }}>
                {`id: ${taskId}`}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
            height: '100%'
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              height: '100%'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                gap: '8px',
                width: 'fit-content',
                height: '33px',
                background: '#111315',
                borderRadius: '8px',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => setOpenRegionModal(true)}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#FFFFFF'
                }}>
                Filtrar Grupos
              </Typography>
            </Box>
            {filteredGroups.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px 12px',
                  gap: '8px',
                  width: 'fit-content',
                  height: '33px',
                  background: 'red',
                  borderRadius: '8px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => setFilteredGroups([])}>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#FFFFFF'
                  }}>
                  LimparFiltros
                </Typography>
              </Box>
            )}
          </Box>
          <CustomToggleButton
            valueState={validationType}
            setValueState={setValidationType}
            values={['segmentation', 'seasons']}
            msgValues={['Validar Talhões', 'Validar Safras']}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        {(function () {
          if (isLoading === true) {
            return <Loading size={40} />
          }
          // No tasks found at all
          if (TasksFetchData && TasksFetchData?.length <= 0) {
            return <EmptyObjectErrorMessage errorMsg="Não há tarefas para este pedido" />
          }
          if (TasksFetchData && areasTasksData && seasonsTasksData) {
            return (
              <TasksDataMapping
                areasData={
                  filteredGroups.length > 0
                    ? areasTasksData.filter(e => filteredGroups.includes(e.group))
                    : areasTasksData
                }
                seasonsData={
                  filteredGroups.length > 0
                    ? seasonsTasksData.filter(e => filteredGroups.includes(e.group))
                    : seasonsTasksData
                }
                validationType={validationType}
              />
            )
          }
        })()}
      </Box>
    </Paper>
  )
}

interface ITasksDataMapping {
  seasonsData: TGroupedSubTasks[]
  areasData: TGroupedSubTasks[]
  validationType: string
}

const TasksDataMapping = ({ seasonsData, areasData, validationType }: ITasksDataMapping) => {
  if (validationType === 'segmentation') {
    if (areasData.length <= 0) {
      return (
        <EmptyObjectErrorMessage
          errorMsg="Ainda não foram geradas tarefas de validação de talhoes para este pedido"
          suggestion="Verifique as Tarefas de validação de Safras"
        />
      )
    }
    return (
      <>
        {areasData.map((task, index) => {
          return (
            <MainCardBox mt={'24px'} key={task.group}>
              <Stack>
                <Typography className="mainGroupText">{`Grupo ${index + 1}`}</Typography>
                <Typography className="secondaryGroupText">
                  Finalize o grupo para liberar tarefas de validação de safras
                </Typography>
              </Stack>
              <Box ml={3}>
                <RequestCard tasksArr={task.subtasks} validationType={validationType} />
              </Box>
            </MainCardBox>
          )
        })}
      </>
    )
  }

  if (seasonsData.length <= 0) {
    return (
      <EmptyObjectErrorMessage
        errorMsg="Ainda não foram geradas tarefas de validação de safras para esse pedido."
        suggestion="Complete as tarefas de áreas por grupo primeiro."
      />
    )
  }

  return (
    <>
      {seasonsData.map((task, index) => {
        return (
          <MainCardBox mt={'24px'} key={task.group}>
            <Stack>
              <Typography className="mainGroupText">{`Grupo ${index + 1}`}</Typography>
            </Stack>
            <Box ml={3}>
              <RequestCard tasksArr={task.subtasks} validationType={validationType} />
            </Box>
          </MainCardBox>
        )
      })}
    </>
  )
}

const MainCardBox = styled(Box)(() => ({
  '& .mainGroupText': {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#212529'
  },
  '& .secondaryGroupText': {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#868E96',
    marginTop: '4px'
  }
}))
