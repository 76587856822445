import { CHART_MODE } from '../AreasChart/AreasChartContainer'
import { AREA_ACTIONS } from '../AreasChart/enums'
import { AreasTableView, TTableArea } from './AreasTableView'

type TAreasTable = {
  areas: TTableArea[]
  setAreas: (areas: TTableArea[]) => void
  selectedAreas: TTableArea[]
  setSelectedAreas: (action: AREA_ACTIONS, area?: TTableArea) => void
  mode: CHART_MODE
  areaIdToScroll?: number
  rowRefToScroll?: React.RefObject<HTMLTableRowElement>
  dateDomain?: { min: Date; max: Date }
}

export const AreasTable = ({
  areas,
  setAreas,
  selectedAreas,
  setSelectedAreas,
  mode,
  areaIdToScroll,
  rowRefToScroll,
  dateDomain
}: TAreasTable) => {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAreas(event.target.checked ? AREA_ACTIONS.SELECT_ALL : AREA_ACTIONS.DESELECT_ALL)
  }

  const handleSelectClick = (area: TTableArea) => {
    setSelectedAreas(
      checkAreaIsSelected(area.id) ? AREA_ACTIONS.DESELECT : AREA_ACTIONS.SELECT,
      area
    )
  }

  const handleChangeDate = (
    newDate: Date,
    areaToUpdate: TTableArea,
    valueToUpdate: 'xMin' | 'xMax'
  ) => {
    // if (dateDomain && (newDate < dateDomain.min || newDate > dateDomain.max)) {
    // alert('Data fora do período permitido')
    // return
    // }
    if (valueToUpdate === 'xMin' && newDate > areaToUpdate.xMax.value) {
      alert('Data inicial não pode suceder data final')
      return
    }
    if (valueToUpdate === 'xMax' && newDate < areaToUpdate.xMin.value) {
      alert('Data final não pode anteceder data inicial')
      return
    }

    setAreas(
      areas.map(area =>
        area.id === areaToUpdate.id
          ? {
              ...area,
              [valueToUpdate]: {
                ...area[valueToUpdate],
                value: newDate
              }
            }
          : area
      )
    )
  }

  const checkAreaIsSelected = (id: number) =>
    !!selectedAreas.find(selectedArea => selectedArea.id === id)

  return (
    <AreasTableView
      areaIdToScroll={areaIdToScroll}
      areas={areas}
      handleChangeDate={handleChangeDate}
      handleSelectAllClick={handleSelectAllClick}
      handleSelectClick={handleSelectClick}
      checkAreaIsSelected={checkAreaIsSelected}
      mode={mode}
      rowRefToScroll={rowRefToScroll}
      selectedAreas={selectedAreas}
    />
  )
}
