/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TSelectedRegion } from '@/services/RadarApi'
import { LocationOn } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import * as turf from '@turf/turf'
import React, { useRef, useEffect } from 'react'
import MapGl, { Layer, MapRef, Source, Marker } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

interface IModalMap {
  selectedRegion: TSelectedRegion
  onSubmit: () => void
}

export const ModalMap = ({ selectedRegion, onSubmit }: IModalMap) => {
  const mapRef = useRef<MapRef>(null)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = turf.bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 600,
      height: 300
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 30 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  useEffect(() => {
    if (mapRef.current && selectedRegion.geometry) {
      mapRef.current?.flyTo({
        ...getBoundsForPoints(selectedRegion.geometry),
        duration: 2000
      })
    } else if (mapRef.current && selectedRegion.latLng) {
      mapRef.current?.flyTo({
        center: { lon: selectedRegion.latLng.lng, lat: selectedRegion.latLng.lat },
        zoom: 14,
        duration: 2000
      })
    }
  }, [mapRef.current, selectedRegion])

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        minWidth: '600px',
        width: '100%',
        height: '100%',
        border: '1px solid #CED4DA',
        borderRadius: '12px',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        padding: 0,
        overflow: 'hidden',
        position: 'relative'
      }}>
      <MapGl
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken="pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg"
        doubleClickZoom={false}
        ref={mapRef}>
        {selectedRegion && selectedRegion.geometry !== undefined && (
          <Source
            id={'areas'}
            type="geojson"
            // @ts-ignore
            data={selectedRegion.geometry}>
            <Layer
              id={'layer-areas'}
              type={'line'}
              source={'areas'}
              paint={{
                'line-color': ' #FFFFFF',
                'line-width': 2
              }}
            />
            <Layer
              id={'fill-areas'}
              type={'fill'}
              source={'areas'}
              paint={{
                'fill-color': '#FFFFFF',
                'fill-opacity': 0.2
              }}
            />
          </Source>
        )}
        {selectedRegion && selectedRegion.latLng && (
          <Marker latitude={selectedRegion.latLng.lat} longitude={selectedRegion.latLng.lng}>
            <LocationOn sx={{ fill: 'white', height: '32px', width: '32px' }} />
          </Marker>
        )}
      </MapGl>
      {!selectedRegion.geometry && !selectedRegion.latLng ? (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.8)',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              color: 'white',
              maxWidth: '400px'
            }}>
            Insira as coordenadas ou o geojson da região nos formulários acima para continuar.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 12px',
            gap: '8px',
            position: 'absolute',
            width: 'fit-content',
            height: '33px',
            left: '20px',
            top: '20px',
            background: '#2B8A3E',
            border: '1px solid #000000',
            borderRadius: '8px',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={onSubmit}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#FFFFFF'
            }}>
            Validar Região
          </Typography>
        </Box>
      )}
    </Box>
  )
}
