/* eslint-disable @typescript-eslint/no-empty-function */

import { IComplianceContext } from '../ComplianceContext'

export const emptyComplianceContext: IComplianceContext = {
  personProfile: {
    canonicalProfileId: undefined,
    govRegCode: undefined,
    govRegType: undefined,
    id: undefined,
    name: undefined
  },
  setPersonProfile: () => {},
  recordCandidates: [],
  addRecordCandidate: () => {},
  removeRecordCandidate: () => {},
  deleteRecords: [],
  addDeleteRecord: () => {},
  hasPendingModifications: false,
  resetContext: () => {},
  numOfResets: 0
}
