import { Dispatch, SetStateAction } from 'react'

import { ButtonUnit } from '../../components/ButtonUnit'
import { MainBox } from './MainBox'

export type TMenuPage = {
  setSelectMenu: Dispatch<SetStateAction<string>>
}

export const MenuPageContainer = ({ setSelectMenu }: TMenuPage) => {
  return (
    <MainBox>
      <ButtonUnit
        onClickAction={setSelectMenu}
        menuOption={'create-forms'}
        label={'Criar Formulário'}
      />
      {/* <ButtonUnit
        onClickAction={setSelectMenu}
        menuOption={'manage-fields'}
        label={'Gerenciar Campos'}
      /> */}
      <ButtonUnit
        onClickAction={setSelectMenu}
        menuOption={'create-autofill'}
        label={'Criar Autofill'}
      />
    </MainBox>
  )
}
