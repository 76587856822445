import { ListItemButton, Link, colors, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import * as turf from '@turf/turf'
import { RefObject } from 'react'

import { GreenCheckedIcon } from '../../../../../../../assets/svg'

const useStyles = makeStyles(() => ({
  saveButton: {
    padding: '5px 10px',
    border: 'none',
    backgroundColor: '#808080',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

interface INotEditableFeature {
  isSelected: boolean
  isProductive: boolean
  id: string | undefined
  imageUrl: string
  index: number
  areaInM2: number
  handleListItemClick: (id: string) => void
  selectedRef: RefObject<HTMLElement>
  isValidated: boolean
  geometry: turf.Geometry
}

export const NotEditableFeature = ({
  isSelected,
  isProductive,
  id,
  imageUrl,
  index,
  areaInM2,
  handleListItemClick,
  selectedRef,
  isValidated,
  geometry
}: INotEditableFeature) => {
  const styles = useStyles()
  return (
    <ListItemButton
      selected={isSelected}
      sx={{
        borderBlock: `1px solid ${isSelected ? colors.amber[500] : 'transparent'}`
      }}
      onClick={() => {
        if (id) handleListItemClick(id)
      }}>
      <Box
        ref={isSelected ? selectedRef : undefined}
        sx={{
          width: '100%',
          display: 'flex',
          // flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center'
        }}>
        {imageUrl.length < 8192 ? (
          <img
            src={imageUrl}
            alt="Imagem de satélite do talhão"
            style={{
              width: '70px',
              height: '70px',
              borderRadius: '12px'
            }}
          />
        ) : (
          <Box
            sx={{
              width: '70px',
              height: '70px',
              borderRadius: '12px',
              background: 'grey',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              style={{
                width: '70px',
                wordWrap: 'break-word',
                color: 'white',
                fontWeight: 700,
                fontSize: '10px',
                textAlign: 'center'
              }}>
              Vizualização Indisponível
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            color: colors.grey[600],
            width: '100%'
          }}>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              color: '#212529'
            }}>
            {`Talhão ${index}`}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '13px',
              color: '#868E96'
            }}>
            {`${(areaInM2 / 10000).toLocaleString('pt-BR', {
              maximumFractionDigits: 2
            })} ha`}
          </Typography>
          <Link
            href={'https://plataforma.alerta.mapbiomas.org/mapa#?p=' + JSON.stringify(geometry)}
            underline="always"
            target="_blank"
            rel="noreferrer">
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '12px',
                color: '#FF922B'
              }}>
              MAPBIOMAS
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            minWidth: '110px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '12px'
          }}>
          {isValidated && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '10px'
              }}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#94D82D'
                }}>
                Validado
              </Typography>
              <GreenCheckedIcon width="22px" height="15px" />
            </Box>
          )}
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: isProductive ? '#228BE6' : '#F76707'
            }}>
            {isProductive ? 'Produtivo' : 'Não Produtivo'}
          </Typography>
        </Box>
      </Box>
    </ListItemButton>
  )
}
