import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface ISubtitle extends TypographyProps {
  children: React.ReactNode
}

export const Subtitle = ({ children, ...props }: ISubtitle) => (
  <Typography variant="body1" {...props}>
    {children}
  </Typography>
)
