import { Delete, Edit } from '@mui/icons-material'
import { colors, FormControlLabel, Typography, Box, Link } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { makeStyles } from '@mui/styles'
import * as turf from '@turf/turf'
import { RefObject, SetStateAction } from 'react'

import { CustomSwitch } from '../../CustomSwitch'

const useStyles = makeStyles(() => ({
  saveButton: {
    padding: '5px 10px',
    border: 'none',
    backgroundColor: '#808080',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

interface IEditableFeature {
  isSelected: boolean
  isProductive: boolean
  id: string | undefined
  imageUrl: string
  index: number
  areaInM2: number
  handleListItemClick: (id: string) => void
  selectedRef: RefObject<HTMLElement>
  saveEditingArea: () => void
  setEditingIndex: (value: SetStateAction<number | null>) => void
  cancelEditing: () => void
  removeArea: (index: number) => void
  handleSwitchChange: (index: number) => void
  isEditable: boolean
  editingIndex: number | null
  geometry: turf.Geometry
}

export const EditableFeature = ({
  isSelected,
  isProductive,
  id,
  imageUrl,
  index,
  areaInM2,
  handleListItemClick,
  selectedRef,
  saveEditingArea,
  setEditingIndex,
  cancelEditing,
  removeArea,
  handleSwitchChange,
  isEditable,
  editingIndex,
  geometry
}: IEditableFeature) => {
  const styles = useStyles()
  return (
    <ListItemButton
      selected={isSelected}
      sx={{
        borderBlock: `1px solid ${isSelected ? colors.amber[500] : 'transparent'}`
      }}
      onClick={() => {
        if (id) handleListItemClick(id)
      }}>
      <Box
        ref={isSelected ? selectedRef : undefined}
        sx={{
          width: '100%',
          display: 'flex',
          // flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center'
        }}>
        {imageUrl.length < 8192 ? (
          <img
            src={imageUrl}
            alt="Imagem de satélite do talhão"
            style={{
              width: '70px',
              height: '70px',
              borderRadius: '12px'
            }}
          />
        ) : (
          <Box
            sx={{
              width: '70px',
              height: '70px',
              borderRadius: '12px',
              background: 'grey',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              style={{
                width: '70px',
                wordWrap: 'break-word',
                color: 'white',
                fontWeight: 700,
                fontSize: '10px',
                textAlign: 'center'
              }}>
              Vizualização Indisponível
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
            color: colors.grey[600],
            width: '100%'
          }}>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              color: '#212529'
            }}>
            {`Talhão ${index}`}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '13px',
              color: '#868E96'
            }}>
            {`${(areaInM2 / 10000).toLocaleString('pt-BR', {
              maximumFractionDigits: 2
            })} ha`}
          </Typography>
          <Link
            href={'https://plataforma.alerta.mapbiomas.org/mapa#?p=' + JSON.stringify(geometry)}
            underline="always"
            target="_blank"
            rel="noreferrer">
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '12px',
                color: '#FF922B'
              }}>
              MAPBIOMAS
            </Typography>
          </Link>
        </Box>
        {isEditable && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {editingIndex === index ? (
              <div style={{ display: 'flex' }}>
                <button
                  className={styles.saveButton}
                  style={{ background: '#2B8A3E' }}
                  onClick={saveEditingArea}>
                  Salvar
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: '5px', background: '#FA5252' }}
                  onClick={() => {
                    if (confirm('Deseja cancelar as mudanças feitas?')) cancelEditing()
                  }}>
                  Cancelar
                </button>
              </div>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Edit onClick={() => setEditingIndex(index)} />
                <Delete
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    if (confirm('Deseja remover a área selecionada?')) removeArea(index)
                  }}
                />
              </Box>
            )}
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={isProductive}
                  disabled={editingIndex !== index}
                  onChange={() => {
                    isProductive = !isProductive
                    handleSwitchChange(index)
                  }}
                  sx={{ m: '0.5rem', mr: '1rem' }}
                />
              }
              labelPlacement="start"
              label={isProductive ? 'Produtivo' : 'Não produtivo'}
              sx={{
                width: '120px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                '& .MuiTypography-root': {
                  color: isProductive ? colors.blue[500] : colors.deepOrange[500],
                  fontSize: '0.825rem'
                }
              }}
            />
          </Box>
        )}
      </Box>
    </ListItemButton>
  )
}
