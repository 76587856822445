import { formatGovRegCode } from '@/utils/formatGovRegCode'
import { FlexBox, NavigateBackButton } from '@components'
import { OpenInNew } from '@mui/icons-material'
import { Button, CardActions, CardContent, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import { renderEmptyState } from '@shared'

import { RejectionDialog } from '../ProfileReview/components/RejectionDialog'
import { TypographyInline } from '../ProfileReview/components/TypographyInline'

interface IWarehouseReviewView {
  profileName?: string
  profileGovRegCode?: string
  profileId?: string
  handleOpenHubPage: () => void
  handleAcceptTask: () => void
  rejectionDialog: {
    open: boolean
    handleOpen: () => void
    handleCancel: () => void
    handleConfirm: (reason: string) => void
  }
  isRequesting: boolean
  error: unknown
}

export const WarehouseReviewView = (props: IWarehouseReviewView) => {
  const {
    profileName,
    profileGovRegCode,
    profileId,
    handleOpenHubPage,
    handleAcceptTask,
    rejectionDialog,
    isRequesting,
    error
  } = props

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 3rem)', mt: '6rem' }}>
        {renderEmptyState(isRequesting, error, 'Finalizando tarefa...') || (
          <>
            <Box mb="1rem">
              <NavigateBackButton />
            </Box>
            <Card sx={{ p: '2rem', borderRadius: '12px' }}>
              <CardContent>
                <FlexBox mb="2rem">
                  <Typography variant="h4" flexGrow={1} minWidth="max-content">
                    Aprovar Armazém?
                  </Typography>
                  <FlexBox sx={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleOpenHubPage}>
                      <OpenInNew />
                      Abrir HUB
                    </Button>
                  </FlexBox>
                </FlexBox>
                <Box>
                  <TypographyInline variant="subtitle1">Nome:</TypographyInline>
                  <TypographyInline>&nbsp;{profileName}</TypographyInline>
                </Box>
                <Box>
                  <TypographyInline variant="subtitle1">CPF/CNPJ:</TypographyInline>
                  <TypographyInline>&nbsp;{formatGovRegCode(profileGovRegCode)}</TypographyInline>
                </Box>
                <Box>
                  <TypographyInline variant="subtitle1">Profile ID:</TypographyInline>
                  <TypographyInline>&nbsp;{profileId}</TypographyInline>
                </Box>
              </CardContent>
              <CardActions>
                <FlexBox mt="1rem">
                  <Button
                    onClick={rejectionDialog.handleOpen}
                    variant="discreet"
                    color="error"
                    sx={{ flexGrow: 1 }}>
                    Rejeitar
                  </Button>
                  <Button
                    onClick={handleAcceptTask}
                    variant="discreet"
                    color="success"
                    sx={{ flexGrow: 1 }}>
                    Aprovar
                  </Button>
                </FlexBox>
              </CardActions>
            </Card>
          </>
        )}
      </Box>
      <RejectionDialog
        open={rejectionDialog.open}
        handleCancel={rejectionDialog.handleCancel}
        handleConfirm={rejectionDialog.handleConfirm}
      />
    </>
  )
}
