import { buildAxiosErrorMessage } from '@/utils/buildAxiosErrorMessage'
import * as Sentry from '@sentry/react'
import axios from 'axios'

export const axiosLutien = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP_URL_LUTIEN_API,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosLutien.interceptors.response.use(undefined, error => {
  const errorMessage = buildAxiosErrorMessage(error, 'Lutien Error')
  console.error(errorMessage)

  if (error?.config?.url === '/api/v1/auth/login') return

  Sentry.captureException(errorMessage)
})
