import { FlexBox } from '@components'
import { Add, Delete, Done, Edit, JoinInner, ZoomIn, ZoomOutMap } from '@mui/icons-material'
import CachedIcon from '@mui/icons-material/Cached'
import Close from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Button, colors, IconButton, Typography, Box, CircularProgress } from '@mui/material'
import moment from 'moment'
import React from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { AxisDomain } from 'recharts/types/util/types'

import { CHART_MODE, TArea, TData } from './AreasChartContainer'
import { AreasChartTooltip } from './components/AreasChartTooltip'

export type TAreasChartView = {
  data: TData[]
  areas: TArea[]
  lines?: { id: string; color: string; data: TData[] }[]
  mode: CHART_MODE
  changeMode: (newMode: CHART_MODE) => void
  handleMouseDown: (mouseX: number) => void
  handleMouseMove: (event: CategoricalChartState) => void
  handleMouseUp: (event: CategoricalChartState) => void
  dragAreaBoundary: (xPos: number, clickedArea: TArea) => void
  domainX: AxisDomain | undefined
  resetDomainX: () => void
  mouseX?: number
  previousMouseX?: number
  title?: string
  legendComponent?: React.ReactNode
  headerComponent?: React.ReactNode
  selectedAreas: TArea[]
  restoreAreas: () => void
  deleteSelectedAreas: () => void
  intersections: TArea[]
  showIntersections: boolean
  setShowIntersections: React.Dispatch<React.SetStateAction<boolean>>
  monthlyTicks: number[]
  disableEditing: boolean
  height?: string
  width?: string
  hideAreas?: boolean
  onFinishClick?: () => Promise<void>
  isEditable?: boolean
  isLoading?: boolean
  isLoadingRecover?: boolean
  isLoadingSave?: boolean
  onSave?: () => void
  onRecover?: () => void
}

export const AreasChartView = ({
  data,
  areas,
  lines,
  mode,
  changeMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  dragAreaBoundary,
  domainX,
  resetDomainX,
  mouseX,
  previousMouseX,
  title,
  legendComponent,
  headerComponent,
  selectedAreas,
  restoreAreas,
  deleteSelectedAreas,
  intersections,
  showIntersections,
  setShowIntersections,
  monthlyTicks,
  disableEditing,
  height,
  width,
  hideAreas,
  onFinishClick,
  isEditable,
  isLoading,
  isLoadingRecover,
  isLoadingSave,
  onSave,
  onRecover
}: TAreasChartView) => {
  const editionMode =
    !disableEditing && (mode === CHART_MODE.EDITION || mode === CHART_MODE.CREATION)
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          p: '0.25rem 0.5rem'
        }}>
        <Typography flexGrow={1}>{title}</Typography>

        {headerComponent}

        {(isEditable === undefined || isEditable) && (
          <Button
            variant="discreet"
            color="warning"
            sx={{
              display: editionMode || disableEditing ? 'none' : 'flex'
            }}
            onClick={() => changeMode(CHART_MODE.EDITION)}>
            <Edit fontSize="inherit" />
            Editar
          </Button>
        )}

        <LoadingButton
          loading={isLoadingRecover}
          variant="contained"
          sx={{
            display: editionMode || disableEditing ? 'none' : 'flex',
            bgcolor: '#fff',
            '&:hover': {
              bgcolor: colors.grey[200]
            }
          }}
          onClick={onRecover}>
          {'Recuperar progresso'}
          <CachedIcon fontSize="small" sx={{ color: colors.orange[500] }} />
        </LoadingButton>

        <LoadingButton
          loading={isLoadingSave}
          variant="contained"
          sx={{
            display: editionMode || disableEditing ? 'none' : 'flex',
            bgcolor: '#fff',
            '&:hover': {
              bgcolor: colors.grey[200]
            }
          }}
          onClick={onSave}>
          {'Salvar progresso'}
          <SaveIcon fontSize="small" sx={{ color: colors.orange[500] }} />
        </LoadingButton>

        <Button
          variant="contained"
          disableElevation
          sx={{
            display: editionMode ? 'flex' : 'none',
            gap: '0.25rem',
            height: '2rem',
            fontSize: '0.75rem',
            color: 'rgba(0, 0, 0, 0.6)',
            bgcolor: colors.lightBlue[400],
            '&:hover': {
              bgcolor: colors.lightBlue[500]
            }
          }}
          onClick={
            onFinishClick
              ? async () => await onFinishClick()
              : () => changeMode(CHART_MODE.VISUALIZATION)
          }>
          <Done fontSize="inherit" />
          Concluído
        </Button>

        <Button
          variant="outlined"
          disableElevation
          sx={{
            display: editionMode ? 'flex' : 'none',
            gap: '0.25rem',
            height: '2rem',
            fontSize: '0.75rem',
            borderColor: colors.grey[400],
            color: 'rgba(0, 0, 0, 0.6)',
            '&:hover': {
              borderColor: colors.grey[500],
              bgcolor: colors.grey[50]
            }
          }}
          onClick={() => {
            confirm('Você realmente deseja descartar todas as alterações?') && restoreAreas()
          }}>
          <Close fontSize="inherit" />
          Cancelar
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingInline: '0.5rem',
          bgcolor: '#fff',
          borderTopLeftRadius: '12px'
        }}>
        <IconButton
          sx={{
            display: editionMode ? 'flex' : 'none',
            p: 0,
            width: '1.75rem',
            height: '1.75rem'
          }}
          title="Adicionar nova área"
          onClick={() => changeMode(CHART_MODE.CREATION)}>
          <Add fontSize="small" />
        </IconButton>

        <IconButton
          sx={{
            display: editionMode ? 'flex' : 'none'
          }}
          title="Excluir área(s) selecionada(s)"
          disabled={selectedAreas.length === 0}
          onClick={() =>
            confirm('Você realmente deseja excluir as áreas selecionadas?') && deleteSelectedAreas()
          }>
          <Delete fontSize="small" />
        </IconButton>

        <IconButton title="Ver interseções" onClick={() => setShowIntersections(old => !old)}>
          <JoinInner fontSize="small" />
        </IconButton>

        <IconButton title="Ampliar" onClick={() => changeMode(CHART_MODE.ZOOM)}>
          <ZoomIn fontSize="small" />
        </IconButton>

        <IconButton title="Exibir todo o gráfico" onClick={resetDomainX}>
          <ZoomOutMap fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: height || '100%',
          width: width || '100%',
          bgcolor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {isLoading ? (
          <CircularProgress />
        ) : data.length === 0 ? (
          <FlexBox>
            <Typography variant="h6">Nenhum dado disponível</Typography>
          </FlexBox>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={
                lines && lines !== undefined
                  ? data.map((e, index) => {
                      let linesObj = {}
                      lines.forEach(line => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        linesObj[line.id] = line.data[index] ? line.data[index].y : 0
                      })
                      linesObj = { ...linesObj, ...e }
                      return linesObj
                    })
                  : data
              }
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              margin={{ left: -24, right: 16, bottom: -25 }}
              style={{
                cursor:
                  mode === CHART_MODE.ZOOM
                    ? 'zoom-in'
                    : mode === CHART_MODE.CREATION
                    ? 'copy'
                    : 'default'
              }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="10%" stopColor={colors.grey[300]} stopOpacity={0.6} />
                  <stop offset="100%" stopColor={colors.grey[300]} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                xAxisId={0}
                dy={-2}
                type="number"
                allowDataOverflow
                domain={domainX}
                dataKey="x"
                axisLine={false}
                ticks={monthlyTicks}
                tickLine={false}
                interval={2}
                scale="time"
                tick={{ fontSize: '10px' }}
                tickFormatter={value => moment(new Date(value)).format('MMM')}
              />
              <XAxis
                xAxisId={1}
                dy={-18}
                type="number"
                allowDataOverflow
                domain={domainX}
                dataKey="x"
                axisLine={false}
                tickLine={false}
                ticks={monthlyTicks}
                interval={11}
                scale="time"
                tick={{ fontSize: '12px' }}
                tickFormatter={value => moment(new Date(value)).format('YYYY')}
              />
              <YAxis
                tick={{ fontSize: '0.8125rem' }}
                axisLine={false}
                tickLine={false}
                tickCount={6}
              />

              <CartesianGrid opacity={0.6} vertical={false} strokeDasharray="3 3" />
              <Tooltip
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                content={<AreasChartTooltip />}
              />

              <Area
                type="monotone"
                dataKey="y"
                stroke={colors.grey[500]}
                fillOpacity={1}
                fill="url(#colorUv)"
              />

              {previousMouseX && (
                <ReferenceArea x1={previousMouseX} x2={mouseX} strokeOpacity={0.3} />
              )}

              {areas.length &&
                !hideAreas &&
                areas.map(area => {
                  const isSelected = selectedAreas.find(value => value.id === area.id)
                  return (
                    <>
                      <ReferenceArea
                        key={area.id}
                        x1={area.xMin.value.getTime()}
                        x2={area.xMax.value.getTime()}
                        stroke={editionMode && isSelected ? colors.pink[500] : area.color}
                        fill={area.color}
                        fillOpacity={editionMode ? (isSelected ? 0.6 : 0.2) : 0.38}
                        strokeOpacity={editionMode ? (isSelected ? 0.6 : 0.2) : 0.38}
                        cursor={editionMode ? 'pointer' : 'default'}
                        onMouseDown={e =>
                          handleMouseDown(
                            (Object.values(e.target)[1].x1 + Object.values(e.target)[1].x2) / 2
                          )
                        }
                      />
                      {editionMode && (
                        <>
                          <ReferenceArea
                            key={`min-${area.id}`}
                            x1={area.xMin.value.getTime() - 100000000}
                            x2={area.xMin.value.getTime() + 100000000}
                            fill="transparent"
                            stroke="transparent"
                            strokeWidth={10}
                            isFront
                            cursor="col-resize"
                            onMouseDown={e => {
                              dragAreaBoundary(Object.values(e.target)[1].x1 + 100000000, area)
                            }}
                          />
                          <ReferenceArea
                            key={`max-${area.id}`}
                            x1={area.xMax.value.getTime() - 100000000}
                            x2={area.xMax.value.getTime() + 100000000}
                            fill="transparent"
                            stroke="transparent"
                            strokeWidth={10}
                            isFront
                            cursor="col-resize"
                            onMouseDown={e =>
                              dragAreaBoundary(Object.values(e.target)[1].x1 + 100000000, area)
                            }
                          />
                        </>
                      )}
                    </>
                  )
                })}

              {intersections.length &&
                showIntersections &&
                intersections.map((intersection, index) => {
                  return (
                    <ReferenceArea
                      key={`intersection${index}`}
                      x1={intersection.xMin.value.getTime()}
                      x2={intersection.xMax.value.getTime()}
                      stroke={intersection.color}
                      fill={intersection.color}
                      fillOpacity={0.5}
                      strokeOpacity={0.5}
                      style={{ zIndex: -1 }}
                      cursor={'default'}
                    />
                  )
                })}
              {data.length && (
                <>
                  <ReferenceLine x={data[0].x} stroke="black" />
                  <ReferenceLine x={data[data.length - 1].x} stroke="black" />
                </>
              )}
              {lines &&
                lines.map((lineData, index) => {
                  return (
                    <Area
                      key={`line${index}`}
                      type="monotone"
                      dataKey={lineData.id}
                      stroke={lineData.color}
                      fillOpacity={1}
                      fill="url(#colorPv)"
                    />
                  )
                })}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
      {legendComponent && <Box bgcolor="#fff">{legendComponent}</Box>}
    </Box>
  )
}
