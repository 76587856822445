import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { HubApi, LutienApi, MembershipDTO, ShortOrganizationDTO } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

import { TData } from '../utils/authorization.utils'

export type TUseAuthorizationPage = {
  tarkenOrganizations: TData<ShortOrganizationDTO[]>
  organizationMembers: TData<MembershipDTO[]>
  organizationRoles: TData<RoleDTO[]>
  hasError: boolean
  updateOrganizationMembers: (orgId: string) => void
  updateOrganizationRoles: (orgId: string, setLoading?: boolean) => Promise<void>
  resetStates: () => void
}

export const useAuthorizationPage = (): TUseAuthorizationPage => {
  const [tarkenOrganizations, setTarkenOrganizations] = useState<TData<ShortOrganizationDTO[]>>({
    data: undefined,
    loading: true
  })
  const [organizationMembers, setOrganizationMembers] = useState<TData<MembershipDTO[]>>({
    data: undefined,
    loading: true
  })
  const [organizationRoles, setOrganizationRoles] = useState<TData<RoleDTO[]>>({
    data: undefined,
    loading: true
  })

  const [hasError, setHasError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const updateTarkenOrganizations = useCallback(async () => {
    try {
      const res = await LutienApi.getOrganizations()
      setTarkenOrganizations({
        data: res,
        loading: false
      })
    } catch (error) {
      enqueueSnackbar('Erro ao carregar organizacoes do sistema', { variant: 'error' })
      setHasError(true)
    }
  }, [enqueueSnackbar])

  useEffect(() => {
    updateTarkenOrganizations()
  }, [updateTarkenOrganizations])

  const updateOrganizationMembers = useCallback(
    async (orgId: string) => {
      setOrganizationMembers(old => ({
        ...old,
        loading: true
      }))
      try {
        const res = await LutienApi.getMemberships(orgId)
        const sortedResponse = res
          .filter(m => Boolean(m.user))
          .sort((a, b) => a.user.name.localeCompare(b.user.name))
        setOrganizationMembers({
          data: sortedResponse,
          loading: false
        })
      } catch (error) {
        enqueueSnackbar('Erro ao carregar membros', { variant: 'error' })
        console.error(error)
        setHasError(true)
        setOrganizationMembers(old => ({
          ...old,
          loading: false
        }))
      }
    },
    [enqueueSnackbar]
  )

  const updateOrganizationRoles = useCallback(
    async (orgId: string, setLoading?: boolean) => {
      setOrganizationRoles(old => ({
        ...old,
        loading: setLoading ?? false
      }))
      try {
        const res = await HubApi.Authorization.getRoles(orgId)
        setOrganizationRoles({
          data: res,
          loading: false
        })
      } catch (error) {
        enqueueSnackbar('Erro ao carregar cargos', { variant: 'error' })
        setHasError(true)
        setOrganizationRoles(old => ({
          ...old,
          loading: false
        }))
      }
    },
    [enqueueSnackbar]
  )

  const resetStates = useCallback(() => {
    setOrganizationMembers({
      data: undefined,
      loading: true
    })
    setOrganizationRoles({
      data: undefined,
      loading: true
    })
  }, [])

  return {
    tarkenOrganizations,
    organizationMembers,
    organizationRoles,
    hasError,
    updateOrganizationMembers,
    updateOrganizationRoles,
    resetStates
  }
}
