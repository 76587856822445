import { normalizeString } from '@/utils/normalizeString'
import Fuse from 'fuse.js'
import { useCallback, useMemo } from 'react'

interface IUseFuzzyFilter<T extends Record<string, unknown>> {
  data: T[] | undefined
  searchKeys: string[]
}

export type TUseFuzzyFilter<T extends Record<string, unknown>> = {
  getFilteredData: (searchValue: string | undefined) => T[]
}

export const useFuzzyFilter = <T extends Record<string, unknown>>(
  props: IUseFuzzyFilter<T>
): TUseFuzzyFilter<T> => {
  const { data, searchKeys } = props

  const customGetFn = useCallback((obj: T, path: string | string[]) => {
    // eslint-disable-next-line import/no-named-as-default-member
    const value = Fuse.config.getFn(obj, path)

    if (typeof value === 'string') {
      return normalizeString(value)
    }
    if (Array.isArray(value)) {
      return value.map(e => normalizeString(e))
    }
    return value
  }, [])

  const fuse = useMemo(() => {
    return new Fuse(data || [], {
      keys: searchKeys,
      shouldSort: true,
      includeMatches: true,
      threshold: 0.4,
      getFn: customGetFn
    })
  }, [searchKeys, data, customGetFn])

  const fuzzySearch = useCallback(
    (searchTerm: string): T[] => {
      const results = fuse.search(normalizeString(searchTerm))

      return results.map(e => e.item)
    },
    [fuse]
  )

  const getFilteredData = useCallback(
    (searchValue: string | undefined): T[] => {
      if (!data) {
        return []
      }
      if (!searchValue) {
        return data
      }

      return fuzzySearch(searchValue)
    },
    [data, fuzzySearch]
  )

  return {
    getFilteredData
  }
}
