/** Generic Actions */
export enum ACTION {
  /** Represents any action */
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',

  READ_DETAILS = 'read:details',
  SUBMIT = 'submit',
  MOVE = 'move'
}

export enum SUBJECT {
  /** Represents any subject */
  ALL = 'all',
  CRM_WORKFLOW = 'CRM:Workflow',
  CRM_TICKET = 'CRM:Ticket',
  INSP_ANALYSIS_REQUEST = 'INSP:AnalysisRequest',
  LAND_ANALYSIS_REQUEST = 'LAND:AnalysisRequest',
  CRM_CREDIT_LIMIT_APPROVAL = 'CRM:CreditLimitApproval',
  CRM_CREDIT_LIMIT_REQUEST = 'CRM:CreditLimitRequest',
  CRM_COLLATERAL_ASSETS = 'CRM:CollateralAssets',
  CRM_FORM = 'CRM:Form',
  CRM_CUSTOMER = 'CRM:Customer',
  INSP_OVERVIEW = 'INSP:Overview',
  INSP_DOCUMENTATION = 'INSP:Documentation',
  INSP_RISK_PROFILE = 'INSP:RiskProfile',
  INSP_PRE_APPROVED_LIMIT = 'INSP:PreApprovedLimit',
  CRM_POWERBI_DASHBOARD = 'CRM:PowerBIDashboards',
  PLATFORM_BILLING = 'PLATFORM:BILLING',
  AGMT_SIGNATURE_TOOL = 'AGMT:SignatureTool',
  AGMT_DOCUMENT_CREATION_TOOL = 'AGMT:DocumentCreationTool',
  AGMT_CLEARING_HOUSE_REGISTER_TOOL = 'AGMT:ClearingHouseRegisterTool',
  AGMT_CROQUI_DRAWER_TOOL = 'AGMT:CroquiDrawerTool',
  FIN_FINANCING_REQUEST_TICKET = 'FIN:FinancingRequestTicket'
}

export enum SUBJECT_DOMAINS {
  CRM = 'CRM',
  INSPECTOR = 'INSPECTOR',
  PLATFORM = 'PLATFORM',
  AGMT = 'AGMT',
  FINANCING = 'FINANCING'
}

export enum RESOURCE {
  'CRM:Phases' = 'CRM:Phases',
  'CRM:Workflows' = 'CRM:Workflows',
  'CRM:Memberships' = 'CRM:Memberships',
  'CRM:Forms' = 'CRM:Forms',
  'CRM:DocumentFormalizationTicketType' = 'CRM:DocumentFormalizationTicketType'
}
