import { BenderApi, HubApi, THubDetectedSeason } from '@services'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

export const useFetchDetectedSeasons = (propertyId: string | undefined) => {
  const [detectedSeasons, setDetectedSeasons] = useState<THubDetectedSeason[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [recoverProgressRequested, setRecoverProgressRequested] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const recoverProgress = async (taskId: string) => {
    setRecoverProgressRequested(true)
    setIsLoading(true)
    try {
      const recoveredDetectedSeasons = (await BenderApi.task.getTaskProgress(
        taskId
      )) as THubDetectedSeason[]

      if (!recoveredDetectedSeasons)
        enqueueSnackbar('Não há progresso para ser recuperado.', { variant: 'warning' })

      setDetectedSeasons(recoveredDetectedSeasons)
      enqueueSnackbar('Progresso recuperado com sucesso!', { variant: 'success' })
      setIsLoading(false)
    } catch (err) {
      enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })
      console.error(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const bootstrap = async () => {
      if (!propertyId) return

      try {
        const response = await HubApi.getDetectedSeasons(propertyId)

        setDetectedSeasons(response)
      } catch (error) {
        enqueueSnackbar('Erro ao buscar safras no servidor.', { variant: 'error' })
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!recoverProgressRequested) bootstrap()
  }, [enqueueSnackbar, propertyId, recoverProgressRequested])

  return { detectedSeasons, isLoading, recoverProgress }
}
