import { COMPLIANCE_TASK_TYPE } from '@/pages/bender/Compliance/enums/ComplianceTaskType'
import {
  ComplianceContext,
  IRecordCandidate
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { OrNull } from '@/utils/OrNull'
import { useFetchTask } from '@/utils/hooks/useFetchTask'
import { openConfirm } from '@components'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getComplianceFromCollectorName } from '../../helpers/getComplianceFromCollectorName'
import { CollectorExceptionMainView } from './CollectorExceptionMainView'

const NUM_OF_FORM_FIELDS = 4

const emptyComplianceRecord: OrNull<IRecordCandidate> = {
  documentExpirationDate: null,
  documentStatus: null,
  file: null,
  recordType: null
}

interface TCollectorExceptionMainProps {
  handleCompleteTask: () => void
}

export const CollectorExceptionMain = (props: TCollectorExceptionMainProps) => {
  const { handleCompleteTask } = props
  const { taskId } = useParams()

  const [completeDisabled, setCompleteDisabled] = useState(true)
  const [complianceRecord, setComplianceRecord] = useState(emptyComplianceRecord)
  const [taskType, setTaskType] = useState<COMPLIANCE_TASK_TYPE>()

  const { personProfile, recordCandidates, addRecordCandidate, removeRecordCandidate } =
    useContext(ComplianceContext)
  const navigate = useNavigate()

  useFetchTask<'CollectorException'>(taskId, task => {
    const collectorName = task?.input?.CollectorInput?.collector
    const compliance = getComplianceFromCollectorName(collectorName)
    if (!compliance) return

    setTaskType(compliance.type)
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      recordType: compliance.subtype
    }))
  })

  const handleOpenHubProfile = () => {
    window.open(
      `${process.env.REACT_APP_URL_HUB_FRONT}/tomador/registro/${personProfile.id}`,
      '_blank'
    )
  }

  const handleClickComplete = () => {
    handleCompleteTask()
  }

  const handleClickCancel = async () => {
    if (await openConfirm('Você deseja sair sem finalizar a tarefa?')) navigate(-1)
  }

  useEffect(() => {
    const thereIsUnfilledField =
      Object.keys(complianceRecord).length < NUM_OF_FORM_FIELDS ||
      Object.values(complianceRecord).some(value => value == null)

    setCompleteDisabled(thereIsUnfilledField)

    if (!thereIsUnfilledField) {
      recordCandidates.forEach(({ index }) => removeRecordCandidate(index ?? 0))
      addRecordCandidate(complianceRecord as IRecordCandidate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceRecord])

  return (
    <CollectorExceptionMainView
      handleClickComplete={handleClickComplete}
      handleClickCancel={handleClickCancel}
      handleOpenHubProfile={handleOpenHubProfile}
      personProfile={personProfile}
      complianceRecord={complianceRecord}
      setComplianceRecord={setComplianceRecord}
      completeDisabled={completeDisabled}
      taskType={taskType}
    />
  )
}
