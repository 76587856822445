import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction } from 'react'

export type TLoginView = {
  error: boolean
  handleSubmit: () => void
  isLoading: boolean
  setEmail: Dispatch<SetStateAction<string>>
  setPassword: Dispatch<SetStateAction<string>>
}

export const LoginView = ({
  error,
  handleSubmit,
  isLoading,
  setEmail,
  setPassword
}: TLoginView) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '2rem',
          gap: '1rem'
        }}>
        <Typography variant="h5" mb="1rem">
          Entrar
        </Typography>
        <TextField error={error} label="Email" onChange={e => setEmail(e.target.value)} />
        <TextField
          error={error}
          helperText={error ? 'Email ou senha inválidos' : ''}
          label="Senha"
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <LoadingButton
          disableElevation
          loading={isLoading}
          onClick={handleSubmit}
          sx={{ mt: '1rem' }}
          variant="contained">
          Entrar
        </LoadingButton>
      </Paper>
    </Box>
  )
}
