import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface ITitle extends TypographyProps {
  children: React.ReactNode
}

export const Title = ({ children, ...props }: ITitle) => (
  <Typography variant="h3" {...props}>
    {children}
  </Typography>
)
