import { Tooltip, Typography, TypographyProps } from '@mui/material'

interface TEllipsisText extends TypographyProps {
  text: string
  maxLength: number
  customTooltip?: string
  customPlacement?: 'top' | 'bottom' | 'left' | 'right'
}

export const EllipsisText = ({
  text,
  maxLength,
  customTooltip,
  customPlacement,
  ...typographyProps
}: TEllipsisText): JSX.Element => {
  if (text?.length <= maxLength || maxLength < 0) {
    return <Typography {...typographyProps}>{text}</Typography>
  }

  const tail = '...'

  const tailStyle = {
    cursor: 'auto'
  }

  let displayText
  if (maxLength - tail?.length <= 0) {
    displayText = ''
  } else {
    displayText = text?.slice(0, maxLength - tail?.length)
  }

  return (
    <>
      <Tooltip title={customTooltip ?? text} arrow placement={customPlacement ?? 'bottom'}>
        <Typography {...typographyProps}>
          {displayText}
          <span style={tailStyle}>{tail}</span>
        </Typography>
      </Tooltip>
    </>
  )
}

export default EllipsisText
