import { useState } from 'react'

import { login } from '../../redux/authenticator'
import { useAppDispatch } from '../../redux/hooks'
import { LoginView } from './LoginView'

export const Login = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    if (!email || !password) return
    setIsLoading(true)
    await dispatch(login({ email, password }))
    setIsLoading(false)
    setError(true)
  }

  return (
    <LoginView
      error={error}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  )
}
