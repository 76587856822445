/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HubAreaRequest } from '@/services/Hub/Areas/contract/request/HubArea.request'
import { getImageMapbox } from '@/utils/getImageMapbox'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
// @ts-ignore
import defaultDrawStyle from '@mapbox/mapbox-gl-draw/src/lib/theme.js'
import { Box, CircularProgress, circularProgressClasses } from '@mui/material'
import { HubApi } from '@services'
// import {crops} from '@services/Gis/'
import bbox from '@turf/bbox'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { FeatureCollection, point } from '@turf/helpers'
import * as turf from '@turf/turf'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import SelectFeatureMode, {
  drawStyles as selectFeatureDrawStyles
} from 'mapbox-gl-draw-select-mode'
import SplitPolygonMode, {
  drawStyles as splitPolygonDrawStyles
} from 'mapbox-gl-draw-split-polygon-mode'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
// eslint-disable-next-line import/no-named-as-default
import WebMercatorViewport from 'viewport-mercator-project'

import { MapView } from './MapView'
// import { Header } from './components/Header/index'
import { SideBar } from './components/SideBar/index'
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface IMap {
  propertyId: string
  closeMap: () => void
}

interface AreaStatus {
  id: string
  wasDeleted: boolean
  wasEdited: boolean
}

export const Map = ({ propertyId, closeMap }: IMap) => {
  const [geoJson, setGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [selectedAreasIds, setSelectedAreasIds] = useState<string[] | null>([])
  const [intersections, setIntersections] = useState<FeatureCollection>({
    features: [],
    type: 'FeatureCollection'
  })
  const [propertyBoundary, setPropertyBoundary] = useState<turf.MultiPolygon>()

  const [initialAreasStatus, setInitialAreasStatus] = useState<AreaStatus[] | null>([])
  const [intersectionsVisibility, setIntersectionsVisibility] = useState(true)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [splittingIndex, setSplittingIndex] = useState<number | null>(null)
  const [isAddingArea, setIsAddingArea] = useState<boolean | null>(false)
  const [addingAreaFlag, setAddingAreaFlag] = useState<boolean>(false)
  const [isSplittingArea, setIsSplittingArea] = useState<boolean | null>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isControlPressed, setIsControlPressed] = useState(false)
  const [highlightedFeatId, setHighlightedFeatId] = useState<number | null>(null)
  // const [reRenderIntersec, setReRenderIntersec] = useState(false)
  const [drawRef, setDrawRef] = useState<MapboxDraw | null>(null)
  const [propertyName, setPropertyName] = useState<string | null>(null)
  const [propertyImage, setPropertyImage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  // const [isSendingReview, setIsSendingReview] = useState(false)
  // const [isSavingTask, setIsSavingTask] = useState(false)
  // const [isRecoveringProgress, setIsRecoveringProgress] = useState(false)
  // const pageOpeningTime = useRef(new Date())
  const [highlightedFeatCoordinates, setHighlightedFeatCoordinates] =
    useState<turf.helpers.Position | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  const mapRef = useRef<MapRef>(null)

  // const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 40 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  const calculateIntersections = () => {
    if (!geoJson) return
    const intersectionsFeatures: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = []
    geoJson.features.forEach((feature, index) => {
      for (let i = index + 1; i < geoJson.features.length; i++) {
        try {
          const intersection = turf.intersect(
            feature as turf.Feature<turf.Polygon>,
            geoJson.features[i] as turf.Feature<turf.Polygon>
          )
          intersection && intersectionsFeatures.push(intersection)
        } catch (error) {
          console.error(error)
        }
      }
    })
    setIntersections({
      type: 'FeatureCollection',
      features: intersectionsFeatures
    })
  }
  const getClickedLayerId = (long: number, lat: number): number | null => {
    const pt = point([long, lat])
    let id = null
    geoJson?.features?.forEach((feature, index) => {
      if (feature && booleanPointInPolygon(pt, feature.geometry as turf.Polygon)) {
        id = index
      }
    })
    return id
  }

  const handleBack = () => {
    setHighlightedFeatId(null)
    closeMap()
  }

  const updateAreas = async () => {
    if (!geoJson?.features || !initialAreasStatus) return
    await Promise.all(
      initialAreasStatus.map(async area => {
        if (area.wasDeleted) {
          await HubApi.areas.deleteById(area.id)
        } else if (area.wasEdited) {
          const feature = geoJson.features.find(feat => area.id == feat.id)
          if (feature) {
            await HubApi.areas.update(area.id, {
              boundary: feature.geometry as turf.Polygon,
              propertyId: propertyId
            })
          }
        }
      })
    )
  }

  const addNewAreas = async () => {
    const createdBulk: HubAreaRequest[] = []
    geoJson?.features.forEach(feat => {
      const newArea = initialAreasStatus?.find(area => {
        return area.id == feat.id
      })
      if (!newArea) {
        createdBulk.push({ boundary: feat.geometry as turf.Polygon, propertyId: propertyId })
      }
    })
    if (createdBulk.length) {
      await HubApi.areas.createBulk(createdBulk)
    }
  }

  const handleFinalizeEdition = async () => {
    if (!geoJson || intersections.features.length) {
      // setReRenderIntersec(!reRenderIntersec)
      alert('Erro, verifique se há interseções entre as áreas desenhadas.')
      return
    }
    setLoading(true)
    try {
      await updateAreas()
      await addNewAreas()
      enqueueSnackbar('Edição finalizada com sucesso.', { variant: 'success' })
      closeMap()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleToggleIntersectionsVisibility = () => {
    setIntersectionsVisibility(value => {
      if (!value && intersections.features.length)
        mapRef.current?.flyTo({
          ...getBoundsForPoints(intersections),
          duration: 400
        })
      return !value
    })
  }

  useEffect(() => {
    const onPageLoad = async () => {
      try {
        const areas = await HubApi.areas.getAreas(propertyId)
        const features: turf.FeatureCollection<
          turf.Geometry | turf.GeometryCollection
        >['features'] = []
        const status: AreaStatus[] = []
        areas.forEach(feat => {
          const polygon = feat.boundary
          features.push({
            type: 'Feature',
            geometry: polygon,
            id: feat.id
          } as turf.Feature)
          status.push({ id: feat.id, wasDeleted: false, wasEdited: false })
        })
        const newGeoJson: FeatureCollection = { features: features, type: 'FeatureCollection' }
        setGeoJson(newGeoJson)
        setInitialAreasStatus(status)
        try {
          if (propertyId) {
            const data = await HubApi.getProperty(propertyId)
            if (data.sketchBoundary) {
              const image = getImageMapbox(data.sketchBoundary)
              setPropertyImage(image)
            }
            if (data.sketchBoundary && data.sketchBoundary.type == 'MultiPolygon') {
              setPropertyBoundary(data.sketchBoundary)
            }
            if (data.name) setPropertyName(data.name)
          }
        } catch (error) {
          console.error(error)
          enqueueSnackbar('Não foi possível carregar a imagem da propriedade.', {
            variant: 'error'
          })
        }
      } catch (error) {
        enqueueSnackbar('Algo deu errado, tente novamente.', { variant: 'error' })

        console.error(error)
      }
    }

    onPageLoad()
  }, [propertyId])

  if (mapRef.current) mapRef.current.getMap().dragRotate.disable()

  // useEffect(() => {
  //   calculateIntersections()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [geoJson])

  useEffect(() => {
    const highLightedFeat = geoJson?.features.find(feature => feature.id == highlightedFeatId)
    if (highLightedFeat) {
      setHighlightedFeatCoordinates(turf.centroid(highLightedFeat).geometry.coordinates)
    } else {
      setHighlightedFeatCoordinates(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId])

  useEffect(() => {
    const map = mapRef.current?.getMap()
    if (
      ((geoJson?.features && geoJson?.features.length > 0) || propertyBoundary) &&
      map &&
      isFirstLoad
    ) {
      if (geoJson?.features.length) {
        map.flyTo({
          ...getBoundsForPoints(geoJson),
          duration: 400,
          padding: 20
        })
      } else {
        if (propertyBoundary?.type === 'MultiPolygon' && propertyBoundary?.coordinates.length > 0) {
          map.flyTo({
            ...getBoundsForPoints(propertyBoundary),
            duration: 400,
            padding: 20
          })
        }
      }
      setIsFirstLoad(false)
    }
  }, [geoJson, propertyBoundary, mapRef, isFirstLoad])
  //MapBoxDraw mode that overrides function of dragging a polygon
  const NewSimpleSelect = _.extend(MapboxDraw.modes.simple_select, {
    dragMove() {
      // @ts-ignore
    }
  })

  const NewDirectSelect = _.extend(MapboxDraw.modes.direct_select, {
    dragFeature() {
      // @ts-ignore
    }
  })

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    modes: {
      ...MapboxDraw.modes,
      simple_select: NewSimpleSelect,
      direct_select: NewDirectSelect,
      ...SplitPolygonMode(SelectFeatureMode(MapboxDraw.modes))
    },
    controls: {
      line_string: false,
      point: false,
      trash: false,
      polygon: false,
      combine_features: false,
      uncombine_features: false
    },
    styles: [...splitPolygonDrawStyles(selectFeatureDrawStyles(defaultDrawStyle))],
    userProperties: true
  })

  const cancelEditing = () => {
    if (drawRef && mapRef.current) {
      mapRef.current.getMap().removeControl(drawRef)
      setEditingIndex(null)
      setDrawRef(null)
    }
  }
  const updateStatus = (props: AreaStatus) => {
    const { wasDeleted, wasEdited, id } = props
    if (!geoJson) return
    const status = initialAreasStatus
    const index = status?.findIndex(area => area.id == id)
    if (index !== undefined && index != -1 && status) {
      if (wasDeleted) {
        status[index].wasEdited = false
        status[index].wasDeleted = wasDeleted
      } else if (wasEdited) status[index].wasEdited = wasEdited
      setInitialAreasStatus(status)
    }
  }
  const saveEditingArea = () => {
    if (editingIndex !== null && drawRef !== null) {
      const newGeoJson = geoJson
      const featCollection = drawRef.getAll()
      if (newGeoJson) {
        newGeoJson.features[editingIndex] = featCollection
          .features[0] as turf.Feature<turf.Geometry>
        updateStatus({
          id: newGeoJson.features[editingIndex].id as string,
          wasEdited: true,
          wasDeleted: false
        } as AreaStatus)
      }
      setGeoJson(newGeoJson)
      cancelEditing()
      // calculateIntersections()
    }
  }

  const removeArea = (featId: turf.helpers.Id | undefined) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id !== featId) features.push(feat)
      })
      // setHighlightedFeatId(null)
      updateStatus({
        id: featId as string,
        wasDeleted: true,
        wasEdited: false
      })
      setEditingIndex(null)
      setGeoJson({ ...geoJson, features: features })
      setSplittingIndex(null)
      // calculateIntersections()
    }
  }
  const removeAreaByFeatureId = (featId: number) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach((feat, index) => {
        if (index != featId) features.push(feat)
      })
      updateStatus({
        id: geoJson.features[featId].id as string,
        wasDeleted: true,
        wasEdited: false
      })
      setGeoJson({ ...geoJson, features: features })
      setHighlightedFeatId(null)
    }
    // calculateIntersections()
  }

  const removeSelectedAreas = () => {
    if (!selectedAreasIds || !geoJson?.features) return
    if (!confirm(`Deseja apagar a(s) ${selectedAreasIds.length} área(s) selecionadas?`)) return
    // selectedAreasIds.map((feat: string) => removeAreaByFeatureId(+feat))
    const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] = []
    geoJson.features.forEach((feat, index) => {
      if (!selectedAreasIds.includes(index.toString())) features.push(feat)
      else {
        updateStatus({
          id: feat.id as string,
          wasDeleted: true,
          wasEdited: false
        })
      }
    })
    setSelectedAreasIds([])
    setGeoJson({ ...geoJson, features: features })
    setHighlightedFeatId(null)

    // setHighlightedFeatCoordinates(null)
  }

  const removeUnproductiveAreas = () => {
    if (geoJson?.features) {
      const features = geoJson.features.filter(feat => {
        const featProps = feat.properties
        if (featProps && featProps.productive == 1) return feat
      })
      const numRemovedAreas: number = geoJson.features.length - features.length
      if (numRemovedAreas == 0) {
        alert('Não existema áreas improdutivas para apagar!')
      } else {
        if (!confirm(`Deseja apagar a(s) ${numRemovedAreas} área(s) improdutivas?`)) return
        setGeoJson({ ...geoJson, features: features })
      }
      setHighlightedFeatId(null)
      setIsControlPressed(false)
      setSelectedAreasIds([])
    }
  }

  const mergeSelectedAreas = () => {
    if (!selectedAreasIds || !geoJson?.features) return
    if (!confirm(`Deseja juntar a(s) ${selectedAreasIds.length} área(s) selecionadas?`)) return
    const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] = []
    const relatedFeatures: turf.FeatureCollection<
      turf.Geometry | turf.GeometryCollection
    >['features'] = []
    geoJson.features.forEach((feat, index) => {
      // if (feat.id)
      if (!selectedAreasIds.includes(index.toString())) {
        features.push(feat)
      } else {
        relatedFeatures.push(feat)
        updateStatus({
          id: feat.id as string,
          wasDeleted: true,
          wasEdited: false
        })
      }
    })

    // features.map((feat, id) => {
    //   feat.id = id.toString()
    // })
    const featuresJoined: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = relatedFeatures.map(
      feat => {
        return feat as turf.Feature<turf.Polygon | turf.MultiPolygon>
      }
    )
    const index = featuresJoined[0].id

    const collection = turf.featureCollection(featuresJoined)
    const polygon = turf.convex(collection)
    if (polygon && index) {
      polygon.id = index + features.length.toString()
      // polygon.properties = relatedFeatures[0].properties
      features.push(polygon)
    }
    setSelectedAreasIds([])
    setGeoJson({
      ...geoJson,
      features: features
    })
    setHighlightedFeatId(features.length - 1)
  }

  const addSelectedAreas = (id: number) => {
    if (isControlPressed && selectedAreasIds && id !== null) {
      if (selectedAreasIds.includes(id.toString())) {
        const newAreas: string[] = selectedAreasIds.filter(
          (idArea: string) => idArea != id.toString()
        )
        setSelectedAreasIds(newAreas)
      } else {
        const newAreas: string[] = []
        selectedAreasIds.forEach(feat => newAreas.push(feat))
        newAreas.push(id.toString())
        newAreas && setSelectedAreasIds(newAreas)
      }
      setHighlightedFeatId(null)
    } else {
      setHighlightedFeatId(id)
      setSelectedAreasIds([])
    }
  }

  const handleToggleAddNewArea = () => {
    if (splittingIndex !== null || editingIndex !== null) return
    if (isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)) {
      setSelectedAreasIds([])
    }
    setHighlightedFeatId(null)
    setAddingAreaFlag(true)
    setIsAddingArea(true)
  }

  const createArea = () => {
    if (drawRef && geoJson?.features && mapRef.current) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        // feat.id = id.toString()
        features.push(feat)
      })
      const newArea = drawRef.getAll().features[0] as turf.Feature
      // newArea.type = 'Feature'
      // newArea.id = geoJson.features.length.toString()
      // newArea.properties = { productive: 1 }
      features.push({
        type: 'Feature',
        id: newArea.id,
        geometry: newArea.geometry
      } as turf.Feature)
      setGeoJson({ ...geoJson, features: features })
      cancelEditing()
      setHighlightedFeatId(features.length - 1)
    }
  }

  const splitPolygon = () => {
    if (confirm('Deseja dividir o talhão?')) {
      if (drawRef && geoJson?.features && mapRef.current) {
        const features: turf.FeatureCollection<
          turf.Geometry | turf.GeometryCollection
        >['features'] = geoJson.features.filter((feat, index) => {
          if (index != splittingIndex) return feat
        })

        // features.forEach((feat, id) => (feat.id = id.toString()))

        const splittedPolygon = drawRef.getAll().features[0]
        const featureGeometry = splittedPolygon.geometry
        if (featureGeometry.type == 'MultiPolygon') {
          featureGeometry.coordinates.forEach((coords, index) => {
            const polygon = {
              type: 'Polygon',
              coordinates: coords
            }

            features.push({
              type: 'Feature',
              geometry: polygon,
              id: splittedPolygon.id + index.toString()
              // properties: { productive: 1 }
            } as turf.Feature)
          })
          if (splittingIndex !== null)
            updateStatus({
              id: geoJson.features[splittingIndex].id as string,
              wasDeleted: true,
              wasEdited: false
            } as AreaStatus)
          setGeoJson({ ...geoJson, features: features })
          setHighlightedFeatId(features.length - 1)
        } else if (featureGeometry.type == 'Polygon') {
          features.push({
            type: 'Feature',
            geometry: featureGeometry,
            id: features.length.toString()
            // properties: { productive: 1 }
          } as turf.Feature)
          if (splittingIndex !== null)
            updateStatus({
              id: geoJson.features[splittingIndex].id as string,
              wasDeleted: true,
              wasEdited: false
            } as AreaStatus)
          setGeoJson({ ...geoJson, features: features })
          setHighlightedFeatId(features.length - 1)
        }
      }
    }
    setSplittingIndex(null)
    if (mapRef?.current && drawRef) mapRef.current.getMap().removeControl(drawRef)
    setDrawRef(null)
  }
  // useEffect for opening split area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson && splittingIndex !== null && !isAddingArea) {
      if (isSplittingArea && drawRef === null) {
        setDrawRef(draw)
        map.getMap().addControl(draw, 'top-left')
        const splittedFeature = geoJson.features[splittingIndex]
        draw.set({
          // @ts-ignore
          features: [splittedFeature],
          type: 'FeatureCollection'
        })
        if (splittedFeature) {
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: splittedFeature.id
            }
          )
          try {
            draw.changeMode('split_polygon', {
              featuresIds: [splittedFeature]
            })
            map.on('draw.update', () => setIsSplittingArea(false))
          } catch (err) {
            console.error(err)
          }
        }
      } else if (!isSplittingArea && splittingIndex !== null && drawRef !== null) {
        splitPolygon()
      }
    }
  }, [isSplittingArea])

  // useEffect for opening edit area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (editingIndex !== null && drawRef === null) {
        map.getMap().addControl(draw)
        setDrawRef(draw)
        if (geoJson.features[editingIndex]) {
          draw.set({
            // @ts-ignore
            features: [geoJson.features[editingIndex]],
            type: 'FeatureCollection'
          })
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: geoJson.features[editingIndex].id
            }
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, editingIndex, geoJson])

  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (isAddingArea && drawRef === null) {
        map.getMap().addControl(draw, 'top-left')
        setDrawRef(draw)
        draw.changeMode('draw_polygon')
        map.on('draw.create', () => setIsAddingArea(false))
      } else if (!isAddingArea && drawRef !== null && !splittingIndex) {
        if (confirm('Deseja adicionar a área criada?')) {
          createArea()
        } else cancelEditing()
        setAddingAreaFlag(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingArea])

  const pressDelete = (event: KeyboardEvent) => {
    if (event.key === 'Delete' && drawRef !== null) {
      drawRef.trash()
    } else if (event.key === 'Delete' && highlightedFeatId !== null && geoJson) {
      if (confirm('Deseja remover a área selecionada?')) {
        removeAreaByFeatureId(highlightedFeatId)
      }
    }
  }

  const pressControl = (event: KeyboardEvent) => {
    if (event.key !== 'Control') return
    setIsControlPressed(true)
  }

  const releaseControl = (event: KeyboardEvent) => {
    if (event.key !== 'Control') return
    setIsControlPressed(false)
    // if (selectedAreasIds?.length == 0) setIsControlPressed(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', pressDelete)
    window.addEventListener('keydown', pressControl)
    window.addEventListener('keyup', releaseControl)
    return () => {
      window.removeEventListener('keydown', pressDelete)
      window.removeEventListener('keydown', pressControl)
      window.removeEventListener('keyup', releaseControl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId, geoJson, drawRef])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {/* <Header
        propertyImage={propertyImage}
        propertyName={propertyName}
        handleRecoverTaskProgress={() => handleRecoverTaskProgress(propertyId)}
        handleSaveTaskProgress={handleSaveTaskProgress}></Header> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100vw',
          height: '100%',
          overflow: 'hidden'
        }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            background: 'rgba(0, 0, 0, 0.4)',
            display: loading ? 'flex' : 'none',
            zIndex: 10000,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ position: 'relative' }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: 'rgba(233, 236, 239, 0.2)'
              }}
              size={40}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: 'white',
                animationDuration: '1000ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round'
                }
              }}
              size={40}
              thickness={4}
            />
          </Box>
        </Box>
        <Box>
          <SideBar
            geoJson={geoJson}
            setGeoJson={setGeoJson}
            highlightedFeatId={highlightedFeatId}
            setHighlightedFeatId={setHighlightedFeatId}
            editingIndex={editingIndex}
            setEditingIndex={setEditingIndex}
            saveEditingArea={saveEditingArea}
            handleBack={handleBack}
            handleFinalizeEdition={handleFinalizeEdition}
            propertyImage={propertyImage}
            propertyName={propertyName}
            cancelEditing={cancelEditing}
            removeArea={removeArea}
            isControlPressed={isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)}
            addSelectedAreas={addSelectedAreas}
            selectedAreas={selectedAreasIds}
            getImage={getImageMapbox}
            removeUnproductiveAreas={removeUnproductiveAreas}
            isClickDisabled={
              (selectedAreasIds && selectedAreasIds.length > 0) ||
              splittingIndex !== null ||
              addingAreaFlag
            }></SideBar>
        </Box>

        <MapView
          geoJson={geoJson}
          mapRef={mapRef}
          onMapClick={props => {
            props.originalEvent.preventDefault()
            if (
              editingIndex === null &&
              splittingIndex === null &&
              !addingAreaFlag &&
              !isSplittingArea
            ) {
              const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
              if (id === null) {
                setHighlightedFeatId(null)
                setSelectedAreasIds([])
              } else if (isControlPressed || (selectedAreasIds && selectedAreasIds.length > 0)) {
                addSelectedAreas(id)
              } else {
                setHighlightedFeatId(id)
                if (selectedAreasIds) if (selectedAreasIds.length > 0) setSelectedAreasIds([])
              }
              // setHighlightedFeatId(id)
            }
          }}
          // onMapDblClick={props => {
          //   props.originalEvent.preventDefault()
          //   if (isControlPressed) return
          //   if (
          //     editingIndex === null &&
          //     splittingIndex == null &&
          //     !isSplittingArea &&
          //     !addingAreaFlag
          //   ) {
          //     const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
          //     setHighlightedFeatId(id)
          //     if (id) handleSwitchChange(id)
          //   }
          // }}
          editingIndex={editingIndex}
          intersections={intersections}
          intersectionsVisibility={intersectionsVisibility}
          isAddingNewArea={true}
          highlightedFeatId={highlightedFeatId}
          splittingIndex={highlightedFeatId}
          hasSelectedAreas={selectedAreasIds ? selectedAreasIds.length > 1 : false}
          handleToggleAddNewArea={handleToggleAddNewArea}
          handleToggleSplitArea={() => {
            if (editingIndex !== null) return
            setSplittingIndex(highlightedFeatId)
            setIsSplittingArea(true)
          }}
          handleDeleteSelectedAreas={removeSelectedAreas}
          handleMergeSelectedAreas={mergeSelectedAreas}
          highlightedFeatCoordinates={highlightedFeatCoordinates}
          handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}
          selectedAreasIds={selectedAreasIds}
          propertyBoundary={propertyBoundary}></MapView>
      </Box>
    </Box>
  )
}
