import { Box, BoxProps } from '@mui/material'

interface IFlexBoxProps extends BoxProps {
  vertical?: boolean
}

export const FlexBox = (props: IFlexBoxProps) => {
  const { vertical = false, children, sx, ...restProps } = props
  return (
    <Box
      sx={Object.assign(
        {
          display: 'flex',
          flexDirection: vertical ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '1.5rem'
        },
        sx
      )}
      {...restProps}>
      {children}
    </Box>
  )
}
