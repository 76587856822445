import { Add } from '@mui/icons-material'
import { IconButton, Paper } from '@mui/material'

import { PageStyle } from '../PageStyle'
import { TPaginationParams } from '../PageStyle/Body'

interface IManageRolesView {
  handleClickCreateRole: () => void
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  paginationParams: TPaginationParams
}

export const ManageRolesView = (props: IManageRolesView) => {
  const { handleClickCreateRole, children, handleSearchInputChange, paginationParams } = props

  return (
    <PageStyle.root>
      <PageStyle.header
        title="Cargos"
        subtitle="Crie e edite cargos que descrevem interações com o sistema">
        <PageStyle.SearchInput
          label="Procurar Cargos"
          placeholder="Digite um termo para aplicar um filtro"
          onChange={handleSearchInputChange}
        />
      </PageStyle.header>
      <PageStyle.body paginationParams={paginationParams}>
        <>
          {children}
          <Paper
            elevation={3}
            sx={{
              order: 1, // places it after the pagination component
              position: 'sticky',
              bottom: '20px',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #fad228'
            }}>
            <IconButton title="Criar cargo" size="small" onClick={handleClickCreateRole}>
              <Add />
            </IconButton>
          </Paper>
        </>
      </PageStyle.body>
    </PageStyle.root>
  )
}
