export const EmptyCubeIMage = () => {
  return (
    <svg width="78" height="88" viewBox="0 0 78 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3133 65.1129L15.1729 35.9199L40.0725 22.4958V51.1244L15.3133 65.1129Z"
        fill="#5C7CFA"
      />
      <g opacity="0.3">
        <path
          d="M15.3133 65.1129L15.1729 35.9199L40.0725 22.4958V51.1244L15.3133 65.1129Z"
          fill="black"
        />
      </g>
      <path
        d="M46.7196 20.4686L46.3369 49.3191L71.6532 63.0568V34.4813L46.7196 20.4686Z"
        fill="#5C7CFA"
      />
      <g opacity="0.4">
        <path
          d="M46.7196 20.4686L46.3369 49.3191L71.6532 63.0568V34.4813L46.7196 20.4686Z"
          fill="black"
        />
      </g>
      <path
        d="M15.1729 65.0742L40.305 79.4392L65.1514 65.4265L40.0677 50.7865L15.1729 65.0742Z"
        fill="#5C7CFA"
      />
      <g opacity="0.5">
        <path
          d="M15.1731 65.0746L40.3053 79.4395L65.1516 65.4268L40.0679 50.7869L15.1731 65.0746Z"
          fill="black"
        />
      </g>
      <path
        d="M40.306 79.4396L40.1606 50.2465L65.0603 36.8225V65.451L40.306 79.4396Z"
        fill="#5C7CFA"
      />
      <path
        d="M15.7012 20.45L40.8334 34.8101L65.6797 20.7974L40.596 6.15747L15.7012 20.45Z"
        fill="#5C7CFA"
      />
      <path
        d="M41.0421 79.0241C41.0421 79.0241 41.1826 78.9324 41.4636 78.7684L42.7183 78.0446C43.8276 77.4076 45.4456 76.4812 47.5238 75.299L65.0117 65.3637L64.9632 65.4505C64.9632 57.2813 64.9632 47.5004 64.939 36.822L65.1183 36.933L51.8498 44.1034L40.2235 50.3763L40.2913 50.2653C40.3349 61.1126 40.3785 71.0575 40.4124 79.4584V79.6176L40.2574 79.5308L21.975 69.0309L16.9369 66.1357L15.6241 65.3685C15.3238 65.19 15.1785 65.0935 15.1785 65.0935L15.6387 65.3395L16.966 66.0826L22.0331 68.9489L40.3591 79.3715L40.2089 79.4584C40.1605 71.0575 40.1023 61.1222 40.0442 50.2701V50.1833L40.1072 50.1495L51.7335 43.8766L65.0262 36.711L65.2103 36.6145V36.8172C65.2103 47.4956 65.2103 57.2765 65.2103 65.4457V65.5036L65.157 65.5326L47.6255 75.3907L42.7812 78.088L41.512 78.7877L41.0421 79.0241Z"
        fill="black"
      />
      <path
        d="M40.0682 50.7866C40.107 50.8445 34.5894 54.1016 27.7589 58.0583C20.9285 62.0151 15.3527 65.1708 15.3139 65.1129C15.2752 65.055 20.7928 61.7979 27.6281 57.8412C34.4634 53.8844 40.0343 50.7238 40.0682 50.7866Z"
        fill="black"
      />
      <path
        d="M8.12953 24.9279L7.74683 53.7784L33.0631 67.516V38.9405L8.12953 24.9279Z"
        fill="#5C7CFA"
      />
      <path
        d="M8.12995 24.9279L8.24137 24.9858L8.58046 25.1739L9.90781 25.9074L14.9701 28.7205L33.1168 38.8537L33.1701 38.8875V38.9502C33.1701 47.2545 33.1701 57.0596 33.1701 67.5257V67.738L32.9811 67.6366L32.7728 67.5257L7.67458 53.8845L7.61646 53.8507V53.788C7.75694 45.1266 7.87804 37.9031 7.9604 32.8317C8.00399 30.3225 8.0379 28.3442 8.06212 26.9883C8.06212 26.3224 8.07182 25.8109 8.0912 25.4538C8.0912 25.2898 8.0912 25.1595 8.0912 25.0678C8.08696 25.0245 8.08696 24.9808 8.0912 24.9375V25.4828C8.0912 25.8447 8.0912 26.3658 8.0912 27.0413C8.0912 28.4069 8.06213 30.3949 8.03791 32.9234C7.98946 37.9707 7.9168 45.1652 7.82476 53.788L7.77148 53.6915L32.8843 67.3037L33.0926 67.4147L32.9085 67.5257C32.9085 57.0596 32.9085 47.2594 32.9085 38.9502L32.9666 39.0467L14.9556 28.8508L9.89328 25.9556L8.5514 25.1932L8.20261 24.9906C8.17673 24.9717 8.15243 24.9507 8.12995 24.9279V24.9279Z"
        fill="#263238"
      />
      <path
        d="M3.03805 56.6056C2.97023 56.6056 2.91211 49.6764 2.91211 41.1356C2.91211 32.5949 2.97023 25.6609 3.03805 25.6609C3.10587 25.6609 3.16401 32.5852 3.16401 41.1356C3.16401 49.6861 3.11072 56.6056 3.03805 56.6056Z"
        fill="#263238"
      />
      <path
        d="M0.625956 49.8312C0.558136 49.8312 0.5 42.902 0.5 34.3612C0.5 25.8204 0.558136 18.8865 0.625956 18.8865C0.693776 18.8865 0.751898 25.8156 0.751898 34.3612C0.751898 42.9068 0.693776 49.8312 0.625956 49.8312Z"
        fill="#263238"
      />
      <path
        d="M42.9798 0C42.9092 0.0584846 42.831 0.107181 42.7472 0.144761L42.069 0.501832L39.5403 1.79984C37.4088 2.88553 34.4635 4.39585 31.2275 6.09436C27.9915 7.79287 25.0704 9.35144 22.9632 10.4854L20.4587 11.8268L19.7756 12.1839C19.6986 12.2306 19.6155 12.2663 19.5286 12.2901C19.5978 12.2318 19.6724 12.1801 19.7514 12.1356L20.4102 11.74C20.9915 11.4022 21.8296 10.9197 22.876 10.331C24.9639 9.15843 27.8704 7.56608 31.1113 5.8724C34.3521 4.17871 37.312 2.6877 39.4677 1.6406C40.5431 1.11465 41.4054 0.675543 42.0303 0.414977L42.7327 0.0965046C42.8121 0.0573607 42.8948 0.0250752 42.9798 0V0Z"
        fill="#263238"
      />
      <path
        d="M36.8801 1.50562C36.8093 1.56 36.733 1.6069 36.6525 1.64555L35.9694 2.00745L33.4407 3.30063C31.3044 4.39114 28.359 5.89664 25.123 7.59515C21.887 9.29366 18.9659 10.8571 16.8587 11.991L14.359 13.3276L13.6711 13.6847C13.5945 13.7321 13.5113 13.7679 13.4241 13.7908C13.4928 13.7333 13.5676 13.6832 13.6469 13.6413L14.3106 13.2456C14.8871 12.903 15.73 12.4205 16.7715 11.8318C18.8594 10.6592 21.7659 9.07169 25.0068 7.37318C28.2476 5.67468 31.2026 4.18848 33.3583 3.14139C34.4386 2.61544 35.296 2.17633 35.921 1.92059L36.6234 1.59729C36.7044 1.55539 36.7908 1.52456 36.8801 1.50562Z"
        fill="#263238"
      />
      <path
        d="M75.2038 67.554C75.136 67.554 75.0779 62.2462 75.0779 55.6645C75.0779 49.0827 75.136 43.7749 75.2038 43.7749C75.2716 43.7749 75.3298 49.0827 75.3298 55.6645C75.3298 62.2462 75.2765 67.554 75.2038 67.554Z"
        fill="#263238"
      />
      <path
        d="M77.3742 71.0871C77.3064 71.0871 77.2483 65.7793 77.2483 59.2024C77.2483 52.6255 77.3064 47.3129 77.3742 47.3129C77.4421 47.3129 77.5002 52.6207 77.5002 59.2024C77.5002 65.7841 77.4469 71.0871 77.3742 71.0871Z"
        fill="#263238"
      />
      <path
        d="M60.0069 73.4261C60.0408 73.484 55.899 76.0221 50.7592 79.091C45.6194 82.1599 41.4194 84.6015 41.3855 84.5436C41.3516 84.4857 45.4886 81.9475 50.6284 78.8787C55.7682 75.8098 59.9682 73.3682 60.0069 73.4261Z"
        fill="#263238"
      />
      <path
        d="M58.6315 76.8815C58.6702 76.9394 54.5284 79.4775 49.3886 82.5464C44.2488 85.6153 40.0488 88.0569 40.01 87.999C39.9713 87.9411 44.0986 85.4078 49.2384 82.3341C54.3782 79.2604 58.5782 76.8236 58.6315 76.8815Z"
        fill="#263238"
      />
    </svg>
  )
}
