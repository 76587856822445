import { buildAxiosErrorMessage } from '@/utils/buildAxiosErrorMessage'
import * as Sentry from '@sentry/react'
import axios from 'axios'

const API_VERSION = 'v2'

export const axiosGisSector = axios.create({
  timeout: 20000,
  baseURL: `${process.env.REACT_APP_URL_GIS_API}/sector/api/${API_VERSION}`,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosGisSector.interceptors.response.use(undefined, error => {
  const errorMessage = buildAxiosErrorMessage(error, 'Gis Sector Error')
  console.error(errorMessage)
  Sentry.captureException(errorMessage)
})

export const axiosGisMisc = axios.create({
  timeout: 20000,
  baseURL: `${process.env.REACT_APP_URL_GIS_API}/misc/api/${API_VERSION}`,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosGisSector.interceptors.response.use(undefined, error => {
  const errorMessage = buildAxiosErrorMessage(error, 'Gis Misc Error')
  console.error(errorMessage)
  Sentry.captureException(errorMessage)
})
