import { FlexBox } from '@components'

import { renderEmptyState } from '@shared'

import { useEnvironmentalAssessment } from '../hooks/useEnvironmentalAssessment'
import { EnvironmentalTable } from './EnvironmentalTable'

interface IComplianceFiscalProps {
  personProfileId: string
}

export const ComplianceEnvironmental = (props: IComplianceFiscalProps) => {
  const { personProfileId } = props

  const { response, isRequesting, error } = useEnvironmentalAssessment({ personProfileId })

  return (
    <FlexBox vertical>
      {renderEmptyState(isRequesting, error) || (
        <EnvironmentalTable
          environmentalDebt={response?.debts ? [response.debts] : []}
          environmentalEmbargo={response?.embargoes ? [response.embargoes] : []}
        />
      )}
    </FlexBox>
  )
}
