import { TMapLegend } from '../../../../../components/EditAreasMap/MapLegend'

export enum MODAL_OPTIONS {
  PERMITTED_INTERSECTION,
  FORBIDDEN_INTERSECTION,
  NO_INTERSECTION
}

export type IModalOptionInfo = {
  subtitle: string
  ConfirmMessageTitle?: string
  ConfirmMessageBody?: string
  legendValues: TMapLegend
}

export function getModalOptions(option: MODAL_OPTIONS): IModalOptionInfo {
  const legendValues = [
    {
      label: 'Talhão em edição',
      color: '#FFE600',
      dashed: true
    },
    {
      label: 'Talhões impactados',
      color: '#C92A2A',
      dashed: false
    },
    {
      label: 'Interseção',
      color: '#6C1891',
      dashed: false
    }
  ]

  switch (option) {
    case MODAL_OPTIONS.PERMITTED_INTERSECTION:
      return {
        subtitle: 'A edição deste talhão irá sobrepor os talhões abaixo:',
        ConfirmMessageTitle: 'Deseja salvar mesmo assim?',
        ConfirmMessageBody:
          'Caso deseje salvar mesmo assim o talhão sobreposto irá ser cortado de acordo com a geometria do talhão em edição. ',
        legendValues: legendValues
      }
    case MODAL_OPTIONS.FORBIDDEN_INTERSECTION:
      return {
        subtitle: 'Você não pode salvar sua edição pois ela sobrepõe talhões já validados.',
        ConfirmMessageBody:
          'Caso deseje continuar, o talhão em edição não deve conter interseção com nenhum talhão validado.',
        legendValues: legendValues
      }
    case MODAL_OPTIONS.NO_INTERSECTION:
      return {
        subtitle: 'Confirme a sua edição.',
        ConfirmMessageTitle: 'Deseja salvar sua edição?',
        legendValues: [legendValues[0]]
      }
  }
}
