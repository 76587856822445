import { SVGProps } from 'react'

export const TarkenSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={38.209}
    height={39.385}
    viewBox="0 0 38.209 39.385"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      style={{
        fill: '#fecb00',
        strokeWidth: 0.32
      }}
      d="m12.576 34.997-6.262-4.388-3.157-7.925L0 14.76l.3-.287.299-.288 8.96-6.931 8.96-6.931.374-.161.374-.161 9.386 7.254 9.386 7.253.085.227.085.227-3.164 7.824-3.165 7.824-6.2 4.388-6.201 4.388h-.64l-6.263-4.388zm7.383-.017v-1.23l2.968-2.09 2.968-2.089 1.112-.232 1.112-.233.716-.454.717-.454 1.434-2.137 1.434-2.137-.099-.1-.099-.098-.932.3-.931.3-1.829.507-1.828.507-.973 1.413-.972 1.413-2.32 1.635-2.318 1.634-.087-6.855-.086-6.854.994-2.679.993-2.678-.003-.96-.003-.96-1.157-3.172-1.157-3.17-.276-.494-.276-.493-.456 1.104-.456 1.105-1.078 3.064-1.077 3.064 1.198 3.362 1.198 3.363-.096 2.135-.095 2.135-1.809-1.285-1.809-1.284-.995-1.55-.995-1.552-.623-.564-.624-.564-3.275-.913-3.275-.913-.129.129-.129.128 2.13 3.055 2.13 3.055 1.181.306 1.182.306 1.28.34 1.28.34 2.32 1.624 2.32 1.624V36.209h1.6zm-2.88-3.232v-.942l-1.936-1.49-1.936-1.49-.83-1.252-.83-1.254-1.634-.456-1.634-.457-1.2-.313-1.2-.314v.277l1.469 2.209 1.469 2.21.85.335.852.336.953.224.953.224 2.087 1.524 2.087 1.524.24.023.24.023zm6.008-9.284 1.305-.946 1.953-.506 1.953-.506.732-.333.731-.334 1.973-2.914 1.973-2.915-.094-.093-.094-.094-3.02.802-3.02.803-.575.177-.575.176-1.424 2.134-1.424 2.134-1.121.761-1.121.761v1.838h.544z"
    />
  </svg>
)
