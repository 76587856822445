import { useParams, useNavigate } from 'react-router-dom'

import { Map } from './components/map/MapContainer'

export const EditAreasView = () => {
  const { property_id } = useParams()
  const navigate = useNavigate()
  if (property_id === undefined) {
    navigate('/bender/')
  }

  return (
    <>
      <Map propertyId={property_id ? property_id : ''} closeMap={() => navigate('/bender')} />
    </>
  )
}
