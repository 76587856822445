import { Box, colors, Typography } from '@mui/material'
import { FileObject } from 'react-mui-dropzone'

import { humanFileSize } from '@shared'

export const CustomDropzoneContent = ({ file }: { file?: FileObject }) => {
  if (file)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
        <Typography variant="body2">Arquivo selecionado</Typography>
        <Typography variant="body1" color={colors.indigo[700]}>
          {file.file.name}
        </Typography>
        <Typography variant="caption">tamanho: {humanFileSize(file.file.size)}</Typography>
      </Box>
    )
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body1">
        Arraste o arquivo PDF aqui ou clique para fazer upload.
      </Typography>
    </Box>
  )
}
