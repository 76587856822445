import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material'
import * as turf from '@turf/turf'
import { useEffect, useState } from 'react'

import {
  AreasChart,
  CHART_MODE,
  TArea,
  TData
} from '../../../../../components/AreasChart/AreasChartContainer'
import { LatLongCopyButton } from '../../../../../components/LatLongCopyButton/LatLongCopyButton'
import { getOrderedColor } from '../../../../../shared/constants/orderedColors'
import { TLandField } from '../../EditSeasons'
import { ModalSidebar } from './components/ModalSidebar'

interface IShareSeasonsModal {
  open: boolean
  data: TData[]
  onClose: () => void
  propertyAreas: TLandField[]
  originAreaId: string
  areas: TArea[]
  shareSeasonsBetweenAreas: (donorAreaId: string, recipientAreasIds: string[]) => void
  selectedAreaCoordinates: turf.helpers.Position | null
  ndviCatalog: Record<string, TData[]>
}

export const ShareSeasonsModal = ({
  open,
  onClose,
  propertyAreas,
  originAreaId,
  areas,
  data,
  shareSeasonsBetweenAreas,
  selectedAreaCoordinates,
  ndviCatalog
}: IShareSeasonsModal) => {
  const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([])
  const [mode, setMode] = useState(CHART_MODE.VISUALIZATION)
  const [lines, setLines] = useState<{ id: string; color: string; data: TData[] }[]>([])
  const [hideSeasonAreas, setHideSeasonAreas] = useState(false)

  useEffect(() => {
    if (Object.keys(ndviCatalog).length === 0) return

    setLines(
      Object.entries(ndviCatalog).map(([key, value], index) => ({
        id: key,
        color: getOrderedColor(index),
        data: value
      }))
    )
  }, [ndviCatalog])

  const addToSelectedAreas = (id: string) => {
    const indexOfId = selectedAreaIds.indexOf(id)
    if (indexOfId < 0) setSelectedAreaIds(oldArray => [...oldArray, id])
    else setSelectedAreaIds(oldArray => oldArray.filter(element => element !== id))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5">Replicar safras</Typography>
          <Typography variant="subtitle1">
            Selecione os talhões com safras similares para replicar a análise
          </Typography>
        </Box>
        <IconButton sx={{ width: '1.5em', height: '1.5em' }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          marginBottom: '0.5rem',
          justifyContent: 'right',
          display: 'flex',
          marginRight: '0.5rem'
        }}>
        {selectedAreaCoordinates ? (
          <LatLongCopyButton coordinates={selectedAreaCoordinates} />
        ) : null}
      </Box>

      <DialogContent sx={{ display: 'flex' }}>
        <ModalSidebar
          areas={propertyAreas}
          originAreaId={originAreaId}
          selectedAreas={selectedAreaIds}
          addToSelectedAreas={addToSelectedAreas}
          colors={lines.map(line => {
            return { id: line.id, value: line.color }
          })}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}>
          <FormControlLabel
            control={
              <Switch checked={hideSeasonAreas} onChange={() => setHideSeasonAreas(old => !old)} />
            }
            label="Esconder safras"
            labelPlacement="start"
          />
          <AreasChart
            data={data}
            areas={areas}
            setAreas={() => {
              return
            }}
            selectedAreas={[]}
            setSelectedAreas={() => {
              return
            }}
            mode={mode}
            disableEditing
            setMode={setMode}
            lines={lines.filter(line => selectedAreaIds.indexOf(line.id) >= 0)}
            height="40vh"
            width="60vw"
            hideAreas={hideSeasonAreas}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="discreet"
          color="info"
          onClick={() => {
            if (confirm('Realmente deseja replicar as áreas?')) {
              shareSeasonsBetweenAreas(originAreaId, selectedAreaIds)
              onClose()
              setSelectedAreaIds([])
            }
          }}>
          Replicar
        </Button>
        <Button
          variant="discreet"
          onClick={() => {
            onClose()
            setSelectedAreaIds([])
          }}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
