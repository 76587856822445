import { MemberInformationAvatar } from './MemberInformationAvatar'
import { MemberInformationContent } from './MemberInformationContent'
import { MemberInformationRoot } from './MemberInformationRoot'
import { MemberInformationSystemRoles } from './MemberInformationSytemRoles'

export const MemberInformation = {
  root: MemberInformationRoot,
  avatar: MemberInformationAvatar,
  information: MemberInformationContent,
  roles: MemberInformationSystemRoles
}
