import { ShortOrganizationDTO } from '@services'
import { Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'

interface IOrgSelect {
  selectedOrg: string | null
  isLoadingOrgs: boolean
  setOrganizationId: Dispatch<SetStateAction<string | null>>
  organizations: ShortOrganizationDTO[] | undefined
  placeholder?: string
  width?: string
}

export const OrgSelect = (props: IOrgSelect) => {
  const { selectedOrg, isLoadingOrgs, setOrganizationId, organizations, placeholder, width } = props

  return (
    <Select
      style={{ width: width ?? '100%', minHeight: 0 }}
      showSearch
      loading={isLoadingOrgs}
      disabled={isLoadingOrgs}
      placeholder={placeholder ?? 'Selecione uma organização'}
      optionFilterProp="children"
      onChange={setOrganizationId}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      value={selectedOrg}
      options={organizations?.map(({ id, fantasyName, corporateName }) => ({
        label: fantasyName || corporateName,
        value: id
      }))}
    />
  )
}
