import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import { makeStyles } from '@mui/styles'
import * as turf from '@turf/turf'

import { EditableFeature } from './components/editableFeature'
import { NotEditableFeature } from './components/notEditableFeature'

const useStyles = makeStyles(() => ({
  saveButton: {
    padding: '5px 10px',
    border: 'none',
    backgroundColor: '#808080',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export type TAreasListView = {
  geoJson: turf.FeatureCollection
  highlightedFeatId: string | null
  handleListItemClick: (id: string) => void
  selectedRef: React.RefObject<HTMLElement>
  editingIndex: number | null
  saveEditingArea: () => void
  cancelEditing: () => void
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  handleSwitchChange: (index: number) => void
  removeArea: (index: number) => void
  getImage: (geometry: turf.Geometry) => string
  isFeatureEditable: (feature: turf.Feature) => boolean
  multipleSelectionMode: boolean
}

export const AreasListView = ({
  geoJson,
  highlightedFeatId,
  handleListItemClick,
  selectedRef,
  editingIndex,
  saveEditingArea,
  cancelEditing,
  setEditingIndex,
  handleSwitchChange,
  removeArea,
  getImage,
  isFeatureEditable,
  multipleSelectionMode
}: TAreasListView) => {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Typography
        sx={{
          fontFamily: 'Arial',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '21px',
          lineHeight: '25px',
          color: '#212529',
          paddingTop: '18px',
          paddingLeft: '18px'
        }}>
        Todos os Talhões
      </Typography>
      <List
        component="nav"
        //style={{ maxHeight: '100%', overflow: 'auto'}}
      >
        {geoJson &&
          geoJson.features.map((feature, index) => {
            const isProductive = feature?.properties?.productive === 1
            const isEditable = isFeatureEditable === undefined || isFeatureEditable(feature)
            if (isEditable && !multipleSelectionMode)
              return (
                <EditableFeature
                  key={feature.id}
                  isSelected={highlightedFeatId == feature.id}
                  isProductive={isProductive}
                  id={feature.id ? feature.id.toString() : undefined}
                  imageUrl={getImage(feature.geometry as turf.Geometry)}
                  index={index}
                  areaInM2={turf.area(feature.geometry as turf.Geometry)}
                  handleListItemClick={handleListItemClick}
                  selectedRef={selectedRef}
                  saveEditingArea={saveEditingArea}
                  setEditingIndex={setEditingIndex}
                  cancelEditing={cancelEditing}
                  removeArea={removeArea}
                  handleSwitchChange={handleSwitchChange}
                  isEditable={isEditable}
                  editingIndex={editingIndex}
                  geometry={feature.geometry as turf.Geometry}
                />
              )
            return (
              <NotEditableFeature
                key={feature.id}
                isSelected={highlightedFeatId == feature.id}
                isProductive={isProductive}
                id={feature.id ? feature.id.toString() : undefined}
                imageUrl={getImage(feature.geometry as turf.Geometry)}
                index={index}
                areaInM2={turf.area(feature.geometry as turf.Geometry)}
                handleListItemClick={handleListItemClick}
                geometry={feature.geometry as turf.Geometry}
                selectedRef={selectedRef}
                isValidated={!isEditable}
              />
            )
          })}
      </List>
    </Box>
  )
}
