import axios from 'axios'

const BASE_URL = process.env.REACT_APP_URL_SOLUTIONS_API //https://zd19myt97h.execute-api.us-east-1.amazonaws.com/dev/api https://vl2syq0ce9.execute-api.us-east-1.amazonaws.com/prod/api

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: false
})

export default api
