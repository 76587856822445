import { CreateEnvironmentalEmbargoDTO } from '@/services/Hub/AssessmentsEnvironmental'
import { ENVIRONMENTAL_EMBARGO_STATUS } from '@/services/Hub/enums/EnvironmentalAssessment.enum'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildCreateEnvironmentalEmbargoDTO {
  record: IRecordCandidate
  documentUrl?: string
  canonicalProfileId: string
}

export const buildCreateEnvironmentalEmbargoDTO = (
  props: IBuildCreateEnvironmentalEmbargoDTO
): CreateEnvironmentalEmbargoDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  return {
    canonicalProfileId,
    creationType: 'USER_INPUT',
    referenceDate: new Date().toISOString(),
    status: record.documentStatus as ENVIRONMENTAL_EMBARGO_STATUS,
    documentUrl,
    expirationDate: record.documentExpirationDate.toISOString()
  }
}
