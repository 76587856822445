import { AuthorizationForm } from '@/lib/AuthorizationForm'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { ErrorPage } from '@components'
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  colors
} from '@mui/material'
import { HubApi, ShortOrganizationDTO } from '@services'
import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'

import { useFuzzyFilter } from '../../../../utils/hooks/useFuzzyFilter'
import { TData } from '../../utils/authorization.utils'
import { ManageRolesView } from './ManageRolesView'
import { RoleCard } from './components/RoleCard'

interface IManageRolesContainer {
  refreshCurrentOrgRoles: () => Promise<void>
  selectedOrganization: ShortOrganizationDTO
  organizationRoles: TData<RoleDTO[]>
}

const MAX_ITEMS_PER_PAGE = 15

export const ManageRolesContainer = (props: IManageRolesContainer) => {
  const { organizationRoles, refreshCurrentOrgRoles, selectedOrganization } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState<RoleDTO | undefined>(undefined)
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()

  const [currentPage, setCurrentPage] = useState(1)
  const sliceProps = {
    start: (currentPage - 1) * MAX_ITEMS_PER_PAGE,
    end: (currentPage - 1) * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE
  }

  const { getFilteredData } = useFuzzyFilter({
    data: organizationRoles.data,
    searchKeys: ['label', 'description']
  })

  const filterResults = useMemo(() => {
    const dataResults = getFilteredData(searchInput)

    return {
      data: dataResults,
      length: dataResults.length
    }
  }, [searchInput, getFilteredData])

  const deleteRole = async (roleId: string, onFailed?: () => void): Promise<void> => {
    try {
      await HubApi.Authorization.deleteRole(roleId)
      await refreshCurrentOrgRoles()
      enqueueSnackbar('Cargo deletado com sucesso', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Erro ao deletar cargo', { variant: 'error' })
      onFailed?.()
    }
  }

  const handleSearchValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value)
      setCurrentPage(1)
    },
    [setSearchInput]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearchValueChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(event), 300),
    [handleSearchValueChange]
  )

  const onSuccessfulSubmission = useCallback(
    (role: RoleDTO) => {
      refreshCurrentOrgRoles()
      setSelectedRole(role)
    },
    [refreshCurrentOrgRoles]
  )

  const handleClickEditRole = (role: RoleDTO) => {
    setSelectedRole(role)
    setIsModalOpen(true)
  }

  const handleClickCreateRole = () => {
    setSelectedRole(undefined)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedRole(undefined)
  }

  if (organizationRoles.loading) {
    return <CircularProgress />
  }

  if (!organizationRoles.data) {
    return <ErrorPage />
  }

  //todo(maiaatala): implement a way to assign members to roles using the extraTabs and a children component
  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            background: '#fff',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <AuthorizationForm
            organizationId={selectedOrganization.id}
            selectedRole={selectedRole}
            onSubmissionCall={{
              onSuccessfulSubmission
            }}
            appBar={
              <Box
                sx={{
                  bgcolor: colors.grey[900],
                  height: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  padding: '0'
                }}>
                <Typography variant="h6" sx={{ color: '#fff' }}>
                  Organização: {selectedOrganization.fantasyName}
                </Typography>
              </Box>
            }
            headerButtons={
              <Tooltip title="Voltar" arrow disableInteractive>
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    border: '1px solid black'
                  }}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            }
          />
        </Box>
      </Modal>

      <ManageRolesView
        handleClickCreateRole={handleClickCreateRole}
        handleSearchInputChange={debounceHandleSearchValueChange}
        paginationParams={{
          count: Math.ceil(filterResults.length / MAX_ITEMS_PER_PAGE),
          page: currentPage,
          onChange: (e, page: number) => setCurrentPage(page)
        }}>
        <>
          {filterResults.data.slice(sliceProps.start, sliceProps.end).map(role => (
            <RoleCard
              key={role.id}
              role={role}
              deleteRole={deleteRole}
              handleClickEditRole={handleClickEditRole}
            />
          ))}
        </>
      </ManageRolesView>
    </>
  )
}
