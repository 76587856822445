// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildAxiosErrorMessage(error: any, tag = 'Error') {
  const sentryError = new Error()
  sentryError.name = `Axios error: ${tag}`
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    sentryError.message = `[${tag}] response: ${JSON.stringify(error.response)}`
    return sentryError
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    sentryError.message = `[${tag}] request: ${JSON.stringify(error.request)}`
    return sentryError
  }
  // Something happened in setting up the request that triggered an Error
  sentryError.message = `[${tag}] unknown: ${JSON.stringify(error.message)}`
  return sentryError
}
