import { Box, TextField } from '@mui/material'
import { Field, FieldProps } from 'formik'

interface fieldType {
  label: string
  description: string
}

const validateLabel = (value: string) => {
  let error
  if (!value) {
    error = 'O nome do cargo é obrigatório'
  }
  return error
}

export const LabelAndDescriptionField = () => {
  return (
    <Box
      className="role-label-and-description"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        '& .MuiFormControl-root': {
          width: '90%'
        }
      }}>
      <Field name={'label'} validate={validateLabel} required fullWidth>
        {({ field, meta }: FieldProps<string, fieldType>) => (
          <TextField
            {...field}
            label="Nome do cargo*"
            size="small"
            variant="standard"
            placeholder='Insira o nome do cargo, Ex: "Diretor Financeiro"'
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      </Field>
      <Field name={'description'}>
        {({ field, meta }: FieldProps<string, fieldType>) => (
          <TextField
            {...field}
            label="descrição do cargo"
            size="small"
            placeholder='Insira a descrição do cargo, Ex: "Responsável aprovar propostas"'
            variant="standard"
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      </Field>
    </Box>
  )
}
