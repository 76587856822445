import { Paper, PaperProps } from '@mui/material'

interface IOrganizationSelectCard extends PaperProps {
  children: React.ReactNode
}

export const OrganizationSelectCard = (params: IOrganizationSelectCard) => {
  const { children, ...props } = params

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        padding: '16px',

        width: '600px',

        borderRadius: '16px',
        background: 'white'
      }}
      {...props}>
      {children}
    </Paper>
  )
}
