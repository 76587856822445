import { buildAxiosErrorMessage } from '@/utils/buildAxiosErrorMessage'
import * as Sentry from '@sentry/react'
import axios from 'axios'

import { store } from '../../redux/store'

export const axiosRadar = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP_URL_RADAR_API,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosRadar.interceptors.request.use(async req => {
  const { authenticator } = store.getState()
  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${authenticator.user.token}`
  }
  return req
})

axiosRadar.interceptors.response.use(undefined, error => {
  const errorMessage = buildAxiosErrorMessage(error, 'Radar Error')
  console.error(errorMessage)
  Sentry.captureException(errorMessage)
})
