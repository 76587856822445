/**
 * This function normalizes a given string, removing any diacritical marks (accents).
 *
 * @param {string} str - The string to be normalized.
 * @returns {string} The normalized string.
 *
 * @example
 * const str = 'café';
 * console.log(normalizeString(str)); // Output: cafe
 */
export function normalizeString(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}
