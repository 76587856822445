import { Box, Typography } from '@mui/material'

interface IHeader {
  title: string
  subtitle?: string
  children?: React.ReactNode
}

export const Header = (params: IHeader) => {
  const { title, subtitle, children } = params
  return (
    <Box
      className="header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '60px',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
      <Box
        className="title-and-subtitle"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}>
        <Typography variant="h4">{title}</Typography>
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </Box>
      {children}
    </Box>
  )
}
