import { Box, colors, Typography } from '@mui/material'
import { DropzoneAreaBase, DropzoneAreaBaseProps } from 'react-mui-dropzone'

const defaultContent = (
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="body1">Arraste arquivos aqui ou clique para fazer o upload.</Typography>
  </Box>
)

interface IDropzoneProps extends DropzoneAreaBaseProps {
  content?: JSX.Element
}

export const Dropzone = (props: IDropzoneProps) => {
  const { content = defaultContent, ...restProps } = props

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        borderRadius: '0.5rem',
        border: `2px dashed ${colors.grey[300]}`,
        '.MuiDropzoneArea-root': {
          opacity: 0
        },
        '.MuiDropzoneArea-textContainer': {
          display: 'none'
        }
      }}>
      <DropzoneAreaBase {...restProps} />
      <Box
        sx={{
          pointerEvents: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        {content}
      </Box>
    </Box>
  )
}
