import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'

import { LutienApi, TUser } from '../services/LutienApi'

type TUserAction = {
  email: string
  password: string
}

export const login = createAsyncThunk('authenticator/login', async (action: TUserAction) => {
  const { token } = await LutienApi.login(action.email, action.password)
  const info = await LutienApi.getUserInfo(token)
  return { token, info: { email: info.email, name: info.name } }
})

const emptyState: { token: string; info: Pick<TUser, 'email' | 'name'> } = {
  token: '',
  info: {
    // createdAt: new Date(0),
    email: '',
    // govRegType: undefined,
    // id: '',
    // memberships: undefined,
    name: ''
    // phoneNumber: '',
    // updatedAt: new Date(0),
  }
}

export const authenticatorSlice = createSlice({
  name: 'authenticator',
  initialState: {
    user: (() => {
      const cachedUser = Cookies.get('user')
      const cachedUserParse = cachedUser
        ? (JSON.parse(cachedUser) as typeof emptyState)
        : emptyState
      Sentry.setUser({ email: cachedUserParse.info.email })
      return cachedUserParse
    })()
  },
  reducers: {
    logout: state => {
      state.user = emptyState
      Cookies.set('user', '')
      Sentry.setUser(null)
    }
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      Sentry.setUser({ email: action.payload.info.email })
      state.user = action.payload
      Cookies.set('user', JSON.stringify(action.payload))
    })
  }
})

export const { logout } = authenticatorSlice.actions

export const authenticatorReducer = authenticatorSlice.reducer
