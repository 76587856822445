import { BenderSearchTasksRequest } from '@/services/Bender/Task/contract/request/BenderSearchTasks.request'
import {
  BenderSearchTasksResponse,
  TBenderInputs
} from '@/services/Bender/Task/contract/response/BenderSearchTasks.response'
import { BenderTaskAttributesResponse } from '@/services/Bender/Task/contract/response/BenderTaskAttributes.response'
import { BenderApi, BENDER_TASK_STATUS, BENDER_TASK_TYPE, LutienApi } from '@services'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { getComplianceTaskType } from '../Compliance/helpers/getComplianceTaskType'
import {
  TaskTableData,
  ReviewTasksView,
  TAutocompleteOption,
  TTableFilter
} from './ReviewTasksView'

function createTaskTableData({
  index,
  taskId,
  status,
  type,
  createdAt,
  PersonId,
  PersonName,
  PropertyId,
  PropertyName,
  OrganizationId,
  OrganizationName,
  collector
}: TaskTableData): TaskTableData {
  return {
    index,
    taskId,
    status,
    type,
    createdAt,
    PersonId,
    PersonName,
    PropertyId,
    PropertyName,
    OrganizationId,
    OrganizationName,
    collector
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const uniqueAndNotNull = (
  value: TAutocompleteOption,
  index: number,
  self: TAutocompleteOption[]
) => {
  return index === self.findIndex(t => t.value === value.value) && !!value.value
}

export const ReviewTasks = () => {
  const [order, setOrder] = React.useState<Order>('desc')
  const [isFetchingList, setIsFetchingList] = React.useState(true)
  const [orderBy, setOrderBy] = React.useState<keyof TaskTableData>('createdAt')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rows, setRows] = React.useState<TaskTableData[]>([])
  const [dataResponse, setDataResponse] = React.useState<
    BenderSearchTasksResponse<TBenderInputs['All']>['tasks']
  >([])
  const [selectedRow, setSelectedRow] = React.useState<number>(-1)
  const [totalRows, setTotalRows] = React.useState<number>(0)
  const [organizationList, setOrganizationList] = React.useState<TAutocompleteOption[]>([])
  const [profileList, setProfileList] = React.useState<TAutocompleteOption[]>([])
  const [propertyList, setPropertyList] = React.useState<TAutocompleteOption[]>([])
  const [taskList, setTaskList] = React.useState<BenderTaskAttributesResponse[]>([])

  const navigate = useNavigate()

  const LIMIT_PER_PAGE = 10

  const returnOrganizationsList = async () => {
    const distinctTasksOrgIds = taskList
      .reduce((ids: string[], attributes) => {
        if (!attributes.organization.organizationName && attributes.organization.organizationId)
          ids.push(attributes.organization.organizationId)
        return ids
      }, [])
      .filter((value, index, self) => self.indexOf(value) === index)
    const distinctTasksOrgNames = Object.create(null)

    await Promise.all(
      distinctTasksOrgIds.map(async id => {
        const organizationName = await getOrganizationName(id).catch(err => console.error(err))
        distinctTasksOrgNames[id] = organizationName || id
      })
    )

    const returnValue = taskList
      .map(attributes => {
        const autocompleteOption: TAutocompleteOption = {
          label: attributes.organization.organizationName,
          value: attributes.organization.organizationId
        }

        if (!autocompleteOption.label) {
          autocompleteOption.label = distinctTasksOrgNames[autocompleteOption.value]
        }
        return autocompleteOption
      })
      .filter(uniqueAndNotNull)

    setOrganizationList(returnValue)
  }

  const returnProfileList = async () => {
    const returnValue = taskList
      .map(attributes => {
        const autocompleteOption: TAutocompleteOption = {
          label: attributes.personProfile.personProfileName,
          value: attributes.personProfile.personProfileId
        }
        return autocompleteOption
      })
      .filter(uniqueAndNotNull)

    setProfileList(returnValue)
  }

  const returnPropertyList = async () => {
    const returnValue = taskList
      .map(attributes => {
        const autocompleteOption: TAutocompleteOption = {
          label: attributes.property.propertyName,
          value: attributes.property.propertyId
        }
        return autocompleteOption
      })
      .filter(uniqueAndNotNull)

    setPropertyList(returnValue)
  }

  const getTaskList = async () => {
    const benderTaskList = await BenderApi.task.getAttributes()
    setTaskList(benderTaskList)
  }

  React.useEffect(() => {
    getTaskList()
  }, [page])

  React.useEffect(() => {
    returnOrganizationsList()
    returnProfileList()
    returnPropertyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskList])

  const getOrganizationName = async (orgId: string): Promise<string> => {
    const orgInfo = await LutienApi.getOrganizationInfo(orgId)
    if (!orgInfo) return '#OrganizationName'
    return orgInfo.fantasyName
  }

  const getBenderTasksList = React.useCallback(
    async (
      setIsFetching = true,
      tableFilter: TTableFilter,
      rowsPerPageP?: number,
      pageP?: number,
      orderP?: string
    ) => {
      setIsFetchingList(setIsFetching)

      const searchTasksBody: BenderSearchTasksRequest = {
        filter: {
          inputArrays: {},
          searchType: '_REVIEW' // get only tasks whose 'type' has the _REVIEW suffix
        },
        fields: ['input', 'createdAt', 'updatedAt', 'status', 'type'],
        page: (pageP || 0) + 1,
        limit: rowsPerPageP || LIMIT_PER_PAGE,
        sortAttribute: 'createdAt',
        sortOrder: (orderP || 'DESC').toUpperCase()
      }

      if (tableFilter.searchValue) {
        searchTasksBody.filter.searchValue = tableFilter.searchValue
      }

      if (tableFilter.type.length) {
        searchTasksBody.filter.types = [...tableFilter.type]
      }

      if (searchTasksBody.filter.inputArrays) {
        if (tableFilter.profileIds.length) {
          searchTasksBody.filter.inputArrays.PersonIds = [...tableFilter.profileIds]
        }
        if (tableFilter.organizationIds.length) {
          searchTasksBody.filter.inputArrays.OrganizationIds = [...tableFilter.organizationIds]
        }
        if (tableFilter.propertyIds.length) {
          searchTasksBody.filter.inputArrays.PropertyIds = [...tableFilter.propertyIds]
        }
        if (tableFilter.status.length) {
          searchTasksBody.filter.inputArrays.Status = [...tableFilter.status]
        }
      }
      const res = await BenderApi.task.search(searchTasksBody)
      setTotalRows(res.total)
      setIsFetchingList(false)
      return res.tasks
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  )

  const emptyResponse = {
    index: 0,
    status: BENDER_TASK_STATUS.PENDING,
    taskId: '',
    type: BENDER_TASK_TYPE.PROFILE_REVIEW,
    PersonId: '',
    PersonName: 'Não identificado',
    PropertyId: '',
    PropertyName: 'Não identificado',
    OrganizationId: '',
    OrganizationName: 'Não identificado',
    createdAt: '',
    collector: ''
  }

  const refreshData = (
    setIsFetching = true,
    tableFilter: TTableFilter,
    rowsPerPageP?: number,
    pageP?: number,
    orderP?: string
  ) => {
    getBenderTasksList(setIsFetching, tableFilter, rowsPerPageP, pageP, orderP).then(async data => {
      if (!data.length) {
        setRows([])
        return
      }
      setDataResponse(data)
      const mappedData = await Promise.all(
        data?.map(async (task, index) => {
          if (!task.input) {
            emptyResponse.createdAt = task.createdAt
            emptyResponse.status = task.status
            emptyResponse.type = task.type
            emptyResponse.index = index
            return createTaskTableData(emptyResponse)
          }

          const organizationName =
            task.input.Organization?.OrganizationName ||
            task.input.PersonInfo?.ownerOrganizationName ||
            (await getOrganizationName(task.input.Organization?.OrganizationId || ''))

          return createTaskTableData({
            index,
            status: task.status,
            taskId: task.id,
            type: task.type,
            createdAt: task.createdAt,
            PersonId: task.input.PersonInfo?.PersonId || task.input.PersonInfo?.id || '-',
            PersonName: task.input.PersonInfo?.PersonName || task.input.PersonInfo?.name || '-',
            PropertyId: task.input.Property?.PropertyId || '-',
            PropertyName: task.input.Property?.PropertyName || '-',
            OrganizationId: task.input.Organization?.OrganizationId || '-',
            OrganizationName: organizationName,
            collector: task.input.CollectorInput?.collector || '-'
          })
        })
      )
      setRows(mappedData)
      setIsFetchingList(false)
    })
  }

  React.useEffect(() => {
    if (rows.length) {
      setIsFetchingList(false)
    }
  }, [rows])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TaskTableData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
    setSelectedRow(index)
    const selectedTask = dataResponse ? dataResponse?.[index] : null
    if (!selectedTask) return

    if (selectedTask.type === BENDER_TASK_TYPE.CONSENT_REVIEW)
      navigate(`/bender/consent-review/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.PROFILE_REVIEW)
      navigate(`/bender/profile-review/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.CREATE_STORAGE_FACILITIES_REVIEW)
      navigate(`/bender/warehouse-review/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.SEGMENTATION_REVIEW)
      navigate(`/bender/segmentations/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.IRPF_REVIEW)
      navigate(`/bender/irpf-review/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.DETECT_SEASON_REVIEW)
      navigate(`/bender/seasons/${selectedTask.input.Property?.PropertyId}/${selectedTask.id}`)
    if (selectedTask.type === BENDER_TASK_TYPE.COLLECTOR_EXCEPTION_REVIEW)
      navigate(`/bender/collector-exception/${selectedTask.id}`)

    const complianceTask = getComplianceTaskType(selectedTask.type)
    if (complianceTask) navigate(`/bender/compliance/${complianceTask}/${selectedTask.id}`)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0

  return (
    <ReviewTasksView
      emptyRows={emptyRows}
      getComparator={getComparator}
      isFetchingList={isFetchingList}
      handleChangePage={handleChangePage}
      refreshData={refreshData}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleClick={handleClick}
      handleRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      selectedRow={selectedRow}
      stableSort={stableSort}
      totalRows={totalRows}
      profileList={profileList}
      organizationList={organizationList}
      propertyList={propertyList}
      getTaskList={getTaskList}
    />
  )
}
