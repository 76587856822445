import { TSelectedRegion } from '@/services/RadarApi'
import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, Typography, Box, IconButton, DialogContent } from '@mui/material'
import { useState, useEffect } from 'react'

import { LngLatInput, GeoJsonInput, ModalMap } from './components'

interface IRegionModal {
  open: boolean
  setOpen: (value: boolean) => void
  onSubmit: (selectedRegion: TSelectedRegion) => void
}

export const RegionModal = ({ open, setOpen, onSubmit }: IRegionModal) => {
  const onClose = () => setOpen(false)
  const [selectedRegion, setSelectedRegion] = useState<TSelectedRegion>({
    latLng: undefined,
    geometry: undefined
  })

  useEffect(() => {
    setSelectedRegion({
      geometry: undefined,
      latLng: undefined
    })
  }, [open])
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 18px',
            gap: '10px',
            height: 'fit-content'
          }}>
          <Box />
          <Typography
            variant="h2"
            sx={{
              fontSize: '1.75rem',
              color: '#111315'
            }}>
            Encontrar região
          </Typography>
          <IconButton sx={{ width: '1em', height: '1em' }} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '32px',
          paddingTop: '0px',
          gap: '24px',
          width: '70vw',
          height: 'calc(90vh - 54px)',
          flex: 'none'
        }}>
        <Typography
          variant="h5"
          sx={{
            color: '#495057'
          }}>
          Busque pela região específica ou insira um arquivo geojson para localizar.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '77px',
            width: 'fit-content',
            height: 'fit-content'
          }}>
          <LngLatInput setSelectedRegion={setSelectedRegion} />
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              color: '#495057'
            }}>
            ou
          </Typography>
          <GeoJsonInput setSelectedRegion={setSelectedRegion} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            width: '100%'
          }}>
          <ModalMap selectedRegion={selectedRegion} onSubmit={() => onSubmit(selectedRegion)} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
