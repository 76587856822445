import { TarkenError } from '@/assets'
import { ArrowRight } from '@mui/icons-material'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { Typography, Box, Button } from '@mui/material'
type TErrorPage = {
  errorTitle?: string
  errorText?: string | JSX.Element
  errorCodeMessage?: string
  maxWidth?: number
}

export const ErrorPage = ({ errorText, errorTitle, errorCodeMessage, maxWidth }: TErrorPage) => {
  const title = errorTitle || 'Algo inesperado ocorreu'
  const codeMessage = errorCodeMessage || ''
  const text = errorText || (
    <>
      Nos desculpe pelo transtorno.
      <br />
      Por favor, avise nosso suporte técnico para que possamos ajudá-lo prontamente. Agradecemos sua
      compreensão e paciência!
    </>
  )

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: ' center',
        padding: '16px',
        gap: '32px'
      }}>
      <TarkenError />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2
        }}>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '36px',
            lineHeight: '39.17px',
            wordBreak: 'normal'
          }}
          color="#495057">
          {title}
        </Typography>

        {codeMessage && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              padding: '4px 6px 4px 6px',
              background: '#EDF2FF'
            }}>
            <Typography
              textAlign="center"
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '16.94px'
              }}
              color="#5C7CFA">
              {codeMessage?.toUpperCase()}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: maxWidth || 'auto'
          }}>
          <Typography
            textAlign="center"
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '21.78px'
            }}
            color="#868E96">
            {text}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          justifyContent: 'center'
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => location.reload()}
          startIcon={<RefreshRoundedIcon />}>
          Recarregar página
        </Button>
        <Button variant="contained" color="primary" href="/" startIcon={<ArrowRight />}>
          Voltar para a tela inicial
        </Button>
      </Box>
    </Box>
  )
}
