import {
  ComplianceContext,
  IDeleteRecord,
  IRecordCandidate
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { FGTSDebtDTO, LaborDebtDTO, SlaveryOccurrenceDTO } from '@/services/Hub/AssessmentsLabor'
import { LABOR_DEBTS_STATUS } from '@/services/Hub/enums/LaborDebts.enum'
import { Table, TRowRules, openConfirm } from '@components'
import { Delete, Download } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'

type TLaborRow = {
  _candidate: boolean
  _id: string
  recordType: string
  status: string
  referenceDate: Date
  documentUrl: { value?: string; candidate: boolean }
  delete: {
    value: string
    handleDelete?: () => void
  }
}

type ILaborDebtCandidate = Omit<IRecordCandidate, 'documentStatus'> & {
  documentStatus: LABOR_DEBTS_STATUS
}

const rowRules: TRowRules = {
  recordType: {
    label: 'Tipo de Registro',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  status: {
    label: 'Status',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  referenceDate: {
    label: 'Data de expedição',
    renderComponent: (value: Date) => (
      <Typography>{moment(value).format('DD/MM/YYYY, h:mm a')}</Typography>
    )
  },
  documentUrl: {
    label: 'Certidão',
    renderComponent: (props: { value: string; candidate: boolean }) =>
      props.candidate ? (
        <Typography>{props.value}</Typography>
      ) : (
        <Button variant="text" color="info" onClick={() => window.open(props.value, '__blank')}>
          <Download />
          Download
        </Button>
      )
  },
  delete: {
    label: 'Remover',
    renderComponent: (props: { value: string; handleDelete?: () => void }) =>
      props?.handleDelete ? (
        <IconButton
          onClick={async () => {
            const res = await openConfirm('Deseja remover o registro?', { title: 'Remover' })
            res && props?.handleDelete && props.handleDelete()
          }}>
          <Delete />
        </IconButton>
      ) : (
        <></>
      )
  }
}

function createDebtRows(
  laborDebts: LaborDebtDTO[],
  handleDelete: (record: IDeleteRecord) => void
): TLaborRow[] {
  return laborDebts.map(laborDebt => ({
    _candidate: false,
    _id: laborDebt.id,
    recordType: 'CNDT',
    status: laborDebt?.status || '-',
    referenceDate: new Date(laborDebt.referenceDate),
    documentUrl: { value: laborDebt.documentUrl, candidate: false },
    delete: {
      value: laborDebt.id,
      handleDelete: () => handleDelete({ id: laborDebt.id, endpoint: 'CNDT' })
    }
  }))
}

function createFgtsRows(
  laborFgtss: FGTSDebtDTO[],
  handleDelete: (record: IDeleteRecord) => void
): TLaborRow[] {
  return laborFgtss.map(laborFgts => ({
    _candidate: false,
    _id: laborFgts.id,
    recordType: 'FGTS',
    status: laborFgts?.status || '-',
    referenceDate: new Date(laborFgts.referenceDate),
    documentUrl: { value: laborFgts.documentUrl, candidate: false },
    delete: {
      value: laborFgts.id,
      handleDelete: () => handleDelete({ id: laborFgts.id, endpoint: 'FGTS' })
    }
  }))
}

function createSlaveryRows(laborSlaveries: SlaveryOccurrenceDTO[]): TLaborRow[] {
  return laborSlaveries.map(laborSlavery => ({
    _candidate: false,
    _id: laborSlavery.id,
    recordType: 'SLAVERY_OCCURRENCE',
    status: laborSlavery?.status || '-',
    referenceDate: new Date(laborSlavery.referenceDate),
    documentUrl: { value: laborSlavery.documentUrl, candidate: false },
    delete: { value: '' }
  }))
}

function createRowsFromCandidates(
  candidates: ILaborDebtCandidate[],
  handleDelete: (index: number) => void
): TLaborRow[] {
  return candidates.map(candidate => ({
    _candidate: true,
    _id: candidate?.index?.toString() || '',
    recordType: candidate.recordType,
    status: candidate.documentStatus,
    referenceDate: new Date(),
    documentUrl: { value: candidate.file.file.name, candidate: true },
    delete: {
      value: candidate.index?.toString() || '',
      handleDelete: () => handleDelete(candidate.index ?? -1)
    }
  }))
}

interface ILaborTableProps {
  fgtsDebts: FGTSDebtDTO[]
  laborDebts: LaborDebtDTO[]
  slaveryOccurrence: SlaveryOccurrenceDTO[]
}

export const LaborTable = (props: ILaborTableProps) => {
  const { fgtsDebts, laborDebts, slaveryOccurrence } = props
  const { recordCandidates, removeRecordCandidate, deleteRecords, addDeleteRecord } =
    useContext(ComplianceContext)
  const rowsFromServer = useRef([
    ...createDebtRows(laborDebts, addDeleteRecord),
    ...createFgtsRows(fgtsDebts, addDeleteRecord),
    ...createSlaveryRows(slaveryOccurrence)
  ])
  const [rows, setRows] = useState(rowsFromServer.current)

  useEffect(() => {
    const recordCandidatesRows = createRowsFromCandidates(
      recordCandidates as ILaborDebtCandidate[],
      removeRecordCandidate
    )
    setRows(prevRows => [
      ...prevRows.filter(({ _candidate }) => !_candidate),
      ...recordCandidatesRows
    ])
  }, [recordCandidates, removeRecordCandidate])

  useEffect(() => {
    setRows(prevRows => prevRows.filter(prevRow => !deleteRecords.find(v => v.id === prevRow._id)))
  }, [deleteRecords])

  return (
    <Box sx={{ minWidth: '900px', maxWidth: '1440px' }}>
      <Typography variant="h2" mb="1rem">
        Compliance Labor
      </Typography>
      <Table rowRules={rowRules} rows={rows} />
    </Box>
  )
}
