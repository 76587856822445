import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../redux/authenticator'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { HeaderView } from './HeaderView'

export type TAppMenuItem = {
  menu: string
  url?: string
}

export type TAppMenu = TAppMenuItem & {
  submenus?: TAppMenuItem[]
}

const appMenus: TAppMenu[] = [
  {
    menu: 'radar',
    submenus: [
      {
        menu: 'Revisar pedidos',
        url: '/radar'
      }
    ]
  },
  {
    menu: 'bender',
    submenus: [
      {
        menu: 'Acompanhar execução',
        url: '/bender/executions'
      },
      {
        menu: 'Revisar Tarefas',
        url: '/bender/tasks'
      }
    ]
  },
  {
    menu: 'Cargos',
    url: '/authorization'
  },
  {
    menu: 'Solutions',
    url: '/solutions'
  },
  {
    menu: 'Serasa',
    submenus: [
      {
        menu: 'Conferir Vendas',
        url: '/serasa/conferir-vendas'
      }
    ]
  }
]

const settings: TAppMenuItem[] = [
  {
    menu: 'Sair'
  }
]

export const HomeMenuItem: TAppMenuItem = {
  menu: 'Home',
  url: '/'
}

export const Header = () => {
  const navigate = useNavigate()
  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)
  const dispatch = useAppDispatch()
  const headerMode = useAppSelector((state: RootState) => state.header.mode)

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElPage, setAnchorElPage] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const [menuToShowDrop, setMenuToShowDrop] = useState<TAppMenu>()

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenPageMenu = (event: MouseEvent<HTMLElement>, openMenu: TAppMenu) => {
    setAnchorElPage(event.currentTarget)
    setMenuToShowDrop(openMenu)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const handleClosePageMenu = () => {
    setAnchorElPage(null)
    setMenuToShowDrop(undefined)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleNavigate = (event: MouseEvent<HTMLElement>, clickedItem: TAppMenu) => {
    if (clickedItem.submenus) {
      handleOpenPageMenu(event, clickedItem)
      return
    }
    handleCloseNavMenu()
    handleClosePageMenu()
    if (clickedItem.url) navigate(clickedItem.url)
    if (clickedItem.menu === 'Sair') dispatch(logout())
  }

  return (
    <HeaderView
      anchorElNav={anchorElNav}
      anchorElPage={anchorElPage}
      anchorElUser={anchorElUser}
      handleCloseNavMenu={handleCloseNavMenu}
      handleClosePageMenu={handleClosePageMenu}
      handleCloseUserMenu={handleCloseUserMenu}
      handleNavigate={handleNavigate}
      handleOpenNavMenu={handleOpenNavMenu}
      handleOpenUserMenu={handleOpenUserMenu}
      appMenus={appMenus}
      settings={settings}
      headerMode={headerMode}
      userInfo={userInfo}
      menuToShowDrop={menuToShowDrop}
    />
  )
}
