import { AxiosError } from 'axios'
import { useContext, useState } from 'react'

import { postCriminalRecordApi } from '../ComplianceCriminal/apis/postCriminalRecordApi'
import { postEnvironmentalRecordApi } from '../ComplianceEnvironmental/apis/postEnvironmentalRecordApi'
import { postFiscalRecordApi } from '../ComplianceFiscal/apis/postFiscalRecordApi'
import { postLaborRecordApi } from '../ComplianceLabor/apis/postLaborDebtApi'
import { COMPLIANCE_TASK_TYPE } from '../enums/ComplianceTaskType'
import { ComplianceContext, IRecordCandidate } from '../providers/ComplianceContext'

export const useCreateRecordsBackend = (taskType: COMPLIANCE_TASK_TYPE | undefined) => {
  const {
    personProfile: { canonicalProfileId, id: personProfileId }
  } = useContext(ComplianceContext)

  const [error, setError] = useState<AxiosError | null>()
  const [isCreatingRecord, setIsCreatingRecord] = useState(false)
  const [response, setResponse] = useState<unknown[]>()
  const [success, setSuccess] = useState<boolean>()

  const resetStates = () => {
    setError(undefined)
    setIsCreatingRecord(false)
    setResponse(undefined)
    setSuccess(undefined)
  }

  const createRecordsBackendApi = (records: IRecordCandidate[]) => {
    if (!canonicalProfileId || !personProfileId)
      throw new Error('Undefined canonical profile id or person profile id.')

    switch (taskType) {
      case COMPLIANCE_TASK_TYPE.criminal:
        return Promise.all(
          records.map(record =>
            postCriminalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      case COMPLIANCE_TASK_TYPE.environmental:
        return Promise.all(
          records.map(record =>
            postEnvironmentalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      case COMPLIANCE_TASK_TYPE.fiscal:
        return Promise.all(
          records.map(record =>
            postFiscalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      case COMPLIANCE_TASK_TYPE.labor:
        return Promise.all(
          records.map(record =>
            postLaborRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      default:
        throw new Error('Unknown task type to create record in HUB')
    }
  }

  const createRecordsBackend = async (records: IRecordCandidate[]) => {
    try {
      resetStates()
      setIsCreatingRecord(true)

      const res = await createRecordsBackendApi(records)

      setError(null)
      setResponse(res)
      setSuccess(true)
    } catch (err) {
      if (err instanceof AxiosError) {
        alert(err.response?.data)
        setError(err)
      }

      alert(err)
      setSuccess(false)
      throw new Error()
    } finally {
      setIsCreatingRecord(false)
    }
  }

  return { createRecordsBackend, error, isCreatingRecord, response, success }
}
