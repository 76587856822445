import MenuIcon from '@mui/icons-material/Menu'
import { colors } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { TarkenSymbol, TarkenTypography } from '../../assets/svg'
import { THeader } from '../../redux/header'
import { RootState } from '../../redux/store'
import { TAppMenuItem, TAppMenu, HomeMenuItem } from './HeaderContainer'
import { CustomMenu } from './components/CustomMenu'

export type THeaderView = {
  handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void
  handleCloseNavMenu: () => void
  anchorElNav: HTMLElement | null
  anchorElUser: HTMLElement | null
  anchorElPage: HTMLElement | null
  appMenus: TAppMenu[]
  handleNavigate: (event: React.MouseEvent<HTMLElement>, page: TAppMenuItem) => void
  handleClosePageMenu: () => void
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void
  handleCloseUserMenu: () => void
  settings: TAppMenuItem[]
  headerMode: THeader['mode']
  userInfo?: RootState['authenticator']['user']['info']
  menuToShowDrop?: TAppMenu
}

export const HeaderView = ({
  handleOpenNavMenu,
  handleCloseNavMenu,
  anchorElNav,
  anchorElUser,
  anchorElPage,
  appMenus,
  handleNavigate,
  handleClosePageMenu,
  handleOpenUserMenu,
  handleCloseUserMenu,
  settings,
  headerMode,
  userInfo,
  menuToShowDrop
}: THeaderView) => (
  <AppBar
    sx={{
      display: headerMode === 'none' ? 'none' : 'block',
      bgcolor: colors.grey[900]
    }}>
    <Container sx={{ bgcolor: colors.grey[900], height: '3rem', p: 0 }} maxWidth="xl">
      <Toolbar
        sx={{
          minHeight: '3rem !important',
          height: '3rem'
        }}
        disableGutters>
        <Box
          sx={{
            mr: '1rem',
            display: { xs: 'flex', md: 'none' }
          }}>
          <IconButton
            size="large"
            aria-label="account of current userInfo"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit">
            <MenuIcon sx={{ color: '#fff' }} />
          </IconButton>
          <CustomMenu
            id="menu-appbar-hamburger"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              mt: '0.5rem',
              display: { xs: 'block', md: 'none' }
            }}>
            {appMenus.map(appMenu =>
              appMenu?.submenus ? (
                [
                  <MenuItem disableRipple dense divider key={appMenu.menu}>
                    {appMenu.menu}
                  </MenuItem>,
                  appMenu?.submenus?.map(submenu => (
                    <MenuItem
                      key={submenu.menu}
                      sx={{ paddingLeft: '24px' }}
                      onClick={e => handleNavigate(e, submenu)}>
                      <Typography>{submenu.menu}</Typography>
                    </MenuItem>
                  ))
                ]
              ) : (
                <MenuItem key={appMenu.menu} onClick={e => handleNavigate(e, appMenu)}>
                  <Typography>{appMenu.menu}</Typography>
                </MenuItem>
              )
            )}
          </CustomMenu>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            '&:hover': {
              cursor: 'pointer',
              filter: 'brightness(.87)'
            }
          }}
          onClick={e => handleNavigate(e, HomeMenuItem)}>
          <TarkenSymbol width="24" height="24" />
          <TarkenTypography color="#fff" height="11" />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
            alignItems: 'center'
          }}>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: '1rem'
            }}>
            {appMenus.map(appMenu => (
              <>
                <Button
                  key={appMenu.menu}
                  onClick={e => {
                    handleNavigate(e, appMenu)
                  }}
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      color: '#fff',
                      bgcolor: 'transparent'
                    }
                  }}>
                  {appMenu.menu}
                </Button>
                <CustomMenu
                  anchorEl={anchorElPage}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={appMenu.menu === menuToShowDrop?.menu}
                  onClose={handleClosePageMenu}>
                  {appMenu?.submenus?.map(submenu => (
                    <MenuItem key={submenu.menu} onClick={e => handleNavigate(e, submenu)}>
                      <Typography>{submenu.menu}</Typography>
                    </MenuItem>
                  ))}
                </CustomMenu>
              </>
            ))}
          </Box>
          <Box sx={{ display: userInfo?.name ? 'block' : 'none' }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar alt={userInfo?.name} src="/" sx={{ width: '2rem', height: '2rem' }} />
              </IconButton>
            </Tooltip>
            <CustomMenu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map(setting => (
                <MenuItem key={setting.menu} onClick={e => handleNavigate(e, setting)}>
                  <Typography textAlign="center">{setting.menu}</Typography>
                </MenuItem>
              ))}
            </CustomMenu>
          </Box>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
)
