import { openConfirm } from '@components'
import { RootState, useAppSelector } from '@redux'
import { BenderApi, BENDER_TASK_STATUS } from '@services'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ComplianceView } from './ComplianceView'
import { COMPLIANCE_TASK_TYPE } from './enums/ComplianceTaskType'
import { useBenderInputCompliance } from './hooks/useBenderInputCompliance'
import { useCreateRecordsBackend } from './hooks/useCreateRecordsBackend'
import { useDeleteRecordsBackend } from './hooks/useDeleteRecordsBackend'
import { ComplianceContext } from './providers/ComplianceContext'

export const Compliance = () => {
  const { taskId, taskType }: { taskId?: string; taskType?: COMPLIANCE_TASK_TYPE } = useParams()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)
  const { setPersonProfile, recordCandidates, deleteRecords, resetContext } =
    useContext(ComplianceContext)

  const { inputCompliance } = useBenderInputCompliance(taskId)
  const { createRecordsBackend, ...createResponse } = useCreateRecordsBackend(taskType)
  const { deleteRecordsBackend, ...deleteResponse } = useDeleteRecordsBackend(taskType)

  const metricsEnterPageTimestamp = useRef(new Date())

  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false)
  const [openSaveFeedback, setOpenSaveFeedback] = useState(false)
  const [sendingCompleteTask, setSendingCompleteTask] = useState(false)

  const handleClickSaveTask = () => {
    setOpenSaveConfirmation(true)
  }

  const handleCloseConfirmation = () => {
    setOpenSaveConfirmation(false)
  }

  const handleConfirmSave = () => {
    setOpenSaveConfirmation(false)
    saveRecordsBackend()
    setOpenSaveFeedback(true)
  }

  const saveRecordsBackend = () => {
    createRecordsBackend(recordCandidates)
    deleteRecordsBackend(deleteRecords)
  }

  const handleCloseSaveFeedback = () => {
    resetContext()
    setOpenSaveFeedback(false)
  }

  const handleClickCompleteTask = async () => {
    if (
      await openConfirm(
        'Após finalizada, a tarefa será concluída e removida da lista de revisão. Você deseja finalizar a tarefa?',
        { title: 'Finalizar Tarefa' }
      )
    ) {
      setSendingCompleteTask(true)
      completeTask()
    }
  }

  const completeTask = () => {
    BenderApi.task
      .complete(taskId ?? '', {
        completedBy: userInfo.email,
        id: taskId ?? '',
        status: BENDER_TASK_STATUS.SUCCEEDED,
        startedAt: metricsEnterPageTimestamp.current
      })
      .then(() => {
        setSendingCompleteTask(false)
        enqueueSnackbar('Tarefa finalizada com sucesso!', { variant: 'success' })
        navigate(-1)
      })
      .catch(err => {
        alert('Algo deu errado, tente novamente')
        console.error(err)
      })
  }

  useEffect(() => {
    const personInfo = inputCompliance?.PersonInfo
    if (!personInfo) return

    setPersonProfile({
      canonicalProfileId: personInfo.PersonCanonicalId,
      govRegCode: personInfo.PersonGovRegCode,
      govRegType: personInfo.PersonType,
      id: personInfo.PersonId,
      name: personInfo.PersonName
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCompliance])

  return (
    <ComplianceView
      createResponse={createResponse}
      deleteResponse={deleteResponse}
      handleClickSaveTask={handleClickSaveTask}
      handleClickCompleteTask={handleClickCompleteTask}
      handleCloseSaveFeedback={handleCloseSaveFeedback}
      handleConfirmSave={handleConfirmSave}
      openSaveConfirmation={openSaveConfirmation}
      openSaveFeedback={openSaveFeedback}
      handleCloseConfirmation={handleCloseConfirmation}
      sendingCompleteTask={sendingCompleteTask}
    />
  )
}
