/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import React, { useMemo, useRef } from 'react'
import MapGl, { Layer, MapRef, Source } from 'react-map-gl'

import { MapLegend, TMapLegend } from '../../../../../../../../components/EditAreasMap/MapLegend'

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface IModalMap {
  geoJson: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  highlightedFeatId: number | null
  legendValues?: TMapLegend
}

export const ModalMap = ({ geoJson, highlightedFeatId, legendValues }: IModalMap) => {
  const mapRef = useRef<MapRef>(null)
  const cornersLongLat = turf.bbox(geoJson)

  return (
    <Box
      sx={{
        flexGrow: '1',
        width: '950px',
        height: '300px',
        position: 'relative'
      }}>
      <MapGl
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        ref={mapRef}
        initialViewState={{
          bounds: [
            [cornersLongLat[0], cornersLongLat[1]],
            [cornersLongLat[2], cornersLongLat[3]]
          ]
        }}
        mapboxAccessToken="pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg">
        {useMemo(() => {
          return geoJson ? (
            // @ts-ignore
            geoJson.features.map((feature, index) => {
              return (
                <Source
                  key={index}
                  id={`area${index}`}
                  type="geojson"
                  // @ts-ignore
                  data={feature}>
                  <Layer
                    id={`layer${index}`}
                    type={'line'}
                    source={`area${index}`}
                    paint={{
                      'line-color': feature.properties?.stroke || 'grey',
                      'line-width': 4,
                      'line-dasharray': [1, 1]
                    }}
                  />
                  <Layer
                    id={`fill${index}`}
                    type={'fill'}
                    source={`area${index}`}
                    paint={{
                      'fill-color': feature.properties?.fill || 'grey',
                      'fill-opacity': 0.7
                    }}
                  />
                </Source>
              )
            })
          ) : (
            <></>
          )
        }, [geoJson, highlightedFeatId])}
      </MapGl>
      {legendValues && legendValues.length && <MapLegend legendValues={legendValues} right />}
    </Box>
  )
}
