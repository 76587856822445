import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { ComplianceCriminal } from '../../ComplianceCriminal/components/ComplianceCriminal'
import { ComplianceEnvironmental } from '../../ComplianceEnvironmental/components/ComplianceEnvironmental'
import { ComplianceFiscal } from '../../ComplianceFiscal/components/ComplianceFiscal'
import { ComplianceLabor } from '../../ComplianceLabor/components/ComplianceLabor'
import { COMPLIANCE_TASK_TYPE } from '../../enums/ComplianceTaskType'
import { ComplianceContext } from '../../providers/ComplianceContext'

export const MainContent = () => {
  const { taskType } = useParams() as { taskType: COMPLIANCE_TASK_TYPE }

  const {
    hasPendingModifications,
    personProfile: { id: personProfileId }
  } = useContext(ComplianceContext)

  if (!personProfileId) return <></>

  return (
    <Box sx={{ position: 'relative' }}>
      {hasPendingModifications && (
        <Box sx={{ position: 'absolute', width: '100%', p: '1rem 2rem' }}>
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            As alterações realizadas ainda não foram salvas no servidor. Antes de sair, clique em
            &quot;Confirmar alterações&quot;.
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          p: '7rem 2rem 4rem 2rem'
        }}>
        {taskType === COMPLIANCE_TASK_TYPE.criminal ? (
          <ComplianceCriminal personProfileId={personProfileId} />
        ) : taskType === COMPLIANCE_TASK_TYPE.environmental ? (
          <ComplianceEnvironmental personProfileId={personProfileId} />
        ) : taskType === COMPLIANCE_TASK_TYPE.fiscal ? (
          <ComplianceFiscal personProfileId={personProfileId} />
        ) : taskType === COMPLIANCE_TASK_TYPE.labor ? (
          <ComplianceLabor personProfileId={personProfileId} />
        ) : (
          <Typography>Error: Unidentified Compliance Type</Typography>
        )}
      </Box>
    </Box>
  )
}
