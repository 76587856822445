import { Box, Typography } from '@mui/material'

import { FaqCollapse } from './components/FaqCollapse'

export const HelpPageContainer = () => {
  //todo: andre will write the questions and then i will create an object and do a proper mapping
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}>
      <Typography variant="h4">FAQ</Typography>
      <Typography variant="body1">Perguntas frequentes sobre o sistema de autorização</Typography>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridAutoRows: 'minmax(auto, auto)',
          gap: '12px'
        }}>
        <FaqCollapse.root
          titleComponent={
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Como faço para criar uma organização?
            </Typography>
          }>
          <Typography variant="body1">
            Para criar uma organização, você precisa ser staff e usar o{' '}
            <a
              href="https://scipiolab.retool.com/apps/d41fca40-f082-11eb-ac10-27eefea5173f/Admin%20-%20Broker%20-%20Prod/Manage%20Organizations"
              target="_blank"
              rel="noreferrer">
              App Manage Organization do retool
            </a>
          </Typography>
        </FaqCollapse.root>

        <FaqCollapse.root
          titleComponent={
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Como faço para trocar a role de um membro?
            </Typography>
          }>
          <Typography variant="body1">
            Para criar uma organização, você precisa ser staff e usar o{' '}
            <a
              href="https://scipiolab.retool.com/apps/d41fca40-f082-11eb-ac10-27eefea5173f/Admin%20-%20Broker%20-%20Prod/Manage%20Organizations"
              target="_blank"
              rel="noreferrer">
              App Manage Organization do retool
            </a>{' '}
            na aba &quot;membros&quot;
          </Typography>
        </FaqCollapse.root>

        <FaqCollapse.root
          titleComponent={
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Compreendendo as Permissoes
            </Typography>
          }>
          <Typography variant="body1">
            Para compreender as permissoes disponíveis no sistema{' '}
            <a
              href="https://stealthvb.atlassian.net/wiki/spaces/Authorization/pages/678100993/Authorization+Managing+System"
              target="_blank"
              rel="noreferrer">
              leia sobre elas e onde estão presentes na documentação
            </a>
          </Typography>
        </FaqCollapse.root>
      </Box>
    </Box>
  )
}
