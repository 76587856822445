import {
  ComplianceContext,
  IDeleteRecord,
  IRecordCandidate
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import {
  EnvironmentalDebtShortDTO,
  EnvironmentalEmbargoShortDTO
} from '@/services/Hub/AssessmentsEnvironmental'
import {
  ENVIRONMENTAL_DEBT_STATUS,
  ENVIRONMENTAL_EMBARGO_STATUS
} from '@/services/Hub/enums/EnvironmentalAssessment.enum'
import { Table, TRowRules, openConfirm } from '@components'
import { Delete, Download } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'

type TEnvironmentalRecordCandidate = Omit<IRecordCandidate, 'documentStatus'> & {
  documentStatus: ENVIRONMENTAL_DEBT_STATUS | ENVIRONMENTAL_EMBARGO_STATUS
}

type TEnvironmentalRow = {
  _candidate: boolean
  _id: string
  recordType: string
  status: ENVIRONMENTAL_DEBT_STATUS | ENVIRONMENTAL_EMBARGO_STATUS
  documentUrl: { value?: string; candidate: boolean }
  delete: {
    value: string
    handleDelete: () => void
  }
}

const rowRules: TRowRules = {
  recordType: {
    label: 'Tipo de Registro',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  status: {
    label: 'Status',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  documentUrl: {
    label: 'Certidão',
    renderComponent: (props: { value: string; candidate: boolean }) =>
      props.candidate ? (
        <Typography>{props.value}</Typography>
      ) : (
        <Button variant="text" color="info" onClick={() => window.open(props.value, '__blank')}>
          <Download />
          Download
        </Button>
      )
  },
  delete: {
    label: 'Remover',
    renderComponent: (props: { value: string; handleDelete: () => void }) => (
      <IconButton
        onClick={async () => {
          const res = await openConfirm('Deseja remover o registro?', { title: 'Remover' })
          res && props.handleDelete()
        }}>
        <Delete />
      </IconButton>
    )
  }
}

function createDebtsRows(
  environmentalDebts: EnvironmentalDebtShortDTO[],
  handleDelete: (record: IDeleteRecord) => void
): TEnvironmentalRow[] {
  return environmentalDebts.map(environmentalDebt => ({
    _candidate: false,
    _id: environmentalDebt.id,
    recordType: 'Environmental Debts',
    status: environmentalDebt.status,
    documentUrl: { value: environmentalDebt.documentUrl, candidate: false },
    delete: {
      value: environmentalDebt.id,
      handleDelete: () =>
        handleDelete({ id: environmentalDebt.id, endpoint: 'Environmental Debts' })
    }
  }))
}

function createEmbargoesRows(
  environmentalEmbargoes: EnvironmentalEmbargoShortDTO[],
  handleDelete: (record: IDeleteRecord) => void
): TEnvironmentalRow[] {
  return environmentalEmbargoes.map(environmentalEmbargo => ({
    _candidate: false,
    _id: environmentalEmbargo.id,
    recordType: 'Environmental Embargoes',
    status: environmentalEmbargo.status,
    documentUrl: { value: environmentalEmbargo.documentUrl, candidate: false },
    delete: {
      value: environmentalEmbargo.id,
      handleDelete: () =>
        handleDelete({ id: environmentalEmbargo.id, endpoint: 'Environmental Embargoes' })
    }
  }))
}

function createRowsFromCandidates(
  candidates: TEnvironmentalRecordCandidate[],
  handleDelete: (index: number) => void
): TEnvironmentalRow[] {
  return candidates.map(candidate => ({
    _candidate: true,
    _id: candidate?.index?.toString() || '',
    recordType: candidate.recordType,
    status: candidate.documentStatus,
    documentUrl: { value: candidate.file.file.name, candidate: true },
    delete: {
      value: candidate.index?.toString() || '',
      handleDelete: () => handleDelete(candidate.index ?? -1)
    }
  }))
}

interface IEnvironmentalTableProps {
  environmentalDebt: EnvironmentalDebtShortDTO[]
  environmentalEmbargo: EnvironmentalEmbargoShortDTO[]
}

export const EnvironmentalTable = (props: IEnvironmentalTableProps) => {
  const { environmentalDebt, environmentalEmbargo } = props
  const { recordCandidates, removeRecordCandidate, deleteRecords, addDeleteRecord } =
    useContext(ComplianceContext)
  const rowsFromServer = useRef([
    ...createDebtsRows(environmentalDebt, addDeleteRecord),
    ...createEmbargoesRows(environmentalEmbargo, addDeleteRecord)
  ])
  const [rows, setRows] = useState(rowsFromServer.current)

  useEffect(() => {
    const recordCandidatesRows = createRowsFromCandidates(
      recordCandidates as TEnvironmentalRecordCandidate[],
      removeRecordCandidate
    )
    setRows(prevRows => [
      ...prevRows.filter(({ _candidate }) => !_candidate),
      ...recordCandidatesRows
    ])
  }, [recordCandidates, removeRecordCandidate])

  useEffect(() => {
    setRows(prevRows => prevRows.filter(prevRow => !deleteRecords.find(v => v.id === prevRow._id)))
  }, [deleteRecords])

  return (
    <Box sx={{ minWidth: '900px', maxWidth: '1440px' }}>
      <Typography variant="h2" mb="1rem">
        Compliance Ambiental
      </Typography>
      <Table rowRules={rowRules} rows={rows} />
    </Box>
  )
}
