import { Box } from '@mui/material'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import React, { Dispatch, SetStateAction } from 'react'
import { MapRef } from 'react-map-gl'
import { useParams, useNavigate } from 'react-router-dom'

import { TMapLegend } from '../../../components/EditAreasMap/MapLegend'
import { ConfirmationModal } from '../../../components/confirmationModal'
import { MessageModal } from '../../../components/messageModal'
import { Map } from './components/map/MapContainer'
// import {TMapLegend} from '../../../components/Map/components/MapLegend';
import { MODAL_OPTIONS } from './components/map/components/ConfirmEditingModal/option.enum'
import { Sidebar } from './components/sidebar'

interface ISegmentationReviewView {
  handleBack: () => void
  geoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  setGeoJson: Dispatch<
    SetStateAction<
      | FeatureCollection<
          turf.helpers.Geometry | turf.helpers.GeometryCollection,
          turf.helpers.Properties
        >
      | null
      | undefined
    >
  >
  highlightedFeatId: string | null
  setHighlitedFeatId: Dispatch<SetStateAction<string | null>>
  editingIndex: number | null
  setEditingIndex: Dispatch<SetStateAction<number | null>>
  cancelEditing: () => void
  removeArea: (index: number) => void
  header?: JSX.Element
  saveEditingArea: () => void
  drawRef: MapboxDraw | null
  setDrawRef: React.Dispatch<React.SetStateAction<MapboxDraw | null>>
  intersectionsVisibility: boolean
  setIntersectionsVisibility: React.Dispatch<React.SetStateAction<boolean>>
  confirmEditingModalOptions: {
    type: MODAL_OPTIONS
    subtitle: string
    ConfirmMessageTitle?: string | undefined
    ConfirmMessageBody?: string | undefined
    legendValues: TMapLegend
  } | null
  setConfirmEditingModalOptions: React.Dispatch<
    React.SetStateAction<{
      type: MODAL_OPTIONS
      subtitle: string
      ConfirmMessageTitle?: string | undefined
      ConfirmMessageBody?: string | undefined
      legendValues: TMapLegend
    } | null>
  >
  confirmSaveEditingArea: () => void
  legendValues: TMapLegend
  modalGeoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  setIntersections: React.Dispatch<
    React.SetStateAction<
      FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    >
  >
  mapRef: React.RefObject<MapRef>
  isFeatureEditable: (feature: turf.Feature) => boolean
  loading: boolean
  messageData?: {
    title: string
    message: string
    clearMessage: () => void
  }
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isModalOpen: boolean
  onDoneButtonClick(): void
  outterFeatures: turf.Feature[]
  multipleSelectionMode: boolean
  setMultipleSelectionMode: React.Dispatch<React.SetStateAction<boolean>>
  submitMultipleSelection: (selectedIds: string[], isProductive: boolean) => Promise<void>
}

export const SegmentationReviewView = ({
  handleBack,
  geoJson,
  setGeoJson,
  highlightedFeatId,
  setHighlitedFeatId,
  editingIndex,
  setEditingIndex,
  cancelEditing,
  removeArea,
  header,
  saveEditingArea,
  drawRef,
  setDrawRef,
  intersectionsVisibility,
  setIntersectionsVisibility,
  confirmEditingModalOptions,
  setConfirmEditingModalOptions,
  confirmSaveEditingArea,
  legendValues,
  modalGeoJson,
  intersections,
  setIntersections,
  mapRef,
  isFeatureEditable,
  loading,
  messageData,
  setIsModalOpen,
  isModalOpen,
  onDoneButtonClick,
  outterFeatures,
  multipleSelectionMode,
  setMultipleSelectionMode,
  submitMultipleSelection
}: ISegmentationReviewView) => {
  const { id } = useParams()
  const navigate = useNavigate()
  if (id === undefined) {
    navigate(-1)
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100vw',
        display: 'flex',
        position: 'relative'
      }}>
      {messageData && <MessageModal {...messageData} />}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          background: 'rgba(0, 0, 0, 0.4)',
          display: loading ? 'flex' : 'none',
          zIndex: 10000,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: 'rgba(233, 236, 239, 0.2)'
            }}
            size={40}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: 'white',
              animationDuration: '1000ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round'
              }
            }}
            size={40}
            thickness={4}
          />
        </Box>
      </Box>
      {id && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          taskType={'segmentation'}
          id={id}
        />
      )}
      <Sidebar
        handleBack={handleBack}
        geoJson={geoJson}
        setGeoJson={setGeoJson}
        highlightedFeatId={highlightedFeatId}
        setHighlitedFeatId={setHighlitedFeatId}
        editingIndex={editingIndex}
        setEditingIndex={setEditingIndex}
        cancelEditing={cancelEditing}
        removeArea={removeArea}
        header={header}
        saveEditingArea={saveEditingArea}
        isFeatureEditable={isFeatureEditable}
        onDoneButtonClick={onDoneButtonClick}
        multipleSelectionMode={multipleSelectionMode}
      />
      <Map
        geoJson={geoJson}
        setGeoJson={setGeoJson}
        editingIndex={editingIndex}
        legendValues={legendValues}
        drawRef={drawRef}
        setDrawRef={setDrawRef}
        intersectionsVisibility={intersectionsVisibility}
        setIntersectionsVisibility={setIntersectionsVisibility}
        confirmEditingModalOptions={confirmEditingModalOptions}
        setConfirmEditingModalOptions={setConfirmEditingModalOptions}
        confirmSaveEditingArea={confirmSaveEditingArea}
        highlightedFeatId={highlightedFeatId}
        setHighlitedFeatId={setHighlitedFeatId}
        modalGeoJson={modalGeoJson}
        intersections={intersections}
        setIntersections={setIntersections}
        mapRef={mapRef}
        isFeatureEditable={isFeatureEditable}
        outterFeatures={outterFeatures}
        multipleSelectionMode={multipleSelectionMode}
        setMultipleSelectionMode={setMultipleSelectionMode}
        submitMultipleSelection={submitMultipleSelection}
      />
    </Box>
  )
}
