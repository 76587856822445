import { Box, CircularProgress } from '@mui/material'

interface ILoading {
  size?: number
}

export const Loading = ({ size }: ILoading) => {
  return (
    <Box
      sx={{
        margin: '40px 18px',
        width: '100%',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent'
      }}>
      <CircularProgress size={size !== undefined ? size : 100} />
    </Box>
  )
}
