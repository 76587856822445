import { axiosHub } from '../config/axiosHub.config'
import { CRIMINAL_ASSESSMENT_STATUS } from './enums/CriminalReports.enum'

export type THubCriminalAssessment = {
  referenceDate: string
  criminal: CriminalRecordDTO
}

export type CriminalRecordDTO = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  referenceDate: string
  status: CRIMINAL_ASSESSMENT_STATUS
  documentUrl: string
  canonicalProfileId: string
  creationType: string
  modifiedByUserId?: string
}

export type CreateCriminalRecordDTO = {
  referenceDate: string
  status: CRIMINAL_ASSESSMENT_STATUS
  documentUrl: string
  canonicalProfileId: string
  creationType: string
}

const getRequest = async (personProfileId: string) => {
  const { data } = await axiosHub.get<THubCriminalAssessment>(
    `/api/v1/assessments/judiciary?personProfileId=${personProfileId}`
  )
  return data
}

const postCriminalRecordRequest = async (body: CreateCriminalRecordDTO) => {
  const { data } = await axiosHub.post<CriminalRecordDTO>(
    '/api/v1/assessments/judiciary/criminal-records',
    body
  )
  return data
}

/**
 * Expects criminalRecordId OR canonicalProfileId as params. If you send both, then the canonicalProfileId will be ignored.
 */
const deleteCriminalReportRequest = async (params: {
  criminalRecordId?: string
  canonicalProfileId?: string
}) => {
  if (!params.criminalRecordId && !params.canonicalProfileId)
    throw new Error('Criminal Report ID or Canonical Profile ID is required')

  const urlParams = params.criminalRecordId
    ? { criminalRecordId: params.criminalRecordId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/judiciary/criminal-records', {
    params: urlParams
  })
  return data
}

export const AssessmentsCriminal = {
  getRequest,
  postCriminalRecordRequest,
  deleteCriminalReportRequest
}
