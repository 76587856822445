import { TConditionType } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Box, FormHelperText } from '@mui/material'

import { TMongoOperatorKeys, TMongoOperators } from '../../AuthorizationForm.constants'
import { TSaveValue } from './ConditionContainer'
import { MultiselectCondition } from './components/MultiselectCondition'
import { NumericCondition } from './components/NumericCondition'

interface IConditionHandler {
  condition: TConditionType
  saveValue: TSaveValue
  fieldValue: Record<string, unknown> | undefined | string
  organizationId: string
  errorMessage: string | undefined
}

export const ConditionHandler = (props: IConditionHandler) => {
  const { condition, saveValue, fieldValue, organizationId, errorMessage } = props

  if (condition.type === 'NUMERIC') {
    let defaultOperator: TMongoOperatorKeys = condition.config.allowedOperators[0]
    let defaultValue: number | undefined = undefined
    if (fieldValue && typeof fieldValue !== 'string') {
      const fieldValueKeys = Object.keys(fieldValue)
      if (fieldValueKeys[0] in TMongoOperators)
        defaultOperator = fieldValueKeys[0] as TMongoOperatorKeys
      defaultValue = fieldValue[defaultOperator] as number
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%'
        }}>
        <NumericCondition
          saveValue={saveValue}
          numericConditon={condition}
          defaultOperator={defaultOperator}
          defaultValue={defaultValue}
        />
        <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
      </Box>
    )
  }

  if (condition.type === 'MULTISELECT') {
    let defaultValues: string[] = []
    if (fieldValue) {
      if (typeof fieldValue === 'string') {
        defaultValues =
          fieldValue.replace(/\s+/g, '') === '{{user.id}}' ? ['{{user.id}}'] : [fieldValue]
      } else {
        defaultValues = fieldValue['$in'] as string[]
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%'
        }}>
        <MultiselectCondition
          saveValue={saveValue}
          multiselectCondition={condition}
          organizationId={organizationId}
          defaultValues={defaultValues}
        />
        <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
      </Box>
    )
  }

  return <></>
}
