import {
  ShortOrganizationDTO,
  HubApi,
  THubFormFieldDefinition,
  THubFormConditionsDefinition,
  THubFormFieldGroup,
  ConditionExpression,
  ConditionAction
} from '@services'
import { useQuery } from '@tanstack/react-query'
import { Modal, Checkbox, Typography } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
const { Title } = Typography

import { OrgSelect, FormsSelect, MainVerticalBox, VerticalBox } from '../../../components'
import useMessage from '../../../hooks/useMessage'
import { GroupsRelationTable } from '../tables'

type GroupRelation = {
  originLabel: string
  originId: string
  destinyId: string | null
}

type TOriginFormsModalPage = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setFormFields: Dispatch<SetStateAction<THubFormFieldDefinition[]>>
  formFields: THubFormFieldDefinition[]
  formConditions: THubFormConditionsDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
  customFieldsGroups: THubFormFieldGroup[] | undefined
  organizations: ShortOrganizationDTO[] | undefined
  selectedOrganization: string | null
  selectedFormEntity: string | null
}

export const OriginFormsModal: React.FC<TOriginFormsModalPage> = ({
  setIsModalOpen,
  isModalOpen,
  setFormFields,
  formConditions,
  setFormConditions,
  formFields,
  customFieldsGroups,
  organizations,
  selectedOrganization,
  selectedFormEntity
}) => {
  const [selectedOriginOrganization, setOriginOrganizationId] = useState<string | null>(null)
  const [selectedForm, setFormId] = useState<string | null>(null)
  const [conditionsCheckBox, setConditionCheckBox] = useState<boolean>(false)
  const [groupsCheckBox, setGroupsCheckBox] = useState<boolean>(false)
  const [groupsRelation, setGroupsRelation] = useState<GroupRelation[]>([])
  const { contextHolder, notify } = useMessage()

  const addedNativeFields = formFields
    ?.filter(field => field.source == 'NATIVE')
    ?.map(field => field.identifier)

  const { data: forms } = useQuery({
    queryKey: ['forms', selectedOriginOrganization],
    queryFn: () => HubApi.getForms(selectedOriginOrganization),
    enabled: !!selectedOriginOrganization
  })

  const { data: originForm } = useQuery({
    queryKey: ['getFormById', selectedForm],
    queryFn: () => HubApi.getFormById(selectedForm),
    enabled: !!selectedForm
  })

  useEffect(() => {
    setFormId(null)
    setGroupsRelation([])
  }, [selectedOriginOrganization])

  const { data: originCustomFieldsGroups } = useQuery({
    queryKey: ['getCustomFieldsGroups', selectedOriginOrganization],
    queryFn: () => HubApi.getCustomFieldsGroups(selectedOriginOrganization),
    enabled: !!selectedOriginOrganization
  })

  useEffect(() => {
    const groupsRelationUpdated: GroupRelation[] = []
    const desiredGroups: string[] = []
    if (originForm && originCustomFieldsGroups) {
      for (let i = 0; i < originForm?.formFields?.length; i += 1) {
        const groupId: string | null =
          originForm?.formFields[i]?.fieldDefinition?.customFieldGroupId
        if (groupId && !desiredGroups.includes(groupId)) {
          desiredGroups.push(groupId)
        }
      }

      for (let i = 0; i < originCustomFieldsGroups?.length; i += 1) {
        const groupId: string = originCustomFieldsGroups[i].id
        if (groupId && desiredGroups.includes(groupId))
          groupsRelationUpdated.push({
            originLabel: originCustomFieldsGroups[i].label ?? '',
            originId: originCustomFieldsGroups[i].id ?? null,
            destinyId: null
          })
      }
      setGroupsRelation(groupsRelationUpdated)
    }
  }, [originCustomFieldsGroups, originForm])

  const handleOk = async () => {
    if (originForm) {
      const identifiers: string[] = formFields.map(field => field.identifier)
      const groupsRelationDictionary: AnyObject = {}
      for (let i = 0; i < groupsRelation.length; i += 1) {
        groupsRelationDictionary[groupsRelation[i].originId] = groupsRelation[i].destinyId
      }

      const repeatedFields: string[] = []
      const filteredFields = []
      for (let i = 0; i < originForm?.formFields?.length; i += 1) {
        const field = originForm?.formFields?.[i]?.fieldDefinition

        if (field?.kind == 'CUSTOM') {
          const source =
            selectedOriginOrganization == selectedOrganization &&
            field?.entity == selectedFormEntity
              ? 'DESTINY'
              : 'ORIGIN'

          let groupId = null
          if (field?.customFieldGroupId) {
            if (selectedOriginOrganization == selectedOrganization)
              groupId = field?.customFieldGroupId
            else if (!!groupsRelationDictionary?.[field?.customFieldGroupId] && groupsCheckBox)
              groupId = groupsRelationDictionary?.[field?.customFieldGroupId]
          }

          filteredFields.push({
            ...field,
            source: source,
            customFieldGroupId: groupId,
            identifier: identifiers.includes(field?.identifier) ? '' : field?.identifier
          })

          if (identifiers.includes(field?.identifier)) repeatedFields.push(`'${field?.identifier}'`)
        } else if (
          originForm.entity == selectedFormEntity &&
          !addedNativeFields.includes(field?.identifier)
        ) {
          filteredFields.push({
            ...field,
            source: 'NATIVE'
          })
        }
      }

      if (conditionsCheckBox) {
        const importedConditions = originForm?.conditions
        const conditions: THubFormConditionsDefinition[] = []
        for (let i = 0; i < importedConditions?.length; i += 1) {
          const actions: ConditionAction[] = []
          const expressions: ConditionExpression[] = []
          for (let j = 0; j < importedConditions[i]?.actions?.length; j += 1) {
            const action: ConditionAction = importedConditions[i]?.actions[j]
            actions.push({ ...action, fieldIdentifier: action?.fieldIdentifier?.split('_')[1] })
          }
          for (let j = 0; j < importedConditions[i]?.expressions?.length; j += 1) {
            const expression: ConditionExpression = importedConditions[i]?.expressions[j]
            expressions.push({
              ...expression,
              fieldIdentifier: expression?.fieldIdentifier?.split('_')[1]
            })
          }
          conditions.push({
            name: importedConditions[i]?.name,
            actions: actions,
            expressions: expressions
          })
        }
        setFormConditions(formConditions.concat(conditions))
      }

      if (repeatedFields.length > 0)
        notify(
          'warning',
          `${
            repeatedFields.length > 1
              ? `Identifiers ${repeatedFields.join(
                  ', '
                )} removidos para evitar duplicidades, preencha-os corretamente!`
              : `Identifier ${repeatedFields.join(
                  ', '
                )} removido para evitar duplicidade, preencha-o corretamente!`
          }`
        )
      setFormFields(formFields.concat(filteredFields))
    }
    setOriginOrganizationId(null)
    setFormId(null)
    setIsModalOpen(false)
    setConditionCheckBox(false)
    setGroupsCheckBox(false)
    setGroupsRelation([])
  }

  const handleCancel = () => {
    setOriginOrganizationId(null)
    setFormId(null)
    setIsModalOpen(false)
    setConditionCheckBox(false)
    setGroupsCheckBox(false)
  }

  const groupsTable = (
    <>
      <Title level={5} style={{ color: '#212121', margin: 0 }}>
        Relacione os grupos das diferentes organizações
      </Title>
      <GroupsRelationTable
        destinyGroups={
          customFieldsGroups?.map(field => ({ value: field?.id, label: field?.label })) ?? []
        }
        setGroupsRelation={setGroupsRelation}
        groupsRelation={groupsRelation}
      />
    </>
  )

  return (
    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText={'Cancelar'}>
      {contextHolder}
      <MainVerticalBox>
        <VerticalBox style={{ width: '100%', alignItems: 'start', marginBottom: 16 }}>
          <Title level={5} style={{ color: '#212121', margin: 0 }}>
            Selecione o formulário a ser importado
          </Title>
          <OrgSelect
            selectedOrg={selectedOriginOrganization}
            isLoadingOrgs={!organizations}
            setOrganizationId={setOriginOrganizationId}
            organizations={organizations}
            placeholder="Organização de origem"
            width="100%"
          />
          <FormsSelect
            selectedForm={selectedForm}
            isLoadingForms={!forms}
            setFormId={setFormId}
            forms={forms}
            width="100%"
          />
          <Checkbox
            checked={conditionsCheckBox}
            onChange={e => {
              setConditionCheckBox(e.target.checked)
            }}>
            Importar condicionais do formulário
          </Checkbox>
          <Checkbox
            checked={groupsCheckBox || selectedOrganization === selectedOriginOrganization}
            disabled={selectedOrganization === selectedOriginOrganization}
            onChange={e => {
              setGroupsCheckBox(e.target.checked)
            }}>
            Importar grupos do formulário
          </Checkbox>
        </VerticalBox>
        {groupsCheckBox && selectedOrganization !== selectedOriginOrganization ? groupsTable : null}
      </MainVerticalBox>
    </Modal>
  )
}
