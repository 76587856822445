export const BackArrow = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7351 5.23301H3.12485L6.88652 1.47135C7.18714 1.17072 7.18714 0.677389 6.88652 0.376764C6.7425 0.232423 6.54698 0.151306 6.34308 0.151306C6.13918 0.151306 5.94366 0.232423 5.79965 0.376764L0.719854 5.45656C0.419229 5.75718 0.419229 6.24281 0.719854 6.54343L5.79965 11.6232C6.10027 11.9238 6.58589 11.9238 6.88652 11.6232C7.18714 11.3226 7.18714 10.837 6.88652 10.5363L3.12485 6.77468H11.7351C12.159 6.77468 12.5059 6.42781 12.5059 6.00385C12.5059 5.57989 12.159 5.23301 11.7351 5.23301Z"
        fill="#212529"
      />
    </svg>
  )
}
