import { UpdateRoleBody } from '@/services/Hub/Authorization/contract/request/role.request'
import { RuleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import {
  SubjectActionDTO,
  TConditionType
} from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Add, Clear } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'

import { TArrayHelpers } from '../AuthorizationForm.interface'
import { generateRuleHelperText } from '../utils/ruleHelperText'
import { ConditionContainer } from './conditions/ConditionContainer'

interface IRulesRender {
  availableConditions: SubjectActionDTO['conditions'] | undefined
  arrayHelpers: Omit<TArrayHelpers, 'push' | 'unshift'>
  currentRuleValue: RuleDTO
  organizationId: string
  isFirstOfKind: boolean
}

export const RulesRender = (props: IRulesRender) => {
  const { availableConditions, arrayHelpers, currentRuleValue, organizationId, isFirstOfKind } =
    props
  const { values, setFieldValue } = useFormikContext<UpdateRoleBody>()
  const [selectedConditions, setSelectedConditions] = useState<TConditionType[]>([])
  const [addConditionMenuAnchorEl, setAddConditionMenuAnchorEl] = useState<null | HTMLElement>(null)

  const isButtonDisabled = !availableConditions?.length
    ? true
    : availableConditions.length === selectedConditions.length

  const getRuleIndex = useCallback(() => {
    return values.rules?.findIndex(r => r.id === currentRuleValue.id)
  }, [currentRuleValue.id, values])

  const removeConditions = () => {
    setFieldValue(`rules[${getRuleIndex()}].conditions`, undefined)
    setSelectedConditions([])
  }

  const removePermission = () => {
    removeConditions()
    arrayHelpers.remove(getRuleIndex())
  }

  useEffect(() => {
    if (currentRuleValue.conditions) {
      const currentRuleConditionsKeys = Object.keys(currentRuleValue.conditions)
      const wantedConditions = availableConditions?.filter(aCondition =>
        currentRuleConditionsKeys.includes(aCondition.key)
      )
      if (wantedConditions?.length) setSelectedConditions(wantedConditions)
    }
  }, [availableConditions, currentRuleValue.conditions])

  const handleConditionAddOrRemove = (action: 'remove' | 'add', condition: TConditionType) => {
    if (action === 'add') {
      setSelectedConditions(prev => [...prev, condition])
      return
    }
    const isRemovingLastCondition =
      selectedConditions.length === 1 && selectedConditions[0].key === condition.key

    //race condition fix for `removeConditions` not running properly
    setSelectedConditions(prev => prev.filter(sC => sC.key !== condition.key))
    if (isRemovingLastCondition) {
      removeConditions()
    }
  }

  return (
    <Box
      className="rule-container"
      sx={{
        width: 'fit-content',
        minWidth: '400px',
        maxWidth: 'calc(100% - 30px)',
        marginLeft: '30px'
      }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: 'rgb(73,80,87)'
        }}>
        {generateRuleHelperText(isFirstOfKind, currentRuleValue.inverted)}
      </Typography>
      <Box
        className="rule-details"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          gap: '8px',
          padding: '12px',

          background: currentRuleValue.inverted
            ? 'rgba(219, 0, 38, 0.3)'
            : 'rgba(28, 200, 89, 0.3)',
          borderRadius: '8px',

          '& .condition-container:first-child': {
            '& .condition-helper-text': {
              display: 'none'
            }
          }
        }}>
        {!selectedConditions.length && <Typography variant="h5">Sempre</Typography>}

        {!!selectedConditions.length && (
          <>
            {selectedConditions.map(condition => (
              <ConditionContainer
                organizationId={organizationId}
                key={condition.key}
                condition={condition}
                ruleIndex={getRuleIndex()}
                onRemove={() => handleConditionAddOrRemove('remove', condition)}
              />
            ))}
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '20px'
          }}>
          <Button
            size="small"
            sx={{
              color: 'rgba(219, 0, 38, 1)'
            }}
            endIcon={<Clear />}
            onClick={() => removePermission()}>
            Deletar grupo
          </Button>
          {!!availableConditions?.length && (
            <>
              <Button
                size="small"
                startIcon={<Add />}
                color="success"
                disabled={isButtonDisabled}
                onClick={e => setAddConditionMenuAnchorEl(e.currentTarget)}>
                Adicionar condição
              </Button>
              <Menu
                anchorEl={addConditionMenuAnchorEl}
                open={Boolean(addConditionMenuAnchorEl)}
                onClose={() => {
                  setAddConditionMenuAnchorEl(null)
                }}>
                {availableConditions.map(aCondition => (
                  <MenuItem
                    key={aCondition.key}
                    onClick={() => {
                      handleConditionAddOrRemove('add', aCondition)
                      setAddConditionMenuAnchorEl(null)
                    }}
                    disabled={selectedConditions.includes(aCondition)}>
                    {aCondition.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
