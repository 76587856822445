import { store, RootState } from '../../../../redux/store'
import api from './instance'

export const createAutofills = async (data: FormData) => {
  const reduxState: RootState = store.getState()
  const response = await api.post('v1/autofills/create/', data, {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return response
}

export const createUsers = async (data: FormData) => {
  const reduxState: RootState = store.getState()
  const response = await api.post('v1/users/', data, {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return response
}

export const createForms = async (data: object) => {
  const reduxState: RootState = store.getState()
  const response = await api.post('v1/forms/create/', data, {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return response
}

export const manageCustomFields = async (data: object) => {
  const reduxState: RootState = store.getState()
  const response = await api.post('v1/forms/manage-fields/', data, {
    headers: {
      Authorization: `Bearer ${reduxState.authenticator.user.token}`
    }
  })
  return response
}
