import { GisApi } from '@services'
import { Feature, Geometry, Properties } from '@turf/turf'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'

export const useFetchCrops = () => {
  const [crops, setCrops] = useState<any[]>()
  const [isFetchingCrops, setIsFetchingCrops] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fetchCropsFromApi = useCallback(
    async (geometry: Feature<Geometry, Properties>): Promise<any> => {
      try {
        const response = await GisApi.crops.getCrops(geometry)

        return response
      } catch (error) {
        enqueueSnackbar('Erro ao buscar informações das produções.', {
          variant: 'error'
        })
        console.error(error)
        throw error
      }
    },
    [enqueueSnackbar]
  )

  const fetchCrops = async (geometry: Feature<Geometry, Properties> | undefined) => {
    if (!geometry) return

    setIsFetchingCrops(true)
    const crops = await fetchCropsFromApi(geometry)
    console.log(crops)
    const sortable = []
    for (const key in crops.probs) {
      sortable.push([key, crops.probs[key]])
    }
    sortable.sort((a, b) => b[1] - a[1])
    setCrops(sortable)
    setIsFetchingCrops(false)
  }

  return { crops, isFetchingCrops, fetchCrops }
}
