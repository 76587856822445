import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogProps,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material'
import { useState } from 'react'

import { WarningIcon } from './icons'

export interface IRejectionDialogProps extends DialogProps {
  handleConfirm: (reason: string) => void
  handleCancel: () => void
  hasRejectionReasonInput?: boolean
}

export const RejectionDialogWithReason = (props: IRejectionDialogProps) => {
  const { handleCancel, handleConfirm, ...restProps } = props

  const [rejectionReason, setRejectionReason] = useState('')
  const [checkbox, setCheckbox] = useState(false)

  return (
    <Dialog onClose={handleCancel} {...restProps}>
      <Box
        sx={{
          width: '560px',
          height: '340px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box sx={{ borderBottom: '1px solid #F1F3F9', width: '100%', padding: '10px 12px' }}>
          <Typography fontSize={16} fontFamily="Inter" color="#0A0D14">
            Justificativa de revogação da Anuência
          </Typography>
        </Box>
        <Box
          sx={{
            height: '220px',
            width: '100%',
            padding: '16px 20px',
            borderBottom: '1px solid #F1F3F9'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '6px',
              padding: '6px 8px',
              borderRadius: '8px',
              background: '#F8F9FC',
              marginBottom: '10px'
            }}>
            <WarningIcon />
            <Typography fontSize={12} fontFamily="Inter" color="#0A0D14">
              Essa mensagem será enviada para o usuário do Inspector e poderá ser consultada.
            </Typography>
          </Box>
          <Box>
            <Typography fontFamily="Inter" fontWeight={600} fontSize={14}>
              Mensagem de justificativa
            </Typography>
            <TextField
              fullWidth
              sx={{
                width: '100%',
                marginTop: '8px'
              }}
              value={rejectionReason}
              onChange={e => setRejectionReason(e.target.value)}
              inputProps={{ style: { fontFamily: 'Inter', fontSize: '14px' } }}
              multiline
              rows={4}
              placeholder="Insira uma mensagem de justificativa para a revogação dessa Anuência"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
            width: '100%',
            padding: '16px 20px'
          }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                  disabled={rejectionReason?.length === 0}
                />
              }
              label="Li atentamente a mensagem e desejo continuar com o envio"
            />
          </FormGroup>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Button onClick={handleCancel} variant="outlined" sx={{ minWidth: '90px' }}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirm(rejectionReason)}
              disabled={!checkbox}
              sx={{ minWidth: '90px', color: 'white' }}>
              Rejeitar
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
