import { Paper, Box, Typography, IconButton } from '@mui/material'
import { setSelectedRegion as setRegion, useAppDispatch } from '@redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { FilterIcon } from '../../../assets/svg'
import { EmptyObjectErrorMessage } from '../../../components/emptyObjectErrorMessage'
import { Loading } from '../../../components/loading'
import { changeHeader } from '../../../redux/header'
import { RadarApi, TRequest, TSelectedRegion } from '../../../services/RadarApi'
import { RegionModal } from '../components/regionModal'
import { Filter } from './components/filter'
import { RequestCard } from './components/requestCard'
export type Request = {
  name: string
  areasPercentage: number
  seasonsPercentage: number
}

export const RequestsPage = () => {
  const [showFilter, setShowFilter] = useState(false)
  const [filteredRequests, setFilteredRequests] = useState<TRequest[]>([])
  const [requests, setRequests] = useState<TRequest[]>([])
  const [loading, setLoading] = useState(true)
  const [openRegionModal, setOpenRegionModal] = useState(false)

  const fetchGetRequests = () => {
    RadarApi.getRequests()
      .then(res => {
        setRequests(res)
        setFilteredRequests(res)
        setTimeout(() => setLoading(false), 1000)
      })
      .catch(() => {
        setRequests([])
        setFilteredRequests([])
        setTimeout(() => setLoading(false), 1000)
      })
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    fetchGetRequests()
    dispatch(changeHeader({ mode: 'fixed' }))
  }, [])
  return (
    <Box
      sx={{
        padding: '40px 0px'
      }}>
      <RegionModal
        open={openRegionModal}
        setOpen={(value: boolean) => setOpenRegionModal(value)}
        onSubmit={(selectedRegion: TSelectedRegion) => {
          dispatch(setRegion({ selectedRegion: selectedRegion }))
          navigate('/radar/selectedRegion')
        }}
      />
      <Paper
        sx={{
          width: '1162px',
          height: 'fit-content',
          borderRadius: '18px',
          padding: '18px 26px'
        }}
        elevation={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '0px',
              gap: '25px'
            }}>
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '29px',
                color: '#212529'
              }}>
              Pedidos
            </Typography>
            <IconButton
              title="Filtrar requisições"
              onClick={() => (requests?.length <= 0 ? null : setShowFilter(e => !e))}>
              <FilterIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              gap: '8px',
              width: 'fit-content',
              height: '33px',
              background: '#111315',
              borderRadius: '8px',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => setOpenRegionModal(true)}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '17px',
                color: '#FFFFFF'
              }}>
              Encontrar região
            </Typography>
          </Box>
        </Box>
        {showFilter && requests?.length > 0 && (
          <Filter rawData={requests} setFilteredRequests={setFilteredRequests} />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          {(function () {
            if (loading) {
              return <Loading size={40} />
            }
            if (requests && requests.length <= 0) {
              return <EmptyObjectErrorMessage errorMsg="Não há pedidos em andamento" />
            }
            if (filteredRequests && filteredRequests.length <= 0) {
              return (
                <EmptyObjectErrorMessage
                  errorMsg="Não há pedidos com este nome."
                  suggestion="Verifique o nome inserido no filtro"
                />
              )
            }
            if (filteredRequests && filteredRequests?.length > 0) {
              return (
                <>
                  {filteredRequests.map((request, index) => {
                    return <RequestCard key={index} request={request} />
                  })}
                </>
              )
            }
          })()}
        </Box>
      </Paper>
    </Box>
  )
}
