/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { colors } from '@material-ui/core'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import { RefObject, useMemo } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'

import { EditAreasMap } from '../../../../../../components/EditAreasMap'

interface TMapViewProps {
  mapRef: RefObject<MapRef>
  onMapClick: (props: mapboxgl.MapLayerMouseEvent) => void
  onMapDblClick?: (props: mapboxgl.MapLayerMouseEvent) => void
  editingIndex: number | null
  splittingIndex: number | null
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  intersectionsVisibility: boolean
  handleToggleIntersectionsVisibility: () => void
  handleToggleAddNewArea: () => void
  handleToggleSplitArea: () => void
  handleDeleteSelectedAreas: () => void
  handleMergeSelectedAreas: () => void
  geoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  highlightedFeatId: number | null
  hasSelectedAreas: boolean
  highlightedFeatCoordinates: turf.helpers.Position | null
  isAddingNewArea: boolean
  selectedAreasIds: string[] | null
  propertyBoundary?: turf.MultiPolygon | null
}
export const MapView = (props: TMapViewProps) => {
  const {
    mapRef,
    onMapClick,
    onMapDblClick,
    editingIndex,
    splittingIndex,
    intersections,
    intersectionsVisibility,
    handleToggleIntersectionsVisibility,
    handleToggleAddNewArea,
    handleToggleSplitArea,
    handleDeleteSelectedAreas,
    handleMergeSelectedAreas,
    highlightedFeatCoordinates,
    hasSelectedAreas,
    geoJson,
    highlightedFeatId,
    isAddingNewArea,
    selectedAreasIds,
    propertyBoundary
  } = props
  return (
    <EditAreasMap
      mapRef={mapRef}
      onMapClick={onMapClick}
      onMapDblClick={onMapDblClick}
      editingIndex={editingIndex}
      intersections={intersections}
      intersectionsVisibility={intersectionsVisibility}
      isAddingNewArea={isAddingNewArea}
      isSplittingArea={splittingIndex !== null}
      hasSelectedAreas={hasSelectedAreas}
      handleToggleAddNewArea={handleToggleAddNewArea}
      handleToggleSplitArea={handleToggleSplitArea}
      handleDeleteSelectedAreas={handleDeleteSelectedAreas}
      handleMergeSelectedAreas={handleMergeSelectedAreas}
      highlightedFeatCoordinates={highlightedFeatCoordinates}
      handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}>
      <>
        {useMemo(() => {
          return geoJson ? (
            geoJson.features.map((feature, index) => {
              // const isProductive = feature?.properties?.productive === 1
              if (editingIndex !== index && feature.id && selectedAreasIds)
                return (
                  <Source
                    key={feature.id}
                    id={`area${feature.id}`}
                    type="geojson"
                    // @ts-ignore
                    data={feature}>
                    <Layer
                      id={`layer${feature.id}`}
                      type={'line'}
                      source={`area${feature.id}`}
                      paint={{
                        'line-color': colors.blue[500],
                        'line-width': 2
                      }}
                    />
                    {highlightedFeatId == index || selectedAreasIds?.includes(index.toString()) ? (
                      <Layer
                        id={`fill${feature.id}`}
                        type={'fill'}
                        source={`area${feature.id}`}
                        paint={{
                          'fill-color': colors.blue[500],
                          'fill-opacity': 0.3
                        }}
                      />
                    ) : (
                      <Layer
                        id={`fill${feature.id}`}
                        type={'fill'}
                        source={`area${feature.id}`}
                        paint={{
                          'fill-color': colors.blue[500],
                          'fill-opacity': 0.05
                        }}
                      />
                    )}
                  </Source>
                )
              return <></>
            })
          ) : (
            <></>
          )
        }, [geoJson, highlightedFeatId, selectedAreasIds, editingIndex])}
        {intersections.features.length && intersectionsVisibility && (
          <Source
            id={'intersections'}
            type="geojson"
            // @ts-ignore
            data={intersections}>
            <Layer
              id={'layer-intersectins'}
              type={'line'}
              source={'intersections'}
              paint={{
                'line-color': colors.red[500],
                'line-width': 2
              }}
            />
            <Layer
              id={'fill-intersectins'}
              type={'fill'}
              source={'intersections'}
              paint={{
                'fill-color': colors.red[500],
                'fill-opacity': 0.7
              }}
            />
          </Source>
        )}
        {propertyBoundary && propertyBoundary.coordinates.length > 0 && (
          <Source
            id={'propertyBoundary'}
            type="geojson"
            // @ts-ignore
            data={propertyBoundary as turf.Geometry}>
            <Layer
              id={'layer-propertyBoundary'}
              type={'line'}
              source={'propertyBoundary'}
              paint={{
                'line-color': 'white',
                'line-width': 2
              }}
            />
          </Source>
        )}
      </>
    </EditAreasMap>
  )
}
