import { FlexBox } from '@components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField
} from '@mui/material'
import { useState } from 'react'

export interface IRejectionDialogProps extends DialogProps {
  handleConfirm: (reason: string) => void
  handleCancel: () => void
  hasRejectionReasonInput?: boolean
}

export const RejectionDialog = (props: IRejectionDialogProps) => {
  const { handleCancel, handleConfirm, hasRejectionReasonInput = true, ...restProps } = props

  const [rejectionReason, setRejectionReason] = useState('')

  return (
    <Dialog onClose={handleCancel} {...restProps}>
      <DialogTitle>Rejeitar Tarefa</DialogTitle>
      {hasRejectionReasonInput && (
        <DialogContent>
          <FlexBox sx={{ p: '1rem', minWidth: '768px' }}>
            <TextField
              onChange={event => setRejectionReason(event.target.value)}
              label="Motivo da rejeição"
              variant="standard"
              sx={{ width: '100%' }}
            />
          </FlexBox>
        </DialogContent>
      )}
      <DialogActions
        sx={{ justifyContent: 'center', flexWrap: 'wrap', padding: '1rem' }}
        disableSpacing={true}>
        <Button onClick={handleCancel} variant="discreet" sx={{ minWidth: '90px' }}>
          Cancelar
        </Button>
        <Button
          onClick={() => handleConfirm(rejectionReason)}
          variant="discreet"
          color="error"
          sx={{ minWidth: '90px' }}>
          Rejeitar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
