import { CreateFGTSDebtDTO } from '@/services/Hub/AssessmentsLabor'
import { FGTS_CREATION_TYPE, LABOR_DEBTS_STATUS } from '@/services/Hub/enums/LaborDebts.enum'

import { IRecordCandidate } from '../../providers/ComplianceContext'

interface IBuildCreateFGTSDebtDTO {
  record: IRecordCandidate
  documentUrl?: string
  canonicalProfileId: string
}

export const buildCreateFGTSDebtDTO = (props: IBuildCreateFGTSDebtDTO): CreateFGTSDebtDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  const expirationDate = record.documentExpirationDate

  return {
    canonicalProfileId,
    creationType: FGTS_CREATION_TYPE.USER_INPUT,
    expirationDate,
    referenceDate: new Date(),
    status: record.documentStatus as LABOR_DEBTS_STATUS,
    documentUrl
  }
}
