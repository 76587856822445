import { TBenderInputs } from '@/services/Bender/Task/contract/response/BenderSearchTasks.response'
import { BenderApi } from '@services'
import { useEffect, useState } from 'react'

export const useBenderInputCompliance = (taskId?: string) => {
  const [isRequesting, setIsRequesting] = useState(true)
  const [response, setResponse] = useState<TBenderInputs['ComplianceReview']>()
  const [error, setError] = useState<unknown>()

  const bootstrap = async () => {
    try {
      const { tasks } = await BenderApi.task.search<TBenderInputs['ComplianceReview']>({
        filter: { id: taskId },
        fields: ['input']
      })
      setResponse(tasks[0].input)
      setError(null)
    } catch (err) {
      setError(err)
    } finally {
      setIsRequesting(false)
    }
  }

  useEffect(() => {
    bootstrap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isRequesting, inputCompliance: response, error }
}
