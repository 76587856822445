import { AwsApi, HubApi } from '@services'

import { IRecordCandidate } from '../../providers/ComplianceContext'
import { buildCreateFGTSDebtDTO } from '../helpers/buildCreateFGTSDebtDTO'
import { buildCreateLaborDebtDTO } from '../helpers/buildCreateLaborDebtDTO'
import { buildGetUploadPresignedUrlDTO } from '../helpers/buildGetUploadPresignedUrlDTO'

interface IPostLaborDebtApi {
  canonicalProfileId: string
  personProfileId: string
  record: IRecordCandidate
}

export const postLaborRecordApi = async (props: IPostLaborDebtApi) => {
  const { canonicalProfileId, personProfileId, record } = props

  const presignedUrl = await HubApi.Assessments.getPostPresignedS3Url(
    buildGetUploadPresignedUrlDTO({ personProfileId, record })
  )
  await AwsApi.S3.uploadFileWithPresignedUrl(presignedUrl, record.file)

  if (record.recordType == 'FGTS') {
    const createFGTSDebtDTO = buildCreateFGTSDebtDTO({
      canonicalProfileId,
      record,
      documentUrl: presignedUrl?.expectedDocumentUrlPath
    })
    return HubApi.AssessmentsLabor.postFGTSDebtRequest(createFGTSDebtDTO)
  }

  if (record.recordType == 'CNDT') {
    const createLaborDebtDTO = buildCreateLaborDebtDTO({
      canonicalProfileId,
      record,
      documentUrl: presignedUrl?.expectedDocumentUrlPath
    })
    return HubApi.AssessmentsLabor.postLaborDebtRequest(createLaborDebtDTO)
  }

  throw new Error(`Unknow labor record subtype: ${record.recordType}`)
}
