import { ArrowRightAlt } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Select, Table, TableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { DefaultOptionType } from 'antd/es/select'
import React, { Dispatch, SetStateAction } from 'react'

type GroupRelation = {
  originLabel: string
  originId: string
  destinyId: string | null
}

type ConditionsTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<ConditionsTableProps['columns'], undefined>

interface IGroupsRelationTable extends TableProps {
  destinyGroups: DefaultOptionType[]
  setGroupsRelation: Dispatch<SetStateAction<GroupRelation[]>>
  groupsRelation: GroupRelation[]
}

export const GroupsRelationTable: React.FC<IGroupsRelationTable> = ({
  destinyGroups,
  setGroupsRelation,
  groupsRelation
}) => {
  const handleChange = (value: string | boolean, row: AnyObject, dataIndex: string) => {
    const newRow = { ...groupsRelation[row.key], ...{ [dataIndex]: value } }
    const newData = [...groupsRelation]
    const item = newData[row.key]
    newData.splice(row.key, 1, {
      ...item,
      ...newRow
    })

    setGroupsRelation(newData)
  }

  const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Origem',
      dataIndex: 'originLabel',
      key: 'originLabel',
      width: '45%'
    },
    {
      dataIndex: 'operation',
      render: () => <ArrowRightAlt />,
      width: '10%'
    },
    {
      title: 'Destino',
      dataIndex: 'destinyId',
      render: (label: string, record: AnyObject) => (
        <Select
          showSearch
          optionFilterProp="label"
          value={label}
          onChange={value => handleChange(value, record, 'destinyId')}
          options={destinyGroups}
          style={{ width: '100%' }}
        />
      ),
      width: '45%'
    }
  ]

  const groupsRelationDataSource = []
  for (let i = 0; i < groupsRelation.length; i += 1) {
    groupsRelationDataSource.push({ ...groupsRelation[i], key: i })
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          width: '100%',
          padding: '16px 0px'
        }}>
        <Table
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={groupsRelationDataSource}
          columns={columns}
          size={'small'}
          style={{ width: '100%' }}
          scroll={{ y: (window.innerHeight - 396) / 2 }}
          pagination={false}
        />
      </Box>
    </>
  )
}
