import { Close, KeyboardArrowUp, KeyboardArrowDown, Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import {
  THubFormFieldDefinition,
  THubFormConditionsDefinition,
  ConditionExpression
} from '@services'
import { Button, Modal, Table, TableProps, Typography } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
const { Title } = Typography

import { EditableCell, EditableRow, HorizontalBox, VerticalBox } from '../../../components'
import { toCamelCase } from '../../../utils/functions.utils'

type SelectOption = {
  label: string
  value: string
}

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface ITableFields extends TableProps {
  selectOptions: SelectOption[]
  setSelectOptions: Dispatch<SetStateAction<SelectOption[]>>
}

const EditableTable: React.FC<ITableFields> = ({ selectOptions, setSelectOptions }) => {
  const handleDelete = (row: AnyObject) => {
    const newData: SelectOption[] = []
    for (let i = 0; i < selectOptions.length; i += 1) {
      if (i != row.key) newData.push(selectOptions[i])
    }
    setSelectOptions(newData)
  }

  const handleMoveUp = (row: AnyObject) => {
    if (row.key > 0) {
      const newData: SelectOption[] = [...selectOptions]
      ;[newData[row.key - 1], newData[row.key]] = [newData[row.key], newData[row.key - 1]]
      setSelectOptions(newData)
    }
  }

  const handleMoveDown = (row: AnyObject) => {
    if (row.key < selectOptions.length - 1) {
      const newData: SelectOption[] = [...selectOptions]
      ;[newData[row.key], newData[row.key + 1]] = [newData[row.key + 1], newData[row.key]]
      setSelectOptions(newData)
    }
  }

  const handleAdd = () => {
    const newData: SelectOption = {
      label: '',
      value: ''
    }
    setSelectOptions([...selectOptions, newData])
  }

  const handleSave = (row: SelectOption, index: number) => {
    const newData = [...selectOptions]
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    newData[index].value = toCamelCase(newData[index].label)
    setSelectOptions(newData)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      editable: true,
      width: '40%'
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      editable: true,
      width: '40%'
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: string, record: AnyObject) =>
        selectOptions.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleMoveUp(record)}>
              <KeyboardArrowUp />
            </Button>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleMoveDown(record)}>
              <KeyboardArrowDown />
            </Button>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleDelete(record)}>
              <Close style={{ color: 'red' }} />
            </Button>
          </div>
        ) : null,
      width: '20%'
    }
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: AnyObject) => ({
        record,
        editable: col.dataIndex == 'label',
        dataIndex: col.dataIndex,
        handleSave,
        records: selectOptions,
        warning: false
      })
    }
  })

  const selectDataSource = []
  for (let i = 0; i < selectOptions.length; i += 1) {
    selectDataSource.push({ ...selectOptions[i], key: i })
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        height: window.innerHeight - 282,
        paddingBottom: '16px',
        paddingTop: '16px'
      }}>
      <HorizontalBox>
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          <Add />
        </Button>
      </HorizontalBox>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={selectDataSource}
        columns={columns as ColumnTypes}
        size={'small'}
        style={{ width: '100%', marginBottom: '16px' }}
        pagination={false}
        scroll={{ y: window.innerHeight - 385 }}
      />
    </Box>
  )
}

type TSelectComponentsPage = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setFormFields: Dispatch<SetStateAction<THubFormFieldDefinition[]>>
  formFields: THubFormFieldDefinition[]
  record: AnyObject | null
  formConditions: THubFormConditionsDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
}

export const SelectComponentsModal: React.FC<TSelectComponentsPage> = ({
  setIsModalOpen,
  isModalOpen,
  setFormFields,
  formFields,
  record,
  formConditions,
  setFormConditions
}) => {
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])
  useEffect(() => {
    setSelectOptions(record?.components ? record?.components?.nodes : [])
  }, [isModalOpen])

  const handleOk = () => {
    if (!!record && record.key >= 0) {
      const selectValues: string[] = selectOptions.map(option => option.value)
      const conditions: THubFormConditionsDefinition[] = []

      let toAtt = false
      for (let i = 0; i < formConditions?.length; i += 1) {
        const expressions: ConditionExpression[] = []

        for (let j = 0; j < formConditions[i]?.expressions?.length; j += 1) {
          if (
            formConditions[i]?.expressions[j]?.fieldIdentifier !=
              formFields[record.key]?.identifier ||
            selectValues.includes(String(formConditions[i]?.expressions[j]?.value))
          )
            expressions.push(formConditions[i]?.expressions[j])
          else {
            expressions.push({
              ...formConditions[i]?.expressions[j],
              ...{ value: '' }
            })
            toAtt = true
          }
        }
        conditions.push({
          name: formConditions[i]?.name,
          actions: formConditions[i]?.actions,
          expressions: expressions
        })
      }
      if (toAtt) setFormConditions(conditions)

      const newData: THubFormFieldDefinition[] = [...formFields]
      newData[record.key].components = { nodes: selectOptions }
      newData[record.key].source = 'MANUAL'
      setFormFields(newData)
    }
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      width={window.innerWidth - 32 > 1000 ? 1000 : window.innerWidth - 32}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={'Cancelar'}>
      <VerticalBox width="100%">
        <Title level={3} style={{ color: '#212121' }}>
          {record?.label}
        </Title>
        <EditableTable selectOptions={selectOptions} setSelectOptions={setSelectOptions} />
      </VerticalBox>
    </Modal>
  )
}
