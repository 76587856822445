import { SubjectActionDTO } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { Add, ExpandMore } from '@mui/icons-material'
import { Box, Collapse, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Children, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { TArrayHelpers } from '../AuthorizationForm.interface'

interface IResolveSubjectAction {
  arrayHelpers: TArrayHelpers
  subjectAction: SubjectActionDTO
  children?: React.ReactNode
}

export const ResolveSubjectAction = (props: IResolveSubjectAction) => {
  const { arrayHelpers, subjectAction, children } = props
  const [openCollapse, setOpenCollapse] = useState(true)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const numberOfChildren = children ? Children.count(children) : 0

  const handleAddPermission = (inverted?: boolean) => {
    if (inverted) {
      arrayHelpers.push({
        id: uuidv4(),
        action: subjectAction.actionKey,
        subject: subjectAction.subjectKey,
        inverted: true
      })
    } else {
      arrayHelpers.unshift({
        id: uuidv4(),
        action: subjectAction.actionKey,
        subject: subjectAction.subjectKey
      })
    }
    setOpenCollapse(true)
  }

  return (
    <Box
      className="subject-action-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        widht: '100%',
        padding: '10px 0 0',
        marginTop: '10px',

        background: '#fff',
        borderTop: '1px solid black'
      }}>
      <Box
        className="label-buttons-container"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px'
        }}>
        <>
          <IconButton onClick={e => setMenuAnchorEl(e.currentTarget)}>
            <Add color="primary" />
          </IconButton>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => {
              setMenuAnchorEl(null)
            }}>
            <MenuItem
              onClick={() => {
                handleAddPermission()
                setMenuAnchorEl(null)
              }}>
              Permitir
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAddPermission(true)
                setMenuAnchorEl(null)
              }}>
              Bloquear
            </MenuItem>
          </Menu>
        </>

        <Box className="label">
          <Typography variant="body1">
            {subjectAction.label}
            {numberOfChildren > 0 && (
              <span
                style={{
                  marginLeft: '5px',
                  fontSize: '0.8rem',
                  opacity: '0.9',
                  color: '#4989f6',
                  fontWeight: 500
                }}>
                ({numberOfChildren} regras)
              </span>
            )}
          </Typography>
          <Typography variant="caption">{subjectAction.description}</Typography>
        </Box>
        {numberOfChildren > 0 && (
          <IconButton
            onClick={() => {
              setOpenCollapse(!openCollapse)
            }}>
            <ExpandMore
              sx={{
                transform: !openCollapse ? 'rotate(0deg)' : 'rotate(180deg)'
              }}
              color="primary"
            />
          </IconButton>
        )}
      </Box>
      {children && (
        <Collapse
          in={openCollapse}
          timeout="auto"
          className="rules-collapse"
          sx={{
            width: '100%',

            '& .MuiCollapse-wrapperInner': {
              padding: '10px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '10px'
            }
          }}>
          {children}
        </Collapse>
      )}
    </Box>
  )
}
