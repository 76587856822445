import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './redux/store'
import { BenderRoutes } from './routes'

Sentry.init({
  dsn: 'https://04c7aa7cdc11452793a9a987dca1b85f@o740682.ingest.sentry.io/4504634667630592',
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  beforeSend: event => {
    if (window.location.hostname === 'localhost') return null
    return event
  }
})

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} />
      <BrowserRouter>
        <BenderRoutes />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
