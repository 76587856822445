import { Customer } from '@/services/Hub/Customer'
import { useCallback, useEffect, useState } from 'react'

export const useGetCustomer = ({
  personProfileId,
  organizationId
}: {
  personProfileId: string | undefined
  organizationId?: string
}) => {
  const [customerId, setCustomerId] = useState<string | null>(null)

  const fetchCustomer = useCallback(async () => {
    if (!personProfileId || !organizationId) return

    try {
      const { results } = await Customer.getCustomers({ personProfileId, organizationId })

      if (results && results.length > 0) setCustomerId(results[0].id)
    } catch (error) {
      console.error(error)
      setCustomerId(null)
    }
  }, [organizationId, personProfileId])

  useEffect(() => {
    fetchCustomer()
  }, [fetchCustomer])

  return { customerId }
}
