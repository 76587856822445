import CheckIcon from '@mui/icons-material/Check'
import { Box, ToggleButton, Typography } from '@mui/material'
import { useState } from 'react'

interface IUUIDToggle {
  handleOptions: () => void
}

export const UUIDToggle = ({ handleOptions }: IUUIDToggle) => {
  const [selected, setSelected] = useState(false)

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        verticalAlign: 'middle',
        width: '100%',
        maxWidth: '90vw',
        flexWrap: 'wrap',
        gap: '1rem',
        pb: '0rem',
        pl: '1.5rem'
      }}>
      <Typography variant="subtitle1">Mostrar UUID</Typography>
      <ToggleButton
        size="small"
        value="check"
        selected={selected}
        onChange={() => {
          setSelected(!selected)
          handleOptions()
        }}>
        <CheckIcon />
      </ToggleButton>
    </Box>
  )
}
