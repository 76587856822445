import { useCallback, useEffect, useState } from 'react'

import { SerasaService } from '../../services/Serasa'
import { CheckSalesDTO } from '../../services/Serasa/SerasaDTO'
import { SerasaView } from './SerasaView'

export const SerasaContainer = () => {
  const [govRegCode, setGovRegCode] = useState<string | null>(null)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const [checkSalesInfo, setCheckSalesInfo] = useState<CheckSalesDTO>()
  const [token, setToken] = useState<string>()

  const getToken = useCallback(async () => {
    const data = await SerasaService.login()
    setToken(data.accessToken)
  }, [])

  const checkSales = useCallback(async () => {
    if (!govRegCode || !token) return
    setIsChecking(true)
    try {
      const info = await SerasaService.checkSales(govRegCode, token)
      setCheckSalesInfo(info)
    } catch (err) {
      console.error(err)
    } finally {
      setIsChecking(false)
    }
  }, [govRegCode, token])

  useEffect(() => {
    getToken()
  }, [getToken])

  return (
    <SerasaView
      checkSalesInfo={checkSalesInfo}
      onHandleFetchInfo={checkSales}
      setGovRegCode={setGovRegCode}
      govRegCode={govRegCode}
      isChecking={isChecking}
    />
  )
}
