/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as turf from '@turf/turf'
import React, { useState, useEffect } from 'react'
import WebMercatorViewport from 'viewport-mercator-project'

import { AreasListView } from './AreasListView'

interface IAreasList {
  geoJson: any
  setGeoJson: React.Dispatch<React.SetStateAction<any>>
  highlightedFeatId: string | null
  setHighlitedFeatId: React.Dispatch<React.SetStateAction<string | null>>
  editingIndex: number | null
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  saveEditingArea: () => void
  cancelEditing: () => void
  removeArea: (index: number) => void
  isFeatureEditable: (feature: turf.Feature) => boolean
  multipleSelectionMode: boolean
}

export default function AreasList({
  geoJson,
  setGeoJson,
  highlightedFeatId,
  setHighlitedFeatId,
  editingIndex,
  setEditingIndex,
  saveEditingArea,
  cancelEditing,
  removeArea,
  isFeatureEditable,
  multipleSelectionMode
}: IAreasList) {
  const handleListItemClick = (id: string) => {
    setHighlitedFeatId(id)
  }

  const selectedRef = React.useRef<HTMLElement>(null)
  const [changedColor, setChangedColor] = useState(false)

  useEffect(() => {
    if (selectedRef.current)
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
  }, [highlightedFeatId])

  useEffect(() => {
    if (editingIndex !== null) setChangedColor(false)
  }, [editingIndex])

  const handleSwitchChange = (index: number) => {
    setChangedColor(e => !e)
    const tempGeoJsonFeatures = geoJson.features
    const isProductive = tempGeoJsonFeatures[index].properties.productive === 1
    tempGeoJsonFeatures[index].properties.productive = isProductive ? 0 : 1
    setGeoJson({
      ...geoJson,
      features: tempGeoJsonFeatures
    })
  }

  const getImage = (geometry: turf.Geometry): string => {
    const MAPBOX_TOKEN =
      'pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg'
    const geoJson = {
      type: 'Feature',
      properties: {
        stroke: 'white',
        'stroke-width': 6,
        fill: '#3E5CD1',
        'fill-opacity': 0.7
      },
      geometry: geometry
    }

    const cornersLongLat = turf.bbox(geometry)
    const viewport = new WebMercatorViewport({
      width: 300,
      height: 300
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 40 }
    )
    const { longitude, latitude, zoom } = viewport

    return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURIComponent(
      JSON.stringify(geoJson)
    )})/${longitude},${latitude},${zoom}/300x300?access_token=${MAPBOX_TOKEN}`
  }

  return (
    <AreasListView
      cancelEditing={() => {
        if (changedColor && editingIndex) handleSwitchChange(editingIndex)
        cancelEditing()
      }}
      editingIndex={editingIndex}
      geoJson={geoJson}
      handleListItemClick={handleListItemClick}
      handleSwitchChange={handleSwitchChange}
      highlightedFeatId={highlightedFeatId}
      saveEditingArea={saveEditingArea}
      selectedRef={selectedRef}
      setEditingIndex={setEditingIndex}
      removeArea={removeArea}
      getImage={getImage}
      isFeatureEditable={isFeatureEditable}
      multipleSelectionMode={multipleSelectionMode}
    />
  )
}
