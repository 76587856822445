import { TData } from '@/pages/authorization/utils/authorization.utils'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { Paper } from '@mui/material'
import { MembershipDTO } from '@services'

import { MemberInformation } from '../../../../../components/MemberInformation'
import { ManageMembershipRoles } from './ManageMembershipRoles'

interface IMembershipRoleCard {
  membership: MembershipDTO
  organizationRoles: TData<RoleDTO[]>
}

export const MembershipRoleCard = (props: IMembershipRoleCard) => {
  const { membership, organizationRoles } = props
  return (
    <Paper
      elevation={2}
      sx={{
        padding: '10px',
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px'
      }}>
      <MemberInformation.root>
        <MemberInformation.avatar user={membership.user} />
        <MemberInformation.information member={membership}>
          <MemberInformation.roles member={membership} />
        </MemberInformation.information>
      </MemberInformation.root>
      <ManageMembershipRoles membershipId={membership.id} organizationRoles={organizationRoles} />
    </Paper>
  )
}
