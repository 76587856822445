import { ImportExport, Refresh, Badge } from '@mui/icons-material'
import { Box, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material'
import { ShortOrganizationDTO } from '@services'
import { useEffect, useState } from 'react'

interface IMenuOrganizationOption {
  fetchMembers: () => void
  updateOrganizationRoles: () => void
  isRolesLoading: boolean
  isMembersLoading: boolean
  selectedOrganization: ShortOrganizationDTO
  children: React.ReactElement
}

export const MenuOrganizationOptions = (props: IMenuOrganizationOption) => {
  const {
    fetchMembers,
    isMembersLoading,
    selectedOrganization,
    children,
    updateOrganizationRoles,
    isRolesLoading
  } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [selectedOrganization?.id])

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        {children}
      </Modal>
      <Box
        className="label-button-container"
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          margin: '8px 0',

          '& .button-container': {
            display: 'none'
          },

          '&:hover': {
            '& .button-container': {
              display: 'flex'
            }
          }
        }}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: '600'
          }}>
          Organização
        </Typography>
        <Box
          className="label-container"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            background: '#fff',

            border: '1px solid black',
            padding: '4px',
            borderRadius: '4px 4px 4px 0'
          }}>
          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',

              fontWeight: '600',
              fontSize: '18px'
            }}>
            {selectedOrganization.fantasyName}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: '#474747',
              fontWeight: '500',
              fontSize: '10px'
            }}>
            {selectedOrganization.id}
          </Typography>
        </Box>
        <Paper
          elevation={4}
          className="button-container"
          sx={{
            position: 'absolute',
            top: '100%',

            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: '#fff',

            border: '1px solid black',
            borderTop: 'none',
            padding: '4px',
            borderRadius: '0 0 4px 4px',

            '& .MuiIconButton-root:hover': {
              background: '#fad228'
            }
          }}>
          <Tooltip title="Trocar Organização" arrow disableInteractive>
            <IconButton className="card-button" onClick={() => setOpen(true)}>
              <ImportExport sx={{ transform: 'rotate(90deg)' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Atualizar Membros" arrow disableInteractive>
            <IconButton className="card-button" onClick={fetchMembers} disabled={isMembersLoading}>
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title="Atualizar Cargos" arrow disableInteractive>
            <IconButton
              className="card-button"
              onClick={updateOrganizationRoles}
              disabled={isRolesLoading}>
              <Badge />
            </IconButton>
          </Tooltip>
        </Paper>
      </Box>
    </>
  )
}
