import { Close, List } from '@mui/icons-material'
import { THubFormFieldDefinition, THubFormConditionsDefinition } from '@services'
import { Button, Table, TableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useState, Dispatch, SetStateAction } from 'react'

import { EditableCell, EditableRow } from '../../../components'
import { EditConditionModal } from '../modal'

type ConditionsTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<ConditionsTableProps['columns'], undefined>

interface IConditionsTable extends TableProps {
  formFields: THubFormFieldDefinition[]
  formConditions: THubFormConditionsDefinition[]
  setFormConditions: Dispatch<SetStateAction<THubFormConditionsDefinition[]>>
}

export const ConditionsTable: React.FC<IConditionsTable> = ({
  formFields,
  formConditions,
  setFormConditions
}) => {
  const handleDelete = (row: AnyObject) => {
    const newData: THubFormConditionsDefinition[] = []
    for (let i = 0; i < formConditions.length; i += 1) {
      if (i != row.key) newData.push(formConditions[i])
    }
    setFormConditions(newData)
  }

  const handleSave = (row: THubFormConditionsDefinition, index: number, dataIndex: string) => {
    const newData: THubFormConditionsDefinition[] = []

    for (let i = 0; i < formConditions.length; i += 1) {
      if (i == index) {
        const item = { ...formConditions[i], ...row }
        newData.push(item)
      } else {
        newData.push(formConditions[i])
      }
    }
    setFormConditions(newData)
  }

  const handleSelect = (row: AnyObject) => {
    setSelectedCondition(row)
    setIsEditConditionOpen(true)
  }

  const [isEditConditionOpen, setIsEditConditionOpen] = useState(false)
  const [selectedCondition, setSelectedCondition] = useState<AnyObject | null>(null)

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '90%'
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: string, record: AnyObject) =>
        formConditions.length >= 1 ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleSelect(record)}>
              <List />
            </Button>
            <Button type="text" style={{ padding: '4px' }} onClick={() => handleDelete(record)}>
              <Close style={{ color: 'red' }} />
            </Button>
          </div>
        ) : null,
      width: '10%'
    }
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: AnyObject) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
        records: formConditions,
        warning: false
      })
    }
  })

  const formConditionsDataSource = []
  for (let i = 0; i < formConditions.length; i += 1) {
    formConditionsDataSource.push({ ...formConditions[i], key: i })
  }

  return (
    <>
      <Table
        showHeader={false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={formConditionsDataSource}
        columns={columns as ColumnTypes}
        size={'small'}
        style={{ width: '100%', marginBottom: '16px' }}
        scroll={{ y: window.innerHeight - 144 }}
        pagination={false}
      />
      <EditConditionModal
        setIsModalOpen={setIsEditConditionOpen}
        isModalOpen={isEditConditionOpen}
        formFields={formFields}
        setFormConditions={setFormConditions}
        formConditions={formConditions}
        record={selectedCondition}
      />
    </>
  )
}
