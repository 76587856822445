import { stringToColor } from '@/components/LetterAvatar/LetterAvatarUtils'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { EllipsisText } from '@components'
import { Article, Delete, Edit, Group } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import { useState } from 'react'

interface IRoleCard {
  role: RoleDTO
  deleteRole: (roleId: string) => Promise<void>
  handleClickEditRole: (role: RoleDTO) => void
}

export const RoleCard = (props: IRoleCard) => {
  const { role, deleteRole, handleClickEditRole } = props
  const memberQuantity = role.membershipIds?.length || 0

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteRole = async () => {
    setIsDeleting(true)
    await deleteRole(role.id)
    setIsDeleting(false)
  }

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        width: '100%',

        background: isDeleting ? '#c73636' : '#fff',
        transition: 'all 2s ease-in'
      }}>
      <Box
        sx={{
          minWidth: 'fit-content',
          width: '25%',
          flexShrink: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          alignItems: 'center',
          gap: '8px'
        }}>
        <Tooltip placement="top" title="Nome do Cargo">
          <Box
            sx={{
              height: '12px',
              width: '12px',
              minWidth: '12px',
              borderRadius: '50%',
              backgroundColor: stringToColor(role.label)
            }}
          />
        </Tooltip>
        <EllipsisText text={role.label} customPlacement="top" maxLength={40} variant="body1" />

        <Typography
          variant="caption"
          sx={{
            color: '#3f6ad6',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '2px',

            '& svg': {
              height: '16px',
              width: '16px'
            }
          }}>
          ({memberQuantity}
          <Group />)
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          alignItems: 'center',
          gap: '8px'
        }}>
        <Tooltip placement="top" title="Descrição do cargo">
          <Article color="primary" />
        </Tooltip>
        <Typography variant="body2">{role.description || '-'}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        sx={{
          width: 'fit-content',
          maxWidth: 'fit-content',
          minWidth: 'fit-content',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          alignItems: 'center',
          gap: '8px'
        }}>
        <IconButton
          size="small"
          onClick={() => handleDeleteRole()}
          disabled={isDeleting}
          sx={{
            color: '#db4f42',
            '& svg': {
              height: '20px',
              width: '20px'
            }
          }}>
          {isDeleting ? <CircularProgress size={20} color="error" /> : <Delete />}
        </IconButton>
        <IconButton
          size="small"
          disabled={isDeleting}
          onClick={() => handleClickEditRole(role)}
          sx={{
            '& svg': {
              height: '20px',
              width: '20px'
            }
          }}>
          <Edit />
        </IconButton>
      </Box>
    </Paper>
  )
}
