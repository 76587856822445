export const inspectorOptions = [
  {
    identifier: 'customerAge',
    type: 'numeric',
    description: 'Idade do cliente'
  },
  {
    identifier: 'storageCapacity',
    type: 'numeric',
    description: 'Capacidade de Armazenamento'
  },
  {
    identifier: 'liquidezGeral',
    type: 'numeric',
    description: 'Liquidez Geral'
  },
  {
    identifier: 'liquidezCorrente',
    type: 'numeric',
    description: 'Liquidez Corrente'
  },
  {
    identifier: 'liquidezSeca',
    type: 'numeric',
    description: 'Liquidez Seca'
  },
  {
    identifier: 'liquidezImediata',
    type: 'numeric',
    description: 'Liquidez Imediata'
  },
  {
    identifier: 'margemBruta',
    type: 'numeric',
    description: 'Margem Bruta'
  },
  {
    identifier: 'margemEBITDA',
    type: 'numeric',
    description: 'Margem EBITDA'
  },
  {
    identifier: 'margemEBIT',
    type: 'numeric',
    description: 'Margem EBIT'
  },
  {
    identifier: 'margemLiquida',
    type: 'numeric',
    description: 'Margem Liquida'
  },
  {
    identifier: 'ipl',
    type: 'numeric',
    description: 'IPL'
  },
  {
    identifier: 'ieg',
    type: 'numeric',
    description: 'IEG'
  },
  {
    identifier: 'ce',
    type: 'numeric',
    description: 'CE'
  },
  {
    identifier: 'ie',
    type: 'numeric',
    description: 'IE'
  },
  {
    identifier: 'ipct',
    type: 'numeric',
    description: 'IPCT'
  },
  {
    identifier: 'iarp',
    type: 'numeric',
    description: 'IARP'
  },
  {
    identifier: 'gaf',
    type: 'numeric',
    description: 'GAF'
  },
  {
    identifier: 'dividaLiquidaPL',
    type: 'numeric',
    description: 'Dívida Líquida / PL'
  },
  {
    identifier: 'dividaLiquidaEBITDA',
    type: 'numeric',
    description: 'Dívida Líquida / EBITDA'
  },
  {
    identifier: 'roa',
    type: 'numeric',
    description: 'ROA'
  },
  {
    identifier: 'roe',
    type: 'numeric',
    description: 'ROE'
  },
  {
    identifier: 'doRL',
    type: 'numeric',
    description: 'DO/RL'
  },
  {
    identifier: 'ebitdaDespesasFinanceiras',
    type: 'numeric',
    description: 'EBITDA/Despesas Financeiras'
  },
  {
    identifier: 'cicloFinanceiro',
    type: 'numeric',
    description: 'Ciclo Financeiro'
  },
  {
    identifier: 'cicloOperacional',
    type: 'numeric',
    description: 'Ciclo Operacional'
  },
  {
    identifier: 'pmr',
    type: 'numeric',
    description: 'PMR'
  },
  {
    identifier: 'pme',
    type: 'numeric',
    description: 'PME'
  },
  {
    identifier: 'pmp',
    type: 'numeric',
    description: 'PMP'
  },
  {
    identifier: 'totalAssetsIrpf',
    type: 'numeric',
    description: 'Patrimônio total IRPF'
  },
  {
    identifier: 'totalAgriculturalAssetsIrpf',
    type: 'numeric',
    description: 'Patrimônio atividade agrária'
  },
  {
    identifier: 'totalAgriculturalRevenueIrpf',
    type: 'numeric',
    description: 'Receita atividade agrária'
  },
  {
    identifier: 'shortTermDebtsSisbacen',
    type: 'numeric',
    description: 'Sisbacen Vencimentos até 12 meses'
  },
  {
    identifier: 'longTermDebtsSisbacen',
    type: 'numeric',
    description: 'Sisbacen Vencimentos após 12 meses'
  },
  {
    identifier: 'otherCreditsSisbacen',
    type: 'numeric',
    description: 'Sisbacen outros créditos (limite e coobrigações)'
  },
  {
    identifier: 'shortTermCPRValue',
    type: 'numeric',
    description: 'Valor CPR´s a vencer nos próximos 12 meses'
  },
  {
    identifier: 'longTermCPRValue',
    type: 'numeric',
    description: 'Valor CPR´s a vencer após 12 meses'
  },
  {
    identifier: 'totalDebtsIrpf',
    type: 'numeric',
    description: 'Valor total dívidas e ônus'
  },
  {
    identifier: 'totalAgriculturalDebtsIrpf',
    type: 'numeric',
    description: 'Valor total dívidas agrícolas'
  },
  {
    identifier: 'clayedSoilPercentage',
    type: 'numeric',
    description: 'Porcentagem de solo argiloso'
  },
  {
    identifier: 'branchesQuantity',
    type: 'numeric',
    description: 'Quantidade de filais'
  },
  {
    identifier: 'totalStorageCapacity',
    type: 'numeric',
    description: 'Capacidade de armazenagem total'
  },
  {
    identifier: 'productiveStorageCapacity',
    type: 'numeric',
    description: 'Capacidade de armazenagem produtiva'
  },
  {
    identifier: 'storageTonneCapacity',
    type: 'numeric',
    description: 'Capacidade de armazenagem'
  },
  {
    identifier: 'pepSituation',
    type: 'text',
    description: 'Pessoa exposta politicamente'
  },
  {
    identifier: 'laborSituation',
    type: 'text',
    description: 'Status de compliance trabalhista'
  },
  {
    identifier: 'fiscalSituation',
    type: 'text',
    description: 'Status de compliance fiscal'
  },
  {
    identifier: 'judiciarySituation',
    type: 'text',
    description: 'Status de compliance criminal'
  },
  {
    identifier: 'environmentalSituation',
    type: 'text',
    description: 'Status de compliance ambiental'
  },
  {
    identifier: 'complianceSituation',
    type: 'text',
    description: 'Resumo do compliance'
  },
  {
    identifier: 'bureauDebts',
    type: 'numeric',
    description: 'Valor total de inadimplências'
  },
  {
    identifier: 'bureauScore',
    type: 'numeric',
    description: 'Score do bureau'
  },
  {
    identifier: 'emailAddress',
    type: 'text',
    description: 'Email do cliente'
  },
  {
    identifier: 'fullAddress',
    type: 'text',
    description: 'Endereço completo do cliente'
  },
  {
    identifier: 'stateLongName',
    type: 'text',
    description: 'Estado do cliente - Nome Completo'
  },
  {
    identifier: 'city',
    type: 'text',
    description: 'Cidade do cliente'
  },
  {
    identifier: 'neighborhood',
    type: 'text',
    description: 'Bairro do cliente'
  },
  {
    identifier: 'streetType',
    type: 'text',
    description: 'Tipo do Logradouro do cliente'
  },
  {
    identifier: 'streetName',
    type: 'text',
    description: 'Nome da Rua do cliente'
  },
  {
    identifier: 'streetNumber',
    type: 'text',
    description: 'Número da residencia do cliente'
  },
  {
    identifier: 'postalCode',
    type: 'text',
    description: 'CEP do cliente'
  },
  {
    identifier: 'stateShortName',
    type: 'text',
    description: 'Estado do cliente - Iniciais em letra minúscula'
  },
  {
    identifier: 'stateShortNameUpperCase',
    type: 'text',
    description: 'Estado do cliente - Iniciais em letra maiúscula'
  },
  {
    identifier: 'sintegraPeriod',
    type: 'numeric',
    description: 'Maior período registrado no Sintegra'
  },
  {
    identifier: 'overdueInvoiceSisbacen',
    type: 'numeric',
    description: 'Valor da Carteira Vencida do Sibacen'
  },
  {
    identifier: 'expiredCPRValue',
    type: 'numeric',
    description: 'Valor total de CPRs expiradas'
  },
  {
    identifier: 'notaryIssuesValue',
    type: 'numeric',
    description: 'Valor total de protestos'
  },
  {
    identifier: 'notaryIssuesQuantity',
    type: 'numeric',
    description: 'Quantidade de protestos'
  },
  {
    identifier: 'notaryProtestQuantity',
    type: 'numeric',
    description: 'Quantidade de protestos CENPROT'
  },
  {
    identifier: 'notaryProtestValue',
    type: 'numeric',
    description: 'Valor total de protestos CENPROT'
  },
  {
    identifier: 'lawsuitsPassiveQuantity',
    type: 'numeric',
    description: 'Quantidade de processos em participação passiva'
  },
  {
    identifier: 'lawsuitsPassiveValue',
    type: 'numeric',
    description: 'Valor de processos em participação passiva'
  },
  {
    identifier: 'lawsuitsPassiveFilteredValue',
    type: 'numeric',
    description: 'Valor de processos em participação passiva filtrados'
  },
  {
    identifier: 'lawsuitsActiveQuantity',
    type: 'numeric',
    description: 'Quantidade de processos em participação ativa'
  },
  {
    identifier: 'lawsuitsActiveValue',
    type: 'numeric',
    description: 'Valor de processos em participação ativa'
  },
  {
    identifier: 'lawstuitsOthersQuantity',
    type: 'numeric',
    description: 'Quantidade de processos em outros tipos de participação'
  },
  {
    identifier: 'lawsuitsOthersValue',
    type: 'numeric',
    description: 'Valor de processos em outros tipos de participação'
  },
  {
    identifier: 'passiveCompliance',
    type: 'numeric',
    description: 'Status de compliance Passivos'
  },
  {
    identifier: 'moratoriumCompliance',
    type: 'numeric',
    description: 'Status de compliance PRODES EM BIOMA AMAZÔNICO'
  },
  {
    identifier: 'overlayCompliance',
    type: 'numeric',
    description: 'Status de compliance Sobreposições'
  },
  {
    identifier: 'deforestationCompliance',
    type: 'numeric',
    description: 'Status de compliance Desflorestamento'
  },
  {
    identifier: 'debtsCompliance',
    type: 'numeric',
    description: 'Status de compliance Débitos'
  },
  {
    identifier: 'embargoesCompliance',
    type: 'numeric',
    description: 'Status de compliance Embargos - IBAMA'
  }
]

export const sourceOptions = [
  'CUSTOMER_INSPECTOR_PROFILE',
  'CUSTOMER',
  'DOCUMENT_FILE',
  'DOCUMENT_FORMALIZATION_TICKET'
]

export const targetOptions = {
  Ticket: 'ticket',
  Customer: 'customer'
  // 'Document Register Request': 'documentRegisterRequest',
  // 'Clearing House Request': 'clearingHouseRequest',
  // 'Request Signature': 'requestSignature'
}
