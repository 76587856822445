import { Pagination } from '@mui/material'

export type TPaginationParams = {
  count: number
  page: number
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void
}

interface IBody {
  children: React.ReactNode
  paginationParams?: TPaginationParams
}

export const Body = (params: IBody) => {
  const { children, paginationParams } = params
  return (
    <>
      {children}
      {paginationParams && (
        <Pagination
          sx={{ alignSelf: 'center' }}
          showFirstButton
          showLastButton
          size="large"
          variant="outlined"
          count={paginationParams.count}
          page={paginationParams.page}
          onChange={paginationParams.onChange}
        />
      )}
    </>
  )
}
