import { Box, Input, Select, MenuItem, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'

import { TRequest } from '../../../../../services/RadarApi'

interface IFilter {
  rawData: TRequest[]
  setFilteredRequests: Dispatch<SetStateAction<TRequest[]>>
}

enum ORDER_BY {
  AREAS = 'AREAS',
  SEASONS = 'SEASONS'
}

export const Filter = ({ rawData, setFilteredRequests }: IFilter) => {
  const [orderBy, setOrderBy] = useState(ORDER_BY.AREAS as string)

  const filterByTerm = (term: string) => {
    setFilteredRequests(
      rawData.filter(request => {
        return request.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            term
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      })
    )
  }

  function compareByCurrentOrder(a: TRequest, b: TRequest) {
    switch (orderBy) {
      case ORDER_BY.AREAS:
        if (a.perc_seg_complete_task < b.perc_seg_complete_task) {
          return 1
        }
        if (a.perc_seg_complete_task > b.perc_seg_complete_task) {
          return -1
        }
        return 0
        break
      case ORDER_BY.SEASONS:
        if (a.perc_season_complete_task < b.perc_season_complete_task) {
          return 1
        }
        if (a.perc_season_complete_task > b.perc_season_complete_task) {
          return -1
        }
        return 0
        break
    }
    return 0
  }

  useEffect(() => {
    setFilteredRequests(e => [...e].sort(compareByCurrentOrder))
  }, [orderBy])

  return (
    <Box sx={{ display: 'flex' }}>
      <Input
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          gap: '2px',
          width: '275px',
          height: '43px',
          background: '#F8F9FA',
          border: '2px solid #DEE2E6',
          borderRadius: '8px',
          margin: 'auto 0px'
        }}
        placeholder="Filtrar por nome do pedido"
        disableUnderline
        // value={filterTerm}
        onChange={e => {
          if (e.target) filterByTerm(e.target.value)
        }}
      />
      {/* <Button sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '14px 18px',
          gap: '10px',
          width: '100px',
          height: '47px',
          borderRadius: '12px',
          fontFamily: 'Arial',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          color: '#212529',
        }}>
          FILTRAR
        </Button> */}
      <Box
        sx={{
          borderLeft: '1px solid #868E96',
          margin: 'auto 14px',
          padding: '0px',
          paddingLeft: '14px',
          display: 'flex',
          alignItems: 'center'
        }}>
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#212529',
            marginRight: '10px'
          }}>
          Ordenar por
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          value={orderBy}
          onChange={e => setOrderBy(e.target.value)}>
          <MenuItem value={ORDER_BY.AREAS}>Áreas validadas</MenuItem>
          <MenuItem value={ORDER_BY.SEASONS}>Safras validadas</MenuItem>
        </Select>
      </Box>
    </Box>
  )
}
