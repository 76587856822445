import { axiosGisMisc } from '@/services/config/axiosGis.config'
import * as turf from '@turf/turf'

const getCrops = async (request: turf.Feature<turf.Geometry, turf.Properties>) => {
  const { data } = await axiosGisMisc.post<Record<string, any>>(
    '/labels/label-from-geometry',
    request
  )
  return data
}

export const crops = {
  getCrops
}
