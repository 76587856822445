import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow
} from '@mui/material'

export type TRowRules = {
  [K: string]: {
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderComponent: (value: any) => JSX.Element
  }
}

export interface ITableProps extends TableContainerProps {
  rowRules: TRowRules
  rows: ({ _id: string } & Record<string, unknown>)[]
}

/**
 * @important
 *
 * The keys of rows and rowRules need to be the same. If you want to add entries in the rows that will not be rendered, you can use the _ prefix and don't need to replicate it in rowRules.
 *
 * - rowRules: The rowRules specify how the cell will be rendered.
 *
 * - rows: The values to be displayed.
 *
 *
 * @example
 *
 * const rowRules = [{name: {label: 'Nome', renderComponent: (value: string) => <span>{value}</span>}}]
 *
 * const rows = [{_id: 0, name: 'foo'}, {_id: 1, name: 'Tom'}, {_id: 2, name: 'Harry'}]
 */
export const Table = (props: ITableProps) => {
  const { rows, rowRules, ...restProps } = props

  return (
    <TableContainer component={Paper} {...restProps}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {Object.values(rowRules).map(({ label }) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row._id}>
              {Object.keys(row).map(currColumnKey => {
                if (currColumnKey.startsWith('_')) return

                return (
                  <TableCell key={currColumnKey}>
                    {rowRules[currColumnKey].renderComponent(row[currColumnKey])}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}
